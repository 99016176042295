import { PostConstruct } from "ag-grid-community";
import { Status } from "app/enums";
export const locale = {
  lang: 'en',
  data: {
    COMMON: {
      CONTROL_LABEL_EMAIL_ADDRESS: 'Email Address',
      PLACEHOLDER_EMAIL_ADDRESS: 'Type here...',
      PLACEHOLDER_TYPE_HERE: 'Type here...',
      PLACEHOLDER_MOBILE_NUMBER: '0123456789',
      BUTTON_TEXT_SAVE_CHANGES: 'Save Changes',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS: 'The email is required!',
      ERROR_MESSAGE_INVALID_EMAIL: 'Please enter your email address in format:\nyourname@example.com',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'Could not connect with server.',
      COMMON_STATUS:
        [
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'User could not be found.' }
        ],
    },
    INSURANCE: {
      BUTTON_TEXT_SAVE_AND_CLOSE: 'Save and Close',
      BUTTON_TEXT_SAVE_AND_NEXT: 'Save and Next',
      BUTTON_TEXT_ABANDON: 'Abandon',
      BUTTON_TEXT_CANCEL: 'Cancel',
      BUTTON_TEXT_UPLOAD_ALL: 'Upload All',
      BUTTON_TEXT_PAUSE_ALL: 'Pause All',
      BUTTON_TEXT_DOWNLOAD_ALL: 'Download All',
      BUTTON_TEXT_REMOVE_ALL: 'Remove All',
      BUTTON_TEXT_SAVE_CHANGES: 'Save Changes',
      CONTROL_LABEL_INSURANCE_TYPE: 'Insurance Type',
      CONTROL_LABEL_INSURANCE_NAME: 'Insurance Name',
      CONTROL_LABEL_POLICY_NUMBER: 'Policy Number',
      CONTROL_LABEL_CARD_NUMBER: 'Card Number',
      CONTROL_LABEL_INSURANCE_CONTACT_NUMBER: 'Insurance Contact Number',
      CONTROL_LABEL_POLICY_START_DATE: 'Policy Start Date',
      CONTROL_LABEL_POLICY_EXPIRATION_DATE: 'Policy Expiration Date',
      CONTROL_LABEL_NOTES: 'Notes',
      CONTROL_LABEL_MULTIPLE: 'Multiple',
      COLUMN_HEADING_NAME: 'NAME',
      COLUMN_HEADING_SIZE: 'SIZE',
      COLUMN_HEADING_PROGRESS: 'PROGRESS',
      COLUMN_HEADING_STATUS: 'STATUS',
      COLUMN_HEADING_DATE_CREATED: 'DATE CREATED',
      COLUMN_HEADING_TYPE: 'TYPE',
      COLUMN_HEADING_ACTIONS: 'ACTIONS',
      DIALOG_TITLE_ADD_INSURANCE: 'Add an Insurance',
      DIALOG_TITLE_EDIT_INSURANCE: 'Edit an Insurance',
      ERROR_MESSAGE_REQUIRED_INSURANCE_TYPE: 'The insurance type is required!',
      ERROR_MESSAGE_REQUIRED_INSURANCE_NAME: 'The insurance name is required!',
      ERROR_MESSAGE_MAX_LENGTH_50: 'You have typed too many characters, maximum allowed is 50',
      ERROR_MESSAGE_MAX_LENGTH_100: 'You have typed too many characters, maximum allowed is 100',
      ERROR_MESSAGE_MAX_LENGTH_12: 'You have typed too many characters, maximum allowed is 12',
      ERROR_MESSAGE_REQUIRED_POLICY_NUMBER: 'The policy number is required!',
      ERROR_MESSAGE_REQUIRED_CARD_NUMBER: 'The card number is required!',
      ERROR_MESSAGE_REQUIRED_CONTACT_NUMBER: 'The contact number is required!',
      ERROR_MESSAGE_REQUIRED_POLICY_START_DATE: 'The policy start date is required!',
      ERROR_MESSAGE_REQUIRED_POLICY_EXPIRATION_DATE: 'The policy expiration date is required!',
      ERROR_MESSAGE_INVALID_CONTACT_NUMBER: 'Invalid contact number!',
      HEADING_TEXT_SELECT_IMAGES: 'Select Documents',
      HEADING_TEXT_UPLOAD_QUEUE: 'Upload Queue',
      HEADING_TEXT_QUEUE_PROGRESS: 'Queue Progress',
      PLACEHOLDER_SELECT_INSURANCE_TYPE: 'Select Insurance type',
      PLACEHOLDER_SELECT_INSURANCE_NAME: 'Select Insurance Name',
      PLACEHOLDER_CHOOSE_FILES: 'Choose Files',
      SECTION_TEXT: 'Add some information about any of your insurances',
      SECTION_TEXT1: 'Select some Documents; you can upload them one at a time or select Upload All at once',
      SECTION_TEXT2: 'All your documents will be displayed here in a list; you can download or delete them individually',
      SECTION_TEXT3: 'Add any specific notes that you have for this insurance',
      SWEET_ALERT_TITLE_ADDRESS_DOCUMENT_TEXT: 'Are you sure, you want to delete this Insurance Document?',
      SWEET_ALERT_TITLE_ALL_ADDRESS_DOCUMENT_TEXT: 'Are you sure, you want to delete all these Insurance Documents?',
      SWEET_ALERT_TITLE_INSURANCE_TEXT: 'Are you sure, you want to delete this Insurance and all the attachments associated with it?',
      SWEET_ALERT_TEXT: "You won't be able to undo this!",
      SWEET_ALERT_CONFIRM_BUTTON_TEXT: 'Yes, delete it!',
      SWEET_ALERT_CANCEL_BUTTON_TEXT: 'Cancel',
      SWEET_ALERT_TEXT_DELETED: 'Deleted!',
      SWEET_ALERT_CONFIRM_BUTTON_TEXT_OKAY: 'OK',
      TAB_HEADING_INSURANCE_DETAILS: 'Insurance Details',
      TAB_HEADING_UPLOADS: 'Uploads',
      TAB_HEADING_DOCUMENTS: 'Documents',
      TAB_HEADING_NOTES: 'Notes',
      TOOLTIP_TOGGLE_CALENDAR: 'Toggle Calendar',
      TOOLTIP_TEXT_INSURANCE_TYPE: 'Select Insurance Type',
      TOOLTIP_TEXT_INSURANCE_NAME: 'Enter the Insurance Name from your documents',
      TOOLTIP_TEXT_POLICY_NUMBER: 'Enter the Policy Number from your documents',
      TOOLTIP_TEXT_CARD_NUMBER: 'Enter the Card Number from your documents',
      TOOLTIP_TEXT_INSURANCE_CONTACT_NUMBER: 'Enter the Insurance Contact Number from your documents',
      TOOLTIP_TEXT_POLICY_START_DATE: 'Enter the Policy Start Date from your documents',
      TOOLTIP_TEXT_POLICY_EXPIRATION_DATE: 'Enter the Policy Expiration Date from your documents',
      TOOLTIP_TEXT_BUTTON_DOWNLOAD: 'Download',
      TOOLTIP_TEXT_BUTTON_VIEW: 'View',
      TOOLTIP_TEXT_BUTTON_DELETE: 'Delete',
      TOOLTIP_TEXT_BUTTON_CLOSE: 'Close',
      TOAST_MESSAGE_INSURANCE_ADDED_SUCCESSFULLY: 'Insurance added successfully.',
      TOAST_MESSAGE_INSURANCE_UPDATED_SUCCESSFULLY: 'Insurance updated successfully.',
      TEXT_FILE_TYPES: 'Allowed file types:',
      TEXT_QUEUE_LENGTH: 'Queue Length:',
      TEXT_MB: 'MB',


    },
  }
};
