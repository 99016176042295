import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from 'app/Services/global.service';
import {TblUserFriends} from '../about-me-model.model'
import { AuthenticationService } from 'app/auth/service';
@Injectable({
  providedIn: 'root'
})
export class FriendsService {
 // private data :number=this.authenticationService.currentUserValue.UserDetailsId;
 public gridRefresh:boolean=false;
  constructor(
    private _http: HttpClient,private globalService: GlobalService,
    private authenticationService:AuthenticationService,
  ) { }
  CreateUpdateUserFriend(userFriend:TblUserFriends){
    const path='api/UserFriend/CreateUpdateUserFriend';
    return  this.globalService.PostDataById(userFriend , path);
  }
  GetUserFriendLoadData(_data:number){
    const path='api/UserFriend/GetUserFriendLoadData';
    return  this.globalService.PostDataById(_data , path);
  }
  GetUserFriends(data){
    const path='api/UserFriend/GetUserFriends';
    return  this.globalService.PostDataById(data , path);
  } 

  SendConnectionRequest(data){
    const path='api/UserConnectionRequest/SendConnectionRequest';
    return  this.globalService.PostDataById(data , path);
  }

  CreateUpdateUserFriendAnonymous(userFriend:TblUserFriends){
    const path='api/UserConnectionRequest/CreateUpdateUserFriend';
    return  this.globalService.PostDataAnonymous(userFriend , path);
  }

  ValidateEmailAddressFriendsFamily(data){
    const path='api/UserConnectionRequest/ValidateEmailAddressFriendsFamily';
    return  this.globalService.PostDataById(data , path);
  }

  getGridRefresh():boolean{
    //console.log(this.gridRefresh)
      return this.gridRefresh;
    }
  
    setGridRefresh(refresh) {
      //console.log('refresh')
      this.gridRefresh = refresh;
      //console.log(this.gridRefresh)
    }

}
