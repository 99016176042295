import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';

import { EventRef } from 'app/main/apps/calendar/calendar.model';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { GlobalService } from 'app/Services/global.service';
import { AuthenticationService } from 'app/auth/service';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { Constant } from 'app/Classes/constant';
import { GetUserQuickPostsInputModel, QuickPostModel } from 'app/quick-post/quick-post-model.model';
import { E } from 'assets/extensions/extensions';
import { locale as english } from 'app/main/apps/calendar/i18n/en';
import { locale as french } from 'app/main/apps/calendar/i18n/fr';
import { locale as german } from 'app/main/apps/calendar/i18n/de';
import { locale as portuguese } from 'app/main/apps/calendar/i18n/pt';
import { locale as gujarati } from 'app/main/apps/calendar/i18n/gj';
import { locale as hindi } from 'app/main/apps/calendar/i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { Status } from 'app/enums';

@Injectable()
export class CalendarService implements Resolve<any> {
  // Public
  public events: any = new Array<EventRef>();
  public calendar;
  public currentEvent;
  public tempEvents;

  currentAppLanguage: any;
  public coreConfig: any;
  public onEventChange: BehaviorSubject<any>;
  public onCurrentEventChange: BehaviorSubject<any>;
  public onCalendarChange: BehaviorSubject<any>;
  getUserQuickPostsInputModel: GetUserQuickPostsInputModel;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient,
    private globalService: GlobalService,
    private authenticationService: AuthenticationService,
    private encrDecr: EncrDecrService,
    private loadingScreenService: LoadingScreenService,
    private _coreTranslationService: CoreTranslationService,) {
    this.onEventChange = new BehaviorSubject({});
    this.onCurrentEventChange = new BehaviorSubject({});
    this.onCalendarChange = new BehaviorSubject({});
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([this.getEvents(), this.getCalendar()]).then(res => {
        resolve(res);
      }, reject);
    });
  }

  /**
   * Get Events
   */
  async getEvents(): Promise<any[]> {
    // const url = `api/calendar-events`;
    return await new Promise((resolve, reject) => {
      this.loadingScreenService.startLoading();
      const path = 'api/UserQuickPost/GetUserQuickPosts';
      let response: Observable<any>;
      this.getUserQuickPostsInputModel = {
        UserDetailsId: this.authenticationService.currentUserValue.UserDetailsId,
        ShowAllYN: true
      }
      response = this.globalService.PostDataById(this.getUserQuickPostsInputModel, path);
      response.subscribe(
        (result) => {
          this.loadingScreenService.stopLoading();
          //console.log(result);
          if (result.status == Status.Success) {
            this.events = [];
            var _list = <Array<QuickPostModel>>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)));
            //var _list = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
            _list.forEach(_post => {
              let _event = new EventRef();
              _event.allDay = false;
              _event.calendar = this.encrDecr.get(Constant.EncrDecrKey,localStorage.getItem('DiaryOrJournal')) ? this.encrDecr.get(Constant.EncrDecrKey,localStorage.getItem('DiaryOrJournal')) == '2' ? 'Journal Entry':'Diary Entry' : this.encrDecr.get(Constant.EncrDecrKey,sessionStorage.getItem('DiaryJournal Entry')) == '2' ? 'Journal':'Diary Entry';
              _event.title = _post.Title as string;
              _event.id = _post.UserQuickPostId;
              _event.start = E.CombineDateAndTime(_post.DiaryDate, _post.DiaryTimeFrom);
              _event.end = E.CombineDateAndTime(_post.DiaryDate, _post.DiaryTimeTo) //'2022-12-22 00:00:00.000';
              _event.extendedProps.description = E.ConvertToString(_post.PostContent); //'details';
              //_event.extendedProps.location = _post.Location;
              _event.url = '';
              this.events.push(_event);
            });
            this.tempEvents = this.events;
            this.onEventChange.next(this.events);
            resolve(this.events);
            resolve(_list);
            //console.log(_list);
            return _list;
          } else {
            reject();
          }
        },
        (error) => {
          //this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          this.loadingScreenService.stopLoading();
        }
      );
    });
  }
  ngOnInit(): void {
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage); 
  }
  /**
   * Get Calendar
   */
  getCalendar(): Promise<any[]> {
    // const url = `api/calendar-filter`;

    // return new Promise((resolve, reject) => {
    //   this._httpClient.get(url).subscribe((response: any) => {
    //     this.calendar = response;
    //     this.onCalendarChange.next(this.calendar);
    //     resolve(this.calendar);
    //   }, reject);
    // });
    return new Promise((resolve, reject) => {
      this.calendar = [
        { id: 1, filter: this.encrDecr.get(Constant.EncrDecrKey,localStorage.getItem('DiaryOrJournal')) ? this.encrDecr.get(Constant.EncrDecrKey,localStorage.getItem('DiaryOrJournal')) == '2' ? 'Journal Entry':'Diary Entry' : this.encrDecr.get(Constant.EncrDecrKey,sessionStorage.getItem('DiaryJournal')) == '2' ? 'Journal Entry':'Diary Entry', color: 'primary', checked: true },
       // { id: 2, filter: 'Event', color: 'warning', checked: true },
        // { id: 3, filter: 'Personal', color: 'danger', checked: true },
        // { id: 4, filter: 'Family', color: 'warning', checked: true },
        // { id: 5, filter: 'ETC', color: 'info', checked: true }
      ];
      this.onCalendarChange.next(this.calendar);
      resolve(this.calendar);
    });

  }

  /**
   * Create New Event
   */
  createNewEvent() {
    this.currentEvent = {};
    this.onCurrentEventChange.next(this.currentEvent);
  }

  /**
   * Calendar Update
   *
   * @param calendars
   */
  calendarUpdate(calendars) {
    const calendarsChecked = calendars.filter(calendar => {
      return calendar.checked === true;
    });

    let calendarRef = [];
    calendarsChecked.map(res => {
      calendarRef.push(res.filter);
    });

    let filteredCalendar = this.tempEvents.filter(event => calendarRef.includes(event.calendar));
    this.events = filteredCalendar;
    this.onEventChange.next(this.events);
  }

  /**
   * Delete Event
   *
   * @param event
   */
  deleteEvent(event) {
    return new Promise((resolve, reject) => {
      this._httpClient.delete('api/calendar-events/' + event.id).subscribe(response => {
        this.getEvents();
        resolve(response);
      }, reject);
    });
  }

  /**
   * Add Event
   *
   * @param eventForm
   */
  addEvent(eventForm) {
    const newEvent = new EventRef();
    newEvent.url = eventForm.url;
    newEvent.title = eventForm.title;
    newEvent.start = eventForm.start;
    newEvent.end = eventForm.end;
    newEvent.allDay = eventForm.allDay;
    newEvent.calendar = eventForm.selectlabel;
    newEvent.extendedProps.location = eventForm.location;
    newEvent.extendedProps.description = eventForm.description;
    newEvent.extendedProps.addGuest = eventForm.addGuest;
    this.currentEvent = newEvent;
    this.onCurrentEventChange.next(this.currentEvent);
    this.postNewEvent();
  }

  /**
   * Update Event
   *
   * @param eventRef
   */
  updateCurrentEvent(eventRef) {
    const newEvent = new EventRef();
    newEvent.allDay = eventRef.event.allDay;
    newEvent.id = parseInt(eventRef.event.id);
    newEvent.url = eventRef.event.url;
    newEvent.title = eventRef.event.title;
    newEvent.start = eventRef.event.start;
    newEvent.end = eventRef.event.end;
    newEvent.calendar = eventRef.event.extendedProps.calendar;
    newEvent.extendedProps.location = eventRef.event.extendedProps.location;
    newEvent.extendedProps.description = eventRef.event.extendedProps.description;
    newEvent.extendedProps.addGuest = eventRef.event.extendedProps.addGuest;
    this.currentEvent = newEvent;
    this.onCurrentEventChange.next(this.currentEvent);
  }

  /**
   * Post New Event
   */
  postNewEvent() {
    return new Promise((resolve, reject) => {
      this._httpClient.post('api/calendar-events/', this.currentEvent).subscribe(response => {
        this.getEvents();
        resolve(response);
      }, reject);
    });
  }

  /**
   * Post Updated Event
   *
   * @param event
   */
  postUpdatedEvent(event) {
    return new Promise((resolve, reject) => {
      this._httpClient.post('api/calendar-events/' + event.id, { ...event }).subscribe(response => {
        this.getEvents();
        resolve(response);
      }, reject);
    });
  }
  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }
}
