import { Status } from "app/enums";

export const locale = {
  lang: 'hn',
  data: {
    COMMON: {
      CONTROL_LABEL_EMAIL_ADDRESS: 'ईमेल पता',
      PLACEHOLDER_EMAIL_ADDRESS: 'यहाँ लिखो...',
      PLACEHOLDER_TYPE_HERE: 'यहाँ लिखो...',
      PLACEHOLDER_MOBILE_NUMBER: '०१२३४५६७८९',
      BUTTON_TEXT_SAVE_CHANGES: 'परिवर्तनों को सुरक्षित करें',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS: 'ईमेल की आवश्यकता है!',
      ERROR_MESSAGE_INVALID_EMAIL: 'कृपया अपना ईमेल पता प्रारूप में दर्ज करें:\nyourname@example.com',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'सर्वर से कनेक्ट नहीं हो सका.',
      COMMON_STATUS:
        [
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'उपयोगकर्ता नहीं मिल सका।' }
        ],
    },
    MENU: {
      CONTROL_LABEL_VERSION: 'रूपांतर',
      CONTROL_LABEL_UPDATED_ON: 'अपडेट किया गया',
      CONTROL_LABEL_SUPPORT: 'सपोर्ट',
      CONTROL_LABEL_FEEDBACK: 'प्रतिपुष्टि',
      TOOLTIP_TEXT_BUTTON_VERSION: 'रूपांतर',
      TOOLTIP_TEXT_BUTTON_UPDATED_ON: 'अपडेट किया गया',
      TOOLTIP_TEXT_BUTTON_SUPPORT: 'सपोर्ट',
      TOOLTIP_TEXT_BUTTON_FEEDBACK: 'प्रतिपुष्टि',
      TOOLTIP_TEXT_LANGUAGE: 'भाषा',
      TOOLTIP_TEXT_TOGGLE_SKIN: 'स्किन टॉगल करें',
      TOOLTIP_TEXT_NOTIFICATION: 'सूचना',
      TOOLTIP_TEXT_USER: 'उपयोगकर्ता',
      CALENDAR:'कैलेंडर',
      UPLOAD:'अपलोड',
      ADD_JOURNAL_ENTRY: 'एक जर्नल एंट्री जोड़ें',
      ADD_DIARY_ENTRY:'एक डायरी एंट्री जोड़ें'
    },
    NAVBAR: {
      CONTROL_LABEL_MY_NOOSOM_PROFILE: 'मेरी नुसम प्रोफाइल',
      CONTROL_LABEL_SETTINGS: 'सेटिंग',
      CONTROL_LABEL_PRICING: 'मूल्य निर्धारण',
      CONTROL_LABEL_GIVE_FEEDBACK: 'प्रतिक्रिया दें',
      CONTROL_LABEL_SUPPORT: 'सहयोग / सामान्य प्रश्न',
      CONTROL_LABEL_LOGOUT: 'लॉग आउट',
    },
  }
};
