import { Status } from "app/enums";

export const locale = {
  lang: 'en',
  data: {
    COMMON:{
      CONTROL_LABEL_EMAIL_ADDRESS:'Email Address',
      PLACEHOLDER_EMAIL_ADDRESS:'Type here...',
      PLACEHOLDER_TYPE_HERE:'Type here...',
      PLACEHOLDER_MOBILE_NUMBER:'0123456789',
      BUTTON_TEXT_SAVE_CHANGES:'Save Changes',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS:'The email is required!',
      ERROR_MESSAGE_INVALID_EMAIL:'Please enter your email address in format:\nyourname@example.com',
      ERROR_MESSAGE_CONNECTIONREFUSED:'Could not connect with server.',
      COMMON_STATUS:
      [
        {ErrorCode:Status.UserNotFound,ErrorMessage:'User could not be found.'}
      ],
    },
    GRIDVIEW:{
      BUTTON_TEXT_VIEW_ALL_ON_A_MAP:'View all on a Map',
      CONTROL_LABEL_SEARCH:'Search',
      CONTROL_LABEL_SHOW:'Show',
      CHARACTER_COUNT:'Character Count',
      OPTION_VALUE_TEXT_5:'5',
      OPTION_VALUE_TEXT_10:'10',
      OPTION_VALUE_TEXT_15:'15',
      OPTION_VALUE_TEXT_20:'20',
      OPTION_VALUE_TEXT_25:'25',
      PLACEHOLDER_SEARCH:'Search'
    },
    GRIDACTION:{
      TOOLTIP_TEXT_BUTTON_EDIT_RECORD:'Edit Record',
      TOOLTIP_TEXT_BUTTON_VIEW_ON_MAP:'View on Map',
      TOOLTIP_TEXT_BUTTON_LINK_RECORD:'Connect with this person',
      TOOLTIP_TEXT_BUTTON_DOC_DESCRIPTION:'Document Description',
      TOOLTIP_TEXT_BUTTON_DELETE_RECORD:'Delete Record',
      TOOLTIP_TEXT_BUTTON_DOWNLOAD_RECORD:'Download',
      TOOLTIP_TEXT_BUTTON_SEE_RECORD:'See Record',
      TOOLTIP_TEXT_BUTTON_VIEW_RECORD:'View Invoice',
    },
  }
};
