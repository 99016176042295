import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
import { locale as german } from '../i18n/de';
import { locale as portuguese } from '../i18n/pt';
import { locale as gujarati } from '../i18n/gj';
import { locale as hindi } from '../i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreConfigService } from '@core/services/config.service';
import { map, takeUntil } from 'rxjs/operators';
import { E } from 'assets/extensions/extensions';
import { AuthenticationService } from 'app/auth/service';
import { Status, Enums, MapModalType } from 'app/enums';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { GlobalService } from 'app/Services/global.service';
import { Constant } from 'app/Classes/constant';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { MapAddressComponent } from 'app/address/map-address/map-address.component';
import { MapAddressDataModel } from 'app/address/map-address/map-address-data-model.model';
import { TextBoxComponent } from 'app/CustomComponents/text-box/text-box.component';
import { JobLoadDataOutputModel, TblUserJobs } from '../about-me-model.model'
import { JobsService } from './jobs.service'
@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
  @ViewChild('Employer') Employer: TextBoxComponent;
  public coreConfig: any;
  public dialogTitle: string;
  public IsForEdit: boolean = false;
  currentAppLanguage: any;
  public loading = false;
  public submitted = false;
  public maxToDate: Date = new Date();
  public maxFromDate: Date = new Date();
  public PlaceholderState;
  public PlaceholderCountry;
  public PlaceholderRegion;
  private _unsubscribeAll: Subject<any>;
  public frmJobs: FormGroup;
  _data: number = 0;
  public error = '';
  jobLoadDataOutputModel$: Observable<JobLoadDataOutputModel>;
  jobLoadDataOutputModel: JobLoadDataOutputModel;
  userJob: TblUserJobs;
  private subSaveJobs$: Subscription;
  infoTooltip: any;
  dateformat: string;
  constructor(
    private dialogRef: MatDialogRef<JobsComponent>,
    private jobsService: JobsService,
    private _toastrService: CustomToastrService,
    private _coreTranslationService: CoreTranslationService,
    private _coreConfigService: CoreConfigService,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private loadingScreenService: LoadingScreenService,
    private globalService: GlobalService,
    private encrDecr: EncrDecrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._unsubscribeAll = new Subject();
    this._data = data;
    this.dateformat=this.globalService.getDateFormat().toUpperCase()
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      if (this.Employer)
      this.Employer.textBox.nativeElement.focus();
    }, 500);
  }

  ngOnInit(): void {
    this.userJob = new TblUserJobs();
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);
    this.dialogTitle = this.currentAppLanguage.data.MY_JOBS.BUTTON_TEXT_ADD_A_NEW_JOBS;
    this.infoTooltip= this.currentAppLanguage.data.MY_JOBS.SECTION_TEXT_JOBS;
    this.frmJobs = this._formBuilder.group({
      Employer: ['', Validators.required],
      JobDescription: ['', Validators.required],
      CurrentJobYn: [],
      Address1: ['', Validators.required],
      Address2: [''],
      Address3: [''],
      AddressTown: ['', Validators.required],
      StateId: [, Validators.required],
      AddressPostCode: ['', Validators.required],
      CountryId: [, Validators.required],
      RegionId: [, Validators.required],
      JobFromDate: [, Validators.required],
      JobToDate: [],
      Notes: []
    });


    if (typeof this._data !== 'undefined') {
      if (this._data > 0) {   //Note: API is being called to load region list in add a new address.
        this.IsForEdit = true;
        this.dialogTitle = this.currentAppLanguage.data.MY_JOBS.DIALOG_TITLE_EDIT_JOBS;
      }



      this.jobsService.GetUserJobLoadData(this._data)
      this.jobLoadDataOutputModel$ = this.jobsService.GetUserJobLoadData(this._data)
        .pipe(
          map((data) => {
            //this.loadingScreenService.stopLoading();
            if (data.status == Status.Success) {
              //console.log(this.jobLoadDataOutputModel$)
              this.jobLoadDataOutputModel = <JobLoadDataOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, data.data)));

              if (this.jobLoadDataOutputModel.UserJob) {
                this.userJob = this.jobLoadDataOutputModel.UserJob;
                this.frmJobs.setValue({
                      Employer:this.userJob.Employer,
                      JobDescription:this.userJob.JobDescription,
                      Address1: this.userJob.Address1,
                      Address2: this.userJob.Address2,
                      Address3: this.userJob.Address3,
                      JobFromDate: E.ConvertToDate(this.userJob.JobFromDate),
                      JobToDate: E.ConvertToDate(this.userJob.JobToDate),
                      AddressPostCode: this.userJob.AddressPostCode,
                      AddressTown: this.userJob.AddressTown,
                      RegionId: this.userJob.RegionId,
                      CountryId: this.userJob.CountryId,
                      StateId: this.userJob.StateId,
                      Notes: this.userJob.Notes,
                      CurrentJobYn: this.userJob.CurrentJobYn,
                });

                // if (this.userJob.CountryId) {
                //   this.frmJobs.get('CountryId').enable();
                // }
                if (this.userJob.StateId) {
                  this.frmJobs.get('StateId').enable();
                }
              }
              // console.log(data)
              return this.jobLoadDataOutputModel;
            }
            else {
              this._toastrService.Error(E.ConvertToString(data.message), Constant.ErrorToastrTitle);
            }
          },
            (error: string) => {
              this._toastrService.Error(error, Constant.ErrorToastrTitle);
              this.error = error;

            }
          ));
    }

    this.PlaceholderRegion = this.currentAppLanguage.data.MY_JOBS.PLACEHOLDER_SELECT_REGION;
    this.PlaceholderCountry = this.currentAppLanguage.data.MY_JOBS.PLACEHOLDER_SELECT_COUNTRY;
    this.PlaceholderState = this.currentAppLanguage.data.MY_JOBS.PLACEHOLDER_SELECT_STATE;
    this.frmJobs.get('CountryId').disable();
    this.frmJobs.get('RegionId').disable();


    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key.toLocaleLowerCase() === "escape") {
        this.onCloseButtonClick();
      }
    });
  }



  onSubmit() {
    // console.log('onSubmit called');
    //console.log(this.userJob.Latitude);
    if (typeof this.userJob.Latitude === 'undefined' || typeof this.userJob.Longitude === 'undefined' || this.userJob.Latitude == null || this.userJob.Longitude == null) {
      this._toastrService.Warning(this.currentAppLanguage.data.MY_JOBS.WARNING_MESSAGE_SELECT_LOCATION, Constant.WarningToastrTitle);
      this.onSearchOnMapClick();
      return;
    }

    this.submitted = true;
    if (this.frmJobs.invalid) {
      return;
    }
    this.submitted = false;
    if (this.userJob == null) {
      this.userJob = new TblUserJobs();
    }

    this.userJob.Employer = this.frmJobs.get('Employer').value;
    this.userJob.JobDescription = this.frmJobs.get('JobDescription').value;
    this.userJob.Address1 = this.frmJobs.get('Address1').value;
    this.userJob.Address2 = this.frmJobs.get('Address2').value;
    this.userJob.Address3 = this.frmJobs.get('Address3').value;
    this.userJob.AddressTown = this.frmJobs.get('AddressTown').value;
    this.userJob.AddressPostCode = this.frmJobs.get('AddressPostCode').value;
    this.userJob.RegionId = this.frmJobs.get('RegionId').value;
    this.userJob.JobFromDate = E.GetDateStringOnly(this.frmJobs.get('JobFromDate').value);
    this.userJob.JobToDate = E.GetDateStringOnly(this.frmJobs.get('JobToDate').value);
    this.userJob.UserDetailsId = this.authenticationService.currentUserValue.UserDetailsId;
    this.userJob.CountryId = this.frmJobs.get('CountryId').value;
    this.userJob.StateId = this.frmJobs.get('StateId').value;
    this.userJob.Notes = this.frmJobs.get('Notes').value;
    if (this.frmJobs.get('CurrentJobYn').value)
      this.userJob.CurrentJobYn = this.frmJobs.get('CurrentJobYn').value;
    else { this.userJob.CurrentJobYn = false }

    if (E.ConvertToNumber(this.userJob.UserJobId) == 0 && this.IsForEdit == false) {
      this.userJob.CreatedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userJob.CreatedDate = E.GetTodayDateStringOnly();
      this.userJob.CreatedTime = E.GetTodayTimeStringOnly();
      this.userJob.ChangedDate = E.GetTodayDateStringOnly();
      this.userJob.ChangedTime = E.GetTodayTimeStringOnly();
    }
    else {
      this.userJob.ChangedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userJob.ChangedDate = E.GetTodayDateStringOnly();
      this.userJob.ChangedTime = E.GetTodayTimeStringOnly();
    }
    this.loading = true;
    //this.loadingScreenService.startLoading();
    this.jobsService.CreateUpdateUserJob(this.userJob)
    this.subSaveJobs$ = this.jobsService.CreateUpdateUserJob(this.userJob).subscribe(
      (result) => {
        this.loading = false;
        //this.loadingScreenService.stopLoading();
        var message = ""; var errormessage = "";
        if (result.status == Status.RecordAdded) {
          message = this.currentAppLanguage.data.MY_JOBS.TOAST_MESSAGE_ADDRESS_ADDED_SUCCESSFULLY;
        }
        else if (result.status == Status.RecordUpdated) {
          message = this.currentAppLanguage.data.MY_JOBS.TOAST_MESSAGE_ADDRESS_UPDATED_SUCCESSFULLY;
        }
        else {
          errormessage = E.ConvertToString(result.message);
        }
        if (message.length > 0) {
          this._toastrService.Success(message, Constant.SuccessToastrTitle);
          this.dialogRef.close(true);
          this.frmJobs.reset();
          this.frmJobs.patchValue({
            JobFromDate: new Date(),
            JobToDate: new Date()
          });
          // (<any>this.frmJobs.get('AddressTag')).nativeElement.focus();
        }
        else {
          this._toastrService.Error(errormessage, Constant.ErrorToastrTitle);
        }
      },
      (error) => {
        this._toastrService.Error(error, Constant.ErrorToastrTitle);
        this.error = error;
        this.loading = false;
        //this.loadingScreenService.stopLoading();
      }
    );
  }

  onStateChange(event) {
    if (event != null) {
      this.PlaceholderCountry = "";
      //this.loadingScreenService.startLoading();
      const path = 'api/State/GetCountryRegionId';
      var list = this.globalService.PostDataAnonymous(event, path).subscribe(
        (data) => {
          //this.loadingScreenService.stopLoading();
          var result = JSON.parse(data._body);
          if (result.status == Status.Success) {
           // console.log(JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data)))
            var countryRegion = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
            this.frmJobs.get('CountryId').setValue(countryRegion?.CountryId);
            this.frmJobs.get('RegionId').setValue(countryRegion?.RegionId);
         //   this.familyMemberLoadDataOutputModel.StateList = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
          //  if (this.familyMemberLoadDataOutputModel.StateList != null) {
          //    this.frmFamily.get('StateId').enable();
          //  }
          }
        },
        (error) => {
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          //this.loadingScreenService.stopLoading();
        }
      );
    }
    else {
      this.PlaceholderCountry = this.currentAppLanguage.data.MY_JOBS.PLACEHOLDER_STATE;
      this.frmJobs.get('RegionId').setValue(null);
      this.PlaceholderRegion = this.currentAppLanguage.data.MY_JOBS.PLACEHOLDER_REGION;
      this.frmJobs.get('RegionId').disable();
      this.frmJobs.get('CountryId').setValue(null);
      this.PlaceholderCountry = this.currentAppLanguage.data.MY_JOBS.PLACEHOLDER_COUNTRY;
      this.frmJobs.get('CountryId').disable();
    }
  }
  onCountryChange(event) {
    // this.healthProviderLoadDataOutputModel.StateList = null;
    this.frmJobs.get('StateId').setValue(null);
    this.PlaceholderState = this.currentAppLanguage.data.MY_JOBS.PLACEHOLDER_SELECT_STATE;
    this.frmJobs.get('StateId').disable();
    if (event != null) {
      this.PlaceholderCountry = "";
      this.loadingScreenService.startLoading();
      const path = 'api/State/GetStateList';
      let response: Observable<any>;
      response = this.globalService.PostDataAnonymous(event, path);
      response.subscribe(
        (data) => {
          this.loadingScreenService.stopLoading();
          var result = JSON.parse(data._body);
          if (result.status == Status.Success) {
            this.jobLoadDataOutputModel.StateList = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
            if (this.jobLoadDataOutputModel.StateList != null) {
              this.frmJobs.get('StateId').enable();
            }
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          this.loadingScreenService.stopLoading();
        }
      );
    }
    else {
      this.PlaceholderCountry = this.currentAppLanguage.data.MY_JOBS.PLACEHOLDER_SELECT_COUNTRY;
    }
  }
  sendEmail() {
    window.location.href = "mailto:" + this.frmJobs.get('EmailAddress').value;
  }
  onRegionChange(event) {
    //  this.healthProviderLoadDataOutputModel.CountryList = null;
    this.frmJobs.get('CountryId').setValue(null);
    this.PlaceholderCountry = this.currentAppLanguage.data.MY_JOBS.PLACEHOLDER_SELECT_COUNTRY;
    this.frmJobs.get('CountryId').disable();
    if (event != null) {
      this.PlaceholderRegion = "";
      this.loadingScreenService.startLoading();
      const path = 'api/Country/GetCountryList';
      let response: Observable<any>;
      response = this.globalService.PostDataAnonymous(event, path);
      response.subscribe(
        (data) => {
          this.loadingScreenService.stopLoading();
          var result = JSON.parse(data._body);
          if (result.status == Status.Success) {
            this.jobLoadDataOutputModel.CountryList = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
            if (this.jobLoadDataOutputModel.CountryList != null) {
              this.frmJobs.get('CountryId').enable();
            }
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          this.loadingScreenService.stopLoading();
        }
      );
    }
    else {
      this.PlaceholderRegion = this.currentAppLanguage.data.MY_JOBS.PLACEHOLDER_SELECT_REGION;
    }
  }
  OnDateToChange(event) {
    let datefrom = this.frmJobs.get('JobFromDate').value;
    let dateto = this.frmJobs.get('JobToDate').value;
    if (datefrom && dateto) {
      if (datefrom.getTime() > dateto.getTime()) {
        this.frmJobs.get('JobFromDate').setValue(dateto);
      }
    }
    if (dateto)
      this.maxFromDate = dateto;
  }
  onSearchOnMapClick() {
    if (E.ConvertToString(this.frmJobs.get('Address1').value) != '' && E.ConvertToString(this.frmJobs.get('AddressTown').value) != ''
      && E.ConvertToString(this.frmJobs.get('AddressPostCode').value) != '') {
      this.openDialogMapAddress();
    }
    else {
      this._toastrService.Error("The required fields are empty", Constant.ErrorToastrTitle);
    }
  }
  GetMapSearchString(): string {
    let result = '';
    if (this.frmJobs.get('Address1').value)
      result += (this.frmJobs.get('Address1').value + ', ');
    if (this.frmJobs.get('AddressTown').value)
      result += (this.frmJobs.get('AddressTown').value + ', ');
    if (this.frmJobs.get('AddressPostCode').value)
      result += (this.frmJobs.get('AddressPostCode').value);
    return result;
  }
  openDialogMapAddress() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "70%"
    dialogConfig.height = "55%";
    dialogConfig.data = new MapAddressDataModel(MapModalType.SearchString, this.GetMapSearchString());
    const dialogRef: MatDialogRef<MapAddressComponent> = this.dialog.open(MapAddressComponent, dialogConfig);
    dialogRef.componentInstance.IsDraggable = true;
    dialogRef.componentInstance.IsSaveButtonVisible = true;
    dialogRef.componentInstance.IsSearchOnMap = true;
    dialogRef.afterClosed().subscribe(
      result => {

        if (result) {
          if (E.ConvertToString(result.event) == 'SearchOnMap' && result.data) {

            this.userJob.Latitude = result.data.lat;
            this.userJob.Longitude = result.data.long;

          }
        }
        // this.loadData();
      }
    );
  }

  onCloseButtonClick() {
    this.dialogRef.close();
  }

  get f() {
    return this.frmJobs.controls;
  }

  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }

}
