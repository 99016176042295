import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TabStateServiceService {
  constructor() { }
  private activeTabIndex: number = 0;

  setTabIndex(index: number): void {
    this.activeTabIndex = index;
  }

  getTabIndex(): number {
    return this.activeTabIndex;
  }
}
