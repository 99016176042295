import { Injectable } from '@angular/core';
import { CustomToastrComponent } from 'app/main/extensions/toastr/custom-toastr/custom-toastr.component';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { cloneDeep } from 'lodash';
import { Constant } from 'app/Classes/constant';

@Injectable({
  providedIn: 'root'
})
export class CustomToastrService {
  private options: GlobalConfig;

  constructor(private _toastrService: ToastrService,) {

    this.options = this._toastrService.toastrConfig;
  }

  public get customToastrRef() {
    let _customToastrRef = cloneDeep(this.options);
    _customToastrRef.toastComponent = CustomToastrComponent;
    _customToastrRef.closeButton = true;
    _customToastrRef.tapToDismiss = false;
    _customToastrRef.progressBar = true;
    _customToastrRef.toastClass = 'toast ngx-toastr';
    //_customToastrRef.disableTimeOut = true;
    return _customToastrRef;
  }

  public Error(message: string, title = Constant.ErrorToastrTitle): any {
    return this._toastrService.error(message, title, this.customToastrRef);
  }
  public Success(message: string, title = Constant.SuccessToastrTitle): any {
    return this._toastrService.success(message, title, this.customToastrRef);
  }
  public Warning(message: string, title = Constant.WarningToastrTitle): any {
    return this._toastrService.warning(message, title, this.customToastrRef);
  }
  public Info(message: string, title = Constant.InfoToastrTitle): any {
    return this._toastrService.info(message, title, this.customToastrRef);
  }
}

