import { Status } from "app/enums";

export const locale = {
  lang: 'hn',
  data: {
    COMMON: {
      CONTROL_LABEL_EMAIL_ADDRESS: 'ईमेल पता',
      PLACEHOLDER_EMAIL_ADDRESS: 'यहाँ लिखो...',
      PLACEHOLDER_TYPE_HERE: 'यहाँ लिखो...',
      PLACEHOLDER_MOBILE_NUMBER: '०१२३४५६७८९',
      BUTTON_TEXT_SAVE_CHANGES: 'परिवर्तनों को सुरक्षित करें',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS: 'ईमेल की आवश्यकता है!',
      ERROR_MESSAGE_INVALID_EMAIL: 'कृपया अपना ईमेल पता प्रारूप में दर्ज करें:\nyourname@example.com',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'सर्वर से कनेक्ट नहीं हो सका.',
      TOOLTIP_TEXT_BUTTON_CLOSE: 'बंद करना',
      COMMON_STATUS:
        [
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'उपयोगकर्ता नहीं मिल सका।' }
        ],
    },
    MYPASTIME: {
      BUTTON_TEXT_BUTTON_ADD_NEW_PASTIME: 'एक पासटाइम जोड़ें',
      BUTTON_TEXT_BUTTON_ADD_NEW_HOBBY: 'एक शौक जोड़ें',
      BUTTON_TEXT_SAVE_AND_CLOSE: 'सहेजें और बंद करें',
      BUTTON_TEXT_SAVE_AND_NEXT: 'सहेजें और आगे',
      BUTTON_TEXT_ABANDON: 'छोड़ें',
      BUTTON_TEXT_CANCEL: 'रद्द करें',
      BUTTON_TEXT_UPLOAD_ALL: 'सभी अपलोड करें',
      BUTTON_TEXT_PAUSE_ALL: 'सभी को रोकें',
      BUTTON_TEXT_DOWNLOAD_ALL: 'सभी डाउनलोड करें',
      BUTTON_TEXT_REMOVE_ALL: 'सभी हटाएं',
      BUTTON_TEXT_SAVE_CHANGES: 'परिवर्तन सहेजें',
      CONTROL_LABEL_PASTIME: 'पासटाइम',
      CONTROL_LABEL_PASTIMES: 'पासटाइमस',
      CONTROL_LABEL_HOBBY: 'शौक',
      CONTROL_LABEL_HOBBIES: 'शौक',
      CONTROL_LABEL_DATE_FROM: 'दिनांक से',
      CONTROL_LABEL_DATE_TO: 'दिनांक तक',
      CONTROL_LABEL_FIELD_DATE_FROM: 'दिनांक से',
      CONTROL_LABEL_FIELD_DATE_TO: 'दिनांक तक',
      CONTROL_LABEL_FIELD_SHORT_DESCRIPTION: 'संक्षिप्त विवरण',
      CONTROL_LABEL_FIELD_LONG_DESCRIPTION: 'लंबा विवरण',
      CONTROL_LABEL_SHORT_DESCRIPTION: 'संक्षिप्त विवरण',
      CONTROL_LABEL_NAME_OF_PASTIME: 'पासटाइम का नाम',
      CONTROL_LABEL_NAME_OF_HOBBY: 'शौक का नाम',
      CONTROL_LABEL_MULTIPLE: 'बहुतायत',
      CONTROL_LABEL_ACTION: 'कार्रवाई',
      CONTROL_LABEL_NOTES: 'नोट्स',
      COLUMN_HEADING_NAME: 'नाम',
      COLUMN_HEADING_SIZE: 'साइज़',
      COLUMN_HEADING_PROGRESS: 'प्रगति',
      COLUMN_HEADING_STATUS: 'स्थिति',
      COLUMN_HEADING_DATE_CREATED: 'तिथि बनाई गई',
      COLUMN_HEADING_TYPE: 'प्रकार',
      COLUMN_HEADING_ACTIONS: 'कार्रवाई',
      DIALOG_TITLE_ADD_PASTIME: 'एक पासटाइम जोड़ें',
      DIALOG_TITLE_EDIT_PASTIME: 'एक पासटाइम संपादित करें',
      DIALOG_TITLE_ADD_HOBBY: 'एक शौक जोड़ें',
      DIALOG_TITLE_EDIT_HOBBY: 'एक शौक संपादित करें',
      ERROR_MESSAGE_ATTACHMENT_UPLOADED: 'संलग्नक अपलोड किया गया',
      ERROR_MESSAGE_PASTIME_REQUIRED: 'पासटाइम आवश्यक है!',
      ERROR_MESSAGE_HOBBIES_REQUIRED: 'शौक आवश्यक है!',
      ERROR_MESSAGE_MAX_50: 'आपने बहुत अधिक वर्ण टाइप किए हैं, अधिकतम 50 वर्ण अनुमत हैं',
      HEADING_TEXT_SELECT_IMAGES: 'दस्तावेज़ चुनें',
      HEADING_TEXT_UPLOAD_QUEUE: 'अपलोड कतार',
      HEADING_TEXT_QUEUE_PROGRESS: 'कतार प्रगति',
      INFO_TEXT2: 'आपकी सभी अपलोड की गई तस्वीरें यहां प्रदर्शित की जाएगी।',
      PLACEHOLDER_CHOOSE_FILES: 'फ़ाइलें चुनें',
      SECTION_HEADING_HI: 'नमस्ते',
      SECTION_HEADING_ABOUT_ME: 'यह आपके और आपके प्रोफ़ाइल के बारे में सब कुछ है',
      SECTION_TEXT1: 'कुछ दस्तावेज़ चुनें। आप उन्हें एक-एक करके अपलोड कर सकते हैं या एक साथ सभी अपलोड करने के लिए एक बार अपलोड करें।',
      SECTION_TEXT2: 'आपके सभी दस्तावेज़ यहां एक सूची में प्रदर्शित किए जाएंगे। आप उन्हें व्यक्तिगत रूप से डाउनलोड या हटा सकते हैं।',
      SECTION_TEXT3: 'इस पासटाइम के लिए आपके पास कोई विशिष्ट नोट्स जोड़ें।',
      SWEET_ALERT_TITLE_PASTIME_TEXT: 'क्या आपको यकीन है, कि आप इस पास्टाइम को हटाना चाहते हैं?',
      SWEET_ALERT_TITLE_HOBBY_TEXT: 'क्या आपको यकीन है, कि आप इस शौक को हटाना चाहते हैं?',
      SWEET_ALERT_TEXT: 'आप इसे वापस नहीं कर सकेंगे!',
      SWEET_ALERT_CONFIRM_BUTTON_TEXT: 'हां, इसे हटाएं!',
      SWEET_ALERT_CANCEL_BUTTON_TEXT: 'रद्द करें',
      SWEET_ALERT_TITLE_ADDRESS_DOCUMENT_TEXT: 'क्या आपको यकीन है, कि आप इस पासटाइम दस्तावेज़ को हटाना चाहते हैं?',
      SWEET_ALERT_TITLE_HOBBY_DOCUMENT_TEXT: 'क्या आपको यकीन है, कि आप इस शौक दस्तावेज़ को हटाना चाहते हैं?',
      SWEET_ALERT_TITLE_ALL_ADDRESS_DOCUMENT_TEXT: 'क्या आपको यकीन है, कि आप इन सभी पासटाइम दस्तावेज़ को हटाना चाहते हैं?',
      SWEET_ALERT_TITLE_ALL_HOBBY_DOCUMENT_TEXT: 'क्या आपको यकीन है, कि आप इन सभी शौक दस्तावेज़ को हटाना चाहते हैं?',
      SWEET_ALERT_TITLE_INSURANCE_TEXT: 'क्या आपको यकीन है, कि आप इस पासटाइम और इससे संबंधित सभी अटैचमेंट्स को हटाना चाहते हैं?',
      SWEET_ALERT_TEXT_DELETED: 'हटाया गया!',
      SWEET_ALERT_CONFIRM_BUTTON_TEXT_OKAY: 'ठीक है',
      TAB_HEADING_PASTIME_DETAILS: 'पासटाइम विवरण',
      TAB_HEADING_HOBBY_DETAILS: 'शौक विवरण',
      TAB_HEADING_UPLOADS: 'अपलोड',
      TAB_HEADING_DOCUMENTS: 'दस्तावेज़',
      TAB_HEADING_NOTES: 'नोट्स',
      TAB_LINK_PASTIMES: 'पासटाइम',
      TAB_HEADING_GALLERY: 'गेलरी',
      TAB_LINK_HOBBIES: 'शौक',
      TOOLTIP_TOGGLE_CALENDAR: 'कैलेंडर स्विच करें',
      TOOLTIP_TEXT_BUTTON_ADD_NEW_RECORD: 'नया रिकॉर्ड जोड़ें',
      TOOLTIP_TEXT_BUTTON_DOWNLOAD: 'डाउनलोड',
      TOOLTIP_TEXT_BUTTON_VIEW: 'देखें',
      TOOLTIP_TEXT_BUTTON_DELETE: 'हटाएँ',
      TEXT_FILE_TYPES: 'अनुमत फ़ाइल प्रकार:',
      TEXT_QUEUE_LENGTH: 'कतार की लंबाई:',
      TEXT_MB: 'मेगाबाइट',
      TOOLTIP_TEXT_PASTIME_INFORMATION: 'किसी भी आपके अतीत के शौक के बारे में कुछ जानकारी जोड़ें',
      TOOLTIP_TEXT_PASTIME_NOTES: 'किसी भी आपके अतीत के शौक के बारे में कुछ नोट्स जोड़ें',
      TOOLTIP_TEXT_UPLOAD_DOCUMENTS: 'कुछ दस्तावेज़ चुनें; आप उन्हें एक-एक करके अपलोड कर सकते हैं या एक साथ सभी अपलोड करने के लिए एक बार अपलोड करें',
      TOOLTIP_TEXT_UPLOADED_DOCUMENTS_DISPLAY: 'आपके अपलोड किए गए सभी दस्तावेज़ यहां प्रदर्शित किए जाएंगे',
      TOOLTIP_TEXT_PASTIME_NAME: 'शौक का नाम दर्ज करें',
      TOOLTIP_TEXT_PASTIME_DATE_FROM: 'शौक की तिथि से दर्ज करें',
      TOOLTIP_TEXT_PASTIME_DATE_TO: 'शौक की तिथि तक दर्ज करें',
      TOOLTIP_TEXT_HOBBIES_NAME: 'शौक का नाम दर्ज करें',
      TOOLTIP_TEXT_HOBBIES_DATE_FROM: 'शौक की दिनांक से दर्ज करें',
      TOOLTIP_TEXT_HOBBIES_DATE_TO: 'शौक की दिनांक तक दर्ज करें',
      TOOLTIP_TEXT_HOBBY_INFORMATION: 'किसी भी शौक के बारे में कुछ जानकारी जोड़ें',
      TOOLTIP_TEXT_HOBBY_NOTES: 'किसी भी शौक के बारे में कुछ नोट्स जोड़ें',
      TOOLTIP_TEXT_PASTIME_SHORT_DESCRIPTION: 'एक संक्षिप्त विवरण दर्ज करें',
      TOOLTIP_TEXT_PASTIME_LONG_DESCRIPTION: 'एक लंबा विवरण दर्ज करें'
    }
  }
};
