import { Status } from "app/enums";

export const locale = {
  lang: 'hn',
  data: {
    COMMON: {
      CONTROL_LABEL_EMAIL_ADDRESS: 'ईमेल पता',
      PLACEHOLDER_EMAIL_ADDRESS: 'यहाँ लिखो...',
      PLACEHOLDER_TYPE_HERE: 'यहाँ लिखो...',
      PLACEHOLDER_MOBILE_NUMBER: '०१२३४५६७८९',
      BUTTON_TEXT_SAVE_CHANGES: 'परिवर्तनों को सुरक्षित करें',
      BUTTON_TEXT_SAVE: 'सहेजें',
      BUTTON_TEXT_SAVE_SEND: 'सहेजें और भेजें',
      BUTTON_TEXT_CANCEL: 'रद्द करें',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS: 'ईमेल की आवश्यकता है!',
      ERROR_MESSAGE_INVALID_EMAIL: 'कृपया अपना ईमेल पता प्रारूप में दर्ज करें:\nyourname@example.com',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'सर्वर से कनेक्ट नहीं हो सका.',
      ERROR_MESSAGE_INVALID_MOBILE_NUMBER: 'अमान्य मोबाइल संपर्क नंबर',
      TOOLTIP_TEXT_BUTTON_CLOSE: 'बंद करना',
      COMMON_STATUS:
        [
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'उपयोगकर्ता नहीं मिल सका।' }
        ],
    },
    ABOUTME: {
      SECTION_HEADING_HI: 'हे',
      SECTION_HEADING_ABOUT_ME: 'यह हमारे साथ आपके और आपकी प्रोफ़ाइल के बारे में सब कुछ है',
      SECTION_HADING_MY_DETAILS: 'मेरे विवरण',
      SECTION_HADING_MY_ADDRESSES: 'मेरे पते',
      SECTION_HADING_MY_SCHOOLS: 'मेरे स्कूल',
      SWEET_ALERT_NO_BUTTON: 'नहीं',
      SWEET_ALERT_YES_BUTTON: 'हाँ',
      SWEET_ALERT_SURE_TEXT: 'क्या आप सुनिश्चित हैं?',
      SWEET_ALERT_TITLE_INVITATION_TEXT: 'आप Personname को Noosom पर आपसे जुड़ने के लिए आमंत्रित करने वाला एक ईमेल भेजने वाले हैं।',
      BUTTON_TEXT_UPLOAD: 'अपलोड',
      BUTTON_TEXT_VIEW_ON_MAP: 'मानचित्र पर देखें',
      BUTTON_TEXT_VIEW_ALL_ON_A_MAP: 'मानचित्र पर सभी देखें',
      BUTTON_TEXT_ADD_NEW_RECORD: 'नया रिकॉर्ड जोड़ें',
      BUTTON_TEXT_ADD_AN_ADDRESS: 'एक पता जोड़ें',
      BUTTON_TEXT_ADD_SCHOOL: 'एक स्कूल जोड़ें',
      CHARACTER_COUNT: 'अक्षर गणना',
      TAB_LINK_TEXT_ABOUT_ME: 'मेरे बारे में',
      TAB_LINK_TEXT_MY_ESSENTIAL_INFO: 'मेरी आवश्यक जानकारी',
      TAB_LINK_TEXT_MY_FAMILIES: 'मेरे परिवार',
      TAB_LINK_TEXT_MY_FRIENDS: 'मेरे मित्र',
      TAB_LINK_TEXT_MY_ACHIEVEMENTS_AND_AWARDS: 'मेरी उपलब्धियां और पुरस्कार',
      TAB_LINK_TEXT_MY_JOBS: 'मेरे काम',
      TOOLTIP_TEXT_BUTTON_UPLOAD: 'डालना',
      TOOLTIP_TEXT_BUTTON_CALENDAR: 'टॉगल कैलेंडर',
      TOOLTIP_TEXT_BUTTON_SAVE_CHANGES: 'परिवर्तनों को सुरक्षित करें',
      TOOLTIP_TEXT_BUTTON_VIEW_ALL_ON_MAP: 'मानचित्र पर सभी देखें',
      TOOLTIP_TEXT_BUTTON_ADD_NEW_RECORD: 'नया रिकॉर्ड जोड़ें',
      TOOLTIP_TEXT_BUTTON_VIEW_ON_MAP: 'मानचित्र पर देखें',
      TOOLTIP_TEXT_COPY: 'कॉपी',
      TOOLTIP_TEXT_EXTERNAL_LINK: 'एक नए टैब में खोलें',
      TOOLTIP_TEXT_SEND_EMAIL: 'ईमेल भेजें',
      TOOLTIP_TEXT_FIRST_NAME: 'पहला नाम दर्ज करें',
      TOOLTIP_TEXT_MIDDLE_NAME: 'अगर आपके पास कोई मध्य नाम है तो दर्ज करें',
      TOOLTIP_TEXT_LAST_NAME: 'अंतिम नाम दर्ज करें',
      TOOLTIP_TEXT_NICK_NAME: 'यह आपका उपनाम है',
      TOOLTIP_TEXT_FULL_NAME: 'यह आपके पहले नाम, मध्य नाम(जीवन में अगर कोई है), और अंतिम नाम का संयोजन है। यह केवल प्रदर्शन के लिए है।',
      TOOLTIP_TEXT_NOOSOM_WEB_URL: 'यह आपका नूसोम वेब URL है। यह आपके उपयोगकर्ता नाम से व्यक्तिगत। यह आपके ब्लॉग के लिए साझा करने के लिए URL है।',
      TOOLTIP_TEXT_NOOSOM_EMAIL: 'आपका ईमेल पता दो-पद्धति प्रमाणीकरण के लिए उपयोग किया जाता है; इस क्षेत्र को बदलने के लिए, कृपया अपने नूसोम प्रोफ़ाइल में जाएं।',
      TOOLTIP_TEXT_PERSONAL_URL: 'यह आपका व्यक्तिगत वेब URL है या आपका कंपनी / व्यवसाय URL है।',
      TOOLTIP_TEXT_USERNAME: 'यह आपका निर्धारित नूसोम उपयोगकर्ता नाम है, कृपया इस क्षेत्र को बदलने के लिए अपने नूसोम प्रोफ़ाइल में जाएं।',
      TOOLTIP_TEXT_DOB: 'यह हमारे साथ पंजीकृत जन्म की तारीख है; सुरक्षा कारणों के लिए, नूसोम का उपयोग केवल 12 वर्ष और उससे अधिक आयु के लिए किया जा सकता है।',
      TOOLTIP_TEXT_SEX_BIRTH: 'यह आपका जन्म के समय का लिंग है - पुरुष / महिला में से चुनें',
      TOOLTIP_TEXT_GENDER: 'यह आपकी पहचान का अभिव्यक्ति है',
      TOOLTIP_TEXT_RELIGION: 'यह धर्म है जिस पर आप विश्वास करते हैं',
      TOOLTIP_TEXT_ZODIAC: 'यह आपका ज्योतिषीय या राशिफल चिन्ह है, प्रत्येक माह के आधार पर',
      TOOLTIP_TEXT_CHINESE_ZODIAC: 'यह आपका जन्म के वर्ष के आधार पर जानवर चिन्ह है, अधिक जानकारी के लिए यहाँ देखें',
      TOOLTIP_TEXT_MOBILE: 'क्षेत्र कोड के साथ मोबाइल नंबर दर्ज करें',
      TOOLTIP_TEXT_DIARY_JOURNAL: 'अपनी पसंद के अनुसार डायरी या जर्नल चुनें, और नूसोम समस्त शेष को संभालेगा',
      TAB_LINK_TEXT_MY_NOOSOM_PROFILE: 'माई नुसम प्रोफाइल',
      CONTROL_LABEL_NOOSOM_USER_NAME: 'नुसम उपयोगकर्ता नाम',
      CONTROL_LABEL_FULL_NAME: 'पूरा नाम',
      CONTROL_LABEL_CALL_ME_NAME: 'मुझे बुलाने का नाम',
      CONTROL_LABEL_FIRST_NAME: 'पहला नाम',
      CONTROL_LABEL_MIDDLE_NAME: 'मध्य नाम',
      CONTROL_LABEL_LAST_NAME: 'उपनाम',
      CONTROL_LABEL_DATE_OF_BIRTH: 'जन्म की तारीख',
      CONTROL_LABEL_SEX_AT_BIRTH: 'जन्म के समय लिंग',
      CONTROL_LABEL_GENDER_IDENTIFY_AS: 'लिंग की पहचान इस प्रकार करें',
      CONTROL_LABEL_RELIGION: 'धर्म',
      CONTROL_LABEL_ZODIAC_SIGN: 'राशि चक्र चिन्ह',
      CONTROL_LABEL_LUNAR_SIGN: 'चीनी राशि चक्र चिन्ह',
      CONTROL_LABEL_NOOSOM_WEB_URL: 'नुसम वेब यूआरएल:',
      CONTROL_LABEL_PERSONAL_WEB_URL: 'व्यक्तिगत वेब यूआरएल:',
      CONTROL_LABEL_MOBILE_NUMBER: 'मोबाइल नंबर',
      CONTROL_LABEL_DIARY_OR_JOURNAL: 'डायरी या जर्नल',
      CONTROL_LABEL_DIARY: 'डायरी',
      CONTROL_LABEL_EMAIL_ADDRESS: 'ईमेल पता',
      CONTROL_LABEL_JOURNAL: 'जर्नल',
      CONTROL_LABEL_SHOW: 'प्रदर्शन',
      CONTROL_LABEL_SEARCH: 'खोजे',
      CONTROL_LABEL_ADD_EMAIL: 'ईमेल जोड़ें',
      PLACEHOLDER_SEX_AT_BIRTH: 'जन्म के समय सेक्स का चयन करें',
      PLACEHOLDER_GENDER_IDENTIFY_AS: 'लिंग का चयन करें',
      PLACEHOLDER_RELIGION: 'धर्म का चयन करें',
      PLACEHOLDER_ZODIAC_SIGN: 'राशि का चयन करें',
      PLACEHOLDER_LUNAR_SIGN: 'चीनी राशि चक्र चिन्ह का चयन करें',
      SWEET_ALERT_TITLE_SCHOOL_TEXT: 'क्या आप सुनिश्चित हैं कि आप इस स्कूल और इससे जुड़े सभी अनुलग्नकों को हटाना चाहते हैं?',
      SWEET_ALERT_TITLE_ADDRESS_TEXT: 'क्या आप सुनिश्चित हैं कि आप इस पते और इससे जुड़े सभी अनुलग्नकों को हटाना चाहते हैं?',
      SWEET_ALERT_TITLE_INSURANCE_TEXT: 'क्या आप सुनिश्चित हैं कि आप इस बीमा और इससे जुड़े सभी अनुलग्नकों को हटाना चाहते हैं?',
      SWEET_ALERT_TEXT: "आप इसे पूर्ववत नहीं कर पाएंगे!",
      SWEET_ALERT_CONFIRM_BUTTON_TEXT: 'हाँ, मिटा दो!',
      SWEET_ALERT_CANCEL_BUTTON_TEXT: 'रद्द करना',
      DATA_TABLE_HEADER_TEXT_SEQUENCE: 'क्रम',
      DATA_TABLE_HEADER_TEXT_ADDRESS_CODE: 'पता कोड',
      DATA_TABLE_HEADER_TEXT_NAME_FOR_THIS_ADDRESS: 'इस पते के लिए नाम',
      DATA_TABLE_HEADER_TEXT_ADDRESS: 'पता',
      DATA_TABLE_HEADER_TEXT_DATE_FROM: 'तारीख से',
      DATA_TABLE_HEADER_TEXT_DATE_TO: 'तारीख तक',
      DATA_TABLE_HEADER_TEXT_ACTIONS: 'कार्रवाई',
      DATA_TABLE_HEADER_TEXT_SCHOOL_NAME: 'विद्यालय का नाम',
      DATA_TABLE_HEADER_TEXT_EDUCATION_RECEIVED: 'शिक्षा प्राप्त',
      DATA_TABLE_HEADER_TEXT_COUNTRY: 'देश',
      TOAST_MESSAGE_ABOUTME_UPDATED_SUCCESSFULLY: 'मेरे बारे में डेटा सफलतापूर्वक अपडेट किया गया।',
      ERROR_MESSAGE_REQUIRED_NOOSOM_USER_NAME: 'नुसम उपयोगकर्ता नाम की आवश्यकता है!',
      ERROR_MESSAGE_MAX_LENGTH_50: 'आपने बहुत अधिक वर्ण टाइप किए हैं, अधिकतम अनुमत 50 है',
      ERROR_MESSAGE_MAX_LENGTH_100: 'आपने बहुत अधिक वर्ण टाइप किए हैं, अधिकतम अनुमत 100 है',
      ERROR_MESSAGE_MAX_LENGTH_250: 'आपने बहुत अधिक वर्ण टाइप किए हैं, अधिकतम अनुमत 250 है',
      ERROR_MESSAGE_MAX_LENGTH_12: 'आपने बहुत अधिक वर्ण टाइप किए हैं, अधिकतम अनुमत 12 है',
      ERROR_MESSAGE_REQUIRED_FULL_NAME: 'पूरा नाम आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_CALL_ME_NAME: 'मुझे बुलाने का नाम आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_FIRST_NAME: 'पहला नाम आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_MIDDLE_NAME: 'मध्य नाम आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_LAST_NAME: 'उपनाम की आवश्य है!',
      ERROR_MESSAGE_REQUIRED_DATE_OF_BIRTH: 'जन्म तिथि आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_SEX_AT_BIRTH: 'जन्म के समय की सेक्स आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_GENDER_IDENTIFY_AS: 'लिंग की पहचान आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_RELIGION: 'धर्म आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_ZODIAC_SIGN: 'राशि चक्र आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_LUNAR_SIGN: 'चंद्र चिन्ह आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_ADDRESSPOSTCODE: 'पोस्ट कोड आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_ADDRESSTOWN: 'शहर का पता आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_ADDRESS_STATE: 'राज्य का पता आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_ADDRESS_REGION: 'क्षेत्र आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_ADDRESS_COUNTRY: 'देश आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_NOOSOM_WEB_URL: 'नुसम वेब यूआरएल आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_PERSONAL_WEB_URL: 'व्यक्तिगत वेब यूआरएल आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_MOBILE_NUMBER: 'मोबाइल नंबर आवश्यक है!',
      ERROR_MESSAGE_EXISTED_EMAILADDRESS: 'वह ईमेल पता लिया गया है। कृपया दूसरा प्रयास करें।',
      ERROR_MESSAGE_EXISTED_MOBILENUMBER: 'वह मोबाइल नंबर लिया गया है। कृपया दूसरा प्रयास करें।',
      ERROR_MESSAGE_EXISTED_USERNAME: 'वह उपयोगकर्ता नाम लिया गया है। कृपया दूसरा प्रयास करें।',
      ERROR_MESSAGE_INVALID_MOBILE_NUMBER: 'अमान्य मोबाइल संपर्क नंबर',
      ERROR_MESSAGE_EXIST_EMAIL: 'यह ईमेल पता पहले से ही आपके किसी मित्र या परिवार के सदस्य से जुड़ा हुआ है। कृपया कोई अन्य ईमेल पता उपयोग करें।'
    },
    ESSENTIAL_INFORMATION: {
      BUTTON_TEXT_ADD_AN_INSURANCE: 'एक बीमा जोड़ें',
      BUTTON_TEXT_ADD_A_PROFFESIONAL: 'एक पेशेवर जोड़ें',
      CONTROL_LABEL_BLOOD_GROUP: 'रक्त समूह',
      CONTROL_LABEL_ORGAN_DONOR: 'अंग दान करने वाला',
      CONTROL_LABEL_YES: 'हां',
      CONTROL_LABEL_NO: 'नहीं',
      CONTROL_LABEL_ALLERGIES: 'एलर्जी',
      CONTROL_LABEL_RELATIONSHIP: 'रिश्ता',
      CONTROL_LABEL_EMERGENCY_CONTACT_FULLNAME: 'आपातकालीन संपर्क नाम',
      CONTROL_LABEL_EMERGENCY_CONTACT_NUMBER: 'आपातकालीन संपर्क नंबर',
      CONTROL_LABEL_GENERAL_MEDICAL_HISTORY: 'सामान्य चिकित्सा इतिहास',
      CONTROL_LABEL_FAMILY_MEDICAL_HISTORY: 'परिवार के मेडिकल इतिहास',
      CONTROL_LABEL_SURGICAL_HISTORY: 'सर्जिकल इतिहास',
      CONTROL_LABEL_POST_CARE_NOTES: 'पोस्ट केयर नोट्स',
      DATA_TABLE_HEADER_TEXT_INSURANCE_TYPE: 'बीमा प्रकार',
      DATA_TABLE_HEADER_TEXT_ACTIONS: 'कार्रवाई',
      DATA_TABLE_HEADER_TEXT_INSURANCE_NAME: 'बीमा का नाम',
      DATA_TABLE_HEADER_TEXT_INSURANCE_POLICY_NUMBER: 'बीमा पॉलिसी संख्या',
      DATA_TABLE_HEADER_TEXT_CARD_NUMBER: 'कार्ड नंबर',
      DATA_TABLE_HEADER_TEXT_INSURANCE_CONTACT_NUMBER: 'बीमा संपर्क नंबर',
      DATA_TABLE_HEADER_TEXT_STATUS: 'स्थति',
      DATA_TABLE_HEADER_TEXT_POLICY_START_DATE: 'नीति प्रारंभ तिथि',
      DATA_TABLE_HEADER_TEXT_POLICY_EXPIRATION_DATE: 'पॉलिसी की समाप्ति तिथि',
      DATA_TABLE_HEADER_TEXT_SPECIALITY: 'स्पेशलिटी',
      DATA_TABLE_HEADER_TEXT_PROFESSIONAL_NAME: "पेशेवर का नाम",
      DATA_TABLE_HEADER_TEXT_FULL_ADDRESS: 'पूरा पता',
      DATA_TABLE_HEADER_TEXT_EMAIL_ADDRESS: 'ई-मेल एड्रेस',
      DATA_TABLE_HEADER_TEXT_CONTACT_NUMBER: 'संपर्क नंबर 1',
      DATA_TABLE_HEADER_TEXT_LAST_EXAM_DATE: 'अंतिम परीक्षा तिथि',
      DATA_TABLE_HEADER_TEXT_NEXT_EXAM_DATE: 'अगली परीक्षा तिथि',
      PLACEHOLDER_SELECT_BLOOD_GROUP: 'ब्लड ग्रुप का चयन करें',
      PLACEHOLDER_SELECT_RELATIONSHIP: 'संबंध चुनें',
      SECTION_HEADING_MY_DEMOGRAPHICS: 'मेरी जनसांख्यिकी',
      SECTION_HEADING_MEDICAL_HISTORY: 'चिकित्सा का इतिहास',
      SECTION_HEADING_MY_INSURANCE_INFORMATION: 'मेरी बीमा जानकारी',
      SECTION_HEADING_MY_HEALTH_PROVIDERS: 'मेरे स्वास्थ्य प्रदाता',
      TAB_HEADING_MY_DEMOGRAPHICS: 'मेरा जनसांख्यिकी और चिकित्सा इतिहास',
      TAB_HEADING_GENERAL_MANAGEMENT: 'सामान्य प्रबंधन',
      TAB_HEADING_MEDICATIONS: 'दवाएं',
      TAB_HEADING_MY_VACCINATION_RECORD: 'मेरा टीकाकरण रिकॉर्ड',
      TOOLTIPTEXT_CONTROL_LABEL_GENERAL_MEDICAL_HISTORY: 'ये क्षेत्र वैकल्पिक हैं। अपनी सर्वश्रेष्ठ क्षमता में प्रवेश करें.',
      FORM_DESCRIPTION: 'यह फ़ॉर्म आपके स्वास्थ्य व्यवस्थापक के पास जाने पर आपके कागज़ से छुटकारा दिलाता है। उनके पास आपके स्वास्थ्य पर एक उल्लेखनीय दृष्टि होती है।',
      TOOLTIP_TEXT_BLOOD_GROUP: 'अपना रक्त समूह चुनें',
      TOOLTIP_TEXT_ORGAN_DONOR: 'आपकी मौत के मामले में, अगर आपने अंगदानकर्ता के रूप में हाँ चुना है, तो आपके परिवार को आवश्यक दान और अंतिम अंतिम कार्यों के लिए इस सूचना को देख सकता है',
      TOOLTIP_TEXT_ALLERGIES: 'यह उन लोगों के लिए है जो दवाओं, खाद्य और अन्य मौसमी एलर्जी के लिए बहुत बुरी प्रतिक्रिया प्राप्त करते हैं',
      TOOLTIP_TEXT_EMERGENCY_CONTACT_INFO: 'आपातकालीन समय में तत्काल परिवार के सदस्य की जानकारी',
      TOOLTIP_TEXT_RELATIONSHIP_TO_EMERGENCY_CONTACT: 'आपातकालीन समय में तत्काल परिवार के सदस्य के संबंध की जानकारी',
      TOOLTIP_TEXT_EMERGENCY_CONTACT_MOBILE: 'आपातकालीन समय में संपर्क करने के लिए किसी के मोबाइल नंबर को क्षेत्र कोड के साथ दर्ज करें',
      TOAST_MESSAGE_ESSENTIAL_ADDED_SUCCESSFULLY: 'रिकॉर्ड सफलतापूर्वक जोड़ा गया',
      TOAST_MESSAGE_ESSENTIAL_UPDATED_SUCCESSFULLY: 'रिकॉर्ड सफलतापूर्वक अपडेट किया गया',
    },
    MY_MEDICATION: {
      BUTTON_TEXT_ADD_A_MEDICATION: 'एक दवा जोड़ें',
      DATA_TABLE_HEADER_TEXT_MEDICATION_NAME: 'दवा का नाम',
      DATA_TABLE_HEADER_TEXT_CONDITION_FOR: 'परिस्थिति',
      DATA_TABLE_HEADER_TEXT_DOSAGE: 'मात्रा निर्धारण',
      DATA_TABLE_HEADER_TEXT_FREQUENCY: 'आवृत्ति',
      DATA_TABLE_HEADER_TEXT_PRESCRIBED_BY: 'निर्धारित',
      DATA_TABLE_HEADER_TEXT_LAST_REFILL_DATE: 'अंतिम रिफिल तिथि',
      DATA_TABLE_HEADER_TEXT_NEXT_REFILL_DATE: 'अगली रिफिल तिथि',
      DATA_TABLE_HEADER_TEXT_ACTIONS: 'कार्रवाई',
      SECTION_HEADING_MY_MEDICATIONS: 'मेरी दवाएं',
    },
    MY_VACCINATION: {
      BUTTON_TEXT_ADD_A_VACCINATION: 'एक टीकाकरण जोड़ें',
      BUTTON_TEXT_ADD_AN_AWARD: 'एक पुरस्कार जोड़ें',
      BUTTON_TEXT_ADD_AN_ACHIEVEMENT: 'एक उपलब्धि जोड़ें',
      BUTTON_TEXT_CANCEL: 'रद्द करें',
      BUTTON_TEXT_ADD_AN_EDUCATIONAL_ACHIEVEMENT: 'एक शैक्षिक उपलब्धि जोड़ें',
      BUTTON_TEXT_ADD_AN_PROFESSIONAL_ACHIEVEMENT: 'एक व्यावसायिक उपलब्धि जोड़ें',
      BUTTON_TEXT_ADD_AN_MISCELLANEOUS_ACHIEVEMENT: 'एक विविध उपलब्धि जोड़ें',
      CONTROL_LABEL_NAME_OF_THE_AWARD: 'पुरस्कार का नाम',
      CONTROL_LABEL_NAME_OF_THE_ACHIEVEMENT: 'उपलब्धि का नाम',
      CONTROL_LABEL_OCCASION: 'अवसर',
      CONTROL_LABEL_DATE_RECEIVED: 'प्राप्ति दिनांक',
      CONTROL_LABEL_OCCASION_NOTES: 'अवसर नोट्स',
      DIALOG_TITLE_ADD_ACHIEVEMENT: 'एक उपलब्धि जोड़ें',
      DIALOG_TITLE_EDIT_ACHIEVEMENT: 'एक उपलब्धि संपादित करें',
      DIALOG_TITLE_ADD_EDUCATIONAL_ACHIEVEMENT: 'एक शैक्षिक उपलब्धि जोड़ें',
      DIALOG_TITLE_ADD_PROFESSIONAL_ACHIEVEMENT: 'एक व्यावसायिक उपलब्धि जोड़ें',
      DIALOG_TITLE_ADD_MISCELLANEOUS_ACHIEVEMENT: 'एक विविध उपलब्धि जोड़ें',
      DATA_TABLE_HEADER_TEXT_VACCINATION_NAME: 'टीकाकरण का नाम',
      DATA_TABLE_HEADER_TEXT_VACCINATION_TYPE: 'टीकाकरण का प्रकार',
      DATA_TABLE_HEADER_TEXT_LAST_DOSAGE_DATE: 'अंतिम खुराक की तारीख',
      DATA_TABLE_HEADER_TEXT_NEXT_DOSAGE_DATE: 'अगली खुराक की तारीख',
      DATA_TABLE_HEADER_TEXT_ACTIONS: 'कार्रवाई',
      DATA_TABLE_HEADER_TEXT_NAME_OF_THE_AWARD: 'पुरस्कार का नाम',
      DATA_TABLE_HEADER_TEXT_NAME_OF_THE_ACHIEVEMENT: 'उपलब्धि का नाम',
      DATA_TABLE_HEADER_TEXT_OCCASION: 'अवसर',
      DATA_TABLE_HEADER_TEXT_OCCASION_NOTES: 'अवसर नोट्स',
      DATA_TABLE_HEADER_TEXT_DATE_RECEIVED: 'प्राप्ति दिनांक',
      ERROR_MESSAGE_REQUIRED_ACHIEVEMENT_NAME: 'उपलब्धि का नाम आवश्यक है!',
      SECTION_HEADING_MY_AWARDS: 'मेरे पुरस्कार',
      SECTION_HEADING_MY_ACHIEVEMENT: 'मेरी उपलब्धियाँ',
      SECTION_HEADING_MY_EDUCATIONAL_ACHIEVEMENT: 'मेरी शैक्षिक उपलब्धियां',
      SECTION_HEADING_MY_PROFESSIONAL_ACHIEVEMENT: 'मेरी व्यावसायिक उपलब्धियां',
      SECTION_HEADING_MY_MISCELLANEOUS_ACHIEVEMENT: 'मेरी विविध उपलब्धियां',
      SECTION_TEXT: 'अपनी उपलब्धियों के बारे में कुछ जानकारी जोड़ें',
      SECTION_HEADING_MY_VACCINATION_RECORD: 'मेरा टीकाकरण रिकॉर्ड',
      TOOLTIP_TOGGLE_CALENDAR: 'कैलेंडर टॉगल करें',
      TOAST_MESSAGE_ACHIEVEMENT_ADDED_SUCCESSFULLY: 'उपलब्धि सफलतापूर्वक जोड़ी गई',
      TOAST_MESSAGE_ACHIEVEMENT_UPDATED_SUCCESSFULLY: 'उपलब्धि सफलतापूर्वक अपडेट की गई',
      TAB_HEADING_MY_AWARDS: 'मेरे पुरस्कार',
      TAB_HEADING_MY_ACHIEVEMENT: 'मेरी उपलब्धियाँ',
    },
    MY_AWARDS_ACHIEVEMENT: {
      BUTTON_TEXT_ADD_AN_ACHIEVEMENT: 'एक उपलब्धि जोड़ें',
      BUTTON_TEXT_CANCEL: 'रद्द करें',
      BUTTON_TEXT_ADD_AN_EDUCATIONAL_ACHIEVEMENT: 'एक शैक्षिक उपलब्धि जोड़ें',
      BUTTON_TEXT_ADD_AN_PROFESSIONAL_ACHIEVEMENT: 'एक व्यावसायिक उपलब्धि जोड़ें',
      BUTTON_TEXT_ADD_AN_MISCELLANEOUS_ACHIEVEMENT: 'एक विविध उपलब्धि जोड़ें',
      BUTTON_TEXT_ADD_AN_AWARD: 'एक पुरस्कार जोड़ें',
      CONTROL_LABEL_NAME_OF_THE_AWARD: 'पुरस्कार का नाम',
      CONTROL_LABEL_NAME_OF_THE_ACHIEVEMENT: 'उपलब्धि का नाम',
      CONTROL_LABEL_OCCASION: 'अवसर',
      CONTROL_LABEL_DATE_RECEIVED: 'प्राप्ति दिनांक',
      CONTROL_LABEL_OCCASION_NOTES: 'अवसर नोट्स',
      DIALOG_TITLE_ADD_ACHIEVEMENT: 'एक उपलब्धि जोड़ें',
      DIALOG_TITLE_EDIT_ACHIEVEMENT: 'एक उपलब्धि संपादित करें',
      DIALOG_TITLE_ADD_EDUCATIONAL_ACHIEVEMENT: 'एक शैक्षिक उपलब्धि जोड़ें',
      DIALOG_TITLE_ADD_PROFESSIONAL_ACHIEVEMENT: 'एक व्यावसायिक उपलब्धि जोड़ें',
      DIALOG_TITLE_ADD_MISCELLANEOUS_ACHIEVEMENT: 'एक विविध उपलब्धि जोड़ें',
      DATA_TABLE_HEADER_TEXT_NAME_OF_THE_AWARD: 'पुरस्कार का नाम',
      DATA_TABLE_HEADER_TEXT_NAME_OF_THE_ACHIEVEMENT: 'उपलब्धि का नाम',
      DATA_TABLE_HEADER_TEXT_OCCASION: 'अवसर',
      DATA_TABLE_HEADER_TEXT_OCCASION_NOTES: 'अवसर नोट्स',
      DATA_TABLE_HEADER_TEXT_DATE_RECEIVED: 'प्राप्ति दिनांक',
      DATA_TABLE_HEADER_TEXT_ACTIONS: 'कार्रवाई',
      ERROR_MESSAGE_REQUIRED_ACHIEVEMENT_NAME: 'उपलब्धि का नाम आवश्यक है!',
      SECTION_HEADING_MY_ACHIEVEMENT: 'मेरी उपलब्धियाँ',
      SECTION_HEADING_MY_EDUCATIONAL_ACHIEVEMENT: 'मेरी शैक्षिक उपलब्धियां',
      SECTION_HEADING_MY_PROFESSIONAL_ACHIEVEMENT: 'मेरी व्यावसायिक उपलब्धियां',
      SECTION_HEADING_MY_MISCELLANEOUS_ACHIEVEMENT: 'मेरी विविध उपलब्धियां',
      SECTION_HEADING_MY_AWARDS: 'मेरे पुरस्कार',
      SECTION_TEXT: 'अपनी उपलब्धियों के बारे में कुछ जानकारी जोड़ें',
      TAB_HEADING_MY_AWARDS: 'मेरे पुरस्कार',
      TAB_HEADING_MY_ACHIEVEMENT: 'मेरी उपलब्धि',
      TOOLTIP_TOGGLE_CALENDAR: 'कैलेंडर टॉगल करें',
      TOAST_MESSAGE_ACHIEVEMENT_ADDED_SUCCESSFULLY: 'उपलब्धि सफलतापूर्वक जोड़ी गई',
      TOAST_MESSAGE_ACHIEVEMENT_UPDATED_SUCCESSFULLY: 'उपलब्धि सफलतापूर्वक अपडेट की गई',
    },
    MY_MEDICATION_FORM: {
      BUTTON_TEXT_ADD_A_MEDICATION: 'एक दवा जोड़ें',
      BUTTON_TEXT_CANCEL: 'रद्द करें',
      CONTROL_LABEL_MEDICATION_NAME: 'दवा का नाम',
      CONTROL_LABEL_CONDITION_FOR: 'परिस्थिति',
      CONTROL_LABEL_DOSAGE: 'मात्रा निर्धारण',
      CONTROL_LABEL_FREQUENCY: 'आवृत्ति',
      CONTROL_LABEL_PRESCRIBED_BY: 'निर्धारित',
      CONTROL_LABEL_REFILL_DURATION: 'रिफिल अवधि',
      CONTROL_LABEL_LAST_REFILL_DATE: 'अंतिम रिफिल तिथि',
      CONTROL_LABEL_NEXT_REFILL_DATE: 'अगली रिफिल तिथि',
      ERROR_MESSAGE_REQUIRED_MEDICATION_NAME: 'दवा का नाम आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_CONDITION_FOR: 'परिस्थिति आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_FREQUENCY: 'आवृत्ति आवश्यक है!',
      CONTROL_LABEL_NOTES: 'टिप्पणियाँ',
      DIALOG_TITLE_ADD_MEDICATION: 'एक दवा जोड़ें',
      PLACEHOLDER_SELECT_PROFESSIONAL_NAME: "प्रोफ़ेशनल का नाम चुनें",
      PLACEHOLDER_SELECT_REFILL_DURATION: 'रिफिल अवधि का चयन करें',
      SECTION_TEXT: 'अपनी दवाओं के बारे में कुछ जानकारी जोड़ें',
      TOOLTIP_TOGGLE_CALENDAR: 'कैलेंडर टॉगल करें',
      TOOLTIP_TEXT_MEDICATION_NAME: 'दवा का नाम जो आपको निर्धारित किया गया है या आप ले रहे हैं',
      TOOLTIP_TEXT_MEDICAL_CONDITION: 'वह चिकित्सा अवस्था या लक्षण जिसका उपचार या रोकथाम के लिए दवा का उपयोग किया जाता है',
      TOOLTIP_TEXT_AMOUNT_OF_MEDICATION: 'एक बार में दी जाने वाली या ली जाने वाली दवा की मात्रा',
      TOOLTIP_TEXT_MEDICATION_FREQUENCY: 'दिन में या हफ्ते में दवा दी जाने या ली जाने की बार या अंतराल की संख्या',
      TOOLTIP_TEXT_PRESCRIBING_DOCTOR: 'डॉक्टर या स्वास्थ्य सेवा प्रदाता का नाम जिसने आपको इस दवा का निर्धारण किया है',
      TOOLTIP_TEXT_REFILL_SUPPLY_DURATION: 'अगली रिफ़िल आपूर्ति तक कितने दिन / हफ्ते / महीने',
      TOOLTIP_TEXT_LAST_SUPPLY_DATE: 'तारीख जब आपने अपनी अंतिम बार फार्मेसी या स्वास्थ्य सेवा प्रदाता से दवा की नई आपूर्ति प्राप्त की थी',
      TOOLTIP_TEXT_NEXT_SUPPLY_DATE: 'तारीख जब आपको फार्मेसी या स्वास्थ्य सेवा प्रदाता से दवा की नई आपूर्ति की आवश्यकता होगी',
      TOOLTIP_TEXT_MEDICATION_NOTES: 'इस दवा के लिए आपके पास कोई विशेष नोट्स जोड़ें'
    },
    AWARD: {
      BUTTON_TEXT_ADD_AN_AWARD: 'एक पुरस्कार जोड़ें',
      BUTTON_TEXT_CANCEL: 'रद्द करें',
      CONTROL_LABEL_NAME_OF_THE_AWARD: 'पुरस्कार का नाम',
      CONTROL_LABEL_OCCASION: 'अवसर',
      CONTROL_LABEL_DATE_RECEIVED: 'प्राप्ति दिनांक',
      CONTROL_LABEL_OCCASION_NOTES: 'अवसर नोट्स',
      DIALOG_TITLE_ADD_AWARD: 'एक पुरस्कार जोड़ें',
      DIALOG_TITLE_EDIT_AWARD: 'एक पुरस्कार संपादित करें',
      ERROR_MESSAGE_REQUIRED_AWARD_NAME: 'पुरस्कार का नाम आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_OCCASION: 'अवसर की आवश्यकता है!',
      ERROR_MESSAGE_REQUIRED_DATE_RECEIVED: 'प्राप्त तिथि आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_OCCASION_NOTES: 'अवसर नोट्स आवश्यक है!',
      SECTION_TEXT: 'अपने पुरस्कारों के बारे में कुछ जानकारी जोड़ें',
      TOOLTIP_TOGGLE_CALENDAR: 'कैलेंडर टॉगल करें',
      TOOLTIP_TEXT_AWARD_NAME: 'पुरस्कार का नाम दर्ज करें',
      TOOLTIP_TEXT_OCCASION_EVENT_NAME: 'उस अवसर या इवेंट का नाम दर्ज करें जहां आपको पुरस्कार प्राप्त हुआ था',
      TOOLTIP_TEXT_AWARD_DATE: 'पुरस्कार प्राप्त की गई तिथि दर्ज करें',
      TOOLTIP_TEXT_AWARD_NOTES: 'इस पुरस्कार के बारे में कोई विशेष नोट्स दर्ज करें',
      TOAST_MESSAGE_AWARD_ADDED_SUCCESSFULLY: 'पुरस्कार सफलतापूर्वक जोड़ा गया',
      TOAST_MESSAGE_AWARD_UPDATED_SUCCESSFULLY: 'पुरस्कार सफलतापूर्वक अपडेट किया गया'
    },
    MY_VACCINATION_FORM: {
      BUTTON_TEXT_ADD_A_VACCINATION: 'टीकाकरण जोड़ें',
      BUTTON_TEXT_CANCEL: 'रद्द करें',
      CONTROL_LABEL_VACCINATION_NAME: 'टीकाकरण का नाम',
      CONTROL_LABEL_OTHER: 'अन्य',
      CONTROL_LABEL_VACCINATION_TYPE: 'टीकाकरण का प्रकार',
      CONTROL_LABEL_LAST_DOSAGE_DATE: 'अंतिम खुराक की तारीख',
      CONTROL_LABEL_NEXT_DOSAGE_DATE: 'अगली खुराक की तारीख',
      CONTROL_LABEL_NOTES: 'टिप्पणियाँ',
      DIALOG_TITLE_ADD_VACCINATION: 'एक टीकाकरण जोड़ें',
      ERROR_MESSAGE_REQUIRED_VACCINATION_NAME: 'टीकाकरण का नाम आवश्यक है!',
      PLACEHOLDER_SELECT_VACCINATION_NAME: "टीकाकरण का नाम चुनें",
      SECTION_TEXT: 'अपने टीकाकरण के बारे में कुछ जानकारी जोड़ें',
      TOOLTIP_TOGGLE_CALENDAR: 'कैलेंडर टॉगल करें'
    },
    MY_FAMILIES: {
      BUTTON_TEXT_SEARCH_ON_MAP: 'मानचित्र पर खोजें',
      BUTTON_TEXT_ADD_AN_OWN_FAMILY_MEMBER: 'अपने परिवार के सदस्य को जोड़ें',
      BUTTON_TEXT_ADD_A_BIRTH_FAMILY_MEMBER: 'जन्म परिवार सदस्य जोड़ें',
      BUTTON_TEXT_ADD_AN_OTHER_FAMILY_MEMBER: 'अन्य परिवार के सदस्य को जोड़ें',
      BUTTON_TEXT_ADD_A_FRIEND: 'मित्र को जोड़ें',
      BUTTON_TEXT_ADD_OWN_FAMILY: 'एक परिवार के सदस्य को जोड़ें',
      BUTTON_TEXT_ADD_BIRTH_FAMILY: 'एक जन्म परिवार सदस्य जोड़ें',
      BUTTON_TEXT_ADD_OTHER_FAMILY: 'एक सदस्य अन्य परिवार जोड़ें',
      CONTROL_LABEL_FIRST_NAME: 'पहला नाम',
      CONTROL_LABEL_MIDDLE_NAME: 'मध्य नाम',
      CONTROL_LABEL_LAST_NAME: 'उपनाम',
      CONTROL_LABEL_DOB: 'जन्म की तारीख',
      CONTROL_LABEL_CONTACT1: 'संपर्क संख्या 1',
      CONTROL_LABEL_CONTACT2: 'वैकल्पिक संपर्क संख्या',
      CONTROL_LABEL_ADDRESS1: 'सड़क का नाम',
      CONTROL_LABEL_ADDRESS2: 'पता पंक्ति 2',
      CONTROL_LABEL_ADDRESS3: 'पता पंक्ति 3',
      CONTROL_LABEL_REGION: 'क्षेत्र',
      CONTROL_LABEL_COUNTRY: 'देश',
      CONTROL_LABEL_ADDRESS_STATE: 'राज्य',
      CONTROL_LABEL_ADDRESS_TOWN: 'टाउन / शहर',
      CONTROL_LABEL_ADDRESS_POSTALCODE: 'पोस्ट / ज़िप कोड',
      CONTROL_LABEL_RELATIONSHIP: 'रिश्ता',
      CONTROL_LABEL_EMAIL_ADDRESS: 'ई-मेल एड्रेस',
      CONTROL_LABEL_PERSONAL_URL: 'व्यक्तिगत वेब यूआरएल',
      CONTROL_LABEL_NOOSOM_URL: 'नोमसोम वेब यूआरएल',
      DATA_TABLE_HEADER_TEXT_FIRST_NAME: 'पहला नाम',
      DATA_TABLE_HEADER_TEXT_LAST_NAME: 'सरनेम',
      DATA_TABLE_HEADER_TEXT_DATE_OF_BIRTH: 'जन्म की तारीख',
      DATA_TABLE_HEADER_TEXT_RELATIONSHIP: 'रिश्ता',
      DATA_TABLE_HEADER_TEXT_EMAIL_ADDRESS: 'ई-मेल एड्रेस',
      DATA_TABLE_HEADER_TEXT_PERSONAL_WEB_URL: 'व्यक्तिगत वेब यूआरएल',
      DATA_TABLE_HEADER_TEXT_NOOSOM_WEB_URL: 'नोमसोम वेब यूआरएल',
      DIALOG_TITLE_ADD_OWN_FAMILY_MEMBERS: 'अपने परिवार के सदस्यों को जोड़ें',
      DIALOG_TITLE_ADD_BIRTH_FAMILY_MEMBERS: 'एक जन्म परिवार सदस्य जोड़ें',
      DIALOG_TITLE_ADD_OTHER_FAMILY_MEMBERS: 'एक सदस्य अन्य परिवार जोड़ें',
      DIALOG_TITLE_ADD_A_FRIEND: 'एक मित्र जोड़ना',
      DIALOG_TITLE_EDIT_A_FRIEND: 'एक मित्र संपादित करें',
      DIALOG_TITLE_EDIT_FAMILY_MEMBERS: 'एक परिवार के सदस्य को संपादित करें',
      PLACEHOLDER_SELECT_REGION: 'क्षेत्र चुनें',
      PLACEHOLDER_SELECT_COUNTRY: 'देश चुनें',
      PLACEHOLDER_SELECT_STATE: 'राज्य चुनें',
      SECTION_HEADING_MY_OWN_FAMILY: 'मेरा अपना परिवार',
      SECTION_HEADING_MY_BIRTH_FAMILY: 'मेरा जन्म परिवार',
      SECTION_HEADING_MY_OTHER_FAMILY: 'मेरा अन्य परिवार',
      SECTION_HEADING_MY_FRIEND: 'मेरे मित्र',
      SECTION_TEXT: 'अपने परिवार के सदस्य के बारे में जानकारी जोड़ें। यदि उनके पास हमारे साथ नुसम प्रोफ़ाइल नहीं है, तो आप उन्हें एक ईमेल भेजकर नुसम खाता बनाने के लिए आमंत्रित कर सकते हैं।',
      SECTION_TEXT_FRIEND: 'अपने मित्र के बारे में जानकारी जोड़ें। यदि उनके पास हमारे साथ नुसम प्रोफ़ाइल नहीं है, तो आप उन्हें एक ईमेल भेजकर नुसम खाता बनाने के लिए आमंत्रित कर सकते हैं।',
      TOAST_MESSAGE_FAMILY_UPDATED_SUCCESSFULLY: 'परिवार के सदस्य को सफलतापूर्वक अपडेट किया गया',
      TOAST_MESSAGE_FAMILY_ADDED_SUCCESSFULLY: 'परिवार के सदस्य को सफलतापूर्वक जोड़ा गया',
      TOAST_MESSAGE_FRIEND_UPDATED_SUCCESSFULLY: 'मित्र सफलतापूर्वक अपडेट किया गया',
      TOAST_MESSAGE_FRIEND_ADDED_SUCCESSFULLY: 'मित्र सफलतापूर्वक जोड़ा गया',
      TOOLTIP_BIRTH_FAMILY: "माँ, पिताजी, दादी, दादा, भाई, बहन",
      TOOLTIP_OWN_FAMILY: "पति, पत्नी, बच्चे",
      TOOLTIP_OTHER_FAMILY: "भाई, बहन, विस्तारित परिवार",
      TOOLTIP_TEXT_FIRST_NAME: 'पहला नाम दर्ज करें',
      TOOLTIP_TEXT_MIDDLE_NAME: 'यदि आपके पास कोई भी मध्य नाम हैं तो उन्हें दर्ज करें',
      TOOLTIP_TEXT_LAST_NAME: 'अंतिम नाम दर्ज करें',
      TOOLTIP_TEXT_DATE_OF_BIRTH: 'जन्म की तारीख दर्ज करें',
      TOOLTIP_TEXT_RELATIONSHIP: 'यह व्यक्ति आपसे कैसे संबंधित है? क्या वे आपकी मां, पिता, बहन(ओं) या भाई(ओं) हैं?',
      TOOLTIP_TEXT_EMAIL: 'परिवार के सदस्य का ईमेल पता दर्ज करें',
      TOOLTIP_TEXT_FRIENDS_EMAIL: 'मित्र के सदस्य का ईमेल पता दर्ज करें',
      TOOLTIP_TEXT_PERSONAL_URL: 'यह परिवार के सदस्य का व्यक्तिगत वेब URL या उनका कंपनी / व्यापार URL है',
      TOOLTIP_TEXT_NOOSOM_URL: 'परिवार के सदस्य का Noosom URL दर्ज करें ताकि उन्हें उनका Noosom ब्लॉग देखने के लिए कनेक्शन लिंक भेजा जा सके',
      TOOLTIP_TEXT_MOBILE: 'क्षेत्र कोड के साथ मोबाइल नंबर दर्ज करें',
      TOOLTIP_TEXT_ALTERNATE_CONTACT: 'क्षेत्र कोड के साथ एक वैकल्पिक संपर्क नंबर दर्ज करें',
      TOOLTIP_TEXT_STREET_NAME: 'यह स्थान को सटीकता से मैप करने के लिए एक आवश्यक फ़ील्ड है; कृपया सड़क का नाम सही दर्ज करें',
      TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_1: 'यह और अधिक सटीक स्थान के मैपिंग के लिए एक अतिरिक्त फ़ील्ड है',
      TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_2: 'यह और अधिक सटीक स्थान के मैपिंग के लिए एक अतिरिक्त फ़ील्ड है',
      TOOLTIP_TEXT_TOWN_VILLAGE_CITY: 'पते के गाँव, गाँव या शहर दर्ज करें',
      TOOLTIP_TEXT_POSTAL_ZIPCODE: 'यह पते के देश के संबंधित पोस्टल ज़िपकोड है',
      TOOLTIP_TEXT_REGION_CONTINENT: 'पते का क्षेत्र या महाद्वीप चुनें; उदाहरण के लिए, संयुक्त राज्य यूनाइटेड किंगडम यूरोप में होगा',
      TOOLTIP_TEXT_COUNTRY: 'पते का देश चुनें; उदाहरण के लिए, इंग्लैंड यूरोप में होगा',
      TOOLTIP_TEXT_STATE_PROVINCE: 'पते का राज्य या प्रांत चुनें; उदाहरण के लिए, रोटुमा फिजी में एक राज्य या द्वीप है',
      TOOLTIP_TEXT_FAMILY_MEMBER_NOTES: 'इस परिवार के सदस्य के लिए आपके पास कोई अन्य नोट्स हो सकते हैं',
      TOOLTIP_TEXT_BIRTH_FAMILY_INFO: 'यह वह परिवार है जिसमें आप पैदा हुए थे, माँ, पिता, भाई, बहन, आदि',
      TOOLTIP_TEXT_OWN_FAMILY_INFO: 'आपका परिवार शादी द्वारा, पत्नी, पति, बच्चे, पोते-पोतियां',
      TOOLTIP_TEXT_OTHER_FAMILY_INFO: 'ससुराल वाले, चाची-चाचा, भतीजी-भतीजे आदि'
    },
    MY_JOBS: {
      BUTTON_TEXT_ADD_A_NEW_JOBS: 'नई नौकरी जोड़ें',
      BUTTON_TEXT_SEARCH_ON_MAP: 'नक्शे पर खोजें',
      CONTROL_LABEL_JOB_DESCRIPTION: 'नौकरी का विवरण',
      CONTROL_LABEL_EMPLOYER: 'नियोक्ता',
      CONTROL_LABEL_ADDRESS1: 'पता 1',
      CONTROL_LABEL_ADDRESS2: 'पता 2',
      CONTROL_LABEL_ADDRESS3: 'पता 3',
      CONTROL_LABEL_DATE_FROM: 'तारीख से',
      CONTROL_LABEL_DATE_TO: 'तारीख तक',
      CONTROL_LABEL_REGION: 'क्षेत्र',
      CONTROL_LABEL_COUNTRY: 'देश',
      CONTROL_LABEL_ADDRESS_STATE: 'राज्य',
      CONTROL_LABEL_ADDRESS_TOWN: 'शहर/टाउन',
      CONTROL_LABEL_ADDRESS_POSTALCODE: 'पोस्टल कोड',
      CONTROL_LABEL_CURRENT_JOBS: 'वर्तमान नौकरियां',
      DATA_TABLE_HEADER_TEXT_EMPLOYER: 'नियोक्ता',
      DATA_TABLE_HEADER_TEXT_JOB_DESCRIPTION: 'नौकरी का विवरण',
      DATA_TABLE_HEADER_TEXT_DATE_FROM: 'तिथि से',
      DATA_TABLE_HEADER_TEXT_DATE_TO: 'तिथि तक',
      DATA_TABLE_HEADER_TEXT_ACTIONS: 'कार्रवाई',
      DATA_TABLE_HEADER_TEXT_ADDRESS: 'पता',
      DATA_TABLE_HEADER_TEXT_NOTES: 'टिप्पणियाँ',
      DIALOG_TITLE_EDIT_JOBS: 'जॉब संपादित करें',
      ERROR_MESSAGE_JOB_FROM_DATE_REQUIRED: 'तारीख से की आवश्यकता है!',
      ERROR_MESSAGE_DATE_TO_REQUIRED: 'तारीख तक की आवश्यकता है!',
      PLACEHOLDER_SELECT_REGION: 'क्षेत्र चुनें',
      PLACEHOLDER_SELECT_COUNTRY: 'देश चुनें',
      PLACEHOLDER_SELECT_STATE: 'राज्य चुनें',
      SECTION_HEADING_MY_JOB: 'मेरी नौकरियाँ',
      SECTION_TEXT_JOBS: 'अपनी पिछली नौकरियों या वर्तमान नौकरियों के बारे में जानकारी जोड़ें।',
      TOOLTIP_TEXT_EMPLOYER_NAME: 'अपने नियोक्ता या कंपनी का नाम दर्ज करें',
      TOOLTIP_TEXT_JOB_ROLE: 'इस नौकरी में आपकी भूमिका(ओं) के बारे में जानकारी दर्ज करें',
      TOOLTIP_TEXT_CURRENT_JOB: 'यदि वर्तमान है - इस बॉक्स को चेक करें',
      TOOLTIP_TEXT_JOB_START_DATE: 'नौकरी शुरू होने की तिथि दर्ज करें',
      TOOLTIP_TEXT_JOB_END_DATE: 'नौकरी समाप्त होने की तिथि दर्ज करें',
      TOOLTIP_TEXT_STREET_NAME: 'यह स्थान को सटीकता से मैप करने के लिए एक आवश्यक फ़ील्ड है; कृपया सड़क का नाम सही दर्ज करें',
      TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_1: 'यह और अधिक सटीक स्थान के मैपिंग के लिए एक अतिरिक्त फ़ील्ड है',
      TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_2: 'यह और अधिक सटीक स्थान के मैपिंग के लिए एक अतिरिक्त फ़ील्ड है',
      TOOLTIP_TEXT_TOWN_VILLAGE_CITY: 'पते के गाँव, गाँव या शहर दर्ज करें',
      TOOLTIP_TEXT_POSTAL_ZIPCODE: 'यह पते के देश के संबंधित पोस्टल ज़िपकोड है',
      TOOLTIP_TEXT_REGION_CONTINENT: 'पते का क्षेत्र या महाद्वीप चुनें; उदाहरण के लिए, संयुक्त राज्य यूनाइटेड किंगडम यूरोप में होगा',
      TOOLTIP_TEXT_COUNTRY: 'पते का देश चुनें; उदाहरण के लिए, इंग्लैंड यूरोप में होगा',
      TOOLTIP_TEXT_STATE_PROVINCE: 'पते का राज्य या प्रांत चुनें; उदाहरण के लिए, रोटुमा फिजी में एक राज्य या द्वीप है',
      TOOLTIP_TEXT_JOB_NOTES: 'इस नौकरी के लिए आपके पास कोई अन्य नोट्स दर्ज करें',
      WARNING_MESSAGE_SELECT_LOCATION: 'कृपया मानचित्र पर स्थान का चयन करें।',
    }
  }
};
