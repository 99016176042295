import { Status } from "app/enums";

export const locale = {
  lang: 'pt',
  data: {
    COMMON:{
      ERROR_MESSAGE_CONNECTIONREFUSED:'Não foi possível conectar com o servidor'
    },
    SIGNIN: {
      WELCOME_TEXT: 'Bem-vindo ao Noosom',
      MOTIVATION_BADGE_SIGNIN:'Faça login e comece a aventura',
      CONTROL_LABEL_EMAIL:'Endereço de email',
      CONTROL_LABEL_PASSWORD:'Senha',
      CONTROL_LABEL_REMEMBERME:'Lembre de mim',
      PLACEHOLDER_EMAIL:'johndoe@gmail.com',
      LINK_TEXT_LABEL_FORGOTPASSWORD:'Esqueceu sua senha?',
      LINK_TEXT_LABEL_CREATEACCOUNT:'Crie a sua conta aqui',
      TEXT_NEWONPLATFORM:'Novo em nossa plataforma?',
      BUTTON_TEXT_LOGIN:'Conecte-se',
      TOAST_MESSAGE_SUCCESSFULLOGINMESSAGE:'Você fez login com sucesso como um usuário {0} no Noosom. Agora você pode começar a explorar. Apreciar! 🎉',
      TOAST_MESSAGE_SUCCESSFULLOGINWELCOMETEXT:'👋 Bem-vindo, {0}!',
      ERROR_MESSAGE_REQUIRED_EMAIL:'O campo de e-mail!',
      ERROR_MESSAGE_REQUIRED_PASSWORD:'O campo de senha!',
      ERROR_MESSAGE_INVALID_EMAIL:'O e-mail deve ser um endereço de e-mail válido',
      ERROR_MESSAGE_NOATTEMPTS:'Nome de usuário ou senha inválidos. Não há tentativas restantes.',
      LOGIN_STATUS:
      [
        {ErrorCode:Status.EmailNotVarified,ErrorMessage:'Seu endereço de e-mail não foi verificado.<br>primeiro varie seu endereço de e-mail.'},
        {ErrorCode:Status.AccountLocked,ErrorMessage:'Sua conta foi bloqueada.'},
        {ErrorCode:0,ErrorMessage:'Logado com sucesso.'},
        {ErrorCode:Status.InvalidCredential,ErrorMessage:'Nome de usuário ou senha inválidos.<br>Você tem {0} tentativas restantes.'}
      ],
      DIGITS:[1,2,3,4],
      ROLES:[{title:'Admin',value:'Administrador'},{titlle:'Client',value:'Cliente'},{title:'User',value:'Do utilizador'}]
    },
    SIGNUP:
    {
      STEPPER_TITLE_ACCOUNT:'Conta',
      STEPPER_SUB_TITLE_ACCOUNT:'Insira nome de usuário',
      STEPPER_TITLE_PERSONAL:'Pessoal',
      STEPPER_SUB_TITLE_PERSONAL:'Insira as informações',
      HEADING_ACCOUNT:'Informação da conta',
      HEADING_PERSONAL:'Informação pessoal',
      HEADING_SELECTPLAN:'Selecione o plano',
      CONTROL_LABEL_USERID:'ID de usuário Noosom',
      CONTROL_LABEL_EMAILADDRESS:'Endereço de email',
      CONTROL_LABEL_PASSWORD:'Senha',
      CONTROL_LABEL_CONFIRMPASSWORD:'Confirme sua senha',
      CONTROL_LABEL_REGION:'Sua região',
      CONTROL_LABEL_HOMECOUNTRY:'Seu país de',
      CONTROL_LABEL_FIRSTNAME:'Primeiro nome',
      CONTROL_LABEL_MIDDLENAME:'Nome do meio',
      CONTROL_LABEL_LASTNAME:'Sobrenome',
      CONTROL_LABEL_BIRTHDATE:'Data de nascimento',
      CONTROL_LABEL_MOBILENO:'Celular não',
      CONTROL_LABEL_SELECTPLAN:'Grátis para sempre por agora, ou compre mais tarde.',
      PLACEHOLDER_TYPEHERE:'Digite aqui...',
      PLACEHOLDER_PASSWORD:'Senha',
      PLACEHOLDER_REGION:'Selecione a região',
      PLACEHOLDER_COUNTRY:'Selecione o pais',
      PLACEHOLDER_TELEPHONENUMBER:'0123456789',
      BUTTON_TEXT_PREVIOUS:'Anterior',
      BUTTON_TEXT_NEXT:'Próxima',
      BUTTON_TEXT_SUBMIT:'Enviar',
      TOOLTIPTEXT_CONTROL_LABEL_REGION_1:'Seus dados serão armazenados',
      TOOLTIPTEXT_CONTROL_LABEL_REGION_2:'na região selecionada',
      TOOLTIPTEXT_CONTROL_LABEL_HOMECOUNTRY:'Selecione o país de',
      TOAST_MESSAGE_SUCCESSFULSIGNUP:'Conta criada com sucesso.',
      ERROR_MESSAGE_REQUIRED_USERID:'O campo de nome de usuário!',
      ERROR_MESSAGE_EXISTED_USERID:'Esse nome de usuário foi usado. Por favor, tente outro.',
      ERROR_MESSAGE_REQUIRED_EMAILADDRESS:'O campo de endereço de e-mail!',
      ERROR_MESSAGE_EXISTED_EMAILADDRESS:'Esse endereço de e-mail foi usado. Por favor, tente outro.',
      ERROR_MESSAGE_REQUIRED_PASSWORD:'O campo de senha!',
      ERROR_MESSAGE_INVALID_PASSWORD:'Deve conter pelo menos um número, uma letra maiúscula e uma minúscula e um mínimo de 8 ou mais caracteres.',
      ERROR_MESSAGE_MISMATCHED_PASSWORD:'A senha repetida deve corresponder.',
      ERROR_MESSAGE_REQUIRED_CONFIRMPASSWORD:'O campo de confirmação de senha!',
      ERROR_MESSAGE_REQUIRED_REGION:'O campo região!',
      ERROR_MESSAGE_REQUIRED_HOMECOUNTRY:'O campo do país de!',
      ERROR_MESSAGE_REQUIRED_FIRSTNAME:'O campo de nome!',
      ERROR_MESSAGE_REQUIRED_MIDDLENAME:'O campo de nome do meio!',
      ERROR_MESSAGE_REQUIRED_LASTNAME:'O campo sobrenome!',
      ERROR_MESSAGE_REQUIRED_BIRTHDATE:'O campo data de nascimento!',
      ERROR_MESSAGE_REQUIRED_MOBILENO:'O celular nenhum campo!',
      ERROR_MESSAGE_REQUIRED_SELECTPLAN:'Selecione a opção de plano gratuito!'
    }
  }
};
