import { Component, Inject, OnDestroy, OnInit, ElementRef, Renderer2, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as Waves from 'node-waves';

import { CoreMenuService } from 'app/core-menu/core-menu.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreLoadingScreenService } from '@core/services/loading-screen.service';
import { CoreTranslationService } from '@core/services/translation.service';

import { menu } from 'app/menu/menu';
import { locale as menuEnglish } from 'app/menu/i18n/en';
import { locale as menuHindi } from 'app/menu/i18n/hn';
import { locale as menuFrench } from 'app/menu/i18n/fr';
import { locale as menuGerman } from 'app/menu/i18n/de';
import { locale as menuPortuguese } from 'app/menu/i18n/pt';
import { AuthenticationService } from './auth/service/authentication.service';
import { Router } from '@angular/router';
import { GlobalService } from './Services/global.service';
import { ApplicationType, Enums, Status } from './enums';
import { CurrentVersionOutputModel } from './Models/version-history-model.model';
import { Constant } from './Classes/constant';
import { EncrDecrService } from './Services/encr-decr.service';
import { LoadingScreenService } from './Services/loading-screen.service';
import { UserDetailsInputModel } from './home/home-model.model';
import { CustomToastrService } from './Services/custom-toastr.service';
import { E } from 'assets/extensions/extensions';
import { HomeService } from './home/home/home.service';
import { storage, User } from './auth/models';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  coreConfig: any;
  menu: any;
  defaultLanguage: 'en'; // This language will be used as a fallback when a translation isn't found in the current language
  appLanguage: 'en'; // Set application default language i.e fr
  currentVersionOutputModel: CurrentVersionOutputModel;
  loading;
  tmplt = `<h1>Loading...</h1> <button (click)="showAlert()">Click me!</button>`
  loadingSubscription: Subscription;
  public error = '';
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {Title} _title
   * @param {Renderer2} _renderer
   * @param {ElementRef} _elementRef
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreLoadingScreenService} _coreLoadingScreenService
   * @param {CoreMenuService} _coreMenuService
   * @param {CoreTranslationService} _coreTranslationService
   * @param {TranslateService} _translateService
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _title: Title,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    public _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    private _coreLoadingScreenService: CoreLoadingScreenService,
    private _coreMenuService: CoreMenuService,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _toastrService: CustomToastrService,
    public homeservice:HomeService,
    private _globalService: GlobalService,
    private _encrDecrService: EncrDecrService,
    private loadingScreenService: LoadingScreenService
  ) {
    // Get the application main menu
    this.menu = menu;

    // Register the menu to the menu service
    this._coreMenuService.register('main', this.menu);

    // Set the main menu as our current menu
    this._coreMenuService.setCurrentMenu('main');

    // Add languages to the translation service
    this._translateService.addLangs(['en', 'fr', 'de', 'pt']);

    // This language will be used as a fallback when a translation isn't found in the current language
    this._translateService.setDefaultLang('en');

    // Set the translations for the menu
    this._coreTranslationService.translate(menuEnglish, menuFrench, menuGerman, menuPortuguese, menuHindi);

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  pageReloaded: boolean = window.performance.getEntriesByType('navigation').map((nav) => (nav as any).type).includes('reload');

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event): void {
    //debugger;
    //console.log(this.pageReloaded);
    if (this.pageReloaded == false) { // The pageReloaded boolean we set earlier
      let tabCount = parseInt(this._encrDecrService.get(Constant.EncrDecrKey,localStorage.getItem('tabCount')));
      --tabCount;
      localStorage.setItem('tabCount', this._encrDecrService.set(Constant.EncrDecrKey,tabCount.toString()));
      //console.log('tabCount' + tabCount);
      if (tabCount <= 0) { //Commented by BM temporary to resolve issue.
        //confirmation to leave
        //event.preventDefault();
        //event.returnValue = '';

        //logout
        //this._authenticationService.logout();
      }
    }
  }
  @HostListener('window:onclose', ['$event'])
  closedWin(event): void {
    //console.log('onclose called.');
    localStorage.setItem('tabCount', this._encrDecrService.set(Constant.EncrDecrKey,"0"));
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  async ngOnInit(): Promise<any> {
    // Init wave effect (Ripple effect)
    if(this._authenticationService.currentUserValue)
      { await this.loadUserFromDetailsId()};
    
    Waves.init();
   
    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      // Set application default language.

      // Change application language? Read the ngxTranslate Fix

      // ? Use app-config.ts file to set default language
      const appLanguage = this.coreConfig.app.appLanguage || 'en';
      this._translateService.use(appLanguage);

      // ? OR
      // ? User the current browser lang if available, if undefined use 'en'
      // const browserLang = this._translateService.getBrowserLang();
      // this._translateService.use(browserLang.match(/en|fr|de|pt/) ? browserLang : 'en');

      /**
       * ! Fix : ngxTranslate
       * ----------------------------------------------------------------------------------------------------
       */

      /**
       *
       * Using different language than the default ('en') one i.e French?
       * In this case, you may find the issue where application is not properly translated when your app is initialized.
       *
       * It's due to ngxTranslate module and below is a fix for that.
       * Eventually we will move to the multi language implementation over to the Angular's core language service.
       *
       **/

      // Set the default language to 'en' and then back to 'fr'.

      setTimeout(() => {
        this._translateService.setDefaultLang('en');
        this._translateService.setDefaultLang(appLanguage);
      });

      /**
       * !Fix: ngxTranslate
       * ----------------------------------------------------------------------------------------------------
       */

      // Layout
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove(
        'vertical-layout',
        'vertical-menu-modern',
        'horizontal-layout',
        'horizontal-menu'
      );
      // Add class based on config options
      if (this.coreConfig.layout.type === 'vertical') {
        this._elementRef.nativeElement.classList.add('vertical-layout', 'vertical-menu-modern');
      } else if (this.coreConfig.layout.type === 'horizontal') {
        this._elementRef.nativeElement.classList.add('horizontal-layout', 'horizontal-menu');
      }

      // Navbar
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove(
        'navbar-floating',
        'navbar-static',
        'navbar-sticky',
        'navbar-hidden'
      );

      // Add class based on config options
      if (this.coreConfig.layout.navbar.type === 'navbar-static-top') {
        this._elementRef.nativeElement.classList.add('navbar-static');
      } else if (this.coreConfig.layout.navbar.type === 'fixed-top') {
        this._elementRef.nativeElement.classList.add('navbar-sticky');
      } else if (this.coreConfig.layout.navbar.type === 'floating-nav') {
        this._elementRef.nativeElement.classList.add('navbar-floating');
      } else {
        this._elementRef.nativeElement.classList.add('navbar-hidden');
      }

      // Footer
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove('footer-fixed', 'footer-static', 'footer-hidden');

      // Add class based on config options
      if (this.coreConfig.layout.footer.type === 'footer-sticky') {
        this._elementRef.nativeElement.classList.add('footer-fixed');
      } else if (this.coreConfig.layout.footer.type === 'footer-static') {
        this._elementRef.nativeElement.classList.add('footer-static');
      } else {
        this._elementRef.nativeElement.classList.add('footer-hidden');
      }

      // Blank layout
      if (
        this.coreConfig.layout.menu.hidden &&
        this.coreConfig.layout.navbar.hidden &&
        this.coreConfig.layout.footer.hidden
      ) {
        this._elementRef.nativeElement.classList.add('blank-page');
        // ! Fix: Transition issue while coming from blank page
        this._renderer.setAttribute(
          this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
          'style',
          'transition:none'
        );
      } else {
        this._elementRef.nativeElement.classList.remove('blank-page');
        // ! Fix: Transition issue while coming from blank page
        setTimeout(() => {
          this._renderer.setAttribute(
            this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
            'style',
            'transition:300ms ease all'
          );
        }, 0);
        // If navbar hidden
        if (this.coreConfig.layout.navbar.hidden) {
          this._elementRef.nativeElement.classList.add('navbar-hidden');
        }
        // Menu (Vertical menu hidden)
        if (this.coreConfig.layout.menu.hidden) {
          this._renderer.setAttribute(this._elementRef.nativeElement, 'data-col', '1-column');
        } else {
          this._renderer.removeAttribute(this._elementRef.nativeElement, 'data-col');
        }
        // Footer
        if (this.coreConfig.layout.footer.hidden) {
          this._elementRef.nativeElement.classList.add('footer-hidden');
        }
      }

      // Skin Class (Adding to body as it requires highest priority)
      if (this.coreConfig.layout.skin !== '' && this.coreConfig.layout.skin !== undefined) {
        this.document.body.classList.remove('default-layout', 'bordered-layout', 'dark-layout', 'semi-dark-layout');
        this.document.body.classList.add(this.coreConfig.layout.skin + '-layout');
      }
    });

    //debugger;
    // We need to parse into integer since local storage can only
    // store strings.
    //console.log('ngOnInit called');
    //console.log(localStorage.getItem("tabCount"));
    //console.log(parseInt(localStorage.getItem("tabCount")));
    let tabCount = parseInt(this._encrDecrService.get(Constant.EncrDecrKey,localStorage.getItem("tabCount")));  // Then we instantiate tabCount if it doesn't already exist
    //console.log(Number.isNaN(tabCount));
    //console.log(++tabCount);
    // OR Increment by 1 if it already exists
    if (this.pageReloaded == false)
      tabCount = Number.isNaN(tabCount) ? 1 : ++tabCount;

    // Set the count on local storage
    localStorage.setItem('tabCount', this._encrDecrService.set(Constant.EncrDecrKey,tabCount.toString()));
    //console.log('API CALLED:');

    let promise = new Promise((resolve, reject) => {
      var Result: boolean = false;
      // Set the application page title
      this._title.setTitle(this.coreConfig.app.appTitle);
      const path = 'api/VersionHistory/GetCurrentVersion';
      let response: Observable<any>;
      response = this._globalService.PostDataAnonymous(ApplicationType.Web, path);
      response.subscribe(
        (data) => {
          //console.log(data);
          //console.log(data._body);
          var result = JSON.parse(data._body);
          //console.log('API result:');
          //console.log(result);
          //console.log(result.status);
          if (result.status == Status.Success) {
            this.currentVersionOutputModel = JSON.parse(this._encrDecrService.get(Constant.EncrDecrKey, result.data));
            sessionStorage.setItem("Version", this._encrDecrService.set(Constant.EncrDecrKey, JSON.stringify(this.currentVersionOutputModel)));
            Result = true;
            resolve(Result)
          }
        },
        (error) => {
        }
      );
    });

    //console.log('API ENDED:');
    
    this.loadingSubscription = this.loadingScreenService.loadingStatus.subscribe((value) => {
      this.loading = value;
    });
    return await promise;
  }
  
  async mergeUsers(currentUser: User, newUser: User): Promise<User>{
    const mergedUser: User = { ...currentUser };
  
    for (const key in newUser) {
      if (newUser.hasOwnProperty(key) && newUser[key] !== null) {
        mergedUser[key] = newUser[key];
      }
    }
  
    return mergedUser;
  }
  

  async loadUserFromDetailsId(): Promise<void> {
    this.loadingScreenService.startLoading();

    const userDetailsInputModel: UserDetailsInputModel = {
      UserDetailsId: this._authenticationService.currentUserValue.UserDetailsId
    };

    try {
      const result = await this.homeservice.GetUserDetails(userDetailsInputModel).toPromise();
      
      this.loadingScreenService.stopLoading();
      
      if (result.status === Status.Success) {
        const user: User = JSON.parse(this._encrDecrService.get(Constant.EncrDecrKey, result.data));
       //console.log(user, 'user');
        
        const updatedUser: User = await this.mergeUsers(this._authenticationService.currentUserValue, user);
        this._authenticationService.SetUserSession(updatedUser, false);
        //console.log(user)
        var templcstg:storage = JSON.parse(this._encrDecrService.get(Constant.EncrDecrKey, localStorage.getItem('lcstrg')))
        templcstg.NoosomPlan=user.CurrentNoosomPlanId;
        templcstg.userCategory=user.NoosomCategoryEnumId;
        localStorage.setItem('lcstrg', this._encrDecrService.set(Constant.EncrDecrKey,JSON.stringify(templcstg)))
        this._globalService.User = updatedUser;
      } else {
        const errormessage = result.message;
        this._toastrService.Error(errormessage, Constant.ErrorToastrTitle);
      }
    } catch (error) {
      this._toastrService.Error(error, Constant.ErrorToastrTitle);
      this.error = error;
    } finally {
      this.loading = false;
      this.loadingScreenService.stopLoading();
    }
  }
  /**
   * On destroyitl
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.loadingSubscription.unsubscribe();
  }

  // Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }
}
