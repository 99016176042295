import { Status } from "app/enums";

export const locale = {
  lang: 'fr',
  data: {
    COMMON:{
      ERROR_MESSAGE_CONNECTIONREFUSED:'Impossible de se connecter au serveur.'
    },
    SIGNIN: {
      WELCOME_TEXT: 'Bienvenue à Noosom',
      MOTIVATION_BADGE_SIGNIN:'Connectez-vous et commencez l\'aventure',
      CONTROL_LABEL_EMAIL:'Adresse e-mail',
      CONTROL_LABEL_PASSWORD:'Mot de passe',
      CONTROL_LABEL_REMEMBERME:'Souviens-toi de moi',
      PLACEHOLDER_EMAIL:'johndoe@gmail.com',
      LINK_TEXT_LABEL_FORGOTPASSWORD:'Mot de passe oublié?',
      LINK_TEXT_LABEL_CREATEACCOUNT:'Créer un compte',
      TEXT_NEWONPLATFORM:'Nouveau sur notre plateforme ?',
      BUTTON_TEXT_LOGIN:'Connexion',
      TOAST_MESSAGE_SUCCESSFULLOGINMESSAGE:'Vous avez réussi à vous connecter en tant qu\'utilisateur {0} à Noosom. Vous pouvez maintenant commencer à explorer. Prendre plaisir! 🎉',
      TOAST_MESSAGE_SUCCESSFULLOGINWELCOMETEXT:'👋 Bienvenue, {0}!',
      ERROR_MESSAGE_REQUIRED_EMAIL:'Le champ email est!',
      ERROR_MESSAGE_REQUIRED_PASSWORD:'Le champ mot de passe est!',
      ERROR_MESSAGE_INVALID_EMAIL:'L\'e-mail doit être une adresse e-mail valide',
      ERROR_MESSAGE_NOATTEMPTS:'Nom d\'utilisateur ou mot de passe invalide. Il ne vous reste plus aucune tentative.',
      LOGIN_STATUS:
      [
        {ErrorCode:Status.EmailNotVarified,ErrorMessage:'Votre adresse e-mail n\'est pas vérifiée.<br>vérifiez d\'abord votre adresse e-mail.'},
        {ErrorCode:Status.AccountLocked,ErrorMessage:'Vôtre compte a été bloqué.'},
        {ErrorCode:0,ErrorMessage:'Connexion réussie.'},
        {ErrorCode:Status.InvalidCredential,ErrorMessage:'Nom d\'utilisateur ou mot de passe invalide.<br>Il vous reste {0} tentatives.'}
      ],
      DIGITS:[1,2,3,4],
      ROLES:[{title:'Admin',value:'Administratrice'},{titlle:'Client',value:'Cliente'},{title:'User',value:'Utilisatrice'}]
    },
    SIGNUP:
    {
      MOTIVATION_BADGE_SIGNUP:'Inscrivez-vous et commencez l\'aventure',
      STEPPER_TITLE_ACCOUNT:'Compte',
      STEPPER_SUB_TITLE_ACCOUNT:'Saisissez votre nom d\'utilisateur',
      STEPPER_TITLE_PERSONAL:'Personnelle',
      STEPPER_SUB_TITLE_PERSONAL:'Entrez les informations',
      HEADING_ACCOUNT:'Information sur le compte',
      HEADING_PERSONAL:'Renseignements personnels',
      HEADING_SELECTPLAN:'Sélectionnez le plan',
      CONTROL_LABEL_USERID:'ID utilisateur Noosom',
      CONTROL_LABEL_EMAILADDRESS:'Adresse e-mail',
      CONTROL_LABEL_PASSWORD:'Mot de passe',
      CONTROL_LABEL_CONFIRMPASSWORD:'Confirmer votre mot de passe',
      CONTROL_LABEL_REGION:'Votre région',
      CONTROL_LABEL_HOMECOUNTRY:'Votre pays',
      CONTROL_LABEL_FIRSTNAME:'Prénom',
      CONTROL_LABEL_MIDDLENAME:'Deuxième nom',
      CONTROL_LABEL_LASTNAME:'Nom de famille',
      CONTROL_LABEL_BIRTHDATE:'Date de naissance',
      CONTROL_LABEL_MOBILENO:'Mobile Non',
      CONTROL_LABEL_SELECTPLAN:'Gratuit pour l\'instant, ou achetez plus tard.',
      PLACEHOLDER_TYPEHERE:'Écrivez ici...',
      PLACEHOLDER_PASSWORD:'Mot de passe',
      PLACEHOLDER_REGION:'Choisissez une région',
      PLACEHOLDER_COUNTRY:'Choisissez le pays',
      PLACEHOLDER_TELEPHONENUMBER:'0123456789',
      BUTTON_TEXT_PREVIOUS:'Précédente',
      BUTTON_TEXT_NEXT:'Prochaine',
      BUTTON_TEXT_SUBMIT:'Soumettre',
      TOOLTIPTEXT_CONTROL_LABEL_REGION_1:'Vos données seront stockées',
      TOOLTIPTEXT_CONTROL_LABEL_REGION_2:'dans la région sélectionnée',
      TOOLTIPTEXT_CONTROL_LABEL_HOMECOUNTRY:'Sélectionnez le pays',
      TOAST_MESSAGE_SUCCESSFULSIGNUP:'Compte créé avec succès.',
      ERROR_MESSAGE_REQUIRED_USERID:'Le champ nom d\'utilisateur est!',
      ERROR_MESSAGE_EXISTED_USERID:'Ce nom d\'utilisateur a été pris. Veuillez en essayer un autre.',
      ERROR_MESSAGE_REQUIRED_EMAILADDRESS:'Le champ adresse e-mail est!',
      ERROR_MESSAGE_EXISTED_EMAILADDRESS:'Cette adresse e-mail a été prise. Veuillez en essayer un autre.',
      ERROR_MESSAGE_REQUIRED_PASSWORD:'Le champ mot de passe est!',
      ERROR_MESSAGE_INVALID_PASSWORD:'Doit contenir au moins un chiffre, une lettre majuscule et minuscule et un minimum de 8 caractères ou plus.',
      ERROR_MESSAGE_MISMATCHED_PASSWORD:'Le mot de passe répété doit correspondre.',
      ERROR_MESSAGE_REQUIRED_CONFIRMPASSWORD:'Le champ de confirmation du mot de passe est!',
      ERROR_MESSAGE_REQUIRED_REGION:'Le champ région est!',
      ERROR_MESSAGE_REQUIRED_HOMECOUNTRY:'Le champ du pays',
      ERROR_MESSAGE_REQUIRED_FIRSTNAME:'Le champ prénom est!',
      ERROR_MESSAGE_REQUIRED_MIDDLENAME:'Le champ du deuxième prénom est!',
      ERROR_MESSAGE_REQUIRED_LASTNAME:'Le champ nom de famille est!',
      ERROR_MESSAGE_REQUIRED_BIRTHDATE:'Le champ date de naissance est!',
      ERROR_MESSAGE_REQUIRED_MOBILENO:'Le mobile aucun champ n\'est requis!',
      ERROR_MESSAGE_REQUIRED_SELECTPLAN:'Veuillez sélectionner l\'option de forfait gratuit!'
    }
  }
};
