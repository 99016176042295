export class OutputModel {
    Status: number;
    Id: number;
    Message: string;
}

export class DeleteInputModel {
    DeleteID: number;
    UserDetailsID: number;
}
export class DownloadFileInputModel {
    DownloadID: number;
    FilePath: string;
    UserDetailsID: number;
}
export class DownloadFileOutputModel {
    FileName: string;
    FileMimeType: string;
    FileBase64String: string;
    FileDownloadPath: string;
    AccessKey: string;
}

export class DeleteMultipleInputModel
{
        DeleteIDs:Array<number> ;
        UserDetailsID : number;
}
