import { DatePipe, formatDate } from "@angular/common";
import { Injectable } from "@angular/core";
import moment from "moment";

@Injectable()
export class E {
    public static _datepipe: DatePipe;
    constructor(private datepipe: DatePipe) {

    }

    public static ConvertToString(value?: any): string {
        //debugger;
        if (typeof (value) === 'undefined')
            return '';
        else if (value === null)
            return '';
        else
            return String(value);
    }
    public static ConvertToNumber(value?: any): number {
        //debugger;
        if (typeof (value) === 'undefined')
            return 0;
        else if (value === null)
            return 0;
        else
            return Number(value);
    }
    public static ConvertToBoolean(value?: any): boolean {
        //debugger;
        if (typeof (value) === 'undefined')
            return false;
        else if (value === null)
            return false;
        else
            return Boolean(value);
    }
    public static ConvertToDate(value?: any): Date | undefined | null {
        //debugger;
        if (typeof (value) === 'undefined')
            return value;
        else if (value === null)
            return value;
        else
            return new Date(value);
    }
    public static DateOnly(value?: Date): Date {
        //debugger;
        if (typeof (value) === 'undefined')
            return new Date();
        else if (value === null)
            return new Date();
        else {
            return new Date(value.getFullYear(), value.getMonth(), value.getDate());
        }
    }
    public static TimeOnly(value?: Date): Date {
        //debugger;
        if (typeof (value) === 'undefined')
            return new Date();
        else if (value === null)
            return new Date();
        else {
            return new Date(1900, 0, 1, value.getHours(), value.getMinutes(), value.getSeconds());
        }
    }
    public static GetDateStringOnly(value?: Date): string {
        //console.log('GetDateStringOnly');
        let result = '';
        if (typeof (value) === 'undefined')
            return '';
        else if (value === null)
            return '';
        //console.log(value);
        value = this.DateOnly(value);
        //console.log(value);
        value = new Date(moment(value).utcOffset(0, true).format());
        //console.log(value);
        result = value.toJSON(); //value.toLocaleString();    //2019-08-01T00:00:00-07:00
        //console.log(result);
        return result;
    }
    public static GetTimeStringOnly(value?: Date): string {
        //console.log('GetTimeStringOnly');
        let result = '';
        if (typeof (value) === 'undefined')
            return '';
        else if (value === null)
            return '';
        //console.log(value);
        value = this.TimeOnly(value);
        //console.log(value);
        value = new Date(moment(value).utcOffset(0, true).format());
        //console.log(value);
        result = value.toJSON();  //value.toLocaleString();
        //console.log(result);
        return result;
    }
    public static GetTodayDateStringOnly(): string {
        //console.log('GetTodayDateOnly');
        let value = new Date(); let result = '';
        //console.log(value);
        value = this.DateOnly(value);
        //console.log(value);
        value = new Date(moment(value).utcOffset(0, true).format());
        //console.log(value);
        result = value.toJSON();
        //console.log(result);
        return result;
    }
    public static GetTodayTimeStringOnly(): string {
        //console.log('GetTodayTimeOnly');
        let value = new Date(); let result = '';
        //console.log(value);
        value = this.TimeOnly(value);
        //console.log(value);
        value = new Date(moment(value).utcOffset(0, true).format());
        //console.log(value);
        result = value.toJSON(); //value.toLocaleString();
        //console.log(result);
        return result;
    }
    public static GetCurrentLocaleDateTime(): Date {
        //console.log('GetCurrentDateTime');
        let value; let result;
        //console.log(value);
        value = new Date().toLocaleString("en-GB");
        //console.log(result);
        let _momentvar = moment(value, 'DD/MM/YYYY, HH:mm:ss');
        //console.log(_momentvar);
        let _moment = _momentvar.format('YYYY-MM-DDTHH:mm:ss.sssZ');
        //console.log(_moment);
        result = new Date(_moment);
        //console.log(result);
        return result;
    }
    public static GetMinDateWithTime(hours: number, minutes: number, seconds = 0): Date {
        let _date = new Date(1900, 0, 1, hours, minutes, seconds + 10);
        return new Date(moment(_date).utcOffset(0, true).format());
    }
    public static CombineDateAndTime(_date: Date, _time: Date) {
        let date = new Date(_date); let time = new Date(_time);
        let result = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds());
        //console.log(result);
        return result.toISOString();
    }
    public static AddMinutesToDateTime(dt: Date, minutes: number) {
        return new Date(dt.getTime() + minutes * 60000);
    }
    // public static GetLocalDate(StrDate: string): Date | null {
    //     if (typeof (StrDate) === 'undefined')
    //         return null;
    //     else if (StrDate === null)
    //         return null;
    //     else {
    //         var tempDate: Date = new Date(StrDate);
    //         var Year: number = tempDate.getFullYear();
    //         var Month: number = tempDate.getMonth();
    //         var Days: number = tempDate.getDate();
    //         var Hours: number = tempDate.getHours();
    //         var Minutes: number = tempDate.getMinutes()
    //         var Seconds: number = tempDate.getMilliseconds();
    //         var OffSetMin: number = (new Date().getTimezoneOffset());
    //         var TotalMinutes: number = (+Minutes) + (+OffSetMin) * -1;

    //         var dateTemp = new Date();
    //         dateTemp.setFullYear(Year);
    //         dateTemp.setMonth(Month);
    //         dateTemp.setDate(Days);
    //         dateTemp.setHours(Hours);
    //         dateTemp.setMinutes(TotalMinutes);
    //         dateTemp.setSeconds(Seconds);
    //         return dateTemp
    //     }
    // }
}