import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from 'app/Services/global.service';
import {TblUserAwards} from '../about-me-model.model'

@Injectable({
  providedIn: 'root'
})
export class AwardService {

  constructor(
    private _http: HttpClient,private globalService: GlobalService
  ) { }
  CreateUpdateUserAward(userAwards:TblUserAwards){
    const path='api/UserAward/CreateUpdateUserAward'
    return  this.globalService.PostDataById(userAwards , path);
  }
  GetUserAwardLoadData(_data:number){
    const path='api/UserAward/GetUserAwardLoadData'
    return  this.globalService.PostDataById(_data , path);
  }
}
