import firebase from 'firebase/app';
import 'firebase/messaging'; // Import Firebase messaging

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAbXukM5MnLSWa2DVIpS41HYYqmKSsITbA",
  authDomain: "noosom-403be.firebaseapp.com",
  projectId: "noosom-403be",
  storageBucket: "noosom-403be.appspot.com",
  messagingSenderId: "949218635521",
  appId: "1:949218635521:web:29fe1a9b3bf87510143500",
  measurementId: "G-CBLN0G9689"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const messaging = firebase.messaging(); // Export messaging for use in services
