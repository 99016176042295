import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';

import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';

import { CalendarService } from 'app/main/apps/calendar/calendar.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { QuickPostComponent } from 'app/quick-post/quick-post/quick-post.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { locale as english } from 'app/main/apps/calendar/i18n/en';
import { locale as french } from 'app/main/apps/calendar/i18n/fr';
import { locale as german } from 'app/main/apps/calendar/i18n/de';
import { locale as portuguese } from 'app/main/apps/calendar/i18n/pt';
import { locale as gujarati } from 'app/main/apps/calendar/i18n/gj';
import { locale as hindi } from 'app/main/apps/calendar/i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/auth/service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CoreConfigService } from '@core/services/config.service';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { Constant } from 'app/Classes/constant';
import { EncrDecrService } from 'app/Services/encr-decr.service';



@Component({
  selector: 'app-calendar-main-sidebar',
  templateUrl: './calendar-main-sidebar.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CalendarMainSidebarComponent implements OnInit {
  // Public
  public calendarRef = [];
  public tempRef = [];
  public checkAll = true;
  DiaryJournal:string;
  currentAppLanguage: any;
  private _unsubscribeAll: Subject<any>;
  public coreConfig: any;

  /**
   * Constructor
   *
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CalendarService} _calendarService
   */
  constructor(private _coreSidebarService: CoreSidebarService,
    private _calendarService: CalendarService,
    private dialog: MatDialog,
    private modalService: NgbModal,
    private _toastrService: CustomToastrService,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private _coreConfigService: CoreConfigService,
    private encrDecr:EncrDecrService
    //private dialogRef: MatDialogRef<CalendarMainSidebarComponent>,

  ) {
    this._unsubscribeAll = new Subject();
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._translateService.onLangChange.subscribe(lang => {
      // console.log('lang');
      // console.log(lang.lang);
      setTimeout(() => {
        let _LangJsonObj = this.GetCurrentLanguageJsonObject(lang.lang);
        if (_LangJsonObj)
          this.currentAppLanguage = _LangJsonObj;
        if (localStorage.getItem('DiaryOrJournal')) {
          if (this.encrDecr.get(Constant.EncrDecrKey,localStorage.getItem('DiaryOrJournal')) == '2') {
            this.DiaryJournal = this.currentAppLanguage.data.CALENDAR_MAIN.CONTROL_LABEL_ADD_JOURNAL;
          }
          else {
            this.DiaryJournal = this.currentAppLanguage.data.CALENDAR_MAIN.CONTROL_LABEL_ADD_DIARY;
          }
        }
        else if (this.authenticationService.currentUserValue.NoosomTypeId == 2) {
          this.DiaryJournal = this.currentAppLanguage.data.CALENDAR_MAIN.CONTROL_LABEL_ADD_JOURNAL;
        }
        else {
          this.DiaryJournal = this.currentAppLanguage.data.CALENDAR_MAIN.CONTROL_LABEL_ADD_DIARY;
        }
      }, 100);
    })
   }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle Event Sidebar
   */
  toggleEventSidebar() {
    this._coreSidebarService.getSidebarRegistry('calendar-event-sidebar').toggleOpen();
  }

  /**
   * Add Event
   *
   */
  AddEvent() {
    this.toggleEventSidebar();
    this._coreSidebarService.getSidebarRegistry('calendar-main-sidebar').toggleOpen();
    this._calendarService.createNewEvent();


  }

  onComingSoon() {
    this._toastrService.Info(this.currentAppLanguage.data.CALENDAR_MAIN.INFO_MESSAGE_COMING_SOON, Constant.InfoToastrTitle);
  }

  AddQuickPost() {
    this.openDialog(QuickPostComponent, 0);
  }

  openDialog(component: any, _data: any = []) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = this.isMobileScreen() ? '100%' : '66%';
   // dialogConfig.height = "80%";
    //dialogConfig.position = { top: '2%' };
    dialogConfig.data = _data;
    const dialogRef = this.dialog.open(component, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        // console.log('data');
        // console.log(data);
        if (data) {
          this._calendarService.getEvents();
        }
      }
    );
  }
  private isMobileScreen(): boolean {
    return window.innerWidth <= 767; // You can adjust this value as per your requirements
  }

  /**
   * If all checkbox are checked : returns TRUE
   */
  allChecked() {
    return this.calendarRef.every(v => v.checked === true);
  }

  /**
   * Checkbox Change
   *
   * @param event
   * @param id
   */
  checkboxChange(event, id) {
    const index = this.calendarRef.findIndex(r => {
      if (r.id === id) {
        return id;
      }
    });
    this.calendarRef[index].checked = event.target.checked;
    this._calendarService.calendarUpdate(this.calendarRef);
    this.checkAll = this.allChecked();
  }

  /**
   * Toggle All Checkbox
   *
   * @param event
   */
  toggleCheckboxAll(event) {
    this.checkAll = event.target.checked;
    if (this.checkAll) {
      this.calendarRef.map(res => {
        res.checked = true;
      });
    } else {
      this.calendarRef.map(res => {
        res.checked = false;
      });
    }
    this._calendarService.calendarUpdate(this.calendarRef);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to Calendar changes
    this._calendarService.onCalendarChange.subscribe(res => {
      this.calendarRef = res;
    });
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);
    if (localStorage.getItem('DiaryOrJournal')) {
      if (this.encrDecr.get(Constant.EncrDecrKey,localStorage.getItem('DiaryOrJournal')) == '2') {
        this.DiaryJournal = this.currentAppLanguage.data.CALENDAR_MAIN.CONTROL_LABEL_ADD_JOURNAL;
      }
      else {
        this.DiaryJournal = this.currentAppLanguage.data.CALENDAR_MAIN.CONTROL_LABEL_ADD_DIARY;
      }
    }
    else if (this.authenticationService.currentUserValue.NoosomTypeId == 2) {
      this.DiaryJournal = this.currentAppLanguage.data.CALENDAR_MAIN.CONTROL_LABEL_ADD_JOURNAL;
    }
    else {
      this.DiaryJournal = this.currentAppLanguage.data.CALENDAR_MAIN.CONTROL_LABEL_ADD_DIARY;
    }
  }

  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }
}
