import { CountryListOutputModel } from "app/Models/country-model.model";
import { RegionListOutputModel } from "app/Models/region-model.model";
import { StateListOutputModel } from "app/Models/state-model.model";

export class AddressesModel {
    //Sequence: number;
    UserAddressId: number;
    UserDetailsId: number;
    RelativeDetailsId: number;
    RegionId: number;
    CountryId: number;
    StateId: number;
    AddressTag: string;
    AddressName: string;
    FullAddress: string;
    AddressHouseName: string;
    Address1: string;
    Address2: string;
    Address3: string;
    AddressTown: string;
    AddressCountry: string;
    AddressPostCode: string;
    AddressState:string;
    CurrentAddressYn: boolean;
    //Country: number;
    AddressUprn: string;
    Latitude: number;
    Longitude: number;
    AddressFromDate: Date;
    AddressToDate: Date;
    Notes: string;
    CreatedDate: Date;
    CreatedTime: Date;
    CreatedBy: number;
    ChangedDate: Date;
    ChangedTime: Date;
    ChangedBy: number;
    //AddressState:string;
}
export class TblUserAddresses {
    UserAddressesId: number;
    UserAddressId: number;
    UserDetailsId: number;
    RelativeDetailsId: number;
    RegionId: number;
    CountryId: number;
    StateId: number;
    AddressTag: string;
    AddressName: string;
    AddressHouseName: string;
    Address1: string;
    Address2: string;
    Address3: string;
    AddressTown: string;
    AddressCounty: string;
    AddressPostCode: string;
    Country: number;
    CurrentAddressYn: boolean;
    AddressUprn: string;
    Latitude: number;
    Longitude: number;
    AddressFromDate: string;
    AddressToDate: string;
    Notes: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}
export class AddressLoadDataOutputModel {
    UserAddress: TblUserAddresses;
    CountryList: Array<CountryListOutputModel>;
    RegionList: Array<RegionListOutputModel>;
    StateList: Array<StateListOutputModel>;
    AddressDocumentList: Array<AddressDocumentListOutputModel>;
}

export class AddressDocumentListOutputModel {
    UserAddressDocumentId: number;
    UserDetailsId: number;
    UserAddressId: number;
    DocumentId: number;
    DocumentType: string;
    Size: string;
    FileType: string;
    DocumentSubType:string;
    DocumentName: string;
    DocumentOldName: string;
    DocumentPath: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}
