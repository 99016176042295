import { Component, Input } from '@angular/core';
import * as TablerIcons from 'angular-tabler-icons/icons';
import { CoreMenuItem } from '@core/types';

@Component({
  selector: '[core-menu-vertical-item]',
  templateUrl: './item.component.html',
  // styles: [`
  //   ::ng-deep .my-custom-class .tooltip-inner {
  //     z-index: 999 !important;
  //     transform:translateY(-10%) !important;
  //   }
  // `]
})
export class CoreMenuVerticalItemComponent {
  @Input()
  item: CoreMenuItem;
  tablerIcons = {};
  tablerIconStyle = 'display: inline-block; vertical-align: middle; margin-right:15px';

  constructor() {
    // Preload Tabler Icons as SVG strings
    for (const [key, value] of Object.entries(TablerIcons)) {
      if (typeof value === 'function') {
        this.tablerIcons[key] = (value as any).toSvg();
      }
    }
  }

  isTablerIcon(icon: string): boolean {
    return icon.startsWith('tabler-');
  }

  getTablerIconName(icon: string): string {
    return icon.replace('tabler-', '');
  }
}
