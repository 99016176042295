import { AddressesModel } from "app/address/address-model.model";
import { FileUploadInputModel } from "app/Models/file-model.model";
import { GenderListOutputModel, GendersListOutputModel } from "app/Models/gender-model.model";
import { LunarSignListOutputModel } from "app/Models/lunar-sign-model.model";
import { NoosomTypeListOutputModel } from "app/Models/noosom-type-model.model";
import { ReligionListOutputModel } from "app/Models/religion-model";
import { ZodiacSignListOutputModel } from "app/Models/zodiac-sign-model";
import { SchoolModel } from "app/school/school-model.model";
import { InsuranceModel } from "app/insurance/add-insurance/insurance.model"
import { CountryListOutputModel } from "app/Models/country-model.model";
import { RegionListOutputModel } from "app/Models/region-model.model";
import { StateListOutputModel } from "app/Models/state-model.model";
import { ApplicationType, RelationType } from "app/enums";

export class AboutMeLoadDataOutputModel {
    UserId: number;
    UserName: string;
    EmailAddress: string;
    FullName: string;
    NoosomTypeId: number;
    ZodiacSignId: number;
    LunarSignId: number;
    ReligionId: number;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    NickName: string;
    DateofBirth: Date;
    MobileNumber: string;
    SexAtBirth: number;
    GenderIdentifyAs: number;
    NoosomWebUrl: string;
    PersonalWebUrl: string;
    //ProfileImagePath: string;
    ContactNumberCountryCode:string;
    CountryShortCode: string;
    ISDCode: string;
}
export class AboutMeCreateUpdateInputModel {
    UserId: number;
    UserName: string;
    EmailAddress: string;
    FullName: string;
    NoosomTypeId: number;
    ZodiacSignId: number;
    LunarSignId: number;
    ReligionId: number;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    NickName: string;
    //ProfileImagePath: string;
    ContactNumberCountryCode :string;
    DateofBirth: string;
    MobileNumber: string;
    SexAtBirth: number;
    GenderIdentifyAs: number
    NoosomWebUrl: string;
    PersonalWebUrl: string;
}
export class UpdateProfilePictureInputModel {
    UserDetailsID: number;
    ProfilePicturePath: string;
    ProfileImageSizeInBytes: number;
}

export class AboutMeLoadDataOutputModelMain {
    aboutMeData: AboutMeLoadDataOutputModel;
    genderList: Array<GenderListOutputModel>;
    gendersList: Array<GendersListOutputModel>;
    religionList: Array<ReligionListOutputModel>;
    zodiacSignList: Array<ZodiacSignListOutputModel>;
    lunarSignList: Array<LunarSignListOutputModel>;
    noosomTypeList: Array<NoosomTypeListOutputModel>;
    userAddresses: Array<AddressesModel>;
    userSchools: Array<SchoolModel>;
    userInsurance: Array<InsuranceModel>;
}

export class BloodGroupListOutputModel {
    BloodGroupId: number;
    BloodGroupName: string;
}

export class UserDemographicsModel {
    UserDemographicId: number;
    UserDetailsId: number;
    BloodGroupId: number;
    Allergies: string;
    EmergencyContactFullName: string;
    EmergencyContactRelationship: string;
    EmergencyContactNumber: string;
    EmergencyContactCountryCode: string;
    GeneralMedicalHistory: string;
    FamilyMedicalHistory: string;
    SurgicalHistory: string;
    PostCareNotes: string;
    OrganDonorYn: boolean;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;

    BloodGroupList: Array<BloodGroupListOutputModel>;
}

export class TblUserHealthProviders {
    UserHealthProviderId: number;
    UserDetailsId: number;
    RegionId: number;
    CountryId: number;
    StateId: number;
    Speciality: string;
    ProfessionalName: string;
    Address1: string;
    Address2: string;
    Address3: string;
    AddressTown: string;
    AddressPostCode: string;
    Latitude: number;
    Longitude: number;
    EmailAddress: string;
    ContactNumber1: string;
    ContactNumber1CountryCode: string;
    ContactNumber2: string;
    ContactNumber2CountryCode: string;
    LastExamDate: string;
    NextExamDate: string;
    Notes: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}
export class HealthProviderModel {
    UserHealthProviderId: number;
    UserDetailsId: number;
    RegionId: number;
    CountryId: number;
    StateId: number;
    Speciality: string;
    ProfessionalName: string;
    FullAddress: string;
    Address1: string;
    Address2: string;
    Address3: string;
    AddressTown: string;
    AddressPostCode: string;
    Latitude: number;
    Longitude: number;
    EmailAddress: string;
    ContactNumber1: string;
    ContactNumber1CountryCode: string;
    ContactNumber2: string;
    ContactNumber2CountryCode: string;
    LastExamDate: string;
    NextExamDate: string;
    Notes: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}
export class HealthProviderLoadDataOutputModel {
    UserHealthProvider: TblUserHealthProviders;
    RegionList: Array<RegionListOutputModel>;
    CountryList: Array<CountryListOutputModel>;
    StateList: Array<StateListOutputModel>
}
export class DeleteInputModel {
    DeleteID: number;
    UserDetailsID: number;
}

export class TblUserMedications {
    UserMedicationId: number;
    UserDetailsId: number;
    PrescribedBy: number;
    RefillDurationId: number;
    MedicationName: string;
    ConditionFor: string;
    Dosage: string;
    Frequency: string;
    LastRefillDate: string;
    NextRefillDate: string;
    Notes: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}
export class MedicationModel {
    UserMedicationId: number;
    UserDetailsId: number;
    PrescribedBy: number;
    RefillDurationId: number;
    MedicationName: string;
    RefillDurationName: string;
    PrescribedByName: string;
    ConditionFor: string;
    Dosage: string;
    Frequency: string;
    LastRefillDate: string;
    NextRefillDate: string;
    Notes: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}
export class RefillDurationListOutputModel {
    RefillDurationId: number;
    RefillDurationName: string;
    ActiveYn: boolean;
}
export class MedicationLoadDataOutputModel {
    UserMedication: TblUserMedications;
    RefillDurationList: Array<RefillDurationListOutputModel>;
    PrescribedByList: Array<HealthProviderModel>;
}
export class MedicationLoadDataInputModel {
    UserMedicationId: number;
    UserDetailsId: number;
}

export class TblUserVaccinations {
    UserVaccinationId: number;
    UserDetailsId: number;
    VaccineId: number;
    VaccinationName: string;
    VaccinationType: string;
    LastDosageDate: string;
    NextDosageDate: string;
    Notes: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}
export class VaccinationModel {
    UserVaccinationId: number;
    UserDetailsId: number;
    VaccineId: number;
    VaccinationName: string;
    VaccineName: string;
    VaccinationType: string;
    LastDosageDate: string;
    NextDosageDate: string;
    Notes: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}
export class VaccinationLoadDataOutputModel {
    UserVaccination: TblUserVaccinations;
    VaccineList: Array<VaccineListOutputModel>;
}
export class VaccineListOutputModel {
    VaccineId: number;
    VaccineName: string;
    OrderSequence: number;
    ActiveYn: boolean;
}

export class TblUserAwards {
    UserAwardId: number;
    UserDetailsId: number;
    AwardName: string;
    Occasion: string;
    DateReceived: string;
    OccasionNotes: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}
export class AwardModel {
    UserAwardId: number;
    UserDetailsId: number;
    AwardName: string;
    Occasion: string;
    DateReceived: string;
    OccasionNotes: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}
export class AwardLoadDataOutputModel {
    UserAward: TblUserAwards;
}
export class TblUserAchievements {
    UserAchievementId: number;
    UserDetailsId: number;
    AchievementName: string;
    AchievementTypeId: number;
    Occasion: string;
    DateReceived: string;
    OccasionNotes: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}
export class AchievementModel {
    UserAchievementId: number;
    UserDetailsId: number;
    AchievementName: string;
    AchievementTypeName: string;
    Occasion: string;
    DateReceived: string;
    OccasionNotes: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}
export class AchievementLoadDataOutputModel {
    UserAchievement: TblUserAchievements;
}
export class AchievementsLoadDataInputModel {
    UserDetailsId: number;
    AchievementTypeId: number;
}
export class TblUserFamilyMembers {
    UserFamilyMemberId: number;
    UserDetailsId: number;
    FamilyMemberTypeId: number;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Relationship: string;
    EmailAddress: string;
    DateofBirth?: string;
    PersonalWebUrl: string;
    NoosomWebUrl: string;
    ContactNumber1: string;
    ContactNumber1CountryCode: string;
    ContactNumber2: string;
    ContactNumber2CountryCode: string;
    Address1: string;
    Address2: string;
    Address3: string;
    AddressTown: string;
    AddressPostCode: string;
    RegionId: number;
    CountryId: number;
    StateId: number;
    Latitude: number;
    Longitude: number;
    ConnectionRequestStatusId?: number;
    ConnectionRequestStatusDescription?: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}
export class FamilyMemberModel {
    UserFamilyMemberId: number;
    UserDetailsId: number;
    FamilyMemberTypeId: number;
    FamilyMemberTypeName: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Relationship: string;
    EmailAddress: string;
    DateofBirth?: string;
    PersonalWebUrl: string;
    NoosomWebUrl: string;
    ConnectionRequestStatusId: number;
    ConnectionRequestStatusDescription: string;
    ProfileImagePath: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}
export class FamilyMemberLoadDataOutputModel {
    UserFamilyMember: TblUserFamilyMembers;
    RegionList: Array<RegionListOutputModel>;
    CountryList: Array<CountryListOutputModel>;
    StateList: Array<StateListOutputModel>
}
export class FamilyMembersLoadDataInputModel {
    UserDetailsId: number;
    FamilyMemberTypeId: number;
}
export class TblUserFriends {
    UserFriendId: number;
    UserDetailsId: number;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    EmailAddress: string;
    DateofBirth?: string;
    PersonalWebUrl: string;
    NoosomWebUrl: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
    AddressState: string;
    Address1: string;
    Address2: string;
    Address3: string;
    AddressTown: string;
    AddressPostCode: string;
    RegionId: number;
    CountryId: number;
    StateId: number;
    Latitude: number;
    Longitude: number;
    ContactNumber1: string;
    ContactNumber1CountryCode: string;
    ContactNumber2: string;
    ContactNumber2CountryCode: string;
    ConnectionRequestStatusId?: number;
    ConnectionRequestStatusDescription?: string;
}
export class FriendModel {
    UserFriendId: number;
    UserDetailsId: number;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    EmailAddress: string;
    DateofBirth?: string;
    PersonalWebUrl: string;
    NoosomWebUrl: string;
    ConnectionRequestStatusId: number;
    ConnectionRequestStatusDescription: string;
    ProfileImagePath: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}
export class FriendLoadDataOutputModel {
    UserFriend: TblUserFriends;
    RegionList: Array<RegionListOutputModel>;
    CountryList: Array<CountryListOutputModel>;
    StateList: Array<StateListOutputModel>

}

export class TblUserJobs {
    UserJobId: number;
    UserDetailsId: number;
    RegionId?: number;
    CountryId: number;
    StateId: number;
    Employer: string;
    JobDescription: string;
    Address1: string;
    Address2: string;
    Address3: string;
    AddressTown: string;
    AddressPostCode: string;
    Latitude: number;
    Longitude: number;
    JobFromDate: string;
    JobToDate: string;
    Notes: string;
    CurrentJobYn: boolean;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}

export class JobModel {
    UserJobId: number;
    UserDetailsId: number;
    RegionId: number;
    CountryId: number;
    StateId: number;
    Employer: string;
    JobDescription: string;
    FullAddress: string;
    Address1: string;
    Address2: string;
    Address3: string;
    AddressTown: string;
    AddressCountry: string;
    AddressState: string;
    AddressPostCode: string;
    Latitude: number;
    Longitude: number;
    JobFromDate: string;
    JobToDate: string;
    Notes: string;
    CurrentJobYn: boolean;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}

export class JobLoadDataOutputModel {
    UserJob: TblUserJobs;
    RegionList: Array<RegionListOutputModel>;
    CountryList: Array<CountryListOutputModel>;
    StateList: Array<StateListOutputModel>
}

export class UpdateCoverPictureInputModel {
    UserDetailsID: number;
    CoverImagePath: string;
    CoverImageSizeInBytes: number;
}

export class UpdateCoverPictureOutputModel {
    UserDetailsId: number;
    CoverImagePath: string;
}

export class UserConnectionRequestModel
    {
        UserConnectionRequestId: number;
        UserDetailsId: number;
        UserFamilyMemberId?: number;
        UserFamilyMemberFullName: string;
        UserFriendId: number;
        UserFriendFullName: string;
        ConnectionRequestStatusId: number;
        ConnectionRequestStatusDescription: string;
        CreatedDate: string;
        CreatedTime: string;
        CreatedBy: number;
        ChangedDate: string;
        ChangedTime: string;
        ChangedBy: number;
    }
    export class UserConnectionRequestLoadDataOutputModel
    {
        UserConnectionRequest:UserConnectionRequestModel
    }
    export class GetUserConnectionRequestsInputModel
    {
        UserDetailsId: number;
        StartDate?: string;
        EndDate?: string;
    }

    export class RequestInputModel
    {
       SenderUserDetailsId: number;
       ReceiverId: number;
       ReceiverEmailAddress?: string;
       SenderEmailAddress: string;
       Type:RelationType;
       EnviornmentId:ApplicationType;
       FamilyMemberType?: number;
    }