import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
import { locale as german } from '../i18n/de';
import { locale as portuguese } from '../i18n/pt';
import { locale as gujarati } from '../i18n/gj';
import { locale as hindi } from '../i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { CoreConfigService } from '@core/services/config.service';
import { Observable, Subject } from 'rxjs';
import { Constant } from 'app/Classes/constant';
import { E } from 'assets/extensions/extensions';
import { Status, Enums } from 'app/enums';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'app/auth/service';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { TblUserVaccinations, VaccinationLoadDataOutputModel, VaccineListOutputModel } from '../about-me-model.model'
import { VaccinationService } from './vaccination.service'
import { NgSelectComponent } from '@ng-select/ng-select';
import { GlobalService } from 'app/Services/global.service';

@Component({
  selector: 'app-my-vaccination',
  templateUrl: './my-vaccination.component.html',
  styleUrls: ['./my-vaccination.component.scss']
})
export class MyVaccinationComponent implements OnInit {
  @ViewChild('Vaccine') Vaccine:NgSelectComponent;
  public coreConfig: any;
  public dialogTitle: string;
  public maxToDate: Date = new Date();
  public maxFromDate: Date = new Date();
  public IsForEdit: boolean = false;
  public loading = false;
  public submitted = false;
  public error = '';
  public frmVaccination: FormGroup;
  public FieldHide = false;
  //VaccineName: any;
  _data: number = 0;
  currentAppLanguage: any;
  private _unsubscribeAll: Subject<any>;
  userVaccination: TblUserVaccinations;
  vaccinationLoadDataOutputModel: VaccinationLoadDataOutputModel;
  infoTooltip: any;
  dateformat: any;

  constructor(
    private dialogRef: MatDialogRef<MyVaccinationComponent>,
    private vaccinationService: VaccinationService,
    private _coreTranslationService: CoreTranslationService,
    private _coreConfigService: CoreConfigService,
    private authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private loadingScreenService: LoadingScreenService,
    private encrDecr: EncrDecrService,
    private _toastrService: CustomToastrService,
    private globalService: GlobalService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._unsubscribeAll = new Subject();
    this._data = data;
    this.dateformat=this.globalService.getDateFormat().toUpperCase()
  }
   ngAfterViewInit(): void {
    this.Vaccine.focus();
  }
  ngOnInit(): void {
    this.userVaccination = new TblUserVaccinations();
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);
    this.dialogTitle = this.currentAppLanguage.data.MY_VACCINATION_FORM.DIALOG_TITLE_ADD_VACCINATION;
    this.infoTooltip= this.currentAppLanguage.data.MY_VACCINATION_FORM.SECTION_TEXT;
    this.frmVaccination = this._formBuilder.group({
      VaccineId: [, Validators.required],
      VaccinationName: [],
      VaccinationType: [],
      LastDosageDate: [],
      NextDosageDate: [],
      Notes: [],
    });
    if (typeof this._data !== 'undefined') {
      // console.log('this._data')
      // console.log(this._data)
      if (this._data > 0) {
        this.IsForEdit = true;
        this.dialogTitle = this.currentAppLanguage.data.MY_VACCINATION_FORM.DIALOG_TITLE_EDIT_VACCINATION;
      }
      setTimeout(() => {
        this.loadingScreenService.startLoading();
        let response: Observable<any>;
        this.vaccinationService.GetUserVaccinationLoadData(this._data)
          .subscribe(
            (data) => {
              this.loadingScreenService.stopLoading();
              //  console.log(data);
              if (data.status == Status.Success) {
                this.vaccinationLoadDataOutputModel = <VaccinationLoadDataOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, data.data)));
                //  console.log(this.vaccinationLoadDataOutputModel.UserVaccination)
                if (this.vaccinationLoadDataOutputModel.UserVaccination) {
                  this.userVaccination = this.vaccinationLoadDataOutputModel.UserVaccination;
                  this.frmVaccination.patchValue({
                    VaccineId: this.userVaccination.VaccineId,
                    VaccinationName: this.userVaccination.VaccinationName,
                    VaccinationType: this.userVaccination.VaccinationType,
                    LastDosageDate: E.ConvertToDate(this.userVaccination.LastDosageDate),
                    NextDosageDate: E.ConvertToDate(this.userVaccination.NextDosageDate),
                    Notes: this.userVaccination.Notes,
                  });

                }

              }
              else {
                this._toastrService.Error(E.ConvertToString(data.message), Constant.ErrorToastrTitle);
              }
            },
            (error) => {
              this._toastrService.Error(error, Constant.ErrorToastrTitle);
              this.error = error;
              this.loadingScreenService.stopLoading();
            }
          );

      }, 500);
      //}
    }
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key.toLocaleLowerCase() === "escape") {
        this.onCloseButtonClick();
      }
    });
  }

  onSubmit() {
    this.frmVaccination.controls['LastDosageDate'].setErrors(null);
    this.frmVaccination.controls['NextDosageDate'].setErrors(null);
    this.submitted = true;
    if (this.frmVaccination.invalid) {
      // console.log(this.frmVaccination.invalid)
      return;
    }
    this.submitted = false;
    if (this.userVaccination == null) {
      this.userVaccination = new TblUserVaccinations();
    }
    // console.log(this.authenticationService.currentUserValue.UserDetailsId)
    this.userVaccination.VaccineId = this.frmVaccination.get('VaccineId').value;
    if (this.frmVaccination.get('VaccinationName').value && this.frmVaccination.get('VaccineId').value==15 ) {
      this.userVaccination.VaccinationName = this.frmVaccination.get('VaccinationName').value;
    }
    //this.userVaccination.UserVaccinationId=this.frmVaccination.get('VaccinationName').value;}
    this.userVaccination.LastDosageDate = E.GetDateStringOnly(this.frmVaccination.get('LastDosageDate').value);
    this.userVaccination.NextDosageDate = E.GetDateStringOnly(this.frmVaccination.get('NextDosageDate').value);
    this.userVaccination.VaccinationType = this.frmVaccination.get('VaccinationType').value;
    this.userVaccination.Notes = this.frmVaccination.get('Notes').value;
    this.userVaccination.UserDetailsId = this.authenticationService.currentUserValue.UserDetailsId;


    if (E.ConvertToNumber(this.userVaccination.UserVaccinationId) == 0 && this.IsForEdit == false) {
      this.userVaccination.CreatedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userVaccination.CreatedDate = E.GetTodayDateStringOnly();
      this.userVaccination.CreatedTime = E.GetTodayTimeStringOnly();
      this.userVaccination.ChangedDate = E.GetTodayDateStringOnly();
      this.userVaccination.ChangedTime = E.GetTodayTimeStringOnly();
    }
    else {
      this.userVaccination.ChangedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userVaccination.ChangedDate = E.GetTodayDateStringOnly();
      this.userVaccination.ChangedTime = E.GetTodayTimeStringOnly();
    }
    this.loading = true;
    this.loadingScreenService.startLoading();
    //  console.log(this.userVaccination)
    let response: Observable<any>;
    this.vaccinationService.CreateUpdateUserVaccination(this.userVaccination)
      .subscribe(
        (result) => {
          this.loading = false;
          this.loadingScreenService.stopLoading();
          var message = ""; var errormessage = "";
          if (result.status == Status.RecordAdded) {
            message = this.currentAppLanguage.data.MY_VACCINATION_FORM.TOAST_MESSAGE_VACCINATION_ADDED_SUCCESSFULLY;
          }
          else if (result.status == Status.RecordUpdated) {
            message = this.currentAppLanguage.data.MY_VACCINATION_FORM.TOAST_MESSAGE_VACCINATION_UPDATED_SUCCESSFULLY;
          }
          else {
            errormessage = E.ConvertToString(result.message);
          }
          if (message.length > 0) {
            this._toastrService.Success(message, Constant.SuccessToastrTitle);
            this.dialogRef.close();
            this.frmVaccination.reset();
            this.frmVaccination.patchValue({
              LastDosageDate: new Date(),
              NextDosageDate: new Date()
            });
          }
          else {
            this._toastrService.Error(errormessage, Constant.ErrorToastrTitle);
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
          this.loading = false;
          this.loadingScreenService.stopLoading();
        }
      );

  }

  OnNextDosageDateChange(event) {
    let datefrom = this.frmVaccination.get('LastDosageDate').value;
    let dateto = this.frmVaccination.get('NextDosageDate').value;
    if (datefrom && dateto) {
      if (datefrom.getTime() > dateto.getTime()) {
        this.frmVaccination.get('LastDosageDate').setValue(dateto);
      }
    }
    if (dateto)
      this.maxFromDate = dateto;
  }

  onOtherField(vaccinelist: Array<VaccineListOutputModel>) {
    let _selectedvaccineid = this.frmVaccination.get('VaccineId').value;
    if (_selectedvaccineid) {
      let _selectedvaccine = vaccinelist.filter(x => x.VaccineId == _selectedvaccineid)[0];
      if (_selectedvaccine) {
        if (_selectedvaccine.VaccineName.toLocaleLowerCase() == 'other') {
          this.FieldHide = true;
        }
        else {
          this.FieldHide = false;
          this.userVaccination.VaccinationName = E.ConvertToString(_selectedvaccine.VaccineName);
          //this.userVaccination.VaccinationName = E.ConvertToString(id[this.frmVaccination.get('VaccineId').value - 1].VaccineName);
        }
      }
      //  console.log(id[this.frmVaccination.get('VaccineId').value - 1].VaccineName)
      //  console.log(id)
    }
  }
  onCloseButtonClick() {
    this.dialogRef.close();
  }
  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }
  get f() {
    return this.frmVaccination.controls;
  }

}
