import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appRemoveMargins]'
})
export class RemoveMarginsDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) { }


  ngAfterViewInit() {
    const elements = this.el.nativeElement.querySelectorAll('*');
    elements.forEach((element: HTMLElement) => {
      this.renderer.setStyle(element, 'margin', '0');
      this.renderer.setStyle(element, 'padding', '0');
    });
  }
}
