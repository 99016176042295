import { MapModalType } from "app/enums";

export class MapAddressDataModel {
    constructor(_mapModalType: MapModalType, _data: any, _zoom: number = 18) {
        this.mapModalType = _mapModalType;
        this.Data = _data;
        this.Zoom = _zoom;
    }
    mapModalType: MapModalType;
    Data: any;
    Zoom: number;
}

export class MapAddressOutputModel {
    constructor(_lat: number, _long: number, _searchstring: string) {
        this.lat = _lat;
        this.long = _long;
        this.searchstring = _searchstring;
    }
    lat: number;
    long: number;
    searchstring: string;
}