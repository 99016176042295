import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from 'app/Services/global.service';
import { AuthenticationService } from 'app/auth/service';
import {TblUserJobs} from '../about-me-model.model'

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  
  constructor(
    private _http: HttpClient,
    private globalService: GlobalService,
    private authenticationService:AuthenticationService
  ) { }

  GetUserJobLoadData(data:number){
    const path='api/UserJob/GetUserJobLoadData'
      return this.globalService.PostDataById(data, path)
  }
  CreateUpdateUserJob(userJobs:TblUserJobs){
    const path='api/UserJob/CreateUpdateUserJob'
      return this.globalService.PostDataById(userJobs, path)
  }
  GetUserJobs(data:number){
    const path='api/UserJob/GetUserJobs'
      return this.globalService.PostDataById(data, path)
  }
}
