import { PostConstruct } from "ag-grid-community";
import { Status } from "app/enums";
export const locale = {
  lang: 'en',
  data: {
    COMMON: {
      CONTROL_LABEL_EMAIL_ADDRESS: 'Email Address',
      PLACEHOLDER_EMAIL_ADDRESS: 'Type here...',
      PLACEHOLDER_TYPE_HERE: 'Type here...',
      PLACEHOLDER_MOBILE_NUMBER: '0123456789',
      BUTTON_TEXT_SAVE_CHANGES: 'Save Changes',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS: 'The email is required!',
      ERROR_MESSAGE_INVALID_EMAIL: 'Please enter your email address in format:\nyourname@example.com',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'Could not connect with server.',
      COMMON_STATUS:
        [
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'User could not be found.' }
        ],
    },
    FILEMANAGER: {
      HEADING_LABEL_FILE_MANAGER: 'File Manager',
      LABEL_ADD_FOLDER: 'Add Folder',
      LABEL_DELETE_FOLDER: 'Delete Folder',
      LABEL_SELECT_ALL: 'Select All',
      LABEL_CHARACTER_COUNT:'Character Count',
      LABEL_FOLDER_NAME_REQUIRED: 'Folder Name is Required!',
      LABEL_MAX_CHARACTERS_20: 'You have typed too many characters, maximum allowed is 20',
      LABEL_CONFIRM: 'Confirm',
      LABEL_CANCEL: 'Cancel',
      LABEL_DELETE: 'Delete',
      LABEL_VIEW: 'View',
      LABEL_UPDATE:'Update',
      LABEL_PLACEHOLDER:'Enter Folder Name',
      LABEL_CREATE_FOLDER:'Create Folder',
      LABEL_UPDATE_FOLDER:'Update Folder',
      LABEL_TOOLTIP:'Toggle Selection'
    },
    BLOGS: {
      LABEL_COVER_PICTURE:'Your Cover Picture',
      LABEL_CROP:'Crop',
      LABEL_RESET:'Reset',
      LABEL_YOUR_PROFILE_PICTURE:'Your Profile Picture',
      LABEL_DATE_RANGE:'Date Range',
      LABEL_MONTH:'Month',
      LABEL_FROM_DATE:'From Date',
      LABEL_TO_DATE:'To Date',
      LABEL_SEARCH:'Search',
      LABEL_ON_THIS_DAY:'On This Day',
      LABEL_YEARS_AGO:'Years Ago',
      LABEL_STAY_ANONYMOUS:'Stay Anonymous',
      LABEL_YES:'Yes',
      LABEL_NO:'No',
      LABEL_ABOUT_ME:'About Me',
      LABEL_JOINED:'Joined',
      LABEL_EMAIL_ADDRESS:'Email Address',
      LABEL_MY_PERSONAL_URL:'My Personal URL',
      LABEL_LIVES:'Lives'
    }
  }
};
