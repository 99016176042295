import { Status } from "app/enums";

export const locale = {
  lang: 'de',
  data: {
    COMMON:{
      ERROR_MESSAGE_CONNECTIONREFUSED:'Es konnte keine Verbindung zum Server hergestellt werden.'
    },
    SIGNIN: {
      MOTIVATION_BADGE_SIGNIN:'Melden Sie sich an und starten Sie das Abenteuer',
      WELCOME_TEXT: 'Willkommen bei Noosom',
      CONTROL_LABEL_EMAIL:'E-Mail-Addresse',
      CONTROL_LABEL_PASSWORD:'Passwort',
      CONTROL_LABEL_REMEMBERME:'Erinnere dich an mich',
      PLACEHOLDER_EMAIL:'johndoe@gmail.com',
      LINK_TEXT_LABEL_FORGOTPASSWORD:'Haben Sie Ihr Passwort vergessen?',
      LINK_TEXT_LABEL_CREATEACCOUNT:'Ein Konto erstellen',
      TEXT_NEWONPLATFORM:'Neu auf unserer Plattform?',
      BUTTON_TEXT_LOGIN:'Anmeldung',
      TOAST_MESSAGE_SUCCESSFULLOGINMESSAGE:'Sie haben sich erfolgreich als {0}-Benutzer bei Noosom angemeldet. Jetzt können Sie mit der Erkundung beginnen. Genießen! 🎉',
      TOAST_MESSAGE_SUCCESSFULLOGINWELCOMETEXT:'👋 Willkommen, {0}!',
      ERROR_MESSAGE_REQUIRED_EMAIL:'Das E-Mail-Feld ist!',
      ERROR_MESSAGE_REQUIRED_PASSWORD:'Das Passwortfeld ist!',
      ERROR_MESSAGE_INVALID_EMAIL:'E-Mail muss eine gültige E-Mail-Adresse sein',
      ERROR_MESSAGE_NOATTEMPTS:'Ungültiger Benutzername oder ungültiges Passwort.<br>Sie haben keine weiteren Versuche mehr.',
      LOGIN_STATUS:
      [
        {ErrorCode:Status.EmailNotVarified,ErrorMessage:'Ihre E-Mail-Adresse wurde nicht verifiziert.<br>überprüfen Sie zuerst Ihre E-Mail-Adresse.'},
        {ErrorCode:Status.AccountLocked,ErrorMessage:'Dein Account wurde gesperrt.'},
        {ErrorCode:0,ErrorMessage:'Eingeloggt erfolgreich.'},
        {ErrorCode:Status.InvalidCredential,ErrorMessage:'Ungültiger Benutzername oder ungültiges Passwort. Sie haben noch {0} Versuche.'}
      ],
      DIGITS:[1,2,3,4],
      ROLES:[{title:'Admin',value:'Administratorin'},{titlle:'Client',value:'Klientin'},{title:'User',value:'Benutzer'}]
    },
    SIGNUP:
    {
      MOTIVATION_BADGE_SIGNUP:'Melden Sie sich an und starten Sie das Abenteuer',
      STEPPER_TITLE_ACCOUNT:'Konto',
      STEPPER_SUB_TITLE_ACCOUNT:'Benutzernamen eingeben',
      STEPPER_TITLE_PERSONAL:'persönlich',
      STEPPER_SUB_TITLE_PERSONAL:'Information eingeben',
      HEADING_ACCOUNT:'Kontoinformationen',
      HEADING_PERSONAL:'Persönliche Informationen',
      HEADING_SELECTPLAN:'Plan auswählen',
      CONTROL_LABEL_USERID:'Noosom-Benutzer-ID',
      CONTROL_LABEL_EMAILADDRESS:'E-Mail-Addresse',
      CONTROL_LABEL_PASSWORD:'Passwort',
      CONTROL_LABEL_CONFIRMPASSWORD:'Bestätigen Sie Ihr Passwort',
      CONTROL_LABEL_REGION:'Ihre Region',
      CONTROL_LABEL_HOMECOUNTRY:'Ihr land',
      CONTROL_LABEL_FIRSTNAME:'Vorname',
      CONTROL_LABEL_MIDDLENAME:'Zweiter Vorname',
      CONTROL_LABEL_LASTNAME:'Nachname',
      CONTROL_LABEL_BIRTHDATE:'Geburtsdatum',
      CONTROL_LABEL_MOBILENO:'Handy-Nr',
      CONTROL_LABEL_SELECTPLAN:'Jetzt für immer kostenlos oder später kaufen.',
      PLACEHOLDER_TYPEHERE:'Geben Sie hier ein ...',
      PLACEHOLDER_PASSWORD:'Passwort',
      PLACEHOLDER_REGION:'Region wählen',
      PLACEHOLDER_COUNTRY:'Land auswählen',
      PLACEHOLDER_TELEPHONENUMBER:'0123456789',
      BUTTON_TEXT_PREVIOUS:'Vorherige',
      BUTTON_TEXT_NEXT:'Nächste',
      BUTTON_TEXT_SUBMIT:'Einreichen',
      TOOLTIPTEXT_CONTROL_LABEL_REGION_1:'Ihre Daten werden gespeichert',
      TOOLTIPTEXT_CONTROL_LABEL_REGION_2:'in der ausgewählten Region',
      TOOLTIPTEXT_CONTROL_LABEL_HOMECOUNTRY:'land auswählen',
      TOAST_MESSAGE_SUCCESSFULSIGNUP:'Konto erfolgreich erstellt.',
      ERROR_MESSAGE_REQUIRED_USERID:'Das Feld „Benutzername“ ist! Das Feld „E-Mail“ ist',
      ERROR_MESSAGE_EXISTED_USERID:'Dieser Benutzername wurde vergeben. Bitte versuchen Sie es mit einem anderen.',
      ERROR_MESSAGE_REQUIRED_EMAILADDRESS:'Das Feld für die E-Mail-Adresse ist!',
      ERROR_MESSAGE_EXISTED_EMAILADDRESS:'Diese E-Mail-Adresse wurde übernommen. Bitte versuchen Sie es mit einem anderen.',
      ERROR_MESSAGE_REQUIRED_PASSWORD:'Das Passwortfeld ist!',
      ERROR_MESSAGE_INVALID_PASSWORD:'Muss mindestens eine Zahl, einen Groß- und Kleinbuchstaben und mindestens 8 Zeichen enthalten.',
      ERROR_MESSAGE_MISMATCHED_PASSWORD:'Passwort wiederholen muss übereinstimmen.',
      ERROR_MESSAGE_REQUIRED_CONFIRMPASSWORD:'Das Feld Passwort bestätigen ist!',
      ERROR_MESSAGE_REQUIRED_REGION:'Das Regionsfeld ist!',
      ERROR_MESSAGE_REQUIRED_HOMECOUNTRY:'Das Feld land ist!',
      ERROR_MESSAGE_REQUIRED_FIRSTNAME:'Das Vornamensfeld ist!',
      ERROR_MESSAGE_REQUIRED_MIDDLENAME:'Das Feld für den zweiten Vornamen ist!',
      ERROR_MESSAGE_REQUIRED_LASTNAME:'Das Feld Nachname ist!',
      ERROR_MESSAGE_REQUIRED_BIRTHDATE:'Das Feld Geburtsdatum ist!',
      ERROR_MESSAGE_REQUIRED_MOBILENO:'Das Handy ist kein Pflichtfeld!',
      ERROR_MESSAGE_REQUIRED_SELECTPLAN:'Bitte wählen Sie die kostenlose Planoption!',
    }
  }
};
