import { Status } from "app/enums";

export const locale = {
  lang: 'hn',
  data: {
    COMMON: {
      THANKS_BADGE_SIGNUP: 'साइन अप करने के लिए धन्यवाद!',
      TEXT_HEY: 'हे',
      TEXT_THANKS: 'धन्यवाद,',
      TEXT_NOOSOM_TEAM: 'नुसम टीम',
      TEXT_READ_OUR: 'हमारी',
      LINK_TEXT_PRIVACY_POLICY: 'गोपनीयता नीति',
      LINK_TEXT_TERMS_CONDITIONS: 'नियम एवं शर्तें',
      LINK_TEXT_BACK_TO_LOGIN: 'लॉगिन पर वापस जाएं',
      PLACEHOLDER_EMAIL: 'यहाँ लिखो...',
      PLACEHOLDER_TYPE_HERE: 'यहाँ लिखो...',
      TOOLTIP_TEXT_BUTTON_LOGIN: 'लॉग इन करें',
      CONTROL_LABEL_EMAIL: 'ईमेल',
      CONTROL_LABEL_EMAIL_ADDRESS: 'ईमेल पता',
      CONTROL_LABEL_CONFIRM_PASSWORD: 'पासवर्ड की पुष्टि कीजिये',
      BUTTON_TEXT_SAVE_CHANGES: 'परिवर्तनों को सुरक्षित करें',
      TOAST_MESSAGE_SUCCESSFUL_OTP_VERIFIED: 'ओटीपी सफलतापूर्वक सत्यापित।',
      ERROR_MESSAGE_REQUIRED_EMAIL: 'ईमेल पता की आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_PASSWORD: 'पासवर्ड की आवश्यक है!',
      ERROR_MESSAGE_INVALID_EMAIL: 'कृपया अपना ईमेल पता प्रारूप में दर्ज करें:\nyourname@example.com',
      ERROR_MESSAGE_INVALID_PASSWORD: 'कम से कम एक नंबर, एक अपरकेस और लोअरकेस अक्षर और कम से कम 8 या अधिक वर्ण होने चाहिए।',
      ERROR_MESSAGE_MISMATCHED_PASSWORD: 'पुष्टि करें कि पासवर्ड मेल खाना चाहिए।',
      ERROR_MESSAGE_REQUIRED_CONFIRMPASSWORD: 'पुष्टिकरण पासवर्ड आवश्यक है!',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'सर्वर से कनेक्ट नहीं हो सका.',
      ERROR_MESSAGE_INVALID_OTP_DATA: 'वैध ओटीपी डेटा दर्ज करें।',
      ERROR_MESSAGE_INVALID_OTP: 'मान्य ओटीपी दर्ज करें।',
      COMMON_STATUS:
        [
          { ErrorCode: Status.InvalidOTP, ErrorMessage: 'अमान्य ओटीपी.' },
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'यह ईमेल पता किसी भी नुसम खाते के साथ पंजीकृत नहीं है।' }
        ],
    },
    SIGNIN: {
      WELCOME_TEXT: 'नुसम में आपका स्वागत है',
      MOTIVATION_BADGE_SIGNIN: 'साइन-इन करें और साहसिक कार्य शुरू करें',
      SIGNIN_AGREEMENT_TEXT: 'साइन इन करके, आप हमारी सहमति देते हैं',
      CONTROL_LABEL_EMAIL: 'ईमेल पता',
      CONTROL_LABEL_PASSWORD: 'पासवर्ड',
      CONTROL_LABEL_REMEMBERME: 'मुझे याद रखें',
      LINK_TEXT_LABEL_FORGOTPASSWORD: 'क्या आप पासवर्ड भूल गए?',
      LINK_TEXT_LABEL_CREATEACCOUNT: 'खाता बनाएं',
      LABEL_TEXT_VERSION: 'रूपांतर',
      LABEL_TEXT_UPDATED_ON: 'अपडेट किया गया',
      TEXT_NEWONPLATFORM: 'हमारे मंच पर नया?',
      BUTTON_TEXT_LOGIN: 'लॉग इन करें',
      TOOLTIP_TEXT_BUTTON_LOGIN: 'लॉग इन करें',
      TOAST_MESSAGE_SUCCESSFULLOGINMESSAGE: 'आपने नुसम में एक {0} उपयोगकर्ता के रूप में सफलतापूर्वक लॉग इन किया है। अब आप एक्सप्लोर करना शुरू कर सकते हैं। आनंद लीजिये! 🎉',
      TOAST_MESSAGE_SUCCESSFULLOGINWELCOMETEXT: '👋 स्वागत हे, {0}!',
      ERROR_MESSAGE_NOATTEMPTS: 'अमान्य उपयोगकर्ता नाम या पासवर्ड। आपके पास कोई प्रयास शेष नहीं है।',
      LOGIN_STATUS:
        [
          { ErrorCode: Status.UserNotFound, ErrorMessage: '<b>क्षमा करें।</b> आपके द्वारा दर्ज किया गया ईमेल पता किसी भी खाते से मेल नहीं खाता है।<br>फिर से जांचें या किसी खाते के लिए साइन अप करें।' },
          { ErrorCode: Status.EmailNotVarified, ErrorMessage: 'आपका ईमेल पता सत्यापित नहीं है।<br>पहले अपना ईमेल पता सत्यापित करें।' },
          { ErrorCode: Status.AccountLocked, ErrorMessage: 'आपका खाता लॉक कर दिया गया है।' },
          { ErrorCode: 0, ErrorMessage: 'लॉग-इन सफल।' },
          { ErrorCode: Status.InvalidCredential, ErrorMessage: 'अमान्य उपयोगकर्ता नाम या पासवर्ड।<br>आपके पास {0} प्रयास शेष हैं।' }
        ],
      DIGITS: ["१", "२", "३", "४"],
      ROLES: [{ title: 'Admin', value: 'व्यवस्थापक' }, { titlle: 'Client', value: 'ग्राहक' }, { title: 'User', value: 'उपयोगकर्ता' }]
    },
    SIGNUP:
    {
      MOTIVATION_BADGE_SIGNUP: 'साइन अप करें! इसमें एक मिनट से भी कम समय लगेगा।',
      STEPPER_TITLE_ACCOUNT: 'खाता',
      STEPPER_SUB_TITLE_ACCOUNT: 'उपयोगकर्ता नाम दर्ज करें',
      STEPPER_TITLE_PERSONAL: 'निजी',
      STEPPER_SUB_TITLE_PERSONAL: 'जानकारी दर्ज करें',
      HEADING_ACCOUNT: 'खाता संबंधी जानकारी',
      HEADING_PERSONAL: 'व्यक्तिगत जानकारी',
      HEADING_SELECTPLAN: 'प्लान का चयन करें',
      TOOLTIP_TEXT_BUTTON_PREVIOUS: 'पिछला',
      TOOLTIP_TEXT_BUTTON_NEXT: 'अगला',
      TOOLTIP_TEXT_BUTTON_SUBMIT: 'प्रस्तुत करना',
      TOOLTIP_TEXT_BUTTON_CALENDAR: 'Toggle Calendar',
      CONTROL_LABEL_USERID: 'नुसम उपयोगकर्ता नाम',
      CONTROL_LABEL_EMAILADDRESS: 'ईमेल पता',
      CONTROL_LABEL_PASSWORD: 'पासवर्ड',
      CONTROL_LABEL_CONFIRMPASSWORD: 'अपने पासवर्ड की पुष्टि करें',
      CONTROL_LABEL_REGION: 'आपका क्षेत्र',
      CONTROL_LABEL_HOMECOUNTRY: 'आपका देश',
      CONTROL_LABEL_FIRSTNAME: 'पहला नाम',
      CONTROL_LABEL_MIDDLENAME: 'मध्य नाम',
      CONTROL_LABEL_LASTNAME: 'उपनाम',
      CONTROL_LABEL_BIRTHDATE: 'जन्म की तारीख',
      CONTROL_LABEL_MOBILENO: 'मोबाइल नंबर',
      CONTROL_LABEL_SELECTPLAN: 'अभी के लिए हमेशा के लिए मुफ़्त, या बाद में ख़रीदें।',
      PLACEHOLDER_PASSWORD: 'पासवर्ड',
      PLACEHOLDER_REGION: 'क्षेत्र का चयन करें',
      PLACEHOLDER_COUNTRY: 'देश का चयन करें',
      PLACEHOLDER_TELEPHONENUMBER: '०१२३४५६७८९',
      BUTTON_TEXT_PREVIOUS: 'पिछला',
      BUTTON_TEXT_NEXT: 'अगला',
      BUTTON_TEXT_SUBMIT: 'प्रस्तुत करना',
      TOOLTIPTEXT_CONTROL_LABEL_REGION_1: 'आपका डेटा संग्रहीत किया जाएगा',
      TOOLTIPTEXT_CONTROL_LABEL_REGION_2: 'चयनित क्षेत्र में',
      TOOLTIPTEXT_CONTROL_LABEL_HOMECOUNTRY: 'अपने देश का चयन करें',
      TOOLTIPTEXT_CONTROL_LABEL_USERNAME: 'आपको एक डिफ़ॉल्ट उपयोगकर्ता नाम आवंटित किया गया है जिसे आप बाद में व्यक्तिगत रूप से समायोजित कर सकते हैं।',
      TOAST_MESSAGE_SUCCESSFULSIGNUP: 'खाता सफलतापूर्वक बनाया गया।',
      ERROR_MESSAGE_REQUIRED_USERID: 'उपयोगकर्ता नाम की आवश्यकता है!',
      ERROR_MESSAGE_EXISTED_USERID: 'वह उपयोगकर्ता नाम लिया गया है। कृपया दूसरा प्रयास करें।',
      ERROR_MESSAGE_REQUIRED_EMAILADDRESS: 'ईमेल पता आवश्यक है!',
      ERROR_MESSAGE_EXISTED_EMAILADDRESS: 'वह ईमेल पता लिया गया है। कृपया दूसरा प्रयास करें।',
      ERROR_MESSAGE_EXISTED_MOBILENUMBER: 'वह मोबाइल नंबर लिया गया है। कृपया दूसरा प्रयास करें।',
      ERROR_MESSAGE_MISMATCHED_PASSWORD: 'रिपीट पासवर्ड मैच होना चाहिए।',
      ERROR_MESSAGE_AGEMAXOUT_BIRTHDATE: 'साइन अप के लिए यह एक अवैध जन्म तिथि है। साइन अप के लिए आपकी आयु 13 या इससे अधिक होनी चाहिए। कृपया एक मान्य जन्म तिथि दर्ज करें।',
      ERROR_MESSAGE_CONTACT_INVALID: 'अमान्य संपर्क नंबर',

      ERROR_MESSAGE_REQUIRED_REGION: 'क्षेत्र आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_HOMECOUNTRY: 'देश आवश्यकत है!',
      ERROR_MESSAGE_REQUIRED_FIRSTNAME: 'पहला नाम आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_MIDDLENAME: 'मध्य नाम आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_LASTNAME: 'उपनाम की आवश्यकता है!',
      ERROR_MESSAGE_REQUIRED_BIRTHDATE: 'जन्म तिथि आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_MOBILENO: 'मोबाइल नंबर की आवश्यकता है!',
      ERROR_MESSAGE_REQUIRED_SELECTPLAN: 'कृपया मुफ्त योजना विकल्प चुनें!',
      ERROR_MESSAGE_MAX_LENGTH_50: 'आपने बहुत अधिक वर्ण टाइप किए हैं, अधिकतम अनुमत 50 है',
      ERROR_MESSAGE_MAX_LENGTH_100: 'आपने बहुत अधिक वर्ण टाइप किए हैं, अधिकतम अनुमत 100 है',
      ERROR_MESSAGE_MAX_LENGTH_12: 'आपने बहुत अधिक वर्ण टाइप किए हैं, अधिकतम अनुमत 12 है',
    },
    SUCCESSFUL_SIGNUP: {
      TEXT_DESCRIPTION_1: 'अब आपको अपने विवरण की पुष्टि करने के लिए एक ईमेल प्राप्त होगा, आपको यह करने की आवश्यकता है पहले।',
      TEXT_DESCRIPTION_2: 'अपना ईमेल पता सत्यापित करने के बाद आप सिस्टम का उपयोग करने में सक्षम होंगे।',
      TEXT_DESCRIPTION_3: 'जारी रखने के लिए "ओके बटन" दबाएं।',
      TOOLTIP_TEXT_BUTTON_OKAY: 'Okay',
      BUTTON_TEXT_OKAY: 'ठीक'
    },
    VERIFY_EMAIL_ADDRESS: {
      TEXT_DESCRIPTION: 'कृपया अपनी पहचान सत्यापित करने के लिए नीचे अपने ईमेल से 6 अंकों का ओटीपी ( वन टाइम पासवर्ड ) दर्ज करें।',
      TEXT_DESCRIPTION_NEW:'आपने अब नूसम को सक्रिय कर दिया है। वेब सिस्टम का उपयोग करने के लिए जारी रखें पर क्लिक करें या ऐप सिस्टम का उपयोग करने के लिए बाहर निकलें।',
      BUTTON_TEXT_CONTINUE_WEB: "जारी रखें और वेब का उपयोग करें",
      BUTTON_TEXT_CONTINUE_APP: "बाहर निकलें और ऐप का उपयोग करें",
      TOOLTIP_TEXT_BUTTON_VERIFY_AND_LOGIN: 'सत्यापित करें और लॉगिन करें',
      BUTTON_TEXT_VERIFY_LOGIN: 'सत्यापित करें और लॉगिन करें',
      TOAST_MESSAGE_SUCCESSFUL_EMAIL_VERIFIED: 'ईमेल पता सफलतापूर्वक सत्यापित किया गया।'
    },
    VERIFY_SIGNIN_OTP: {
      TEXT_DESCRIPTION: 'कृपया अपने नुसम खाते में साइन इन करने के लिए नीचे दिए गए हमारे ईमेल पता से 6 अंकों का OTP ( वन टाइम पासवर्ड ) दर्ज करें।',
      TOOLTIP_TEXT_BUTTON_VERIFY_OTP: 'ओटीपी सत्यापित करें',
      BUTTON_TEXT_VERIFY_OTP: 'ओटीपी सत्यापित करें'
    },
    FORGOT_PASSWORD: {
      HEADING_FORGOT_PASSWORD: 'क्या आप पासवर्ड भूल गए?',
      TEXT_DESCRIPTION: 'अपना ईमेल पता दर्ज करें और हम आपको अपना पासवर्ड रीसेट करने के निर्देश भेजेंगे।',
      TOOLTIP_TEXT_BUTTON_SEND_RESET_LINK: 'रीसेट लिंक भेजें',
      BUTTON_TEXT_SEND_RESET_LINK: 'रीसेट लिंक भेजें',
      TOAST_MESSAGE_SENT_RESET_PASSWORD_LINK: 'रीसेट पासवर्ड लिंक आपके पंजीकृत ईमेल पते पर भेज दिया गया है।'   //mobile number and 
    },
    RESET_PASSWORD: {
      HEADING_RESET_PASSWORD: 'अपना पासवर्ड रीसेट करें।',
      TEXT_DESCRIPTION: 'यह सुनिश्चित करने के लिए कि खाता सुरक्षित है, अपना नया पासवर्ड सेट करने के बाद आप अन्य उपकरणों से लॉग आउट हो जाएंगे',
      TOOLTIP_TEXT_BUTTON_SAVE_CHANGES: 'परिवर्तनों को सुरक्षित करें',
      CONTROL_LABEL_NEW_PASSWORD: 'नया पासवर्ड',
      TOAST_MESSAGE_RESET_PASSWORD: 'आपका पासवर्ड सफलतापूर्वक बदल दिया गया है।',
      ERROR_MESSAGE_LINK_EXPIRED: 'यह लिंक समाप्त हो गया है, कृपया पुनः प्रयास करें।',
    }
  }
};
