<!-- <div class="content-wrapper container-xxl p-0"> -->
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="content-body">
        <div class="card-container">
            <div class="">
                <div cdkDragHandle  class="header">
                    <p class="title m-0">File Manager</p>
                    <button class="close-button close" type="button"
        (click)="onCancelClick()" placement="right"
        ngbTooltip="Close">&times;</button>
                  </div>
                  
                <!-- <hr clas="solid m-0"> -->
                <div class="card scroll-area" >
                    <div class="folder-container">
                        <div *ngIf="folders && folders.length==0" class="center-container">
                            <img src="assets/images/pages/noimageuploaded.png" alt="No Image Uploaded" class="center-image">
                          </div>                          
                          <div class="folder" *ngFor="let folder of folders; let i = index">
                            <div class="folder-content">
                              <img src="assets/images/icons/foldernew.svg" ngbTooltip="Select" placement="top" alt="Folder" class="folder-icon" (click)="onFolderImageClick(folder)">
                              <span class="folder-name"   >{{ folder.UserFolderName }}</span>
                            </div>
                          </div>
                          
                      </div>
                </div>
            </div>
        </div>
    </div>
<!-- </div>     -->