import { Component, OnDestroy, OnInit, ViewEncapsulation, ElementRef, Renderer2, RendererStyleFlags2 } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreConfigService } from '@core/services/config.service';
import { Router, NavigationEnd, NavigationError, NavigationStart } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { GlobalService } from 'app/Services/global.service';
@Component({
  selector: 'vertical-layout',
  templateUrl: './vertical-layout.component.html',
  styleUrls: ['./vertical-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VerticalLayoutComponent implements OnInit, OnDestroy {
  coreConfig: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(private _coreConfigService: CoreConfigService, private _elementRef: ElementRef,private router: Router,private renderer: Renderer2,private _authenticationService: AuthenticationService,private globalService:GlobalService) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationStart) {
    //     console.log('Navigation started');
    //   } else if (event instanceof NavigationEnd) {
    //     console.log('Navigation ended successfully');
    //   } else if (event instanceof NavigationError) {
    //     console.error('Navigation error:', event.error);
    //   }
    // });
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
   // console.log(this.router)
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.adjustAppContentPadding();
      }
    });
  }
  ngAfterViewInit(){
    this.adjustAppContentPadding();
  }
 adjustAppContentPadding() {
  const appContentElement = document.querySelector('.app-content');
  if (appContentElement && this._authenticationService.currentUserValue) {
  //   this.renderer.setStyle(appContentElement, 'padding', '6.75rem 2rem 0', RendererStyleFlags2.Important);
  // 
  setTimeout(() => {
    this.renderer.setStyle(appContentElement, 'padding', '6.75rem 2rem 0', RendererStyleFlags2.Important); 
  }, 500);}
  
 // console.log(appContentElement)
}

  
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
