import { PostConstruct } from "ag-grid-community";
import { Status } from "app/enums";
export const locale = {
  lang: 'en',
  data: {
    COMMON: {
      CONTROL_LABEL_EMAIL_ADDRESS: 'Email Address',
      PLACEHOLDER_EMAIL_ADDRESS: 'Type here...',
      PLACEHOLDER_TYPE_HERE: 'Type here...',
      PLACEHOLDER_MOBILE_NUMBER: '0123456789',
      BUTTON_TEXT_SAVE_CHANGES: 'Save Changes',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS: 'The email is required!',
      ERROR_MESSAGE_INVALID_EMAIL: 'Please enter your email address in format:\nyourname@example.com',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'Could not connect with server.',
      COMMON_STATUS:
        [
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'User could not be found.' }
        ],
    },
    PROFESSIONAL: {
      BUTTON_TEXT_SEARCH_ON_MAP: 'Search On Map',
      BUTTON_TEXT_ADD_A_PROFESSIONAL: 'Add a Professional',
      BUTTON_TEXT_CANCEL: 'Cancel',
      CONTROL_LABEL_SPECIALITY: 'Speciality',
      CONTROL_LABEL_PROFESSIONAL_NAME: "Professional / Practice Name",
      CONTROL_LABEL_ADDRESS1: 'Street Name',
      CONTROL_LABEL_ADDRESS2: 'Address Line 2',
      CONTROL_LABEL_ADDRESS3: 'Address Line 3',
      CONTROL_LABEL_REGION: 'Region',
      CONTROL_LABEL_COUNTRY: 'Country',
      CONTROL_LABEL_ADDRESS_STATE: 'State',
      CONTROL_LABEL_ADDRESS_TOWN: 'Town / City',
      CONTROL_LABEL_ADDRESS_POSTALCODE: 'Post / Zip Code',
      CONTROL_LABEL_EMAIL_ADDRESS: 'Email Address',
      CONTROL_LABEL_CONTACT1: 'Contact Number 1',
      CONTROL_LABEL_CONTACT2: 'Contact Number 2',
      CONTROL_LABEL_LAST_EXAM_DATE: 'Last Exam Date',
      CONTROL_LABEL_NEXT_EXAM_DATE: 'Next Exam Date',
      CONTROL_LABEL_NOTES: 'Notes',
      DIALOG_TITLE_ADD_PROFESSIONAL: 'Add a Professional',
      DIALOG_TITLE_EDIT_PROFESSIONAL: 'Edit a Professional',
      ERROR_MESSAGE_REQUIRED_SPECIALITY: 'The speciality is required!',
      ERROR_MESSAGE_REQUIRED_PROFESSIONALNAME: 'The professional name is required!',
      ERROR_MESSAGE_REQUIRED_ADDRESS: 'The address 1 is required!',
      ERROR_MESSAGE_REQUIRED_ADDRESSTOWN: 'The address town/city is required!',
      ERROR_MESSAGE_REQUIRED_ADDRESSPOSTCODE: 'The address post code is required!',
      ERROR_MESSAGE_REQUIRED_EMAILADDRESS: 'The email address is required!',
      ERROR_MESSAGE_REQUIRED_ADDRESS_TOWN: 'The address town is required!',
      ERROR_MESSAGE_REQUIRED_ADDRESS_STATE: 'The address state is required!',
      ERROR_MESSAGE_REQUIRED_ADDRESS_POST_CODE: 'The address post code is required!',
      ERROR_MESSAGE_REQUIRED_COUNTRY: 'The country is required!',
      ERROR_MESSAGE_REQUIRED_REGION: 'The region is required!',
      ERROR_MESSAGE_MAX_LENGTH_12: 'You have typed too many characters, maximum allowed is 12',
      ERROR_MESSAGE_MAX_LENGTH_50: 'You have typed too many characters, maximum allowed is 50',
      ERROR_MESSAGE_MAX_LENGTH_150: 'You have typed too many characters, maximum allowed is 150',
      ERROR_MESSAGE_MAX_LENGTH_320: 'You have typed too many characters, maximum allowed is 320',
      ERROR_MESSAGE_INVALID_EMAIL: 'Invalid Email Format!',
      ERROR_MESSAGE_INVALID_DATA: 'Invalid Contact Number!',
      ERROR_MESSAGE_REQUIRED_MOBILE_NUMBER: 'The contact number 1 is required!',
      ERROR_MESSAGE_LAST_EXAM_DATE_REQUIRED: 'The last exam date is required',
      ERROR_MESSAGE_NEXT_EXAM_DATE_REQUIRED: 'The next exam date is required',
      PLACEHOLDER_SELECT_REGION: 'Select Region',
      PLACEHOLDER_SELECT_COUNTRY: 'Select Country',
      PLACEHOLDER_SELECT_STATE: 'Select State',
      SECTION_TEXT: 'Add some information about any of the members of your healthcare team.',
      TOOLTIP_TOGGLE_CALENDAR: 'Toggle Calendar',
      TOOLTIP_TEXT_PRACTITIONER_SPECIALTY: 'Enter the specialty of your practitioner(s)',
      TOOLTIP_TEXT_STREET_NAME: 'This is a required field for mapping your location accurately; please enter the street name correctly',
      TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_1: 'This is an additional field for more precise location mapping',
      TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_2: 'This is an additional field for more precise location mapping',
      TOOLTIP_TEXT_TOWN_VILLAGE_CITY: 'Enter the Town, Village, or City of the address',
      TOOLTIP_TEXT_POSTAL_ZIPCODE: 'This is the Postal Zipcode associated with the address\'s country',
      TOOLTIP_TEXT_REGION_CONTINENT: 'Choose the Region or Continent of the address; for example, United Kingdom will be in Europe',
      TOOLTIP_TEXT_COUNTRY: 'Select the Country of the address; for example, England will be in Europe',
      TOOLTIP_TEXT_STATE_PROVINCE: 'Choose the State or Province of the address; for example, Rotuma is a state or island in Fiji',
      TOOLTIP_TEXT_PRACTITIONER_EMAIL: 'Enter the practitioner(s) Email Address',
      TOOLTIP_TEXT_PRACTITIONER_MOBILE: 'Enter Mobile Number with the area code',
      TOOLTIP_TEXT_LAST_APPOINTMENT_DATE: 'This is your Last Exam Date',
      TOOLTIP_TEXT_NEXT_APPOINTMENT_DATE: 'This is your Next Exam Date',
      TOOLTIP_TEXT_NOTES: 'Add any specific notes that you have for this practitioner',
      TOOLTIP_TEXT_BUTTON_CLOSE: 'Close',
      TOAST_MESSAGE_PROFESSIONAL_ADDED_SUCCESSFULLY: 'Professional Added Successfully',
      TOAST_MESSAGE_PROFESSIONAL_UPDATED_SUCCESSFULLY: 'Professional Updated Successfully',
      WARNING_MESSAGE_SELECT_LOCATION: 'Please select location on a map.'
    }
  }
};
