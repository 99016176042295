import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js'
@Injectable({
	providedIn: 'root'
})
export class EncrDecrService {

	constructor() { }
	set(keys: string, value: string) {
		if (value) {
			var key = CryptoJS.enc.Utf8.parse(keys);
			var iv = CryptoJS.enc.Utf8.parse(keys);
			var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
				{
					keySize: 128 / 8,
					iv: iv,
					mode: CryptoJS.mode.ECB,
					padding: CryptoJS.pad.Pkcs7
				});
			return encrypted.toString();
		}
		else
			return value;
	}



	//The get method is use for decrypt the value.
	get(keys: string, value: string) {
		if (value) {
			var key = CryptoJS.enc.Utf8.parse(keys);
			var iv = CryptoJS.enc.Utf8.parse(keys);
			var decrypted = CryptoJS.AES.decrypt(value, key, {
				keySize: 128 / 8,
				iv: iv,
				mode: CryptoJS.mode.ECB,
				padding: CryptoJS.pad.Pkcs7
			});
			return decrypted.toString(CryptoJS.enc.Utf8);
		}
		else
			return value;
	}

	setString(keys: any, value: any) {
		let valueArray = CryptoJS.enc.Utf8.parse(value)

		var key = CryptoJS.enc.Utf8.parse(keys);
		var iv = CryptoJS.enc.Utf8.parse(keys);
		var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(valueArray.toString()), key,
			{
				keySize: 128 / 8,
				iv: iv,
				mode: CryptoJS.mode.ECB,
				padding: CryptoJS.pad.Pkcs7
			});

		return encrypted.toString();
	}

	getstring(keys: any, value: any) {

		var key = CryptoJS.enc.Utf8.parse(keys);
		var iv = CryptoJS.enc.Utf8.parse(keys);
		var decrypted = CryptoJS.AES.decrypt(value, key, {
			keySize: 128 / 8,
			iv: iv,
			mode: CryptoJS.mode.ECB,
			padding: CryptoJS.pad.Pkcs7
		});

		return decrypted;

		// return decrypted.toString(CryptoJS.enc.Utf8);
	}

}
