import { NgModule } from '@angular/core';
import { TablerIconsModule } from 'angular-tabler-icons';
import { IconCamera, IconHeart, IconBrandGithub, IconMessages,IconEdit } from 'angular-tabler-icons/icons';
import * as TablerIcons from 'angular-tabler-icons/icons';

// Select some icons (use an object, not an array)
// const icons = {
//   IconCamera,
//   IconHeart,
//   IconBrandGithub,
//   IconMessages,
//   IconEdit
// };

@NgModule({
  imports: [
    TablerIconsModule.pick(TablerIcons)
  ],
  exports: [
    TablerIconsModule
  ]
})
export class IconsModule { }
