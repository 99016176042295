<!-- Menu header -->
<div class="navbar-header">
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item mr-auto">
      <!-- App Branding -->
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img height="50" width="50" src="https://sgstorage.noosom.com/NoosomAssets/Images/logo/Orange_logo.svg"
            alt="brand logo" onerror="this.onerror=null;this.src='assets/images/logo/Orange_logo.svg';">
        </span>
      </a>
    </li>
    <li class="nav-item mr-auto mx-auto">
      <a class="navbar-brand" [routerLink]="['/']">
        <img height="60" width="110" src="assets/images/logo/Black Noosom Text.svg">
      </a>
    </li>
    <!-- Menu Toggler  Commented By BM according to requirement. -->
    <li class="nav-item nav-toggle">
      <a class="nav-link modern-nav-toggle d-none d-xl-block pr-0" (click)="toggleSidebarCollapsible()">
        <i [ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'"
          class="toggle-icon feather font-medium-4 collapse-toggle-icon"></i>
      </a>
      <a class="nav-link modern-nav-toggle d-block d-xl-none pr-0" (click)="toggleSidebar()">
        <i data-feather="x" class="font-medium-4 text-primary toggle-icon"></i>
      </a>
    </li>
  </ul>
</div>
<!--/ Menu header -->

<!-- Navbar shadow -->
<div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>

<!-- Main menu -->
<div class="main-menu-content-new" [perfectScrollbar] (scroll)="onSidebarScroll()">
  <ul class="navigation navigation-main" layout="vertical" core-menunew></ul>
</div>
  <div class="container-fluid fixed-bottom">
    <div class="row">
      <div class="col text-center">
        <div *ngIf="!this.isCollapsed ">
          <a [routerLink]="['/']"> <img height="40" width="40" class="mx-auto"
              src="https://sgstorage.noosom.com/NoosomAssets/Images/logo/Orange_logo.svg" alt="brand logo"
              onerror="this.onerror=null;this.src='assets/images/logo/Orange_logo.svg';"> </a>
          <p class="pt-2">
            <b>{{'MENU.CONTROL_LABEL_VERSION'|translate}} {{currentVersionOutputModel.CurrentVersionNumber}}</b><br>
            {{'MENU.CONTROL_LABEL_UPDATED_ON'|translate}}: {{currentVersionOutputModel.LastVersionUpdateDate |date:dateformat}}
          </p>
        </div>
        <div *ngIf="this.isCollapsed">
          <button type="button" [routerLink]="['/']" class="btn" rippleEffect placement="top"
            ngbTooltip="{{'MENU.TOOLTIP_TEXT_BUTTON_VERSION'|translate}} {{currentVersionOutputModel.CurrentVersionNumber}}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{'MENU.TOOLTIP_TEXT_BUTTON_UPDATED_ON'|translate}}: {{currentVersionOutputModel.LastVersionUpdateDate | date:'MMMM d, y'}}">
            <img src="assets/images/icons/info-circle.svg">
          </button>
          <!--  <img src="assets/images/icons/info-circle.svg" title="Version {{currentVersionOutputModel.CurrentVersionNumber}}
Updated on: {{currentVersionOutputModel.LastVersionUpdateDate | date:'MMMM d, y'}}"> -->
        </div>
        <hr>
      </div>
    </div>
    <div *ngIf="!this.isCollapsed">
      <div class="row">
        <p class="col-sm justified" [routerLink]="['/pages/faq']" style="cursor:pointer" ngbTooltip="{{'MENU.TOOLTIP_TEXT_BUTTON_SUPPORT'|translate}}">
          <a [routerLink]="['/pages/faq']"> <img class="col-sm-justified" src="assets/images/icons/support.svg"> </a>
          {{'MENU.CONTROL_LABEL_SUPPORT'|translate}}
        </p>
        <p class="col-sm justified" [routerLink]="['/pages/give-feedback']" style="cursor:pointer" ngbTooltip="{{'MENU.TOOLTIP_TEXT_BUTTON_FEEDBACK'|translate}}">
          <a [routerLink]="['/pages/give-feedback']"> <img src="assets/images/icons/Feedback.svg" placement="top"> </a> 
          {{'MENU.CONTROL_LABEL_FEEDBACK'|translate}}
        </p>
      </div>
    </div>
    <div *ngIf="this.isCollapsed" class="row">
      <button type="button" [routerLink]="['/pages/faq']" class="btn col-sm" placement="top"
        ngbTooltip="{{'MENU.TOOLTIP_TEXT_BUTTON_SUPPORT'|translate}}" style="padding-left:5px;">
        <img src="assets/images/icons/support.svg">
      </button>
      <button type="button" [routerLink]="['/pages/give-feedback']" class="btn col-sm" rippleEffect placement="top"
        ngbTooltip="{{'MENU.TOOLTIP_TEXT_BUTTON_FEEDBACK'|translate}}"><img src="assets/images/icons/Feedback.svg">
      </button>
      <!--<img class="col-sm-12" height="25px" width="25px" src="assets/images/icons/support.svg"> 
      <img class="col-sm-12" height="25px" width="25px" src="assets/images/icons/Feedback.svg"> -->
    </div>
  </div>
<!--/ Main menu -->