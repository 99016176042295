<div class="customizer-content" [perfectScrollbar]>
    <!-- Customizer header -->
    <div class="customizer-header px-2 pt-1 pb-0 position-relative">
      <h4 class="mb-0">Theme Customizer</h4>
      <p class="m-0">Customize & Preview in Real Time</p>
  
      <a class="customizer-close" (click)="toggleSidebar('themeCustomizer')"><span [data-feather]="'x'"></span></a>
    </div>
  
    <hr />
    <form [formGroup]="form">
      <div formGroupName="layout">
        <!-- Styling & Text Direction -->
        <div id="customizer-styling" class="px-2">
          <!-- Skin -->
          <div id="customizer-styling-skin">
            <p class="font-weight-bold">Skin</p>
            <div class="d-flex">
              <div class="custom-control custom-radio mr-1">
                <input
                  type="radio"
                  id="skinlight"
                  formControlName="skin"
                  class="custom-control-input layout-name"
                  value="default"
                />
                <label class="custom-control-label" for="skinlight">Light</label>
              </div>
              <div class="custom-control custom-radio mr-1">
                <input
                  type="radio"
                  id="skinbordered"
                  formControlName="skin"
                  class="custom-control-input layout-name"
                  value="bordered"
                />
                <label class="custom-control-label" for="skinbordered">Bordered</label>
              </div>
              <div class="custom-control custom-radio mr-1">
                <input
                  type="radio"
                  id="skindark"
                  formControlName="skin"
                  class="custom-control-input layout-name"
                  value="dark"
                />
                <label class="custom-control-label" for="skindark">Dark</label>
              </div>
              <div class="custom-control custom-radio" *ngIf="coreConfig.layout.type == 'vertical'">
                <input
                  type="radio"
                  id="skinsemidark"
                  formControlName="skin"
                  class="custom-control-input layout-name"
                  value="semi-dark"
                />
                <label class="custom-control-label" for="skinsemidark">Semi Dark</label>
              </div>
            </div>
            <!-- Router animation -->
            <div class="d-flex justify-content-between align-items-center mt-2">
              <p class="font-weight-bold mr-auto m-0" for="routeSelect">Route Transition</p>
              <select formControlName="animation" class="form-control w-50" id="routeSelect">
                <option value="fadeInLeft">Fade In Left</option>
                <option value="zoomIn">Zoom In</option>
                <option value="fadeIn">Fade In</option>
                <option value="none">None</option>
              </select>
            </div>
          </div>
        </div>
  
        <hr />
  
        <!-- Menu -->
        <div class="customizer-menu px-2">
          <!-- Menu Layout -->
          <!-- <div id="customizer-menu-type">
            <p class="font-weight-bold">Menu Layout</p>
            <div class="d-flex">
              <div class="custom-control custom-radio mr-1">
                <input
                  type="radio"
                  id="menu-type-vertical"
                  value="vertical"
                  formControlName="type"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="menu-type-vertical">Vertical</label>
              </div>
              <div class="custom-control custom-radio mr-1">
                <input
                  type="radio"
                  id="menu-type-horizontal"
                  value="horizontal"
                  formControlName="type"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="menu-type-horizontal">Horizontal</label>
              </div>
            </div>
          </div> -->
  
          <!-- Menu Collapsed -->
          <div
            id="customizer-menu-collapsible"
            class="d-flex mt-2"
            formGroupName="menu"
            *ngIf="coreConfig.layout.type == 'vertical'"
          >
            <p class="font-weight-bold mr-auto m-0">Menu Collapsed</p>
            <div class="custom-control custom-control-primary custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="collapse-sidebar-switch"
                formControlName="collapsed"
              />
              <label class="custom-control-label" for="collapse-sidebar-switch"></label>
            </div>
          </div>
  
          <!-- Menu Hidden -->
          <div id="customizer-menu-hidden" class="d-flex mt-2" formGroupName="menu">
            <p class="font-weight-bold mr-auto m-0">Menu Hidden</p>
            <div class="custom-control custom-control-primary custom-switch">
              <input type="checkbox" class="custom-control-input" id="collapse-sidebar-hidden" formControlName="hidden" />
              <label class="custom-control-label" for="collapse-sidebar-hidden"></label>
            </div>
          </div>
        </div>
        <hr />
  
        <!-- Navbar -->
        <div id="customizer-navbar" class="px-2" formGroupName="navbar">
          <!-- Navbar Color -->
          <div id="customizer-navbar-colors" *ngIf="coreConfig.layout.type == 'vertical'">
            <p class="font-weight-bold">Navbar Color</p>
            <ul class="list-inline unstyled-list">
              <li
                class="color-box bg-white border"
                [ngClass]="{ selected: navbarColorValue === '' }"
                (click)="navbarColor('')"
              ></li>
              <li
                class="color-box bg-primary"
                [ngClass]="{ selected: navbarColorValue === 'bg-primary' }"
                (click)="navbarColor('bg-primary')"
              ></li>
              <li
                class="color-box bg-secondary"
                [ngClass]="{ selected: navbarColorValue === 'bg-secondary' }"
                (click)="navbarColor('bg-secondary')"
              ></li>
              <li
                class="color-box bg-success"
                [ngClass]="{ selected: navbarColorValue === 'bg-success' }"
                (click)="navbarColor('bg-success')"
              ></li>
              <li
                class="color-box bg-danger"
                [ngClass]="{ selected: navbarColorValue === 'bg-danger' }"
                (click)="navbarColor('bg-danger')"
              ></li>
              <li
                class="color-box bg-info"
                [ngClass]="{ selected: navbarColorValue === 'bg-info' }"
                (click)="navbarColor('bg-info')"
              ></li>
              <li
                class="color-box bg-warning"
                [ngClass]="{ selected: navbarColorValue === 'bg-warning' }"
                (click)="navbarColor('bg-warning')"
              ></li>
              <li
                class="color-box bg-dark"
                [ngClass]="{ selected: navbarColorValue === 'bg-dark' }"
                (click)="navbarColor('bg-dark')"
              ></li>
            </ul>
          </div>
          <!-- Navbar Type -->
          <div id="customizer-navbar-type">
            <p
              class="navbar-type-text font-weight-bold"
              *ngIf="coreConfig.layout.type == 'vertical'; else navbarTypeHorizontal"
            >
              Navbar Type
            </p>
            <ng-template #navbarTypeHorizontal> <p class="navbar-type-text font-weight-bold">Menu Type</p> </ng-template>
            <div class="d-flex">
              <div class="custom-control custom-radio mr-1">
                <input
                  type="radio"
                  id="nav-type-floating"
                  value="floating-nav"
                  formControlName="type"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="nav-type-floating">Floating</label>
              </div>
              <div class="custom-control custom-radio mr-1">
                <input
                  type="radio"
                  id="nav-type-sticky"
                  value="fixed-top"
                  formControlName="type"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="nav-type-sticky">Sticky</label>
              </div>
              <div class="custom-control custom-radio mr-1">
                <input
                  type="radio"
                  id="nav-type-static"
                  value="navbar-static-top"
                  formControlName="type"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="nav-type-static">Static</label>
              </div>
              <div class="custom-control custom-radio" *ngIf="coreConfig.layout.type == 'vertical'">
                <input
                  type="radio"
                  id="nav-type-hidden"
                  value="d-none"
                  formControlName="type"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="nav-type-hidden">Hidden</label>
              </div>
            </div>
          </div>
        </div>
        <hr />
  
        <!-- Footer -->
        <div id="customizer-footer" class="px-2 mb-2" formGroupName="footer">
          <div id="customizer-footer-type">
            <p class="font-weight-bold">Footer Type</p>
            <div class="d-flex">
              <div class="custom-control custom-radio mr-1">
                <input
                  type="radio"
                  id="footer-type-sticky"
                  value="footer-sticky"
                  formControlName="type"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="footer-type-sticky">Sticky</label>
              </div>
              <div class="custom-control custom-radio mr-1">
                <input
                  type="radio"
                  id="footer-type-static"
                  value="footer-static"
                  formControlName="type"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="footer-type-static">Static</label>
              </div>
              <div class="custom-control custom-radio mr-1">
                <input
                  type="radio"
                  id="footer-type-hidden"
                  value="d-none"
                  formControlName="type"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="footer-type-hidden">Hidden</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  