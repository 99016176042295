import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from 'app/Services/global.service';
import { DeleteInputModel, DownloadFileInputModel } from 'app/Models/output-model';
import {TblUserHealthProviders} from 'app/about-me/about-me-model.model'

@Injectable({
  providedIn: 'root'
})
export class AddProfessionalService {

  constructor(
    private _http: HttpClient,private globalService: GlobalService
  ) { }
  GetUserHealthProviderLoadData(_data:number){
   const path='api/UserHealthProvider/GetUserHealthProviderLoadData'
   return  this.globalService.PostDataById(_data, path);
  }
  CreateUpdateUserHealthProvider(userProfessional:TblUserHealthProviders){
    const path='api/UserHealthProvider/CreateUpdateUserHealthProvider'
   return  this.globalService.PostDataById(userProfessional, path);
  }
 
}
