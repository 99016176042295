<div class="skeleton-loader">
  <div *ngIf="showcircle" class="circle"></div>
  <!-- Large rectangle -->
  <div *ngIf="showimage" class="large-rectangle" [style.height.px]="largeRectangleHeight">
    <!-- Small rectangle inside large rectangle -->
    <div class="small-rectangle"></div>
  </div>
  <!-- Multiple lines -->
  <div *ngFor="let item of generateArray()" class="line"></div>
</div>


  