import { Status } from "app/enums";

export const locale = {
    lang: 'gj',
    data: {
      COMMON:{
        ERROR_MESSAGE_CONNECTIONREFUSED:'સર્વર સાથે કનેક્ટ કરી શકાયું નથી'
      },
      SIGNIN: {
        WELCOME_TEXT: 'નૂસોમ માં આપનું સ્વાગત છે',
        MOTIVATION_BADGE_SIGNIN:'સાઇન ઇન કરો અને સાહસ શરૂ કરો',
        CONTROL_LABEL_EMAIL:'ઈ - મેઈલ સરનામું',
        CONTROL_LABEL_PASSWORD:'પાસવર્ડ',
        CONTROL_LABEL_REMEMBERME:'મને યાદ રાખો',
        PLACEHOLDER_EMAIL:'johndoe@gmail.com',
        LINK_TEXT_LABEL_FORGOTPASSWORD:'તમારો પાસવર્ડ ભૂલી ગયા છો?',
        LINK_TEXT_LABEL_CREATEACCOUNT:'એક ખાતુ બનાવો',
        TEXT_NEWONPLATFORM:'અમારા પ્લેટફોર્મ પર નવા છો?',
        BUTTON_TEXT_LOGIN:'પ્રવેશ કરો',
        TOAST_MESSAGE_SUCCESSFULLOGINMESSAGE:'તમે Noosom માં {0} વપરાશકર્તા તરીકે સફળતાપૂર્વક લૉગ ઇન કર્યું છે. હવે તમે અન્વેષણ કરવાનું શરૂ કરી શકો છો. આનંદ માણો! 🎉',
        TOAST_MESSAGE_SUCCESSFULLOGINWELCOMETEXT:'👋 સ્વાગત છે, {0}!',
        ERROR_MESSAGE_REQUIRED_EMAIL:'ઈમેલ આવશ્યક છે!',
        ERROR_MESSAGE_REQUIRED_PASSWORD:'પાસવર્ડ આવશ્યક છે!',
        ERROR_MESSAGE_INVALID_EMAIL:'ઇમેઇલ એક માન્ય ઇમેઇલ સરનામું હોવું આવશ્યક છે',
        ERROR_MESSAGE_NOATTEMPTS:'અમાન્ય વપરાશકર્તાનામ અથવા પાસવર્ડ. તમારી પાસે કોઈ પ્રયાસો બાકી નથી.',
        LOGIN_STATUS:
      [
        {ErrorCode:Status.EmailNotVarified,ErrorMessage:'તમારું ઇમેઇલ સરનામું ચકાસાયેલ નથી.<br>પહેલા તમારું ઇમેઇલ સરનામું બદલો.'},
        {ErrorCode:Status.AccountLocked,ErrorMessage:'તમારું એકાઉન્ટ લોક કરી દેવામાં આવ્યું છે.'},
        {ErrorCode:0,ErrorMessage:'લૉગ-ઇન સફળ.'},
        {ErrorCode:Status.InvalidCredential,ErrorMessage:'અમાન્ય વપરાશકર્તાનામ અથવા પાસવર્ડ.<br>તમારી પાસે {0} પ્રયાસો બાકી છે.'}
      ],
      DIGITS:["૧","૨","૩","૪"],
      ROLES:[{title:'Admin',value:'એડમિન'},{titlle:'Client',value:'ક્લાયન્ટ'},{title:'User',value:'વપરાશકર્તા'}]
      },
      SIGNUP:
      {
        MOTIVATION_BADGE_SIGNUP:'સાઇન અપ કરો અને સાહસ શરૂ કરો',
        STEPPER_TITLE_ACCOUNT:'એકાઉન્ટ',
        STEPPER_SUB_TITLE_ACCOUNT:'વપરાશકર્તા નામ દાખલ કરો',
        STEPPER_TITLE_PERSONAL:'અંગત',
        STEPPER_SUB_TITLE_PERSONAL:'માહિતી દાખલ કરો',
        HEADING_ACCOUNT:'ખાતાની માહિતી',
        HEADING_PERSONAL:'વ્યક્તિગત માહિતી',
        HEADING_SELECTPLAN:'પ્લાન પસંદ કરો',
        CONTROL_LABEL_USERID:'નૂસોમ વપરાશકર્તા આઈડી',
        CONTROL_LABEL_EMAILADDRESS:'ઈ - મેઈલ સરનામું',
        CONTROL_LABEL_PASSWORD:'પાસવર્ડ',
        CONTROL_LABEL_CONFIRMPASSWORD:'તમારા પાસવર્ડની ખાતરી કરો',
        CONTROL_LABEL_REGION:'તમારો પ્રદેશ',
        CONTROL_LABEL_HOMECOUNTRY:'તમારો  દેશ',
        CONTROL_LABEL_FIRSTNAME:'પ્રથમ નામ',
        CONTROL_LABEL_MIDDLENAME:'પિતાનું નામ',
        CONTROL_LABEL_LASTNAME:'છેલ્લું નામ',
        CONTROL_LABEL_BIRTHDATE:'જન્મ તારીખ',
        CONTROL_LABEL_MOBILENO:'મોબાઈલ નમ્બર',
        CONTROL_LABEL_SELECTPLAN:'હમણાં માટે હંમેશ માટે મફત, અથવા પછીથી ખરીદો.',
        PLACEHOLDER_TYPEHERE:'અહી લખો...',
        PLACEHOLDER_PASSWORD:'પાસવર્ડ',
        PLACEHOLDER_REGION:'પ્રદેશ પસંદ કરો',
        PLACEHOLDER_COUNTRY:'દેશ પસંદ કરો',
        PLACEHOLDER_TELEPHONENUMBER:'૦૧૨૩૪૫૬૭૮૯',
        BUTTON_TEXT_PREVIOUS:'અગાઉના',
        BUTTON_TEXT_NEXT:'આગળ',
        BUTTON_TEXT_SUBMIT:'સબમિટ કરો',
        TOOLTIPTEXT_CONTROL_LABEL_REGION_1:'તમારો ડેટા પસંદ કરેલ પ્રદેશમાં',
        TOOLTIPTEXT_CONTROL_LABEL_REGION_2:'સંગ્રહિત કરવામાં આવશે',
        TOOLTIPTEXT_CONTROL_LABEL_HOMECOUNTRY:'દેશ પસંદ કરો',
        TOAST_MESSAGE_SUCCESSFULSIGNUP:'એકાઉન્ટ સફળતાપૂર્વક બનાવ્યું.',
        ERROR_MESSAGE_REQUIRED_USERID:'યુઝરનેમ આવશ્યક છે!',
        ERROR_MESSAGE_EXISTED_USERID:'તે વપરાશકર્તા નામ લેવામાં આવ્યું છે. કૃપા કરીને બીજો પ્રયાસ કરો.',
        ERROR_MESSAGE_REQUIRED_EMAILADDRESS:'ઈમેલ એડ્રેસ ફીલ્ડ છે!',
        ERROR_MESSAGE_EXISTED_EMAILADDRESS:'તે ઈમેલ એડ્રેસ લેવામાં આવ્યું છે. કૃપા કરીને બીજો પ્રયાસ કરો.',
        ERROR_MESSAGE_REQUIRED_PASSWORD:'પાસવર્ડ ફીલ્ડ છે!',
        ERROR_MESSAGE_INVALID_PASSWORD:'ઓછામાં ઓછા એક નંબર, એક અપરકેસ અને લોઅરકેસ અક્ષર અને ઓછામાં ઓછા 8 અથવા વધુ અક્ષરો હોવા જોઈએ.',
        ERROR_MESSAGE_MISMATCHED_PASSWORD:'પુનરાવર્તિત પાસવર્ડ મેળ ખાતો હોવો જોઈએ.',
        ERROR_MESSAGE_REQUIRED_CONFIRMPASSWORD:'કન્ફર્મ પાસવર્ડ આવશ્યક છે!',
        ERROR_MESSAGE_REQUIRED_REGION:'પ્રદેશ ક્ષેત્ર આવશ્યક છે!',
        ERROR_MESSAGE_REQUIRED_HOMECOUNTRY:'દેશ આવશ્યક છે!',
        ERROR_MESSAGE_REQUIRED_FIRSTNAME:'પ્રથમ નામ આવશ્યક છે!',
        ERROR_MESSAGE_REQUIRED_MIDDLENAME:'મધ્યમ નામ આવશ્યક છે!',
        ERROR_MESSAGE_REQUIRED_LASTNAME:'છેલ્લું નામ આવશ્યક છે!',
        ERROR_MESSAGE_REQUIRED_BIRTHDATE:'જન્મ તારીખ આવશ્યક છે!',
        ERROR_MESSAGE_REQUIRED_MOBILENO:'મોબાઇલ નંબર આવશ્યક છે!',
        ERROR_MESSAGE_REQUIRED_SELECTPLAN:'કૃપા કરીને ફ્રી પ્લાન વિકલ્પ પસંદ કરો!'
      }
    }
  };
  