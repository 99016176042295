export class Constant {
  public static API: string = Constant.getAPIURL();
  //public static API = 'https://localhost:44345/';
   //public static API =  'http://www.mdipl.com/noosomapi/'; //  (Local IIS Server)
  // public static API =  'https://apitest.noosom.com/'; //  (Test Server)
  //public static API ='https://api.noosom.com/'; // (Live Server)

  public static EncrDecrKey = 'MyComplexKeyForm';
  public static ErrorToastrTitle: string = 'Error!';
  public static SuccessToastrTitle: string = 'Success!';
  public static WarningToastrTitle: string = 'Warning!';
  public static InfoToastrTitle: string = 'Info!';
  public static CDNStorageAccessKey: string = 'fcc836b2-eb78-4ef0-bb9721a7d9ca-7d77-4676';
  public static CDNStorageRootURL: string = 'https://sg.storage.bunnycdn.com/';
  public static CDNStorageZoneName: string = 'noosom1sin/';
  public static CDNDefaultFolder: string = 'UserAssets/';
  public static CDNPullZoneRootURL: string = 'https://sgstorage.noosom.com/';
  public static DefaultProfilePictureURL: string = 'https://sgstorage.noosom.com/NoosomAssets/Images/profile/default-profile-picture.png';

  private static getAPIURL(): string {
    const currentURL = window.location.href;
    const sitename = currentURL.split('/').slice(0, 3).join('/');
    // console.log(sitename)
    let APIURl: string='https://api.noosom.com/';

    if (sitename === 'https://app.noosom.com') {
      APIURl = 'https://api.noosom.com/';
    } else if (sitename === 'https://test.noosom.com') {
      APIURl = 'https://apitest.noosom.com/';
    } else if (sitename === 'http://localhost:4200') {
      APIURl = 'https://localhost:44345/';
    }

    return APIURl;
  }
}
