export class EventRef {
  id?= undefined;
  url: string;
  title: string = '';
  start: string;
  end: string;
  allDay = false;
  calendar: string = '';
  extendedProps = {
    location: '',
    description: '',
    addGuest: []
  };
}

export class QuickPostModel {
  UserQuickPostId: number;
  UserDetailsId: number;
  QuickPostTypeId: number;
  MoodId: number;
  MoodRatingId: number;
  Title: string
  DiaryDate: Date;
  DiaryTimeFrom: Date;
  DiaryTimeTo: Date;
  PostContent: string
  Location: string
  Latitude: number;
  Longitude: number;
  TagCategory: string
}