import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from 'app/Services/global.service';
import {TblUserMedications} from '../about-me-model.model'
import {MedicationLoadDataInputModel} from '../about-me-model.model'
@Injectable({
  providedIn: 'root'
})
export class MyMedicationService {
  constructor(
    private _http: HttpClient,private globalService: GlobalService

  ) { }
  GetUserMedicationLoadData(medicationLoadDataInputModel:MedicationLoadDataInputModel){
    const path='api/UserMedication/GetUserMedicationLoadData'
    return  this.globalService.PostDataById(medicationLoadDataInputModel , path);
  }
  CreateUpdateUserMedication(tblUserMedications:TblUserMedications){
    const path='api/UserMedication/CreateUpdateUserMedication'
    return  this.globalService.PostDataById( tblUserMedications,path);
  }
}
