import { AddressesModel } from "app/Models/user-address-model";
import { FileType, QuickPostType, RecordsType, SharedType } from "app/enums";

export class HomeModel {

}

export class StorageDetailOutputModel {
    AllowedStorageInBytes: number;
    UsedStorageInBytes: number;
    UsedStorageInPercentage: number;
    AllowedStorageInGB: string;
    UsedStorageInGB: string;
    AllowedStorage: string;
    UsedStorage: string;
}

export class GetQuickPostListInputModel {
    UserDetailsId: number;
    StartDate: string;
    EndDate: string;
    DocLoadYN: boolean;
    GetMetaDataYN?:boolean;
    NumberOfAttachments?: number;
    FileType?:FileType;
    QuickPostType?:QuickPostType;
}

export class GetLatestQuickPostInputModel {
    UserDetailsId: number;
    GetMetaDataYN?: boolean;
    AttachmentCount?: number;
}
export class GetMoodeTrackersInputModel {
    UserDetailsId: number;
    StartDate: string;
    EndDate: string;
    MoodType?: number;
}
export class MoodTrackersDetailModel {
    MoodID: number;
    MoodName: string;
    Percentage: number;
}
export class GetMoodeTrackersOutputModel {
    MoodList: Array<MoodTrackersDetailModel>;
}
export class GetStorageStatisticsInputModel {
    UserDetailsId: number;
}
export class StorageStatisticsOutputModel {
    Statistics: Array<StorageStatisticsDetailModel>;
}
export class StorageStatisticsDetailModel {
    FileType: string;
    TypeCount: number;
}
export class GetRandomUserQuickPostDocumentInputModel {
    UserDetailsId: number;
    NumberOfRecords: number;
}
export class GetOnThisDayInputModel {
    TodayDate: string;
    UserDetailsId: number;
    GetMetaDataYN: boolean;
    AttachmentCount?: number;
  }
  export class NoosomCouponCodeInputModel
  {
     UserDetailsId:number;
  }
  export class GetMyAttachmentsInputModel
    {  
        UserDetailsId: number;
        Page: number;
        Pagesize: number;
        FileType: FileType;
        QuickPostType: QuickPostType;
        GetMetaDataYN:boolean;
    }

    export class GetMyAttachmentsOutputModel {
        TotalNumberOfRecords: number;
        TotalNumberOfPages: number;
        AttachmentId: number;
        UserDetailsId: number;
        ParentId?: number;
        DocumentName: string;
        DocumentOldName: string;
        DocumentPath: string;
        FileType: string;
        Size: string;
        DocumentType: string;
        FileCreatedDate?: Date;
        FileLastModifiedDate?: Date;
        FileCreatedLatitude?: number;
        FileCreatedLongitude?: number;
        AllFileMetadata: string;
        CreatedDate: Date;
        CreatedTime: Date;
        CreatedBy: number;
        ChangedDate: Date;
        ChangedTime: Date;
        ChangedBy: number;
    }

    export class MyBlogAboutMeCreateUpdateInputModel {
        UserDetailsId: number;
        AboutMeDescription: string;
        StayAnonymousYn?: boolean;
    }

    export class GetMyBlogAboutMeInputModel {
        UserDetailsId: number;
    }

    export class GetMyBlogAboutMeOutputModel {
        AboutMeData: GetMyBlogAboutMeDataModel;
    }

    export class GetMyBlogAboutMeDataModel {
        UserDetailsId: number;
        UserId: number;
        UserName: string;
        EmailAddress: string;
        FullName: string;
        FirstName: string;
        MiddleName: string;
        LastName: string;
        ProfileImagePath: string;
        CoverImagePath: string;
        JoinedDate: Date;
        NoosomWebUrl: string;
        PersonalWebUrl: string;
        AboutMeDescription: string;
        StayAnonymousYn?: boolean;
        CurrentUserAddress: AddressesModel;
    }

    export class UserDetailsInputModel
    {
        UserDetailsId:number;
    }

    export class SearchInputModel
    {
        UserDetailsId:number;
        SearchString:string;
        NoosomPlanID:number;
    }

    export class SearchOutputModel
    {
        RecordId:number;
        DocumentPath:string;
        Title:string;
        EnumId:number;
    }

    export class UserQuickPostInputModelShared
    {
      
        UserDetailsId:number;
        Shared:SharedType;
        NumberOfAttachments?:number;
        StartDate?:string;
        EndDate?:string;

    }