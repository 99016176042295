import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss']
})
export class SkeletonLoaderComponent implements OnInit {
  @Input() width: number = 100;
  @Input() height: number = 20;
  @Input() linenumber: number = 20;
  @Input() showline: boolean = true;
  @Input() showcircle: boolean = true;
  @Input() showimage: boolean = false;
  @Input() largeRectangleHeight: number = 255;
  constructor() { }

  ngOnInit(): void {
  }
  generateArray(): any[] {
    return Array(this.linenumber).fill(0).map((x, i) => i);
  }

}
