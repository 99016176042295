<div class="card" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <h5 cdkDragHandle class="card-header" style="justify-content: unset;">{{dialogTitle}}&nbsp;<span ngbTooltip={{infoTooltip}}>
      <span data-feather="info"></span>
    </span><button class="close-button close" ngbTooltip="{{'COMMON.TOOLTIP_TEXT_BUTTON_CLOSE'|translate}}" type="button" (click)="onCloseButtonClick()"
        placement="right" >&times;</button>
    </h5>
    <div class="card-body card-body-dialog">
      <!-- <div class="row py-3 pl-3">
        <div class="col-sm">
          <i>{{'MY_AWARDS_ACHIEVEMENT.SECTION_TEXT'|translate}}</i>
        </div>
      </div> -->
        <form id="frmAchievement" [formGroup]="frmAchievement" (ngSubmit)="onSubmit()">
          <!-- <form >  -->
            <div class="row scroll-area">
            <div class="col-sm-12">
              <app-text-box [type]="'text'" #achievementname [showInfoIcon]="true"
              [tooltipInfo]="'MY_AWARDS_ACHIEVEMENT.TOOLTIP_TEXT_ACHIEVEMENT_NAME'|translate" [position]="'right'"
              [label]="'MY_AWARDS_ACHIEVEMENT.CONTROL_LABEL_NAME_OF_THE_ACHIEVEMENT'| translate" [showRequiredSign]="true" [maxlength]="100"
              [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="" formControlName="AchievementName"
              for="AchievementName" [classes]="{ 'is-invalid': submitted && f.AchievementName.errors }">
            </app-text-box>
            <span *ngIf="submitted && f.AchievementName.errors">
              <small *ngIf="f.AchievementName.errors.required"
                class="text-danger">{{'MY_AWARDS_ACHIEVEMENT.ERROR_MESSAGE_REQUIRED_ACHIEVEMENT_NAME'|translate}}</small>
              <small *ngIf="f.AchievementName.errors.maxlength"
                class="text-danger">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_100'|translate}}</small>
            </span>
            </div>   
           
              <div class="col-sm-6">
                <app-text-box [type]="'text'" [showInfoIcon]="true"
                [tooltipInfo]="'MY_AWARDS_ACHIEVEMENT.TOOLTIP_TEXT_OCCASION_EVENT_NAME'|translate" [position]="'right'"
              [label]="'MY_AWARDS_ACHIEVEMENT.CONTROL_LABEL_OCCASION'| translate" [showRequiredSign]="true" [maxlength]="50"
              [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="" formControlName="Occasion"
              for="Occasion" [classes]="{ 'is-invalid': submitted && f.Occasion.errors }">
            </app-text-box>
            <span *ngIf="submitted && f.Occasion.errors">
              <small *ngIf="f.Occasion.errors.required"
                class="text-danger">{{'AWARD.ERROR_MESSAGE_REQUIRED_OCCASION'|translate}}</small>
              <small *ngIf="f.Occasion.errors.maxlength"
                class="text-danger">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
            </span>
              </div>
              <div class="col-sm-6">
                <div class="">
                  <label class="form-label" for="DateReceived">{{'MY_AWARDS_ACHIEVEMENT.CONTROL_LABEL_DATE_RECEIVED' |translate}}
                    <span ngbTooltip="{{'MY_AWARDS_ACHIEVEMENT.TOOLTIP_TEXT_ACHIEVEMENT_DATE'|translate}}" placement="right">
                      <span data-feather="help-circle"
                        class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                    <strong> *</strong></label>
                  <div class="input-group">
                    <input id="" name=""  formControlName="DateReceived" [maxDate]="maxDate" [class]="{ 'is-invalid': submitted && f.DateReceived.errors }"
                      [bsConfig]="{ showWeeks: false,containerClass:'theme-blue',dateInputFormat:dateformat}" [placeholder]="dateformat"
                       required bsDatepicker #dpNext="bsDatepicker" class="form-control">
                    <div class="input-group-append">
                      <button class="btn btn-outline-primary feather icon-calendar" (click)="dpNext.toggle()"
                        type="button" rippleEffect placement="top"  
                        ngbTooltip="{{'MY_AWARDS_ACHIEVEMENT.TOOLTIP_TOGGLE_CALENDAR' | translate}}"></button>
                    </div>
                  </div>
                  <span *ngIf="submitted && f.DateReceived.invalid">
                    <small class="text-danger"
                      *ngIf="f.DateReceived.errors.required">{{'AWARD.ERROR_MESSAGE_REQUIRED_DATE_RECEIVED'
                      | translate}}</small>
                  </span>
                </div>
           
            </div>
              <div class="col-sm-12 pt-2">
                <label class="form-label" for="OccasionNotes">{{'MY_AWARDS_ACHIEVEMENT.CONTROL_LABEL_OCCASION_NOTES' | translate}}
                  <span ngbTooltip="{{'MY_AWARDS_ACHIEVEMENT.TOOLTIP_TEXT_ACHIEVEMENT_NOTES'|translate}}" placement="right">
                    <span data-feather="help-circle"
                      class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                  <strong> *</strong></label>
                <fieldset >
                    <textarea class="col-sm-12 form-control" rows="3" formControlName="OccasionNotes" [class]="{ 'is-invalid': submitted && f.OccasionNotes.errors }"
                      placeholder="{{'COMMON.PLACEHOLDER_TYPE_HERE'|translate}}"></textarea>
                  </fieldset> 
                  <span *ngIf="submitted && f.OccasionNotes.invalid">
                    <small class="text-danger"
                      *ngIf="f.OccasionNotes.errors.required">{{'AWARD.ERROR_MESSAGE_REQUIRED_OCCASION_NOTES'
                      | translate}}</small>
                  </span>
              </div>
          </div>
          </form> 
    </div>      
    </div>
<div class="card-footer">
    <div class="align">
      <button type="submit" *ngIf="!IsForEdit" class="btn btn-primary" placement="top" (click)="onSubmit()"
        ngbTooltip="{{'MY_AWARDS_ACHIEVEMENT.BUTTON_TEXT_ADD_AN_ACHIEVEMENT'|translate}}">{{'MY_AWARDS_ACHIEVEMENT.BUTTON_TEXT_ADD_AN_ACHIEVEMENT'|translate}}</button>

        <button type="submit" *ngIf="IsForEdit" class="btn btn-primary" [disabled]="loading" placement="top"
        ngbTooltip="{{'COMMON.BUTTON_TEXT_SAVE_CHANGES' | translate}}" (click)="onSubmit()"><span *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"></span>{{'COMMON.BUTTON_TEXT_SAVE_CHANGES'|translate}}</button>  
  
      <button type="button"  class="btn btn-outline-secondary ml-2" placement="top"
      (click)="onCloseButtonClick()"
        ngbTooltip="{{'MY_AWARDS_ACHIEVEMENT.BUTTON_TEXT_CANCEL'|translate}}">{{'MY_AWARDS_ACHIEVEMENT.BUTTON_TEXT_CANCEL'|translate}}</button>
    </div>
  </div>
