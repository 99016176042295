<!-- vertical-layout -->
<ng-container *ngIf="coreConfig?.layout?.type === 'vertical'">  
  <vertical-layout>
  </vertical-layout>  
  <!-- <app-auth-login-v2></app-auth-login-v2> -->
  <!-- <app-signup></app-signup> -->


  <div class="my-container">
    <ng-template #customLoadingTemplate>
      <div class="custom-class">
        <button class="btn btn-outline-primary" type="button" disabled rippleEffect>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span class="ml-25 align-middle">Loading...</span>
        </button>
      </div>
    </ng-template>
  
    <ngx-loading
      [show]="loading"
      [config]="{ backdropBorderRadius: '3px'}"
      [template]="customLoadingTemplate"
    ></ngx-loading>
  </div>

</ng-container>
<!-- / vertical-layout -->

<!-- horizontal-layout -->
<ng-container *ngIf="coreConfig?.layout?.type === 'horizontal'">
  <horizontal-layout>  
  </horizontal-layout>
  <!-- <app-signup></app-signup> -->
  

  <div class="my-container">
    <ng-template #customLoadingTemplate>
      <div class="custom-class">
        <button class="btn btn-outline-primary" type="button" disabled rippleEffect>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span class="ml-25 align-middle">Loading...</span>
        </button>
      </div>
    </ng-template>
  
    <ngx-loading
      [show]="loading"
      [config]="{ backdropBorderRadius: '3px'}"
      [template]="customLoadingTemplate"
    ></ngx-loading>
  </div>

</ng-container>
<!-- / horizontal-layout -->

<!-- theme customizer -->
<core-sidebar
  name="themeCustomizer"
  class="customizer d-none d-md-block"
  [invisibleOverlay]="true"
  *ngIf="coreConfig?.layout?.customizer"
>
  <a
    class="customizer-toggle d-flex align-items-center justify-content-center"
    (click)="toggleSidebar('themeCustomizer')"
  >
    <span [data-feather]="'settings'" [class]="'spinner'"></span>
  </a>
  <app-theme-customiser></app-theme-customiser>
</core-sidebar>
<!-- / theme customizer -->

