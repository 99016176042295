<li class="nav-item nav-search">
  <!-- Search icon -->
  <a class="nav-link nav-link-search" (click)="toggleSearch()" ngbTooltip="Search"
    ><span [data-feather]="'search'" [class]="'ficon'"></span
  ></a>
  <!-- <ng-template #tooltipTemplate>
    <div class="newpop-up">
      <p>Search</p>
      <p>Click to open search bar, double click to close!</p>
    </div>
  </ng-template>  -->

  <!-- Search -->
  <div class="search-input"  [class.open]="openSearchRef === true">
    <!-- Search Input -->
    <div class="search-input-icon">
      <span [data-feather]="'search'"></span>
    </div>
    <input
      class="form-control input"
      type="text"
      placeholder="Explore Noosom..."
      tabindex="-1"
      data-search="search"
      (keyup)="searchUpdate($event)"
      [(ngModel)]="searchText"
      #openSearch
    />
    <!-- Dropdown Button  (keyup)="searchUpdate($event)" -->
    <!-- <button
      class="btn search-input-close p-0"
      (click)="searchUpdate($event)"
    >
      <span [data-feather]="'search'"></span>
    </button> -->
    <button
    class="btn search-input-close p-0"
    (click)="toggleSearch()"
  >
    <span [data-feather]="'x'"></span>
  </button>
    
    <!-- Dropdown Menu -->
    <!-- <div *ngIf="isDropdownOpen" class="dropdown-menu dropdown-menu-right">
      <div *ngFor="let item of dropdown" class="dropdown-item checkbox-item">
        <label class="checkbox-label">
          <input 
            type="checkbox" 
            [checked]="isEnumSelected(item.value)"
            (change)="onCheckboxChange($event, item.value)"
            class="checkbox-input"
          />
          {{item.key| replace:'QuickPost':btnNameJorD}}
        </label>
      </div>
    </div> -->
    <!-- Search List -->
    <ul class="search-list search-list-main"  [class.show]="searchText !== ''" #pageList>
      <!-- Pages -->
      <!-- <li class="d-flex align-items-center">
        <a href="javascript:void(0)"><h6 class="section-label mt-75 mb-0">Pages</h6></a>
      </li>
      <ng-container *ngIf="(pages | filter: searchText:'title').length; else noResultsPages">
        <li
          class="auto-suggestion"
          *ngFor="let page of pages | filter: searchText:'title' | slice: 0:4; let i = index"
          [ngClass]="{ current_item: i === activeIndex }"
          [class.current_item]="page.hover"
          (mouseover)="page.hover = true"
          (mouseout)="page.hover = false"
        >
          <a
            class="d-flex align-items-center justify-content-between w-100"
            (click)="toggleSearch()"
            (keyup.enter)="navigate(page.link)"
            [routerLink]="page.link"
            ><div class="d-flex justify-content-start align-items-center">
              <i [data-feather]="page.icon" class="mr-75"></i><span>{{ page.title }}</span>
            </div></a
          >
        </li>
      </ng-container> -->

      <!-- No results found -->
      <!-- <ng-template #noResultsPages>
        <li class="auto-suggestion justify-content-between">
          <a class="d-flex align-items-center justify-content-between w-100 py-50">
            <div class="d-flex justify-content-start">
              <i data-feather="alert-circle" class="mr-25"></i>
              <span>No results found.</span>
            </div></a
          >
        </li>
      </ng-template> -->
      <!--/ Pages -->

      <!-- Files -->
      <!-- <li class="d-flex align-items-center ">
        <a class="d-flex align-items-center justify-content-between w-100" href="javascript:void(0)">
          <h6 class="section-label mt-75 mb-0">Records</h6>
          <h6 class="section-label mt-75 mb-0">Record Type</h6>
          <h6 class="section-label mt-75 mb-0">Date Created</h6>
          <h6 class="section-label mt-75 mb-0">Actions</h6>
        </a>
      </li>
      <ng-container *ngIf="(files | filter: searchText:'Title').length; else noResultsFiles">
        <li
          class="auto-suggestion"
          *ngFor="let file of files | filter: searchText:'Title' "
          [ngClass]="{ current_item: i === activeIndex }"
        >
          <a class="d-flex align-items-center justify-content-between w-100" (click)="openEditForm(file)">
           
            <p class="search-data-title mb-0">{{ file.Title }}</p>
            <p class="search-data-title mb-0">{{ file.EnumKey }}</p>
            <p class="search-data-title mb-0">{{ file.CreatedDate }}</p>
            <small class="search-data-size mr-50 text-muted"> <img height="18px" class="change-color" (click)="openEditForm(file)" width="18px" placement="left-bottom"  ngbTooltip="Edit/Open" src="assets/images/icons/edit.svg"></small>
          </a>
        </li>
      </ng-container> -->
      <!-- No results found -->
      <!-- <ng-template #noResultsFiles>
        <li class="auto-suggestion justify-content-between">
          <a class="d-flex align-items-center justify-content-between w-100 py-50">
            <div class="d-flex justify-content-start">
              <i data-feather="alert-circle" class="mr-25"></i>
              <span>No results found.</span>
            </div></a
          >
        </li>
      </ng-template> -->

      <li class="table-row header-row sticky-header">
        <div class="table-cell header-cell">Records</div>
        <div class="table-cell header-cell">Form Type</div>
        <div class="table-cell header-cell">Record Type</div>
        <div class="table-cell header-cell">Date Created</div>
        <div class="table-cell header-cell">Size</div>
        <div class="table-cell header-cell">Actions</div>
    </li>
    
    <ng-container *ngIf="files?.length; else noResultsFiles">
        <li style="cursor: pointer;"
          class="table-row" (click)="openEditForm(file)"
          *ngFor="let file of files ; let i = index"
          [ngClass]="{ current_item: i === activeIndex }"
        >
            <div style="padding: 0 15px;" ngbTooltip="{{ file?.Title }} {{ file?.AddressTag }} {{ file?.SchoolName }} {{ file?.InsuranceName }} {{ file?.Speciality }} {{ file?.MedicationName }} {{ file?.VaccinationName }} {{ file?.DocumentOldName }}
            {{ file?.PastimeName }} {{ file?.EntryName }} {{ file?.Tagline}} {{ file?.LegalDocumentName }} {{ file?.Employer}} {{ file?.HobbyName }} {{ file?.FirstName}} {{ file?.AwardName}} {{ file?.AchievementName}}" class="table-cell">{{ file?.Title }} {{ file?.AddressTag }} {{ file?.SchoolName }} {{ file?.InsuranceName }} {{ file?.Speciality }} {{ file?.MedicationName }} {{ file?.VaccinationName }} {{ file?.DocumentOldName | truncate:55 }}
              {{ file?.PastimeName }} {{ file?.EntryName }} {{ file?.Tagline}} {{ file?.LegalDocumentName }} {{ file?.Employer}} {{ file?.HobbyName }} {{ file?.FirstName}} {{ file?.AwardName}} {{ file?.AchievementName}}</div>
            <div style="padding: 0 15px;" class="table-cell" ngbTooltip="{{ file.TableName| formatTableName | replace:'Quick Posts':btnNameJorD}}">{{ file.TableName| formatTableName | replace:'Quick Posts':btnNameJorD}}</div>
            <div style="padding: 0 15px;" ngbTooltip="{{ file?.FileType || 'Form' }}" class="table-cell">{{ file?.FileType || 'Form' }}</div>
            <div style="padding: 0 15px;" ngbTooltip="{{ file.CreatedDate |date:dateformat }}" class="table-cell">{{ file.CreatedDate |date:dateformat }}</div>
            <div style="padding: 0 15px;" ngbTooltip="{{ file.Size}}" class="table-cell">{{ file.Size}}</div>
            <div class="table-cell" >
                <img height="18px" class="change-color" (click)="openEditForm(file)" width="18px" placement="left-bottom" ngbTooltip="Edit/Open" src="assets/images/icons/edit.svg">
            </div>
        </li>
    </ng-container>

    <div [hidden]="true" >
      <lightgallery *ngIf="attachmentlistimage?.length>0" #AddressGallery [settings]="settings" [onInit]="onInitGallery">
            <a *ngFor="let item of attachmentlistimage" 
            [attr.data-video]="item.type === 'Videos' ? item.data : null"
            [attr.href]="item.type === 'Images' ? item.src : null"
            [data-poster]="item.type === 'Videos' ? item.thumb :null"
             className="gallery-item"
            data-sub-html="<p>{{item.id}}</p>"
            className="gallery-item">
               <img  class="img-fluid" src={{item.src}} loading="lazy">
            </a>
      </lightgallery>
   </div>
    
       <!-- No results found -->
      <ng-template #noResultsFiles>
        <li class="auto-suggestion justify-content-between">
          <a class="d-flex align-items-center justify-content-between w-100 py-50">
            <div class="d-flex justify-content-start">
              <i data-feather="alert-circle" class="mr-25"></i>
              <span>No results found.</span>
            </div></a
          >
        </li>
      </ng-template>

      <!--/ Files -->

      <!-- Members -->
      <!-- <li class="d-flex align-items-center">
        <a href="javascript:void(0)"><h6 class="section-label mt-75 mb-0">Members</h6></a>
      </li>
      <ng-container *ngIf="(contacts | filter: searchText:'title').length; else noResultsMembers">
        <li class="auto-suggestion" *ngFor="let contact of contacts | filter: searchText:'title' | slice: 0:4">
          <a class="d-flex align-items-center justify-content-between py-50 w-100" href="javascript:void(0)"
            ><div class="d-flex align-items-center">
              <div class="avatar mr-75"><img [src]="contact.img" alt="png" height="32" /></div>
              <div class="search-data">
                <p class="search-data-title mb-0">{{ contact.title }}</p>
                <small class="text-muted">{{ contact.email }}</small>
              </div>
            </div>
            <small class="search-data-size mr-50 text-muted">{{ contact.date }}</small></a
          >
        </li>
      </ng-container> -->
      <!-- No results found -->
      <!-- <ng-template #noResultsMembers>
        <li class="auto-suggestion justify-content-between">
          <a class="d-flex align-items-center justify-content-between w-100 py-50">
            <div class="d-flex justify-content-start">
              <i data-feather="alert-circle" class="mr-25"></i>
              <span>No results found.</span>
            </div></a
          >
        </li>
      </ng-template> -->
      <!--/ Members -->
    </ul>
    <!--/ Search List -->
  </div>
</li>
