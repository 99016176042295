import { Injectable } from '@angular/core';
import { GlobalService } from 'app/Services/global.service';

@Injectable({
  providedIn: 'root'
})
export class UsernameService {

  constructor(private globalService: GlobalService) { }

  GetPublicProfileData(getPublicProfileDataInputModel) {
    const path = 'api/MyBlogs/GetPublicProfileData';
    return this.globalService.PostDataAnonymous(getPublicProfileDataInputModel, path);
  }

  GetPublicQuickPosts(GetPublicQuickPostsInputModel) {
    const path = 'api/MyBlogs/GetPublicQuickPosts';
    return this.globalService.PostDataAnonymous(GetPublicQuickPostsInputModel, path);
  }

  GetPublicAttachments(GetPublicQuickPostsInputModel) {
    const path = 'api/MyBlogs/GetPublicAttachments';
    return this.globalService.PostDataAnonymous(GetPublicQuickPostsInputModel, path);
  }

  GetPublicQuickPostLoadData(data) {
    const path = 'api/MyBlogs/GetPublicQuickPostLoadData';
    return this.globalService.PostDataAnonymous(data, path);
  }
}
