import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
import { locale as german } from '../i18n/de';
import { locale as portuguese } from '../i18n/pt';
import { locale as gujarati } from '../i18n/gj';
import { locale as hindi } from '../i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { CoreConfigService } from '@core/services/config.service';
import {Observable, Subject} from 'rxjs';
import { Constant } from 'app/Classes/constant';
import { E } from 'assets/extensions/extensions';
import { Status, Enums, MapModalType } from 'app/enums';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'app/auth/service';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { GlobalService } from 'app/Services/global.service';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import {AwardLoadDataOutputModel,TblUserAwards} from '../about-me-model.model'
import {AwardService} from './award.service'
import { TextBoxComponent } from 'app/CustomComponents/text-box/text-box.component';


@Component({
  selector: 'app-award',
  templateUrl: './award.component.html',
  styleUrls: ['./award.component.scss']
})
export class AwardComponent implements OnInit {
  @ViewChild('award') award: TextBoxComponent;
  public coreConfig: any;
  public dialogTitle: string;
  public IsForEdit: boolean = false;
  public loading = false;
  public submitted = false;
  public error = '';
  _data: number = 0;
  maxDate=new Date();
  currentAppLanguage: any;
  private _unsubscribeAll: Subject<any>;
  public frmAward: FormGroup;
  userAwards:TblUserAwards;
  awardLoadDataOutputModel:AwardLoadDataOutputModel;
  infoTooltip: string;
  dateformat: string;
  constructor(
    private dialogRef: MatDialogRef<AwardComponent>,
    private awardService : AwardService,
    private _coreTranslationService: CoreTranslationService,
    private _coreConfigService: CoreConfigService,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private loadingScreenService: LoadingScreenService,
    private globalService: GlobalService,
    private encrDecr: EncrDecrService,
    private _toastrService: CustomToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._unsubscribeAll = new Subject();
    this._data = data;
    this.dateformat=this.globalService.getDateFormat().toUpperCase()
  }
  ngAfterViewInit(): void {
    this.award.textBox.nativeElement.focus();
  }
  ngOnInit(): void {
    this.userAwards= new TblUserAwards();
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
     });
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);
    this.dialogTitle = this.currentAppLanguage.data.AWARD.DIALOG_TITLE_ADD_AWARD;
    this.infoTooltip= this.currentAppLanguage.data.AWARD.SECTION_TEXT;
    this.frmAward = this._formBuilder.group({
      AwardName: ['', Validators.required],
      Occasion:['',Validators.required],
      DateReceived: [,Validators.required],
      OccasionNotes: [,Validators.required],
    });
    if (typeof this._data !== 'undefined') {
      // console.log('this._data')
      // console.log(this._data)
      if (this._data > 0) {
        this.IsForEdit = true;
        this.dialogTitle = this.currentAppLanguage.data.AWARD.DIALOG_TITLE_EDIT_AWARD;
      }
      setTimeout(() => {
        this.loadingScreenService.startLoading();
        this.awardService.GetUserAwardLoadData(this._data)
          .subscribe(
            (data) => {
              this.loadingScreenService.stopLoading();
              //  console.log(data);
              if (data.status == Status.Success) {
                this.awardLoadDataOutputModel = <AwardLoadDataOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, data.data)));
               //  console.log(this.vaccinationLoadDataOutputModel.UserVaccination)
                if (this.awardLoadDataOutputModel.UserAward) {
                  this.userAwards = this.awardLoadDataOutputModel.UserAward;
                  this.frmAward.patchValue({
                     AwardName: this.userAwards.AwardName,
                     Occasion:this.userAwards.Occasion,
                     DateReceived: E.ConvertToDate(this.userAwards.DateReceived),
                     OccasionNotes: this.userAwards.OccasionNotes,
                  });

                }

              }
              else {
                this._toastrService.Error(E.ConvertToString(data.message), Constant.ErrorToastrTitle);
              }
            },
            (error) => {
              this._toastrService.Error(error, Constant.ErrorToastrTitle);
              this.error = error;
              this.loadingScreenService.stopLoading();
            }
          );

      }, 500);
      //}
    }
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key.toLocaleLowerCase() === "escape") {
        this.onCloseButtonClick();
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.frmAward.invalid) {
      return;
    }
    this.submitted = false;
    if (this.userAwards == null) {
      this.userAwards = new TblUserAwards();
    }
    this.userAwards.AwardName = this.frmAward.get('AwardName').value;
    this.userAwards.Occasion = this.frmAward.get('Occasion').value;
    this.userAwards.DateReceived = E.GetDateStringOnly(this.frmAward.get('DateReceived').value);
    this.userAwards.OccasionNotes = this.frmAward.get('OccasionNotes').value;
    this.userAwards.UserDetailsId = this.authenticationService.currentUserValue.UserDetailsId;

    
    if (E.ConvertToNumber(this.userAwards.UserAwardId) == 0 && this.IsForEdit == false) {
      this.userAwards.CreatedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userAwards.CreatedDate = E.GetTodayDateStringOnly();
      this.userAwards.CreatedTime = E.GetTodayTimeStringOnly();
      this.userAwards.ChangedDate = E.GetTodayDateStringOnly();
      this.userAwards.ChangedTime = E.GetTodayTimeStringOnly();
    }
    else {
      this.userAwards.ChangedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userAwards.ChangedDate = E.GetTodayDateStringOnly();
      this.userAwards.ChangedTime = E.GetTodayTimeStringOnly();
    }
    this.loading = true;
    this.loadingScreenService.startLoading();
   // console.log(this.userAwards)
    let response: Observable<any>;
    this.awardService.CreateUpdateUserAward(this.userAwards)
      .subscribe(
        (result) => {
          this.loading = false;
          this.loadingScreenService.stopLoading();
          var message = ""; var errormessage = "";
          if (result.status == Status.RecordAdded) {
            message = this.currentAppLanguage.data.AWARD.TOAST_MESSAGE_AWARD_ADDED_SUCCESSFULLY;
          }
          else if (result.status == Status.RecordUpdated) {
            message = this.currentAppLanguage.data.AWARD.TOAST_MESSAGE_AWARD_UPDATED_SUCCESSFULLY;
          }
          else {
            errormessage = E.ConvertToString(result.message);
          }
          if (message.length > 0) {
            this._toastrService.Success(message, Constant.SuccessToastrTitle);
            this.dialogRef.close();
            this.frmAward.reset();
            this.frmAward.patchValue({
              DateReceived: new Date()
            });
          }
          else {
            this._toastrService.Error(errormessage, Constant.ErrorToastrTitle);
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
          this.loading = false;
          this.loadingScreenService.stopLoading();
        }
      );

  }

  onCloseButtonClick() {
    this.dialogRef.close();
  }
  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }
  get f(){
    return this.frmAward.controls;
  }
}
