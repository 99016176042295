import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  private activityTimeout: number = 60 * 60 * 1000; // 60 minutes
  private activitySubject: Subject<void> = new Subject<void>();

  constructor() {
    this.initializeEvents();
  }

  private initializeEvents() {
    let inactivityTimer: any;

    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        this.activitySubject.next();
      }, this.activityTimeout);
    };

    const onUserActivity = () => {
      resetTimer();
    };

    // Add event listeners for user activity
    ['mousemove', 'keydown', 'scroll', 'touchstart'].forEach((event) => {
      window.addEventListener(event, onUserActivity);
    });

    // Initial timer start
    resetTimer();
  }

  get activityObservable(): Observable<void> {
    return this.activitySubject.asObservable();
  }
}
