<div class="card" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h5 cdkDragHandle class="card-header" style="justify-content: unset;">{{dialogTitle}} &nbsp;<span ngbTooltip={{infoTooltip}}>
    <span data-feather="info"></span>
  </span><button class="close-button close" type="button" ngbTooltip="{{'PROFESSIONAL.TOOLTIP_TEXT_BUTTON_CLOSE'|translate}}"
      (click)="onCloseButtonClick()" placement="right">&times;</button>
  </h5>
  <div class="card-body card-body-dialog">
    <!-- <div class="row py-3 pl-3">
      <div class="col-sm">
       <i>{{'PROFESSIONAL.SECTION_TEXT'|translate}}</i>
      </div>
    </div> -->
    <form id="frmProfessional" [formGroup]="frmProfessional" (ngSubmit)="onSubmit()">
      <!-- <form >  -->
      <div class="row scroll-area">
        <div class="col-lg-6 col-sm-12 form-group mb-0">
          <app-text-box [type]="'text'" [label]="'PROFESSIONAL.CONTROL_LABEL_SPECIALITY'| translate" [showInfoIcon]="true"
          [tooltipInfo]="'PROFESSIONAL.TOOLTIP_TEXT_PRACTITIONER_SPECIALTY'|translate" [position]="'bottom'"
            [showRequiredSign]="true" #speciality [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate"
            aria-describedby="Speciality" [maxlength]="50" for="Speciality" formControlName="Speciality"
            [classes]="{ 'is-invalid': submitted && f.Speciality.errors }">
          </app-text-box>
          <span *ngIf="submitted && f.Speciality.errors">
            <small *ngIf="f.Speciality.errors.required"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_REQUIRED_SPECIALITY'|translate}}</small>
            <small *ngIf="f.Speciality.errors.maxlength"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
          </span>
        </div>
        <div class="col-lg-6 col-sm-12 form-group mb-0">
          <app-text-box [type]="'text'" [label]="'PROFESSIONAL.CONTROL_LABEL_PROFESSIONAL_NAME'| translate"
            [showRequiredSign]="true" [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" [showInfoIcon]="true"
            [tooltipInfo]="'PROFESSIONAL.TOOLTIP_TEXT_PRACTITIONER_SPECIALTY'|translate" [position]="'right'"
            aria-describedby="ProfessionalName" [maxlength]="150" for="ProfessionalName"
            formControlName="ProfessionalName" [classes]="{ 'is-invalid': submitted && f.ProfessionalName.errors }">
          </app-text-box>
          <span *ngIf="submitted && f.ProfessionalName.errors">
            <small *ngIf="f.ProfessionalName.errors.required"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_REQUIRED_PROFESSIONALNAME'|translate}}</small>
            <small *ngIf="f.ProfessionalName.errors.maxlength"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_MAX_LENGTH_150'|translate}}</small>
          </span>
        </div>
        <div class="col-lg-4 col-sm-12 form-group mb-0">
          <app-text-box [type]="'text'" [label]="'PROFESSIONAL.CONTROL_LABEL_ADDRESS1'| translate"
             [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" [showInfoIcon]="true"
             [tooltipInfo]="'PROFESSIONAL.TOOLTIP_TEXT_STREET_NAME'|translate" [position]="'right'"
            aria-describedby="Address1" [maxlength]="50" for="Address1" formControlName="Address1"
            [classes]="{ 'is-invalid': submitted && f.Address1.errors }">
          </app-text-box>
          <span *ngIf="submitted && f.Address1.errors">
            <small *ngIf="f.Address1.errors.required"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_REQUIRED_ADDRESS'|translate}}</small>
            <small *ngIf="f.Address1.errors.maxlength"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
          </span>
        </div>
        <div class="col-lg-4 col-sm-12 form-group mb-0">
          <app-text-box [type]="'text'" [label]="'PROFESSIONAL.CONTROL_LABEL_ADDRESS2'| translate"
            [classes]="{ 'is-invalid': submitted && f.Address2.errors }" [showInfoIcon]="true"
            [tooltipInfo]="'PROFESSIONAL.TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_1'|translate" [position]="'bottom'"
            [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="Address2" [maxlength]="50"
            for="Address2" formControlName="Address2">
          </app-text-box>
          <span *ngIf="submitted && f.Address2.errors">
            <small *ngIf="f.Address2.errors.maxlength"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
          </span>
        </div>
        <div class="col-lg-4 col-sm-12 form-group mb-0">
          <app-text-box [type]="'text'" [label]="'PROFESSIONAL.CONTROL_LABEL_ADDRESS3'| translate"
            [classes]="{ 'is-invalid': submitted && f.Address3.errors }" [showInfoIcon]="true"
            [tooltipInfo]="'PROFESSIONAL.TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_2'|translate" [position]="'bottom'"
            [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="Address3" [maxlength]="50"
            for="Address3" formControlName="Address3">
          </app-text-box>
          <span *ngIf="submitted && f.Address3.errors">
            <small *ngIf="f.Address3.errors.maxlength"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
          </span>
        </div>
        <div class="col-lg-4 col-sm-12 form-group mb-0">
          <app-text-box [type]="'text'" [classes]="{ 'is-invalid': submitted && f.AddressTown.errors }"
            [label]="'PROFESSIONAL.CONTROL_LABEL_ADDRESS_TOWN'| translate"  [showInfoIcon]="true"
            [tooltipInfo]="'PROFESSIONAL.TOOLTIP_TEXT_TOWN_VILLAGE_CITY'|translate" [position]="'bottom'"
            [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="" [maxlength]="50"
            for="AddressTown" formControlName="AddressTown">
          </app-text-box>
          <span *ngIf="submitted && f.AddressTown.errors">
            <small *ngIf="f.AddressTown.errors.required"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_REQUIRED_ADDRESSTOWN'|translate}}</small>
            <small *ngIf="f.AddressTown.errors.maxlength"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
          </span>
        </div>
        <!-- <div class="row padding-left"> -->
        <div class="col-lg-4 col-sm-7 form-group mb-0">
          <app-text-box [type]="'text'" [label]="'PROFESSIONAL.CONTROL_LABEL_ADDRESS_POSTALCODE'| translate"
             [maxlength]="50" [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" [showInfoIcon]="true"
             [tooltipInfo]="'PROFESSIONAL.TOOLTIP_TEXT_POSTAL_ZIPCODE'|translate" [position]="'bottom'"
            aria-describedby="" [classes]="{ 'is-invalid': submitted && f.AddressPostCode.errors }"
            for="AddressPostCode" formControlName="AddressPostCode">
          </app-text-box>
          <span *ngIf="submitted && f.AddressPostCode.errors">
            <small *ngIf="f.AddressPostCode.errors.required"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_REQUIRED_ADDRESSPOSTCODE'|translate}}</small>
            <small *ngIf="f.AddressPostCode.errors.maxlength"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
          </span>
        </div>
        <div class="col-sm py-4" style="top:9px;">
          <button type="button" class="btn btn-primary" style="width:100%" [disabled]="loading" placement="top"
            (click)="onSearchOnMapClick()"
            ngbTooltip="{{'PROFESSIONAL.BUTTON_TEXT_SEARCH_ON_MAP' | translate}}"><span></span><i data-feather="search"
              class="mr-0 mr-sm-1"></i>
            {{'PROFESSIONAL.BUTTON_TEXT_SEARCH_ON_MAP'|translate}}</button>
        </div>
       
        <div class="col-lg-4 col-sm-12 pb-2">
          <label class="form-label" for="StateId">{{'PROFESSIONAL.CONTROL_LABEL_ADDRESS_STATE' |translate}}
            <span ngbTooltip="{{'PROFESSIONAL.TOOLTIP_TEXT_STATE_PROVINCE'|translate}}" placement="right">
              <span data-feather="help-circle"
                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
          </label>
          <ng-select [items]="healthProviderLoadDataOutputModel?.StateList" bindLabel="StateName" selectOnTab="true"
            bindValue="StateId" id="StateId" name="StateId"
            placeholder="{{'PROFESSIONAL.PLACEHOLDER_SELECT_STATE'|translate}}" formControlName="StateId"
            [class]="{ 'is-invalid': submitted && f.StateId.errors }"
            (ngModelChange)="onStateChange($event)"></ng-select>
          <span *ngIf="submitted && f.StateId.errors">
            <small *ngIf="f.StateId.errors.required"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_REQUIRED_ADDRESS_STATE'|translate}}</small>
          </span>
        </div>
        <div class="col-lg-4 col-sm-12 pb-2">
          <label class="form-label" for="CountryId">{{'PROFESSIONAL.CONTROL_LABEL_COUNTRY' |translate}}
            <span ngbTooltip="{{'PROFESSIONAL.TOOLTIP_TEXT_COUNTRY'|translate}}" placement="right">
              <span data-feather="help-circle"
                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
          </label>
          <ng-select [items]="healthProviderLoadDataOutputModel?.CountryList" bindLabel="CountryName" selectOnTab="true"
            bindValue="CountryId" id="CountryId" name="CountryId"
            placeholder="{{'PROFESSIONAL.PLACEHOLDER_SELECT_COUNTRY'|translate}}" formControlName="CountryId"
            [class]="{ 'is-invalid': submitted && f.CountryId.errors }"
            ></ng-select>
          <span *ngIf="submitted && f.CountryId.errors">
            <small *ngIf="f.CountryId.errors.required"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_REQUIRED_COUNTRY'|translate}}</small>
          </span>
        </div>
        <div class="col-lg-4 col-sm-12 pb-2">
          <label class="form-label" for="RegionId">{{'PROFESSIONAL.CONTROL_LABEL_REGION' | translate}}
            <span ngbTooltip="{{'PROFESSIONAL.TOOLTIP_TEXT_REGION_CONTINENT'|translate}}" placement="right">
              <span data-feather="help-circle"
                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
          </label>
          <ng-select [items]="healthProviderLoadDataOutputModel?.RegionList" bindLabel="RegionName" selectOnTab="true"
            bindValue="RegionId" id="RegionId" name="RegionId"
            placeholder="{{'PROFESSIONAL.PLACEHOLDER_SELECT_REGION'|translate}}" formControlName="RegionId"
            [class]="{ 'is-invalid': submitted && f.RegionId.errors }"
            ></ng-select>
          <span *ngIf="submitted && f.RegionId.errors">
            <small *ngIf="f.RegionId.errors.required"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_REQUIRED_REGION'|translate}}</small>
          </span>
        </div>


        <div class="col-lg-4 col-sm-12 pb-2">
          <label class="form-label" for="EmailAddress">{{'PROFESSIONAL.CONTROL_LABEL_EMAIL_ADDRESS'| translate}}
            <span ngbTooltip="{{'PROFESSIONAL.TOOLTIP_TEXT_PRACTITIONER_EMAIL'|translate}}" placement="right">
              <span data-feather="help-circle"
                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
             </label>
          <div class="input-group">
            <input ngModel [email]="true" type="text"
              class="{ 'is-invalid': submitted && f.EmailAddress.errors } form-control" placeholder="Type here..."
              aria-describedby="button-addon1" (focusout)="OnFocusOutEmailAddress()" (ngModelChange)="updateCharacterCount($event)"
              [maxlength]="320" formControlName="EmailAddress" ngModel [email]="true" />
            <div class="input-group-append" id="button-addon1">
              <button class="btn btn-outline-primary" [disabled]="!IsForEdit" type="button" (click)="sendEmail()"
                rippleEffect><i data-feather="mail"></i></button>
            </div>
          </div>
          <p  class="text-right mb-0">{{'GRIDVIEW.CHARACTER_COUNT'|translate}}: {{ characterCount }} / {{ maxlength }}</p>
          <span *ngIf="submitted && f.EmailAddress.errors">
            <small *ngIf="f.EmailAddress.errors.required"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_REQUIRED_EMAILADDRESS'|translate}}</small>
            <small *ngIf="f.EmailAddress.errors.maxlength"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_MAX_LENGTH_320'|translate}}</small>
            <small *ngIf="f.EmailAddress.errors.email|| f.EmailAddress.errors.pattern"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_INVALID_EMAIL'|translate}}</small>
          </span>
        </div>

        <!--         
        <div class="col-sm-12">
          <app-text-box [type]="'text'" 
          [label]="'PROFESSIONAL.CONTROL_LABEL_EMAIL_ADDRESS'| translate" [showRequiredSign]="true"  ngModel [email]="true"
          [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="" [maxlength]="320" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,4}$"
          for="EmailAddress" formControlName="EmailAddress" [classes]="{ 'is-invalid': submitted && f.EmailAddress.errors }">
        </app-text-box>
        <span *ngIf="submitted && f.EmailAddress.errors">
          <small *ngIf="f.EmailAddress.errors.required"
            class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_REQUIRED_EMAILADDRESS'|translate}}</small>
          <small *ngIf="f.EmailAddress.errors.maxlength"
            class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_MAX_LENGTH_320'|translate}}</small>
            <small *ngIf="f.EmailAddress.errors.email|| f.EmailAddress.errors.pattern"
            class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_INVALID_EMAIL'|translate}}</small>
        </span>
        </div> -->


        <div class="col-lg-4 col-sm-12">
          <label class="form-label d-block" for="ContactNumber1">{{'PROFESSIONAL.CONTROL_LABEL_CONTACT1'| translate}}
            <span ngbTooltip="{{'PROFESSIONAL.TOOLTIP_TEXT_PRACTITIONER_MOBILE'|translate}}" placement="right">
              <span data-feather="help-circle"
                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
          </label>
          <ngx-intl-tel-input [separateDialCode]="true" [searchCountryFlag]="true" name="ContactNumber1"
            [maxlength]="12" [phoneValidation]="true" formControlName="ContactNumber1"
            [selectedCountryISO]="SelectedCountryISO1" [class]="{ 'is-invalid': submitted && f.ContactNumber1.errors }"
            id="ContactNumber1" [customPlaceholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate">
          </ngx-intl-tel-input>
          <span *ngIf="submitted && f.ContactNumber1.errors">
            <small *ngIf="f.ContactNumber1.errors.required"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_REQUIRED_MOBILE_NUMBER'
              | translate}}</small>
            <small *ngIf="f.ContactNumber1.errors.maxlength"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_MAX_LENGTH_12'
              | translate}}</small>
            <small *ngIf="f.ContactNumber1.errors && !f.ContactNumber1.errors.required"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_INVALID_DATA'
              | translate}}</small>
          </span>
        </div>
        <div class="col-lg-4 col-sm-12 py-2">
          <label class="form-label d-block" for="ContactNumber2">{{'PROFESSIONAL.CONTROL_LABEL_CONTACT2'| translate}}
            <span ngbTooltip="{{'PROFESSIONAL.TOOLTIP_TEXT_PRACTITIONER_MOBILE'|translate}}" placement="right">
              <span data-feather="help-circle"
                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
          </label>
          <ngx-intl-tel-input [separateDialCode]="true" [searchCountryFlag]="true" name="ContactNumber2"
            [class]="{ 'is-invalid': submitted && f.ContactNumber2.errors }" [phoneValidation]="true"
            formControlName="ContactNumber2" [selectedCountryISO]="SelectedCountryISO2" id="ContactNumber2"
            [customPlaceholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate">
          </ngx-intl-tel-input>
          <span *ngIf="submitted && f.ContactNumber2.errors">
            <small *ngIf="f.ContactNumber2.errors" class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_INVALID_DATA'|
              translate}}</small>
            <small *ngIf="f.ContactNumber2.errors.maxlength"
              class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_MAX_LENGTH_12'
              | translate}}</small>
          </span>
        </div>

        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label" for="LastExamDate">{{'PROFESSIONAL.CONTROL_LABEL_LAST_EXAM_DATE' |translate}}
              <span ngbTooltip="{{'PROFESSIONAL.TOOLTIP_TEXT_LAST_APPOINTMENT_DATE'|translate}}" placement="right">
                <span data-feather="help-circle"
                  class="info-icon align-middle mr-sm-25 mr-0"></span></span>
              </label>
            <div class="input-group border-color">
              <input id="LastExamDate" name="LastExamDate" [placeholder]="dateformat"
                [bsConfig]="{ showWeeks: false,containerClass:'theme-blue',dateInputFormat:dateformat}"
                [maxDate]="maxFromDate" [class]="{ 'is-invalid': submitted && f.LastExamDate.errors }" required
                bsDatepicker #dpLast="bsDatepicker" formControlName="LastExamDate" class="form-control">
              <div class="input-group-append">
                <button class="btn btn-outline-primary feather icon-calendar" (click)="dpLast.toggle()" type="button"
                  rippleEffect placement="top"
                  ngbTooltip="{{'PROFESSIONAL.TOOLTIP_TOGGLE_CALENDAR' | translate}}"></button>
              </div>
            </div>
            <span *ngIf="submitted && f.LastExamDate.errors">
              <small *ngIf="f.LastExamDate.errors"
                class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_LAST_EXAM_DATE_REQUIRED'| translate}}</small>
            </span>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label" for="NextExamDate">{{'PROFESSIONAL.CONTROL_LABEL_NEXT_EXAM_DATE' |translate}}
              <span ngbTooltip="{{'PROFESSIONAL.TOOLTIP_TEXT_NEXT_APPOINTMENT_DATE'|translate}}" placement="right">
                <span data-feather="help-circle"
                  class="info-icon align-middle mr-sm-25 mr-0"></span></span>
              </label>
            <div class="input-group">
              <input id="NextExamDate" name="NextExamDate"
                [class]="{ 'is-invalid': submitted && f.NextExamDate.errors }" [placeholder]="dateformat"
                [bsConfig]="{ showWeeks: false,containerClass:'theme-blue',dateInputFormat:dateformat}"
                (ngModelChange)="OnNextExamDateChange($event)" required bsDatepicker #dpNext="bsDatepicker"
                formControlName="NextExamDate" class="form-control">
              <div class="input-group-append">
                <button class="btn btn-outline-primary feather icon-calendar" (click)="dpNext.toggle()" type="button"
                  rippleEffect placement="top"
                  ngbTooltip="{{'PROFESSIONAL.TOOLTIP_TOGGLE_CALENDAR' | translate}}"></button>
              </div>
            </div>
            <span *ngIf="submitted && f.NextExamDate.errors">
              <small *ngIf="f.NextExamDate.errors"
                class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_NEXT_EXAM_DATE_REQUIRED'| translate}}</small>
            </span>
          </div>
        </div>

        <div class="col-sm-12 pt-1">
          <label class="form-label" for="Notes">{{'PROFESSIONAL.CONTROL_LABEL_NOTES' | translate}}</label>
          <fieldset>
            <textarea class="col-sm-12 form-control" formControlName="Notes" rows="4"
              placeholder="{{'COMMON.PLACEHOLDER_TYPE_HERE'|translate}}"></textarea>
          </fieldset>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="card-footer">
  <div class="align">
    <button type="submit" *ngIf="!IsForEdit" class="btn btn-primary" placement="top"
      ngbTooltip="{{'PROFESSIONAL.BUTTON_TEXT_ADD_A_PROFESSIONAL'|translate}}"
      (click)="onSubmit()">{{'PROFESSIONAL.BUTTON_TEXT_ADD_A_PROFESSIONAL'|translate}}</button>

    <button type="submit" *ngIf="IsForEdit" class="btn btn-primary" [disabled]="loading" placement="top"
      ngbTooltip="{{'COMMON.BUTTON_TEXT_SAVE_CHANGES' | translate}}" (click)="onSubmit()"><span *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"></span>{{'COMMON.BUTTON_TEXT_SAVE_CHANGES'|translate}}</button>

    <button type="button" class="btn btn-outline-secondary ml-2" placement="top" (click)="onCloseButtonClick()"
      ngbTooltip="{{'PROFESSIONAL.BUTTON_TEXT_CANCEL'|translate}}">{{'PROFESSIONAL.BUTTON_TEXT_CANCEL'|translate}}</button>
  </div>
</div>