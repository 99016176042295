<!-- <div class="content-wrapper container-xxl p-0"> -->
<div class="content-body">
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- calendar main sidebar -->
      <core-sidebar class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        name="calendar-main-sidebar" overlayClass="body-content-overlay" collapsibleSidebar="bs-gt-lg">
        <app-calendar-main-sidebar></app-calendar-main-sidebar>
      </core-sidebar>
      <!--/ calendar main sidebar -->

      <!-- calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar #calendareventplanner id="calendareventandplanner"
              [options]="calendarOptions"></full-calendar>

            <!-- Hidden Datepicker Triggered by Click -->
            <input type="text" #datepicker="bsDatepicker" bsDatepicker [bsValue]="initialDate" 
            (bsValueChange)="onDateChange($event)" [bsConfig]="{ selectWeek:dateMode,containerClass:'theme-blue',minMode:dateMode,todayButton: true}"
            style="display: none"
            />

            <!-- Add this script directly under the title element in the calendar component -->
            <!-- <script>
              document.addEventListener('DOMContentLoaded', function () {
                const titleElement = document.querySelector('.fc-toolbar-title');
            
                if (titleElement) {
                  titleElement.style.cursor = 'pointer'; // Set cursor style
                  titleElement.setAttribute('contenteditable', 'false'); // Prevent blinking text cursor
            
                  // Listen for click events to open the datepicker
                  titleElement.addEventListener('click', function () {
                    const datePickerInput = document.querySelector('#datepicker');
            
                    if (datePickerInput) {
                      datePickerInput.click(); // Trigger the datepicker click event
                    }
                  });
                }
              });
            </script> -->
            
          </div>
        </div>
      </div>
      <!-- calendar -->
    </div>
  </div>

  <!-- calendar event sidebar -->
  <core-sidebar class="modal modal-slide-in fade event-sidebar" name="calendar-event-sidebar"
    overlayClass="modal-backdrop" hideOnEsc="true">
    <!-- <app-calendar-event-sidebar></app-calendar-event-sidebar> -->
  </core-sidebar>
  <!--/ calendar event sidebar -->
</div>
<!-- </div> -->