import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
import { locale as german } from '../i18n/de';
import { locale as portuguese } from '../i18n/pt';
import { locale as gujarati } from '../i18n/gj';
import { locale as hindi } from '../i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { TextBoxComponent } from 'app/CustomComponents/text-box/text-box.component';
import { CoreConfigService } from '@core/services/config.service';
import { E } from 'assets/extensions/extensions';
import { AuthenticationService } from 'app/auth/service';
import { Status, Enums, MapModalType } from 'app/enums';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { GlobalService } from 'app/Services/global.service';
import { Constant } from 'app/Classes/constant';
import { takeUntil } from 'rxjs/operators';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import {TblUserFriends,FriendLoadDataOutputModel} from '../about-me-model.model'
import {FriendsService} from './friends.service'
import { Clipboard } from '@angular/cdk/clipboard';
import { CountryISO } from 'ngx-intl-tel-input';
import { MapAddressComponent } from 'app/address/map-address/map-address.component';
import { MapAddressDataModel } from 'app/address/map-address/map-address-data-model.model';
import { NoosomPlans, NoosomCategoryID } from 'app/enums';
@Component({
  selector: 'app-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.scss']
})
export class FriendsComponent implements OnInit {
  @ViewChild('FirstName') FirstName: TextBoxComponent;
  public coreConfig: any;
  public dialogTitle: string;
  public IsForEdit: boolean = false;
  currentAppLanguage: any;
  private _unsubscribeAll: Subject<any>;
  public loading = false;
  public submitted = false;
  maxDate=new Date();
  _data: number = 0;
  public error = '';
  public frmFriend: FormGroup;
  SelectedCountryISO1: CountryISO;
  SelectedCountryISO2: CountryISO;
  public PlaceholderState: any;
  public PlaceholderCountry: any;
  public PlaceholderRegion: any;
  userFriend:TblUserFriends;
  friendLoadDataOutputModel:FriendLoadDataOutputModel;
  public maxlength = 320;
  public maxlength2 = 100;
  public characterCount = 0;
  public characterCount2 = 0;
  private subDownloadAddressAtt$?: Subscription;
  private subDeleteAddressAtt$?: Subscription;
  private subStateList$?: Subscription;
  private subCountryList$?: Subscription;
  infoTooltip: string;
  dateformat: string;
  connectionStatus: string='';
  templcstg = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, localStorage.getItem('lcstrg'))) 
  showIcon:boolean=false;
  constructor(
    private dialogRef: MatDialogRef<FriendsComponent>,
    private _coreTranslationService: CoreTranslationService,
    private _coreConfigService: CoreConfigService,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private clipboard:Clipboard,
    private authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private loadingScreenService: LoadingScreenService,
    private globalService: GlobalService,
    private encrDecr: EncrDecrService,
    private _toastrService: CustomToastrService,
    public friendService:FriendsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._unsubscribeAll = new Subject();
    this._data = data;
    this.dateformat=this.globalService.getDateFormat().toUpperCase()
  }
  ngAfterViewInit(): void {
    this.FirstName.textBox.nativeElement.focus();
  }
  ngOnInit(): void {
    this.userFriend= new TblUserFriends();
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
    this.coreConfig = config;
    });
    if (this.templcstg) {
      var plan = this.templcstg?.NoosomPlan;
      var userCategory = this.templcstg?.userCategory
    }
    if((plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test)){
      this.showIcon=true;
    }else{
      this.showIcon=true;
    }
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);
    this.dialogTitle = this.currentAppLanguage.data.MY_FAMILIES.DIALOG_TITLE_ADD_A_FRIEND;
    this.infoTooltip= this.currentAppLanguage.data.MY_FAMILIES.SECTION_TEXT_FRIEND;
    this.frmFriend = this._formBuilder.group({
      FirstName: ['', Validators.required],
      MiddleName:[''],
      LastName:['',Validators.required],
      EmailAddress:[''],
      DateofBirth: [],
      PersonalWebUrl:[''],
      NoosomWebUrl:[''],
      ContactNumber1: [],
      ContactNumber2: [],
      ContactNumber1CountryCode: [],
      ContactNumber2CountryCode: [],
      Address1: [''],
      Address2: [''],
      Address3: [''],
      AddressTown: [''],
      StateId: [],
      AddressPostCode: [''],
      CountryId: [],
      RegionId: [],
    });
    if (typeof this._data !== 'undefined') {
      // console.log('this._data')
      // console.log(this._data)
      if (this._data > 0) {
        this.IsForEdit = true;
        this.dialogTitle = this.currentAppLanguage.data.MY_FAMILIES.DIALOG_TITLE_EDIT_A_FRIEND;
      }
      else {
        //Add Mode
        if (this.authenticationService.currentUserValue.CountryShortCode) {
          this.SelectedCountryISO1 = this.globalService.GetCountryISOObject(this.authenticationService.currentUserValue.CountryShortCode);
          this.SelectedCountryISO2 = this.globalService.GetCountryISOObject(this.authenticationService.currentUserValue.CountryShortCode);
        }
      }
      setTimeout(() => {
        this.loadingScreenService.startLoading();
        // console.log(this.achievementsLoadDataInputModel);
        let response: Observable<any>;
        this.friendService.GetUserFriendLoadData(this._data)
          .subscribe(
            (data) => {
              this.loadingScreenService.stopLoading();
              if (data.status == Status.Success) {
                this.friendLoadDataOutputModel = <FriendLoadDataOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, data.data)));
              // console.log(this.friendLoadDataOutputModel)
                if (this.friendLoadDataOutputModel.UserFriend) {
                  this.userFriend = this.friendLoadDataOutputModel.UserFriend;
                //  console.log(this.userFriend)
                  this.frmFriend.patchValue({
                    FirstName:this.userFriend.FirstName,
                    MiddleName:this.userFriend.MiddleName,
                    LastName:this.userFriend.LastName,
                    EmailAddress:this.userFriend.EmailAddress,
                    DateofBirth: E.ConvertToDate(this.userFriend.DateofBirth),
                    PersonalWebUrl:this.userFriend.PersonalWebUrl,
                    NoosomWebUrl:this.userFriend.NoosomWebUrl,
                    Address1:this.userFriend.Address1,
                    Address2:this.userFriend.Address2,
                    Address3:this.userFriend.Address3,
                    AddressTown:this.userFriend.AddressTown,
                    AddressPostCode:this.userFriend.AddressPostCode,
                    RegionId:this.userFriend.RegionId,
                    CountryId:this.userFriend.CountryId,
                    StateId:this.userFriend.StateId,
                    ContactNumber1: this.userFriend.ContactNumber1,
                    ContactNumber2: this.userFriend.ContactNumber2,
                  });
                  // if (this.userFriend.CountryId) {
                  //   this.frmFriend.get('CountryId').enable();
                  // }
                  if (this.userFriend.StateId) {
                    this.frmFriend.get('StateId').enable();
                  }
                  if (this.userFriend.ContactNumber1CountryCode)
                    this.SelectedCountryISO1 = this.globalService.GetCountryISOObject(this.userFriend.ContactNumber1CountryCode);
                    else
                    this.SelectedCountryISO1 = this.globalService.GetCountryISOObject(this.authenticationService.currentUserValue.CountryShortCode);
                  if (this.userFriend.ContactNumber2CountryCode)
                    this.SelectedCountryISO2 = this.globalService.GetCountryISOObject(this.userFriend.ContactNumber2CountryCode);
                    else
                    this.SelectedCountryISO2 = this.globalService.GetCountryISOObject(this.authenticationService.currentUserValue.CountryShortCode);

                    if(this.userFriend.ConnectionRequestStatusId){
                      this.connectionStatus=this.CustomButtonRenderer(this.userFriend.ConnectionRequestStatusId)
                    }
                }

              }
              else {
                this._toastrService.Error(E.ConvertToString(data.message), Constant.ErrorToastrTitle);
              }
            },
            (error) => {
              this.error = error;
              this.loadingScreenService.stopLoading();
            }
          );

      }, 500);
     // }
    }

    this.PlaceholderRegion = this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_REGION;
    this.PlaceholderCountry = this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_COUNTRY;
    this.PlaceholderState = this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_STATE;
    this.frmFriend.get('CountryId').disable();
    this.frmFriend.get('RegionId').disable();

    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key.toLocaleLowerCase() === "escape") {
        this.onCloseButtonClick();
      }
    });
  }

  CustomButtonRenderer(params) {
    let value = params ? params : '';
    let btnType = '';
    switch (value) {
        case 3:
            btnType = 'btn-success';
            break;
        case 2:
            btnType = 'btn-warning';
            break;
        case 4:
            btnType = 'btn-danger';
            break;
        case 1:
            btnType = 'btn-grey';
            break;
    }
    return btnType;
}

  onSubmit() {
    this.submitted = true;
    // if (this.frmFriend.invalid || this.frmFriend.get('Address1').value) {
    //   if ([this.frmFriend.get('AddressTown').value, this.frmFriend.get('AddressPostCode').value, this.frmFriend.get('RegionId').value, this.frmFriend.get('CountryId').value, this.frmFriend.get('StateId').value].some(field => field === null || field === '')) {
    //     return;
    //   }
    //   if (typeof this.userFriend.Latitude === 'undefined' || typeof this.userFriend.Longitude === 'undefined' || this.userFriend.Latitude == null || this.userFriend.Longitude == null) {
    //     this._toastrService.Warning(this.currentAppLanguage.data.MY_JOBS.WARNING_MESSAGE_SELECT_LOCATION, Constant.WarningToastrTitle);
    //     this.onSearchOnMapClick();
    //     return;
    //   }
    this.frmFriend.controls['DateofBirth'].setErrors(null);
    
      if(this.frmFriend.invalid)
      {return;}
    // }
    this.submitted = false;
    if (this.userFriend == null) {
      this.userFriend = new TblUserFriends();
    }
    this.userFriend.FirstName = this.frmFriend.get('FirstName').value;
    this.userFriend.MiddleName = this.frmFriend.get('MiddleName').value;
    this.userFriend.LastName = this.frmFriend.get('LastName').value;
    this.userFriend.EmailAddress = this.frmFriend.get('EmailAddress').value;
    this.userFriend.DateofBirth = E.GetDateStringOnly(this.frmFriend.get('DateofBirth').value);
    this.userFriend.PersonalWebUrl = this.frmFriend.get('PersonalWebUrl').value;
    this.userFriend.NoosomWebUrl = this.frmFriend.get('NoosomWebUrl').value;
    this.userFriend.Address1 = this.frmFriend.get('Address1').value;
    this.userFriend.Address2 = this.frmFriend.get('Address2').value;
    this.userFriend.Address3 = this.frmFriend.get('Address3').value;
    this.userFriend.AddressTown = this.frmFriend.get('AddressTown').value;
    if(this.frmFriend.get('ContactNumber1').value){
    this.userFriend.ContactNumber1 = this.frmFriend.get('ContactNumber1').value.number.replace(/\s/g, "");
    this.userFriend.ContactNumber1CountryCode = this.frmFriend.get('ContactNumber1').value.countryCode.toLocaleLowerCase();}
    this.userFriend.AddressPostCode = this.frmFriend.get('AddressPostCode').value;
    this.userFriend.RegionId = this.frmFriend.get('RegionId').value;
    this.userFriend.CountryId = this.frmFriend.get('CountryId').value;
    this.userFriend.StateId = this.frmFriend.get('StateId').value;
    if (this.frmFriend.get('ContactNumber2').value) {
      this.userFriend.ContactNumber2 = this.frmFriend.get('ContactNumber2').value.number.replace(/\s/g, "");
      this.userFriend.ContactNumber2CountryCode = this.frmFriend.get('ContactNumber2').value.countryCode.toLocaleLowerCase();
    }
    else { this.userFriend.ContactNumber2 = this.frmFriend.get('ContactNumber2').value; }
    this.userFriend.UserDetailsId= this.authenticationService.currentUserValue.UserDetailsId;

    if (E.ConvertToNumber(this.userFriend.UserFriendId) == 0 && this.IsForEdit == false) {
      this.userFriend.CreatedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userFriend.CreatedDate = E.GetTodayDateStringOnly();
      this.userFriend.CreatedTime = E.GetTodayTimeStringOnly();
      this.userFriend.ChangedDate = E.GetTodayDateStringOnly();
      this.userFriend.ChangedTime = E.GetTodayTimeStringOnly();
    }
    else {
      this.userFriend.ChangedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userFriend.ChangedDate = E.GetTodayDateStringOnly();
      this.userFriend.ChangedTime = E.GetTodayTimeStringOnly();
    }
    this.loading = true;
    this.loadingScreenService.startLoading();
   // console.log(this.userFriend)
    let response: Observable<any>;
    this.friendService.CreateUpdateUserFriend(this.userFriend)
      .subscribe(
        (result) => {
          this.loading = false;
          this.loadingScreenService.stopLoading();
          var message = ""; var errormessage = "";
          // console.log(result)
          if (result.status == Status.RecordAdded) {
            message = this.currentAppLanguage.data.MY_FAMILIES.TOAST_MESSAGE_FRIEND_ADDED_SUCCESSFULLY;
          }
          else if (result.status == Status.RecordUpdated) {
            message = this.currentAppLanguage.data.MY_FAMILIES.TOAST_MESSAGE_FRIEND_UPDATED_SUCCESSFULLY;
          }
          else {
            errormessage = E.ConvertToString(result.message);
          }
          if (message.length > 0) {
            this._toastrService.Success(message, Constant.SuccessToastrTitle);
            this.dialogRef.close(true);
            this.frmFriend.reset();
            this.frmFriend.patchValue({
            DateOfBirth: new Date()
            });
          }
          else {
            this._toastrService.Error(errormessage, Constant.ErrorToastrTitle);
          }
        },
        (error) => {
          this.error = error;
          this.loading = false;
          this.loadingScreenService.stopLoading();
        }
      );

  }

  updateCharacterCount(value: string) {
    // console.log(this.maxlength)
     this.characterCount = value ? value.length : 0;
   }
     
   updateCharacterCount2(value: string) {
    // console.log(this.maxlength)
     this.characterCount2 = value ? value.length : 0;
   }



  copyText(textToCopy: string) {
    if(textToCopy!=''){
    this.clipboard.copy(textToCopy);
    this._toastrService.Success('Copied.', Constant.SuccessToastrTitle);
  }
  }

  onStateChange(event) {
    if (event != null) {
      this.PlaceholderCountry = "";
      const path = 'api/State/GetCountryRegionId';
      this.subStateList$ = this.globalService.PostDataAnonymous(event, path).subscribe(
        (data) => {
          var result = JSON.parse(data._body);
          if (result.status == Status.Success) {
            var countryRegion = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
            this.frmFriend.get('CountryId').setValue(countryRegion?.CountryId);
            this.frmFriend.get('RegionId').setValue(countryRegion?.RegionId);
          }
        },
        (error) => {
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
        }
      );
    }
    else {
      this.PlaceholderCountry = this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_STATE;
      this.frmFriend.get('RegionId').setValue(null);
      this.PlaceholderRegion = this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_REGION;
      this.frmFriend.get('RegionId').disable();
      this.frmFriend.get('CountryId').setValue(null);
      this.PlaceholderCountry = this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_COUNTRY;
      this.frmFriend.get('CountryId').disable();
    }
  }
  onSearchOnMapClick() {
    if (E.ConvertToString(this.frmFriend.get('Address1').value) != '' && E.ConvertToString(this.frmFriend.get('AddressTown').value) != ''
      && E.ConvertToString(this.frmFriend.get('AddressPostCode').value) != '') {
      this.openDialogMapAddress();
    }
    else {
      this._toastrService.Error(E.ConvertToString( this.warningMessage()+' is required'), Constant.ErrorToastrTitle);
    }
  }
  warningMessage(){
    let emptyFields= '';
    if ((this.frmFriend.get('Address1').value == '') || (this.frmFriend.get('Address1').value == null))
      emptyFields += " " +"Street Name,";
    if ((this.frmFriend.get('AddressTown').value == ('')) || (this.frmFriend.get('AddressTown').value == (null)))
      emptyFields += " " +"Town/City,";
    if ((this.frmFriend.get('AddressPostCode').value == '') || (this.frmFriend.get('AddressPostCode').value ==null))
      emptyFields += " " +"Post/Zip Code";

    return emptyFields;
  }
  GetMapSearchString(): string {
    let result = '';
    if (this.frmFriend.get('Address1').value)
      result += (this.frmFriend.get('Address1').value + ', ');
    if (this.frmFriend.get('AddressTown').value)
      result += (this.frmFriend.get('AddressTown').value + ', ');
    if (this.frmFriend.get('AddressPostCode').value)
      result += (this.frmFriend.get('AddressPostCode').value);
    return result;
  }
  openDialogMapAddress() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "70%"
    dialogConfig.height = "55%";
    dialogConfig.data = new MapAddressDataModel(MapModalType.SearchString, this.GetMapSearchString());
    const dialogRef: MatDialogRef<MapAddressComponent> = this.dialog.open(MapAddressComponent, dialogConfig);
    dialogRef.componentInstance.IsDraggable = true;
    dialogRef.componentInstance.IsSaveButtonVisible = true;
    dialogRef.componentInstance.IsSearchOnMap = true;
    dialogRef.afterClosed().subscribe(
      result => {
        // console.log('Map closed');
        // console.log(result);
        if (result) {
          if (E.ConvertToString(result.event) == 'SearchOnMap' && result.data) {
            //console.log('this.userFriend');
            // console.log(this.userFriend.Latitude);
            this.userFriend.Latitude = result.data.lat;
            this.userFriend.Longitude = result.data.long;
          }
        }
        // this.loadData();
      }
    );
  }

  validateEmailAddressFriendsFamily(data){

    var requestModel = {
      UserDetailsId: this.authenticationService.currentUserValue.UserDetailsId,
      EmailAddress:data.controls['EmailAddress'].value
    }
    setTimeout(() => {
      this.loadingScreenService.startLoading();
      let response: Observable<any>;
        this.friendService.ValidateEmailAddressFriendsFamily(requestModel)
          .subscribe(
            (result) => {
              this.loadingScreenService.stopLoading();
              if (result.status == Status.Error) {
                data.controls['EmailAddress'].setErrors({ 'exist': true });
              }
              else if (result.status == Status.Success) {
                data.controls['EmailAddress'].setErrors(null);
              }
            },
            (error) => {
              this._toastrService.Error(error, Constant.ErrorToastrTitle);
              this.error = error;
              this.loadingScreenService.stopLoading();
            }
          );
    }, 50);
  }

  focusoutEmail(data){
    //console.log(data)
    if ( !(data.controls['EmailAddress'].value == this.userFriend?.EmailAddress) &&
      data.controls['EmailAddress'].value !== undefined &&
      data.controls['EmailAddress'].value.length > 0 && !((this.userFriend?.ConnectionRequestStatusId == 2) || (this.userFriend?.ConnectionRequestStatusId == 3))
    ) {
      var emailInput = data.controls['EmailAddress'].value;
      var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,4}$/;
    
      if (!emailRegex.test(emailInput)) {
        // Invalid email format
        data.controls['EmailAddress'].setErrors({ 'pattern': true });
      } else {
        // Valid email format
        data.controls['EmailAddress'].setErrors(null); // Set errors to null to remove 'pattern' error
        this.validateEmailAddressFriendsFamily(data);
      }
    }
  }
  
  onCountryChange(event: any) {
    this.friendLoadDataOutputModel.StateList = null;
    this.frmFriend.get('StateId').setValue(null);
    this.PlaceholderState = this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_STATE;
    this.frmFriend.get('StateId').disable();
    if (event != null) {
      this.PlaceholderCountry = "";
      //this.loadingScreenService.startLoading();
      const path = 'api/State/GetStateList';
      this.subStateList$ = this.globalService.PostDataAnonymous(event, path).subscribe(
        (data) => {
          //this.loadingScreenService.stopLoading();
          var result = JSON.parse(data._body);
          if (result.status == Status.Success) {
            this.friendLoadDataOutputModel.StateList = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
            if (this.friendLoadDataOutputModel.StateList != null) {
              this.frmFriend.get('StateId').enable();
            }
          }
        },
        (error) => {
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          //this.loadingScreenService.stopLoading();
        }
      );
    }
    else {
      this.PlaceholderCountry = this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_COUNTRY;
    }
  }
  onRegionChange(event: any) {
    this.friendLoadDataOutputModel.CountryList = null;
    this.frmFriend.get('CountryId').setValue(null);
    this.PlaceholderCountry = this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_COUNTRY;
    this.frmFriend.get('CountryId').disable();
    if (event != null) {
      this.PlaceholderRegion = "";
      //this.loadingScreenService.startLoading();
      const path = 'api/Country/GetCountryList';
      this.subCountryList$ = this.globalService.PostDataAnonymous(event, path).subscribe(
        (data) => {
          //this.loadingScreenService.stopLoading();
          var result = JSON.parse(data._body);
          if (result.status == Status.Success) {
            this.friendLoadDataOutputModel.CountryList = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
            if (this.friendLoadDataOutputModel.CountryList != null) {
              this.frmFriend.get('CountryId').enable();
            }
          }
        },
        (error) => {
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          //this.loadingScreenService.stopLoading();
        }
      );
    }
    else {
      this.PlaceholderRegion = this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_REGION;
    }
  }



  openURL(url) {
    // window.open(url, "_blank","noopener");
    if(url){
    const protocol = /^https?:\/\//i;
    const url1 = protocol.test(url) ? url : `http://${url}`;
    window.open(url1, '_blank');}
    else{
      this._toastrService.Warning('Please enter the URL')
    }
  }


  sendEmail(){
    if(this.frmFriend.get('EmailAddress').value)
      window.location.href = "mailto:" +  this.frmFriend.get('EmailAddress').value;
    else{
      this._toastrService.Warning('Please enter the Email Address')
    }
  }
  onCloseButtonClick() {
    this.dialogRef.close(false);
  }
  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }
  ngOnDestroy() {
    if (this.subDownloadAddressAtt$)
      this.subDownloadAddressAtt$.unsubscribe();
    if (this.subDeleteAddressAtt$)
      this.subDeleteAddressAtt$.unsubscribe();
    if (this.subStateList$)
      this.subStateList$.unsubscribe();
    if (this.subCountryList$)
      this.subCountryList$.unsubscribe();
  }

  get f() {
    return this.frmFriend.controls;
  }
}
