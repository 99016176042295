import { Status } from "app/enums";

export const locale = {
  lang: 'en',
  data: {
    COMMON: {
      CONTROL_LABEL_EMAIL_ADDRESS: 'Email Address',
      PLACEHOLDER_EMAIL_ADDRESS: 'Type here...',
      PLACEHOLDER_TYPE_HERE: 'Type here...',
      PLACEHOLDER_MOBILE_NUMBER: '0123456789',
      BUTTON_TEXT_SAVE_CHANGES: 'Save Changes',
      BUTTON_TEXT_CANCEL: 'Cancel',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS: 'The email is required!',
      ERROR_MESSAGE_INVALID_EMAIL: 'Please enter your email address in format:\nyourname@example.com',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'Could not connect with server.',
      TOOLTIP_TEXT_BUTTON_CLOSE: 'Close',
      BUTTON_TEXT_SAVE:'Save',
      BUTTON_TEXT_SAVE_SEND:'Save and Send',
      COMMON_STATUS:
        [
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'User could not be found.' }
        ],
    },
    MEONWEB: {
      BUTTON_TEXT_BUTTON_ADD_NEW_PASTIME: 'Add a Web Entry',
      CONTROL_LABEL_ENTRY_NAME: 'ENTRY NAME',
      CONTROL_LABEL_DATE_POSTED: 'DATE POSTED',
      CONTROL_LABEL_URL_WEBSITE: 'URL OF THE WEBSITE',
      CONTROL_LABEL_DESCRIPTION: 'DESCRIPTION',
      CONTROL_LABEL_ACTION: 'ACTIONS',
      CONTROL_LABEL_FIELD_ENTRY_NAME: 'Entry Name',
      CONTROL_LABEL_FIELD_DATE_POSTED: 'Date Posted',
      CONTROL_LABEL_FIELD_URL_WEBSITE: 'URL of the Website',
      CONTROL_LABEL_FIELD_DESCRIPTION: 'Description',
      CONTROL_LABEL_FIELD_NOTES: 'Notes',
      CONTROL_LABEL_LEGAL_DOCUMENTS: 'LEGAL DOCUMENT NAME',
      CONTROL_LABEL_NOTES: 'NOTES',
      DIALOG_TITLE_ADD_WEB: 'Add a Web Entry',
      ERROR_MESSAGE_ENTRY_NAME_REQUIRED:'The entry name is required!',
      HEADING_LABEL_ME_ON_WEB: 'Me on the Web',
      SECTION_HEADING_HI: 'Hi',
      SECTION_HEADING_ABOUT_ME: 'This is everything about you and your profile with us',
      TOOLTIP_TEXT_BUTTON_ADD_NEW_RECORD: 'Add a New Record',
      TOOLTIP_TEXT_WEB_TITLE: 'A Short Title',
      TOOLTIP_TEXT_WEB_PUBLICATION_DATE: 'Date the Web / Article Entry was published',
      TOOLTIP_TEXT_WEB_URL: 'The website URL or link of the Page published on',
      TOOLTIP_TEXT_WEB_DESCRIPTION: 'Enter a short description about this web entry',
      TOOLTIP_TEXT_WEB_OTHER_INFO: 'Add any other information about this web entry',
      TOOLTIP_TEXT_WEB_ENTRY_INFORMATION: 'Add some information about any of your Web Entry',
      ERROR_MESSAGE_MAX_LENGTH_50: 'You have typed too many characters, maximum allowed is 50',
      ERROR_MESSAGE_MAX_LENGTH_100: 'You have typed too many characters, maximum allowed is 100',
      ERROR_MESSAGE_MAX_LENGTH_120: 'You have typed too many characters, maximum allowed is 120',
      ERROR_MESSAGE_MAX_LENGTH_250: 'You have typed too many characters, maximum allowed is 250',
      ERROR_MESSAGE_MAX_LENGTH_1000: 'You have typed too many characters, maximum allowed is 1000',
      ERROR_MESSAGE_INVALID_EMAIL: 'Invalid Email Address!',
      ERROR_MESSAGE_EXIST_EMAIL: 'This email address is already connected with a friend, family member, or is your own. Please use a different one.',
      ERROR_MESSAGE_MAX_LENGTH_320: 'You have typed too many characters, maximum allowed is 320',
      CONTROL_LABEL_ADD_EMAIL:'Add Email',
      CONTROL_LABEL_EMAIL_ADDRESS: 'Email Address',
      TOOLTIP_TEXT_EMAIL: 'Enter the Family Member\'s Email Address',
      TOOLTIP_TEXT_FRIENDS_EMAIL:'Enter your friend\'s Email Address',
      TOAST_MESSAGE_FAMILY_UPDATED_SUCCESSFULLY: 'Family Member Updated Successfully',
      TOAST_MESSAGE_FAMILY_ADDED_SUCCESSFULLY: 'Family Member Added Successfully',
      TOAST_MESSAGE_FRIEND_UPDATED_SUCCESSFULLY: 'Friend Updated Successfully',
      TOAST_MESSAGE_FRIEND_ADDED_SUCCESSFULLY: 'Friend Added Successfully',
      SWEET_ALERT_TITLE_FAMILY_TEXT: 'Are you sure, you want to delete this Family Detail?',
      SWEET_ALERT_TITLE_FRIEND_TEXT: 'Are you sure, you want to delete this Friend Detail?',
      SWEET_ALERT_TITLE_INVITATION_TEXT: 'You are about to send Personname an invitation email to connect with you on Noosom.',
      SWEET_ALERT_TITLE_JOB_TEXT: 'Are you sure, you want to delete this Job Detail?',
      SWEET_ALERT_TEXT: "You won't be able to undo this!",
      SWEET_ALERT_NO_BUTTON: 'NO',
      SWEET_ALERT_YES_BUTTON: 'YES',
      SWEET_ALERT_SURE_TEXT: 'Are you sure ?',
      SWEET_ALERT_CONFIRM_BUTTON_TEXT: 'Yes, delete it!',
      SWEET_ALERT_CANCEL_BUTTON_TEXT: 'Cancel',
    },
    AWARDS: {
      HEADING_MY_AWARDS: 'My Awards',
      BUTTON_ADD_AN_AWARD: 'Add an Award',
      BUTTON_TEXT_ADD_AN_ACHIEVEMENT: 'Add an Achievement',
      BUTTON_TEXT_ADD_AN_EDUCATIONAL_ACHIEVEMENT: 'Add an Educational Achievement',
      BUTTON_TEXT_ADD_AN_PROFESSIONAL_ACHIEVEMENT: 'Add a Professional Achievement',
      BUTTON_TEXT_ADD_AN_MISCELLANEOUS_ACHIEVEMENT: 'Add a Miscellaneous Achievement',
      DATA_TABLE_HEADER_TEXT_NAME_OF_THE_AWARD: 'NAME OF THE AWARD',
      DATA_TABLE_HEADER_TEXT_OCCASION: 'OCCASION',
      DATA_TABLE_HEADER_TEXT_OCCASION_NOTES: 'OCCASION NOTES',
      DATA_TABLE_HEADER_TEXT_DATE_RECEIVED: 'DATE RECEIVED',
      DATA_TABLE_HEADER_TEXT_ACTIONS: 'ACTIONS',
      DATA_TABLE_HEADER_TEXT_NAME_OF_THE_ACHIEVEMENT: 'NAME OF THE ACHIEVEMENT',
      SECTION_HEADING_MY_ACHIEVEMENT: 'My Achievements',
      SECTION_HEADING_MY_EDUCATIONAL_ACHIEVEMENT: 'My Educational Achievements',
      SECTION_HEADING_MY_PROFESSIONAL_ACHIEVEMENT: 'My Professional Achievements',
      SECTION_HEADING_MY_MISCELLANEOUS_ACHIEVEMENT: 'My Miscellaneous Achievements',
      SWEET_ALERT_TITLE_AWARD_TEXT: 'Are you sure, you want to delete this Award?',
      SWEET_ALERT_TITLE_ACHIEVEMENT_TEXT: 'Are you sure, you want to delete this Achievement?',
      SWEET_ALERT_TEXT: "You won't be able to undo this!",
      SWEET_ALERT_CONFIRM_BUTTON_TEXT: 'Yes, delete it!',
      SWEET_ALERT_CANCEL_BUTTON_TEXT: 'Cancel',
    },
    MY_JOBS: {
      BUTTON_TEXT_ADD_A_NEW_JOBS: 'Add a New Job',
      DATA_TABLE_HEADER_TEXT_EMPLOYER: 'EMPLOYER',
      DATA_TABLE_HEADER_TEXT_JOB_DESCRIPTION: 'JOB DESCRIPTION',
      DATA_TABLE_HEADER_TEXT_DATE_FROM: 'DATE FROM',
      DATA_TABLE_HEADER_TEXT_DATE_TO: 'DATE TO',
      DATA_TABLE_HEADER_TEXT_ACTIONS: 'ACTIONS',
      DATA_TABLE_HEADER_TEXT_ADDRESS: 'ADDRESS',
      DATA_TABLE_HEADER_TEXT_NOTES: 'NOTES',
      SECTION_HEADING_MY_JOB: 'My Jobs',
    },
    MY_FAMILIES: {
      BUTTON_TEXT_ADD_AN_OWN_FAMILY_MEMBER: 'Add an Own Family Member',
      BUTTON_TEXT_ADD_A_BIRTH_FAMILY_MEMBER: 'Add a Birth Family Member',
      BUTTON_TEXT_ADD_AN_OTHER_FAMILY_MEMBER: 'Add an Other Family Member',
      DATA_TABLE_HEADER_TEXT_FIRST_NAME: 'FIRST NAME',
      DATA_TABLE_HEADER_TEXT_LAST_NAME: 'LAST NAME',
      DATA_TABLE_HEADER_TEXT_DATE_OF_BIRTH: 'DATE OF BIRTH',
      DATA_TABLE_HEADER_TEXT_RELATIONSHIP: 'RELATIONSHIP',
      DATA_TABLE_HEADER_TEXT_EMAIL_ADDRESS: 'EMAIL ADDRESS',
      DATA_TABLE_HEADER_TEXT_PERSONAL_WEB_URL: 'PERSONAL WEB URL',
      DATA_TABLE_HEADER_TEXT_NOOSOM_WEB_URL: 'NOOSOM WEB URL',
      DATA_TABLE_HEADER_TEXT_LINKED: 'LINKED?',
      TAB_LINK_TEXT_MY_OWN_FAMILY: 'My Own Family',
      TAB_LINK_TEXT_MY_BIRTH_FAMILY: 'My Birth Family',
      TAB_LINK_TEXT_MY_OTHER_FAMILY: 'My Other Family',
      TOOLTIP_TEXT_BIRTH_FAMILY_INFO:'This is the family you were born into, Mother, Father, Brother Sister etc',
      TOOLTIP_TEXT_OWN_FAMILY_INFO:'Your family by marriage, wife, husband, children, grand children',
      TOOLTIP_TEXT_OTHER_FAMILY_INFO:'In laws, aunties, uncles, nieces, nephews etc'
    },
    LEGALS: {
      ADD_TITLE: 'Add a Legal Document',
      EDIT_TITLE: 'Edit a Legal Document',
      CONTROL_LABEL_LEGAL: 'Name of the Legal Document',
      CONTROL_LABEL_NOTES: 'Notes',
      TAB_HEADING_LEGAL_DETAILS: 'Legal Details',
      TAB_HEADING_UPLOAD: 'Upload',
      TAB_HEADING_DOCUMENT: 'Document',
      TAB_HEADING_IMAGES: 'Images',
      BUTTON_TEXT_SAVE_AND_CLOSE: 'Save and Close',
      BUTTON_TEXT_SAVE_AND_NEXT: 'Save and Next',
      BUTTON_TEXT_ABANDON: 'Abandon',
      BUTTON_TEXT_CANCEL: 'Cancel',
      BUTTON_TEXT_UPLOAD_ALL: 'Upload All',
      BUTTON_TEXT_PAUSE_ALL: 'Pause All',
      BUTTON_TEXT_DOWNLOAD_ALL: 'Download All',
      BUTTON_TEXT_REMOVE_ALL: 'Remove All',
      BUTTON_TEXT_SAVE_CHANGES: 'Save Changes',
      BUTTON_TEXT_ADD_DOCUMENTS:'Add a Document',
      COLUMN_HEADING_NAME: 'NAME',
      COLUMN_HEADING_SIZE: 'SIZE',
      COLUMN_HEADING_PROGRESS: 'PROGRESS',
      COLUMN_HEADING_STATUS: 'STATUS',
      COLUMN_HEADING_DATE_CREATED: 'DATE CREATED',
      COLUMN_HEADING_TYPE: 'TYPE',
      COLUMN_HEADING_ACTIONS: 'ACTIONS',
      ERROR_MESSAGE_LEGAL_DOC_NAME:'The name of the legal document is required!',
      HEADING_MY_LEGALS:'My Legals',
      HEADING_TEXT_SELECT_IMAGES: 'Select Documents',
      HEADING_TEXT_UPLOAD_QUEUE: 'Upload Queue',
      HEADING_TEXT_QUEUE_PROGRESS: 'Queue Progress',
      PLACEHOLDER_CHOOSE_FILES: 'Choose Files',
      SECTION_TEXT1: 'Select some documents. You can upload them one at a time or select Upload All at once.',
      SECTION_TEXT2: 'All your documents will be displayed here in a list. You can download or delete them individually.',
      SWEET_ALERT_TITLE_PASTIME_TEXT: 'Are you sure, you want to delete this Legal Document?',
      // SWEET_ALERT_TITLE_HOBBY_TEXT:'Are you sure, you want to delete this Hobby?',
      SWEET_ALERT_TEXT: "You won't be able to undo this!",
      SWEET_ALERT_CONFIRM_BUTTON_TEXT: 'Yes, delete it!',
      SWEET_ALERT_CANCEL_BUTTON_TEXT: 'Cancel',
      CONTROL_LABEL_MULTIPLE: 'Multiple',
      SWEET_ALERT_TITLE_ADDRESS_DOCUMENT_TEXT: 'Are you sure, you want to delete this Legals Document?',
      SWEET_ALERT_TITLE_HOBBY_DOCUMENT_TEXT: 'Are you sure, you want to delete this Hobby Document?',
      SWEET_ALERT_TITLE_ALL_ADDRESS_DOCUMENT_TEXT: 'Are you sure, you want to delete all these Pastime Documents?',
      SWEET_ALERT_TITLE_ALL_HOBBY_DOCUMENT_TEXT: 'Are you sure, you want to delete all these Hobby Documents?',
      SWEET_ALERT_TITLE_INSURANCE_TEXT: 'Are you sure, you want to delete this Legals and all the attachments associated with it?',
      SWEET_ALERT_TEXT_DELETED: 'Deleted!',
      SWEET_ALERT_CONFIRM_BUTTON_TEXT_OKAY: 'OK',
      TEXT_FILE_TYPES: 'Allowed file types:',
      TEXT_QUEUE_LENGTH: 'Queue Length:',
      TEXT_MB: 'MB',
      TOOLTIP_TEXT_BUTTON_UPLOAD: 'Upload',
      TOOLTIP_TEXT_BUTTON_DOWNLOAD: 'Download',
      TOOLTIP_TEXT_BUTTON_VIEW: 'View',
      TOOLTIP_TEXT_BUTTON_DELETE: 'Delete',
      TOOLTIP_TEXT_BUTTON_REMOVE: 'Remove',
      TOOLTIP_TOGGLE_CALENDAR: 'Toggle Calendar',
      TOOLTIP_TEXT_TEXT_STYLE: 'Text Style',
      TOOLTIP_TEXT_TEXT_SIZE: 'Text Size',
      TOOLTIP_TEXT_TEXT_COLOUR: 'Text Colour',
      TOOLTIP_TEXT_TEXT_ALIGNMENT: 'Text Alignment',
      TOOLTIP_TEXT_BOLD: 'Bold',
      TOOLTIP_TEXT_ITALIC: 'Italic',
      TOOLTIP_TEXT_UNDERLINE: 'Underline',
      TOOLTIP_TEXT_LINK: 'Link',
      TOOLTIP_TEXT_BULLET_LIST: 'Bullet List',
      TOOLTIP_TEXT_NUMBERED_LIST: 'Numbered List',
      TOOLTIP_TEXT_CLEAR_FORMAT: 'Clear Format',
      TOOLTIP_TEXT_LEGAL_DOCUMENT_INFORMATION: 'Add some information about any of your legal document details',
      TOOLTIP_TEXT_UPLOAD_LEGAL_DOCUMENTS: 'Select some Documents; you can upload them one at a time or select Upload All at once',
      TOOLTIP_TEXT_UPLOADED_LEGAL_DOCUMENTS_DISPLAY: 'All your uploaded documents will be displayed here',
      INFO_TEXT2: 'All your uploaded images will be displayed here',

    }
  }
};
