import { Status } from "app/enums";

export const locale = {
  lang: 'en',
  data: {
    COMMON: {
      CONTROL_LABEL_EMAIL_ADDRESS: 'Email Address',
      PLACEHOLDER_EMAIL_ADDRESS: 'Type here...',
      PLACEHOLDER_TYPE_HERE: 'Type here...',
      PLACEHOLDER_MOBILE_NUMBER: '0123456789',
      BUTTON_TEXT_SAVE_CHANGES: 'Save Changes',
      BUTTON_TEXT_CANCEL: 'Cancel',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS: 'The email is required!',
      ERROR_MESSAGE_INVALID_EMAIL: 'Please enter your email address in format:\nyourname@example.com',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'Could not connect with server.',
      TOOLTIP_TEXT_BUTTON_CLOSE: 'Close',
      COMMON_STATUS:
        [
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'User could not be found.' }
        ],
    },
    MYPASTIME: {
      BUTTON_TEXT_BUTTON_ADD_NEW_PASTIME: 'Add a Pastime',
      BUTTON_TEXT_BUTTON_ADD_NEW_HOBBY: 'Add a Hobby',
      BUTTON_TEXT_SAVE_AND_CLOSE: 'Save and Close',
      BUTTON_TEXT_SAVE_AND_NEXT: 'Save and Next',
      BUTTON_TEXT_ABANDON: 'Abandon',
      BUTTON_TEXT_CANCEL: 'Cancel',
      BUTTON_TEXT_UPLOAD_ALL: 'Upload All',
      BUTTON_TEXT_PAUSE_ALL: 'Pause All',
      BUTTON_TEXT_DOWNLOAD_ALL: 'Download All',
      BUTTON_TEXT_REMOVE_ALL: 'Remove All',
      BUTTON_TEXT_SAVE_CHANGES: 'Save Changes',
      CONTROL_LABEL_PASTIME: 'PASTIME',
      CONTROL_LABEL_PASTIMES:'Pastimes',
      CONTROL_LABEL_HOBBY: 'HOBBY',
      CONTROL_LABEL_HOBBIES:'Hobbies',
      CONTROL_LABEL_DATE_FROM: 'DATE FROM',
      CONTROL_LABEL_DATE_TO: 'DATE TO',
      CONTROL_LABEL_FIELD_DATE_FROM: 'Date From',
      CONTROL_LABEL_FIELD_DATE_TO: 'Date To',
      CONTROL_LABEL_FIELD_SHORT_DESCRIPTION: 'Short Description',
      CONTROL_LABEL_FIELD_LONG_DESCRIPTION: 'Long Description',
      CONTROL_LABEL_SHORT_DESCRIPTION: 'SHORT DESCRIPTION',
      CONTROL_LABEL_NAME_OF_PASTIME: 'Name of the Pastime',
      CONTROL_LABEL_NAME_OF_HOBBY: 'Name of the Hobby',
      CONTROL_LABEL_MULTIPLE: 'Multiple',
      CONTROL_LABEL_ACTION: 'ACTION',
      CONTROL_LABEL_NOTES: 'Notes',
      COLUMN_HEADING_NAME: 'NAME',
      COLUMN_HEADING_SIZE: 'SIZE',
      COLUMN_HEADING_PROGRESS: 'PROGRESS',
      COLUMN_HEADING_STATUS: 'STATUS',
      COLUMN_HEADING_DATE_CREATED: 'DATE CREATED',
      COLUMN_HEADING_TYPE: 'TYPE',
      COLUMN_HEADING_ACTIONS: 'ACTIONS',
      DIALOG_TITLE_ADD_PASTIME: 'Add a Pastime',
      DIALOG_TITLE_EDIT_PASTIME: 'Edit a Pastime',
      DIALOG_TITLE_ADD_HOBBY: 'Add a Hobby',
      DIALOG_TITLE_EDIT_HOBBY: 'Edit a Hobby',
      ERROR_MESSAGE_ATTACHMENT_UPLOADED:'Attachment Uploaded',
      ERROR_MESSAGE_PASTIME_REQUIRED:'The pastime is required!',
      ERROR_MESSAGE_HOBBIES_REQUIRED:'The hobby is required!',
      ERROR_MESSAGE_MAX_50:'You have typed too many characters, maximum allowed is 50',
      HEADING_TEXT_SELECT_IMAGES: 'Select Documents',
      HEADING_TEXT_UPLOAD_QUEUE: 'Upload Queue',
      HEADING_TEXT_QUEUE_PROGRESS: 'Queue Progress',
      INFO_TEXT2: 'All your uploaded images will be displayed here',
      PLACEHOLDER_CHOOSE_FILES: 'Choose Files',
      SECTION_HEADING_HI: 'Hi',
      SECTION_HEADING_ABOUT_ME: 'This is everything about you and your profile with us',
      SECTION_TEXT1: 'Select some documents. You can upload them one at a time or select Upload All at once.',
      SECTION_TEXT2: 'All your documents will be displayed here in a list. You can download or delete them individually.',
      SECTION_TEXT3: 'Add any specific notes that you have for this pastime.',
      SWEET_ALERT_TITLE_PASTIME_TEXT: 'Are you sure, you want to delete this Past Time?',
      SWEET_ALERT_TITLE_HOBBY_TEXT: 'Are you sure, you want to delete this Hobby?',
      SWEET_ALERT_TEXT: "You won't be able to undo this!",
      SWEET_ALERT_CONFIRM_BUTTON_TEXT: 'Yes, delete it!',
      SWEET_ALERT_CANCEL_BUTTON_TEXT: 'Cancel',
      SWEET_ALERT_TITLE_ADDRESS_DOCUMENT_TEXT: 'Are you sure, you want to delete this Pastime Document?',
      SWEET_ALERT_TITLE_HOBBY_DOCUMENT_TEXT: 'Are you sure, you want to delete this Hobby Document?',
      SWEET_ALERT_TITLE_ALL_ADDRESS_DOCUMENT_TEXT: 'Are you sure, you want to delete all these Pastime Documents?',
      SWEET_ALERT_TITLE_ALL_HOBBY_DOCUMENT_TEXT: 'Are you sure, you want to delete all these Hobby Documents?',
      SWEET_ALERT_TITLE_INSURANCE_TEXT: 'Are you sure, you want to delete this Pastime and all the attachments associated with it?',
      SWEET_ALERT_TEXT_DELETED: 'Deleted!',
      SWEET_ALERT_CONFIRM_BUTTON_TEXT_OKAY: 'OK',
      TAB_HEADING_PASTIME_DETAILS: 'Pastime Details',
      TAB_HEADING_HOBBY_DETAILS: 'Hobby Details',
      TAB_HEADING_UPLOADS: 'Uploads',
      TAB_HEADING_DOCUMENTS: 'Documents',
      TAB_HEADING_NOTES: 'Notes',
      TAB_LINK_PASTIMES: 'Pastimes',
      TAB_HEADING_GALLERY: 'Gallery',
      TAB_LINK_HOBBIES: 'Hobbies',
      TOOLTIP_TOGGLE_CALENDAR: 'Toggle Calendar',
      TOOLTIP_TEXT_BUTTON_ADD_NEW_RECORD: 'Add a New Record',
      TOOLTIP_TEXT_PASTIME_INFORMATION: 'Add some information about any of your Pastimes',
      TOOLTIP_TEXT_PASTIME_NOTES: 'Add some notes about any of your Pastimes',
      TOOLTIP_TEXT_UPLOAD_DOCUMENTS: 'Select some Documents; you can upload them one at a time or select Upload All at once',
      TOOLTIP_TEXT_UPLOADED_DOCUMENTS_DISPLAY: 'All your uploaded documents will be displayed here',
      TOOLTIP_TEXT_PASTIME_NAME: 'Enter Name of the Pastime',
      TOOLTIP_TEXT_PASTIME_DATE_FROM: 'Enter the Date From of the Pastime',
      TOOLTIP_TEXT_PASTIME_DATE_TO: 'Enter the Date To of the Pastime',
      TOOLTIP_TEXT_HOBBIES_NAME: 'Enter Name of the Hobby',
      TOOLTIP_TEXT_HOBBIES_DATE_FROM: 'Enter the Date From of the Hobby',
      TOOLTIP_TEXT_HOBBIES_DATE_TO: 'Enter the Date To of the Hobby',
      TOOLTIP_TEXT_HOBBY_INFORMATION: 'Add some information about any of your Hobbies',
      TOOLTIP_TEXT_HOBBY_NOTES: 'Add some notes about any of your Hobbies',
      TOOLTIP_TEXT_PASTIME_SHORT_DESCRIPTION: 'Enter a Short Description',
      TOOLTIP_TEXT_PASTIME_LONG_DESCRIPTION: 'Enter a Long Description',
      TOOLTIP_TEXT_BUTTON_DOWNLOAD: 'Download',
      TOOLTIP_TEXT_BUTTON_VIEW: 'View',
      TOOLTIP_TEXT_BUTTON_DELETE: 'Delete',
      TEXT_FILE_TYPES: 'Allowed file types:',
      TEXT_QUEUE_LENGTH: 'Queue Length:',
      TEXT_MB: 'MB',
    }
  }
};
