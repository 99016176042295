import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
import { locale as german } from '../i18n/de';
import { locale as portuguese } from '../i18n/pt';
import { locale as gujarati } from '../i18n/gj';
import { locale as hindi } from '../i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { TextBoxComponent } from 'app/CustomComponents/text-box/text-box.component';
import { CoreConfigService } from '@core/services/config.service';
import { E } from 'assets/extensions/extensions';
import { AuthenticationService } from 'app/auth/service';
import { Status, Enums, MapModalType } from 'app/enums';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { GlobalService } from 'app/Services/global.service';
import { Constant } from 'app/Classes/constant';
import { takeUntil } from 'rxjs/operators';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import {OwnFamilyService} from './own-family.service';
import { FamilyMemberLoadDataOutputModel, TblUserFamilyMembers } from '../about-me-model.model';
import {Clipboard} from '@angular/cdk/clipboard'
import { CountryISO } from 'ngx-intl-tel-input';
import { MapAddressComponent } from 'app/address/map-address/map-address.component';
import { MapAddressDataModel } from 'app/address/map-address/map-address-data-model.model';
import { NoosomPlans, NoosomCategoryID } from 'app/enums';
import { FriendsService } from '../friends/friends.service';
@Component({
  selector: 'app-own-family',
  templateUrl: './own-family.component.html',
  styleUrls: ['./own-family.component.scss']
})
export class OwnFamilyComponent implements OnInit {
  @ViewChild('FirstName') FirstName: TextBoxComponent;
  public coreConfig: any;
  public dialogTitle: string;
  public IsForEdit: boolean = false;
  currentAppLanguage: any;
  private _unsubscribeAll: Subject<any>;
  public loading = false;
  public submitted = false;
  private clipboard: Clipboard;
  _data: number = 0;
  maxlength = 320;
  maxlength2 = 100;
  characterCount = 0;
  characterCount2 = 0;
  maxDate=new Date();
  public error = '';
  public frmFamily: FormGroup;
  SelectedCountryISO1: CountryISO;
  SelectedCountryISO2: CountryISO;
  public PlaceholderState: any;
  public PlaceholderCountry: any;
  public PlaceholderRegion: any;
  userFamily:TblUserFamilyMembers;
  familyMemberLoadDataOutputModel:FamilyMemberLoadDataOutputModel;
  private subStateList$?: Subscription;
  private subCountryList$?: Subscription;
  infoTooltip: string;
  dateformat: string;
  connectionStatus: string='';
  templcstg = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, localStorage.getItem('lcstrg'))) 
  showIcon:boolean=false;
  constructor(
    private dialogRef: MatDialogRef<OwnFamilyComponent>,
    private _coreTranslationService: CoreTranslationService,
    private _coreConfigService: CoreConfigService,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private loadingScreenService: LoadingScreenService,
    private globalService: GlobalService,
    private encrDecr: EncrDecrService,
    private _toastrService: CustomToastrService,
    public familyService: OwnFamilyService,
    public friendService:FriendsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._unsubscribeAll = new Subject();
    this._data = data;
    this.dateformat=this.globalService.getDateFormat().toUpperCase()
  }
  ngAfterViewInit(): void {
    this.FirstName.textBox.nativeElement.focus();
  }
  ngOnInit(): void {
    this.userFamily= new TblUserFamilyMembers();
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
    this.coreConfig = config;
    });
    if (this.templcstg) {
      var plan = this.templcstg?.NoosomPlan;
      var userCategory = this.templcstg?.userCategory
    }
    if((plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test)){
      this.showIcon=true;
    }else{
      this.showIcon=true;
    }

    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);
    if(this._data==1)
    this.dialogTitle = this.currentAppLanguage.data.MY_FAMILIES.DIALOG_TITLE_ADD_OWN_FAMILY_MEMBERS;
    if(this._data==2)
    this.dialogTitle = this.currentAppLanguage.data.MY_FAMILIES.DIALOG_TITLE_ADD_BIRTH_FAMILY_MEMBERS;
    if(this._data==3)
    this.dialogTitle = this.currentAppLanguage.data.MY_FAMILIES.DIALOG_TITLE_ADD_OTHER_FAMILY_MEMBERS;
    this.infoTooltip= this.currentAppLanguage.data.MY_FAMILIES.SECTION_TEXT;
    this.frmFamily = this._formBuilder.group({
      FirstName: ['', Validators.required],
      MiddleName:[''],
      LastName:['',Validators.required],
      Relationship:[''],
      EmailAddress:[''],
      DateofBirth: [],
      PersonalWebUrl:[''],
      NoosomWebUrl:[''],
      ContactNumber1:[''],
      ContactNumber2:[''],
      Address1: [''],
      Address2: [''],
      Address3: [''],
      AddressTown: [''],
      StateId: [],
      AddressPostCode: [''],
      CountryId: [],
      RegionId: [],
    });
    if (typeof this._data !== 'undefined') {
      // console.log('this._data')
      // console.log(this._data)
      if (this._data > 4) {
        this.IsForEdit = true;
        this.dialogTitle = this.currentAppLanguage.data.MY_FAMILIES.DIALOG_TITLE_EDIT_FAMILY_MEMBERS;
      }
      else {
        //Add Mode
        if (this.authenticationService.currentUserValue.CountryShortCode) {
          this.SelectedCountryISO1 = this.globalService.GetCountryISOObject(this.authenticationService.currentUserValue.CountryShortCode);
          this.SelectedCountryISO2 = this.globalService.GetCountryISOObject(this.authenticationService.currentUserValue.CountryShortCode);
        }
      }
      setTimeout(() => {
        this.loadingScreenService.startLoading();
        let response: Observable<any>;
        this.familyService.GetUserFamilyMemberLoadData(this._data)
          .subscribe(
            (data) => {
              this.loadingScreenService.stopLoading();
              if (data.status == Status.Success) {
                this.familyMemberLoadDataOutputModel = <FamilyMemberLoadDataOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, data.data)));
                 //console.log(this.familyMemberLoadDataOutputModel)
                if (this.familyMemberLoadDataOutputModel.UserFamilyMember) {
                  this.userFamily = this.familyMemberLoadDataOutputModel.UserFamilyMember;
                  this.frmFamily.patchValue({
                    FirstName:this.userFamily.FirstName,
                    MiddleName:this.userFamily.MiddleName,
                    LastName:this.userFamily.LastName,
                    Relationship:this.userFamily.Relationship,
                    EmailAddress:this.userFamily.EmailAddress,
                    DateofBirth: E.ConvertToDate(this.userFamily.DateofBirth),
                    PersonalWebUrl:this.userFamily.PersonalWebUrl,
                    NoosomWebUrl:this.userFamily.NoosomWebUrl,
                    Address1:this.userFamily.Address1,
                    Address2:this.userFamily.Address2,
                    Address3:this.userFamily.Address3,
                    AddressTown:this.userFamily.AddressTown,
                    AddressPostCode:this.userFamily.AddressPostCode,
                    RegionId:this.userFamily.RegionId,
                    CountryId:this.userFamily.CountryId,
                    StateId:this.userFamily.StateId,
                    ContactNumber1: this.userFamily.ContactNumber1,
                    ContactNumber2: this.userFamily.ContactNumber2,
                  });

                  // if (this.userFamily.CountryId) {
                  //   this.frmFamily.get('CountryId').enable();
                  // }
                  if (this.userFamily.StateId) {
                    this.frmFamily.get('StateId').enable();
                  }
                  if (this.userFamily.ContactNumber1CountryCode)
                    this.SelectedCountryISO1 = this.globalService.GetCountryISOObject(this.userFamily.ContactNumber1CountryCode);
                    else
                    this.SelectedCountryISO1 = this.globalService.GetCountryISOObject(this.authenticationService.currentUserValue.CountryShortCode);
                  if (this.userFamily.ContactNumber2CountryCode)
                    this.SelectedCountryISO2 = this.globalService.GetCountryISOObject(this.userFamily.ContactNumber2CountryCode);
                    else
                    this.SelectedCountryISO2 = this.globalService.GetCountryISOObject(this.authenticationService.currentUserValue.CountryShortCode);
                  if(this.userFamily.ConnectionRequestStatusId){
                    this.connectionStatus=this.CustomButtonRenderer(this.userFamily.ConnectionRequestStatusId)
                  }

                }

              }
              else {
                this._toastrService.Error(E.ConvertToString(data.message), Constant.ErrorToastrTitle);
              }
            },
            (error) => {
              this._toastrService.Error(E.ConvertToString(error), Constant.ErrorToastrTitle);
              this.error = error;
              this.loadingScreenService.stopLoading();
            }
          );

      }, 500);
      //}
      this.userFamily.FamilyMemberTypeId=this.userFamily.FamilyMemberTypeId;
    }
    this.PlaceholderRegion = this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_REGION;
    this.PlaceholderCountry = this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_COUNTRY;
    this.PlaceholderState = this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_STATE;
    this.frmFamily.get('CountryId').disable();
    this.frmFamily.get('RegionId').disable();
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key.toLocaleLowerCase() === "escape") {
        this.onCloseButtonClick();
      }
    });
  }

  updateCharacterCount(value: string) {
    // console.log(this.maxlength)
     this.characterCount = value ? value.length : 0;
   }

   updateCharacterCount2(value: string) {
    // console.log(this.maxlength)
     this.characterCount2 = value ? value.length : 0;
   }

  CustomButtonRenderer(params) {
    let value = params ? params : '';
    let btnType = '';
    switch (value) {
        case 3:
            btnType = 'btn-success';
            break;
        case 2:
            btnType = 'btn-warning';
            break;
        case 4:
            btnType = 'btn-danger';
            break;
        case 1:
            btnType = 'btn-grey';
            break;
    }

    // Create a container div element
    

    return btnType;
}
  onSubmit() {

    this.submitted = true;
    // if (this.frmFamily.invalid || this.frmFamily.get('Address1').value) {
      // if ([this.frmFamily.get('AddressTown').value, this.frmFamily.get('AddressPostCode').value, this.frmFamily.get('RegionId').value, this.frmFamily.get('CountryId').value, this.frmFamily.get('StateId').value].some(field => field === null || field === '')) {
      //   return;
      // }
      // if (typeof this.userFamily.Latitude === 'undefined' || typeof this.userFamily.Longitude === 'undefined' || this.userFamily.Latitude == null || this.userFamily.Longitude == null) {
      //   this._toastrService.Warning(this.currentAppLanguage.data.MY_JOBS.WARNING_MESSAGE_SELECT_LOCATION, Constant.WarningToastrTitle);
      //   this.onSearchOnMapClick();
      //   return;
      // }
      //console.log([this.frmFamily.get('AddressTown').value, this.frmFamily.get('AddressPostCode').value, this.frmFamily.get('RegionId').value, this.frmFamily.get('CountryId').value, this.frmFamily.get('StateId').value])
      this.frmFamily.controls['DateofBirth'].setErrors(null);
      if(this.frmFamily.invalid)
      {return;}
    // }
    this.submitted = false;
    if (this.userFamily == null) {
      this.userFamily = new TblUserFamilyMembers();
    }
    // console.log(E.GetDateStringOnly(this.frmFamily.get('DateofBirth').value))
    this.userFamily.FirstName = this.frmFamily.get('FirstName').value;
    this.userFamily.MiddleName = this.frmFamily.get('MiddleName').value;
    this.userFamily.LastName = this.frmFamily.get('LastName').value;
    this.userFamily.Relationship = this.frmFamily.get('Relationship').value;
    this.userFamily.EmailAddress = this.frmFamily.get('EmailAddress').value;
    this.userFamily.DateofBirth = E.GetDateStringOnly(this.frmFamily.get('DateofBirth').value);
    this.userFamily.PersonalWebUrl = this.frmFamily.get('PersonalWebUrl').value;
    this.userFamily.NoosomWebUrl = this.frmFamily.get('NoosomWebUrl').value;
    this.userFamily.Address1 = this.frmFamily.get('Address1').value;
    this.userFamily.Address2 = this.frmFamily.get('Address2').value;
    this.userFamily.Address3 = this.frmFamily.get('Address3').value;
    this.userFamily.AddressTown = this.frmFamily.get('AddressTown').value;
    if(this.frmFamily.get('ContactNumber1').value){
    this.userFamily.ContactNumber1 = this.frmFamily.get('ContactNumber1').value.number.replace(/\s/g, "");
    this.userFamily.ContactNumber1CountryCode = this.frmFamily.get('ContactNumber1').value.countryCode.toLocaleLowerCase();}
    this.userFamily.AddressPostCode = this.frmFamily.get('AddressPostCode').value;
    this.userFamily.RegionId = this.frmFamily.get('RegionId').value;
    this.userFamily.CountryId = this.frmFamily.get('CountryId').value;
    this.userFamily.StateId = this.frmFamily.get('StateId').value;
    if (this.frmFamily.get('ContactNumber2').value) {
      this.userFamily.ContactNumber2 = this.frmFamily.get('ContactNumber2').value.number.replace(/\s/g, "");
      this.userFamily.ContactNumber2CountryCode = this.frmFamily.get('ContactNumber2').value.countryCode.toLocaleLowerCase();
    }
    else { this.userFamily.ContactNumber2 = this.frmFamily.get('ContactNumber2').value; }
    this.userFamily.UserDetailsId= this.authenticationService.currentUserValue.UserDetailsId;
    if(this._data<=3){
    // console.log('this._data')
    // console.log(this._data)
    this.userFamily.FamilyMemberTypeId = this._data;}
    if (E.ConvertToNumber(this.userFamily.UserFamilyMemberId) == 0 && this.IsForEdit == false) {
      this.userFamily.CreatedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userFamily.CreatedDate = E.GetTodayDateStringOnly();
      this.userFamily.CreatedTime = E.GetTodayTimeStringOnly();
      this.userFamily.ChangedDate = E.GetTodayDateStringOnly();
      this.userFamily.ChangedTime = E.GetTodayTimeStringOnly();
    }
    else {
      this.userFamily.ChangedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userFamily.ChangedDate = E.GetTodayDateStringOnly();
      this.userFamily.ChangedTime = E.GetTodayTimeStringOnly();
    }
    this.loading = true;
    this.loadingScreenService.startLoading();
    let response: Observable<any>;
    this.familyService.CreateUpdateUserFamilyMember(this.userFamily)
      .subscribe(
        (result) => {
          this.loading = false;
          this.loadingScreenService.stopLoading();
          var message = ""; var errormessage = "";
          // console.log(result)
          if (result.status == Status.RecordAdded) {
            message = this.currentAppLanguage.data.MY_FAMILIES.TOAST_MESSAGE_FAMILY_ADDED_SUCCESSFULLY;
          }
          else if (result.status == Status.RecordUpdated) {
            message = this.currentAppLanguage.data.MY_FAMILIES.TOAST_MESSAGE_FAMILY_UPDATED_SUCCESSFULLY;
          }
          else {
            errormessage = E.ConvertToString(result.message);
          }
          if (message.length > 0) {
            this._toastrService.Success(message, Constant.SuccessToastrTitle);
            this.dialogRef.close(true);
            this.frmFamily.reset();
            this.frmFamily.patchValue({
            //  DateOfBirth: new Date()
            });
          }
          else {
            this._toastrService.Error(errormessage, Constant.ErrorToastrTitle);
          }
        },
        (error) => {
          this.error = error;
          this.loading = false;
          this.loadingScreenService.stopLoading();
        }
      );

  }

  copyText(textToCopy: string) {
    if(textToCopy!=''){
    this.clipboard.copy(textToCopy);
    this._toastrService.Success('Copied.', Constant.SuccessToastrTitle);
  }
  }

  validateEmailAddressFriendsFamily(data){

    var requestModel = {
      UserDetailsId: this.authenticationService.currentUserValue.UserDetailsId,
      EmailAddress:data.controls['EmailAddress'].value
    }
    setTimeout(() => {
      this.loadingScreenService.startLoading();
      let response: Observable<any>;
        this.friendService.ValidateEmailAddressFriendsFamily(requestModel)
          .subscribe(
            (result) => {
              this.loadingScreenService.stopLoading();
              if (result.status == Status.Error) {
                data.controls['EmailAddress'].setErrors({ 'exist': true });
              }
              else if (result.status == Status.Success) {
                data.controls['EmailAddress'].setErrors(null);
              }
            },
            (error) => {
              this._toastrService.Error(error, Constant.ErrorToastrTitle);
              this.error = error;
              this.loadingScreenService.stopLoading();
            }
          );
    }, 50);
  }

  focusoutEmail(data){
    //console.log(data)
    if ( !(data.controls['EmailAddress'].value == this.userFamily?.EmailAddress) &&
      data.controls['EmailAddress'].value !== undefined &&
      data.controls['EmailAddress'].value.length > 0 && !((this.userFamily?.ConnectionRequestStatusId == 2) || (this.userFamily?.ConnectionRequestStatusId == 3))
    ) {
      var emailInput = data.controls['EmailAddress'].value;
      var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,4}$/;
    
      if (!emailRegex.test(emailInput)) {
        // Invalid email format
        data.controls['EmailAddress'].setErrors({ 'pattern': true });
      } else {
        // Valid email format
        data.controls['EmailAddress'].setErrors(null); // Set errors to null to remove 'pattern' error
        this.validateEmailAddressFriendsFamily(data)
      }
    }
  }

 

  onSearchOnMapClick() {
    if (E.ConvertToString(this.frmFamily.get('Address1').value) != '' && E.ConvertToString(this.frmFamily.get('AddressTown').value) != ''
      && E.ConvertToString(this.frmFamily.get('AddressPostCode').value) != '') {
      this.openDialogMapAddress();
    }
    else {
      this._toastrService.Error(E.ConvertToString( this.warningMessage()+' is required'), Constant.ErrorToastrTitle);
    }
  }
  warningMessage(){
    let emptyFields= '';
    if ((this.frmFamily.get('Address1').value == '') || (this.frmFamily.get('Address1').value == null))
      emptyFields += " " +"Street Name,";
    if ((this.frmFamily.get('AddressTown').value == ('')) || (this.frmFamily.get('AddressTown').value == (null)))
      emptyFields += " " +"Town/City,";
    if ((this.frmFamily.get('AddressPostCode').value == '') || (this.frmFamily.get('AddressPostCode').value ==null))
      emptyFields += " " +"Post/Zip Code";

    return emptyFields;
  }

  GetMapSearchString(): string {
    let result = '';
    if (this.frmFamily.get('Address1').value)
      result += (this.frmFamily.get('Address1').value + ', ');
    if (this.frmFamily.get('AddressTown').value)
      result += (this.frmFamily.get('AddressTown').value + ', ');
    if (this.frmFamily.get('AddressPostCode').value)
      result += (this.frmFamily.get('AddressPostCode').value);
    return result;
  }
  openDialogMapAddress() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "70%"
    dialogConfig.height = "55%";
    dialogConfig.data = new MapAddressDataModel(MapModalType.SearchString, this.GetMapSearchString());
    const dialogRef: MatDialogRef<MapAddressComponent> = this.dialog.open(MapAddressComponent, dialogConfig);
    dialogRef.componentInstance.IsDraggable = true;
    dialogRef.componentInstance.IsSaveButtonVisible = true;
    dialogRef.componentInstance.IsSearchOnMap = true;
    dialogRef.afterClosed().subscribe(
      result => {
        // console.log('Map closed');
        // console.log(result);
        if (result) {
          if (E.ConvertToString(result.event) == 'SearchOnMap' && result.data) {
            //console.log('this.userFriend');
            // console.log(this.userFriend.Latitude);
            this.userFamily.Latitude = result.data.lat;
            this.userFamily.Longitude = result.data.long;
          }
        }
        // this.loadData();
      }
    );
  }

  onStateChange(event) {
    // console.log(event)
    // this.PlaceholderState = (event == null || (event != null && event < 1)) ? this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_STATE : "";
     if (event != null) {
      this.PlaceholderCountry = "";
      //this.loadingScreenService.startLoading();
      const path = 'api/State/GetCountryRegionId';
      this.subStateList$ = this.globalService.PostDataAnonymous(event, path).subscribe(
        (data) => {
          //this.loadingScreenService.stopLoading();
          var result = JSON.parse(data._body);
          if (result.status == Status.Success) {
           // console.log(JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data)))
            var countryRegion = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
            this.frmFamily.get('CountryId').setValue(countryRegion?.CountryId);
            this.frmFamily.get('RegionId').setValue(countryRegion?.RegionId);
         //   this.familyMemberLoadDataOutputModel.StateList = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
          //  if (this.familyMemberLoadDataOutputModel.StateList != null) {
          //    this.frmFamily.get('StateId').enable();
          //  }
          }
        },
        (error) => {
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          //this.loadingScreenService.stopLoading();
        }
      );
    }
    else {
      this.PlaceholderCountry = this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_STATE;
      this.frmFamily.get('RegionId').setValue(null);
      this.PlaceholderRegion = this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_REGION;
      this.frmFamily.get('RegionId').disable();
      this.frmFamily.get('CountryId').setValue(null);
      this.PlaceholderCountry = this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_COUNTRY;
      this.frmFamily.get('CountryId').disable();
    }
   }
  
  onCountryChange(event: any) {
    this.familyMemberLoadDataOutputModel.StateList = null;
    this.frmFamily.get('StateId').setValue(null);
    this.PlaceholderState = this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_STATE;
    this.frmFamily.get('StateId').disable();
    if (event != null) {
      this.PlaceholderCountry = "";
      //this.loadingScreenService.startLoading();
      const path = 'api/State/GetStateList';
      this.subStateList$ = this.globalService.PostDataAnonymous(event, path).subscribe(
        (data) => {
          //this.loadingScreenService.stopLoading();
          var result = JSON.parse(data._body);
          if (result.status == Status.Success) {
            this.familyMemberLoadDataOutputModel.StateList = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
            if (this.familyMemberLoadDataOutputModel.StateList != null) {
              this.frmFamily.get('StateId').enable();
            }
          }
        },
        (error) => {
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          //this.loadingScreenService.stopLoading();
        }
      );
    }
    else {
      this.PlaceholderCountry = this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_COUNTRY;
    }
  }
  onRegionChange(event: any) {
    this.familyMemberLoadDataOutputModel.CountryList = null;
    this.frmFamily.get('CountryId').setValue(null);
    this.PlaceholderCountry = this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_COUNTRY;
    this.frmFamily.get('CountryId').disable();
    if (event != null) {
      this.PlaceholderRegion = "";
      //this.loadingScreenService.startLoading();
      const path = 'api/Country/GetCountryList';
      this.subCountryList$ = this.globalService.PostDataAnonymous(event, path).subscribe(
        (data) => {
          //this.loadingScreenService.stopLoading();
          var result = JSON.parse(data._body);
          if (result.status == Status.Success) {
            this.familyMemberLoadDataOutputModel.CountryList = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
            if (this.familyMemberLoadDataOutputModel.CountryList != null) {
              this.frmFamily.get('CountryId').enable();
            }
          }
        },
        (error) => {
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          //this.loadingScreenService.stopLoading();
        }
      );
    }
    else {
      this.PlaceholderRegion = this.currentAppLanguage.data.MY_FAMILIES.PLACEHOLDER_REGION;
    }
  }


  openURL(url) {
   // console.log(url)
   if(url){
   const protocol = /^https?:\/\//i;
   const url1 = protocol.test(url) ? url : `http://${url}`;
   window.open(url1, '_blank');}
   else{
    this._toastrService.Warning('Please enter the URL')
  }
  }


  sendEmail(){
    if(this.frmFamily.get('EmailAddress').value)
      window.location.href = "mailto:" +  this.frmFamily.get('EmailAddress').value;
    else{
      this._toastrService.Warning('Please enter the Email Address')
    }
  }

  onCloseButtonClick() {
    this.dialogRef.close(false);
  }
  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }
  ngOnDestroy() {
    if (this.subStateList$)
      this.subStateList$.unsubscribe();
    if (this.subCountryList$)
      this.subCountryList$.unsubscribe();
  }
  get f() {
    return this.frmFamily.controls;
  }
}
