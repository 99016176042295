export class Lifestory {
}
export class TblUserLifeStory {
    UserLifeStoryId: number;
    UserDetailsId: number;
    Tagline: string;
    VeryShortDescription: string;
    BriefDescription: string;
    LongDescription: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}

export class UserMeOnWebModel {
    UserMeOnWebId: number;
    UserDetailsId: number;
    EntryName: string;
    URL: string;
    Description: string;
    Notes: string;
    DatePosted: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
  }
  
  export class UserMeOnWebLoadDataOutputModel {
    MeOnWebListData: Array<UserMeOnWebModel>;
  }
  