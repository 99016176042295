import { Status } from "app/enums";

export const locale = {
  lang: 'hn',
  data: {
    COMMON: {
      CONTROL_LABEL_EMAIL_ADDRESS: 'ईमेल पता',
      PLACEHOLDER_EMAIL_ADDRESS: 'यहाँ लिखो...',
      PLACEHOLDER_TYPE_HERE: 'यहाँ लिखो...',
      PLACEHOLDER_MOBILE_NUMBER: '०१२३४५६७८९',
      BUTTON_TEXT_SAVE_CHANGES: 'परिवर्तनों को सुरक्षित करें',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS: 'ईमेल की आवश्यकता है!',
      ERROR_MESSAGE_INVALID_EMAIL: 'कृपया अपना ईमेल पता प्रारूप में दर्ज करें:\nyourname@example.com',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'सर्वर से कनेक्ट नहीं हो सका.',
      COMMON_STATUS:
        [
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'उपयोगकर्ता नहीं मिल सका।' }
        ],
    },
    PROFESSIONAL: {
      BUTTON_TEXT_SEARCH_ON_MAP: 'मानचित्र पर खोजें',
      BUTTON_TEXT_ADD_A_PROFESSIONAL: 'एक पेशेवर जोड़ें',
      BUTTON_TEXT_CANCEL: 'रद्द करें',
      CONTROL_LABEL_SPECIALITY: 'स्पेशलिटी',
      CONTROL_LABEL_PROFESSIONAL_NAME: 'पेशावर का नाम',
      CONTROL_LABEL_ADDRESS1: 'पता 1',
      CONTROL_LABEL_ADDRESS2: 'पता 2',
      CONTROL_LABEL_ADDRESS3: 'पता 3',
      CONTROL_LABEL_REGION: 'क्षेत्र',
      CONTROL_LABEL_COUNTRY: 'देश',
      CONTROL_LABEL_ADDRESS_STATE: 'पता राज्य',
      CONTROL_LABEL_ADDRESS_TOWN: 'पता नगर/शहर',
      CONTROL_LABEL_ADDRESS_POSTALCODE: 'पता डाक कोड',
      CONTROL_LABEL_EMAIL_ADDRESS: 'ई-मेल एड्रेस',
      CONTROL_LABEL_CONTACT1: 'संपर्क नंबर 1',
      CONTROL_LABEL_CONTACT2: 'संपर्क नंबर 2',
      CONTROL_LABEL_LAST_EXAM_DATE: 'अंतिम परीक्षा तिथि',
      CONTROL_LABEL_NEXT_EXAM_DATE: 'अगली परीक्षा तिथि',
      CONTROL_LABEL_NOTES: 'टिप्पणियाँ',
      DIALOG_TITLE_ADD_PROFESSIONAL: 'एक पेशेवर जोड़ें',
      ERROR_MESSAGE_REQUIRED_SPECIALITY: 'विशेषज्ञता की आवश्यकता है!',
      ERROR_MESSAGE_REQUIRED_PROFESSIONALNAME: 'पेशेवर नाम की आवश्यकता है!',
      PLACEHOLDER_SELECT_REGION: 'क्षेत्र का चयन करें',
      PLACEHOLDER_SELECT_COUNTRY: 'देश चुनें',
      PLACEHOLDER_SELECT_STATE: 'राज्य चुनें',
      SECTION_TEXT: 'अपनी स्वास्थ्य सेवा टीम के किसी भी सदस्य के बारे में कुछ जानकारी जोड़ें',
      TOOLTIP_TOGGLE_CALENDAR: 'कैलेंडर टॉगल करें',
      TOOLTIP_TEXT_PRACTITIONER_SPECIALTY: 'अपने विशेषज्ञ(ओं) की विशेषज्ञता दर्ज करें',
      TOOLTIP_TEXT_STREET_NAME: 'यह स्थान को सटीकता से मैप करने के लिए एक आवश्यक फ़ील्ड है; कृपया सड़क का नाम सही दर्ज करें',
      TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_1: 'यह और अधिक सटीक स्थान के मैपिंग के लिए एक अतिरिक्त फ़ील्ड है',
      TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_2: 'यह और अधिक सटीक स्थान के मैपिंग के लिए एक अतिरिक्त फ़ील्ड है',
      TOOLTIP_TEXT_TOWN_VILLAGE_CITY: 'पते के गाँव, गाँव या शहर दर्ज करें',
      TOOLTIP_TEXT_POSTAL_ZIPCODE: 'यह पते के देश के संबंधित पोस्टल ज़िपकोड है',
      TOOLTIP_TEXT_REGION_CONTINENT: 'पते का क्षेत्र या महाद्वीप चुनें; उदाहरण के लिए, संयुक्त राज्य यूनाइटेड किंगडम यूरोप में होगा',
      TOOLTIP_TEXT_COUNTRY: 'पते का देश चुनें; उदाहरण के लिए, इंग्लैंड यूरोप में होगा',
      TOOLTIP_TEXT_STATE_PROVINCE: 'पते का राज्य या प्रांत चुनें; उदाहरण के लिए, रोटुमा फिजी में एक राज्य या द्वीप है',
      TOOLTIP_TEXT_PRACTITIONER_EMAIL: 'वैध (ओं) का ईमेल पता दर्ज करें',
      TOOLTIP_TEXT_PRACTITIONER_MOBILE: 'क्षेत्र कोड के साथ मोबाइल नंबर दर्ज करें',
      TOOLTIP_TEXT_LAST_APPOINTMENT_DATE: 'यह आपकी आखिरी नियुक्ति तिथि है',
      TOOLTIP_TEXT_NEXT_APPOINTMENT_DATE: 'यह आपकी अगली नियुक्ति तिथि है',
      TOOLTIP_TEXT_NOTES: 'इस वैधानिक के लिए आपके पास कोई विशेष नोट्स जोड़ें',
      TOOLTIP_TEXT_BUTTON_CLOSE: 'बंद करना',
    }
  }
};
