import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {InsuranceLoadDataOutputModel, TblUserInsurances} from './insurance.model'
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { GlobalService } from 'app/Services/global.service';
import { DeleteInputModel, DownloadFileInputModel } from 'app/Models/output-model';

@Injectable({
  providedIn: 'root'
})
export class AddInsuranceService {
  //insuranceLoadDataOutputModel: InsuranceLoadDataOutputModel
  //public deleteDocumentsInputModel: Array<DeleteInputModel> = [];
  constructor(private _http: HttpClient,private globalService: GlobalService) 
    {
      
    }

    CreateUpdateUserInsurance(userInsurance:TblUserInsurances){
      const path = 'api/UserInsurance/CreateUpdateUserInsurance';
      return this.globalService.PostDataById(userInsurance, path);
      
    }
    GetUserInsuranceLoadData(_data:number){
      const path = 'api/UserInsurance/GetUserInsuranceLoadData';
      return this.globalService.PostDataById(_data, path);
    
    }

    DeleteUserInsuranceDocuments(deleteDocumentsInputModel: Array<DeleteInputModel>){
      const path = 'api/UserInsurance/DeleteUserInsuranceDocuments';
  
      return  this.globalService.PostDataById(deleteDocumentsInputModel, path);
    }

    DownloadUserInsuranceDocuments(downloadFileInputModel: DownloadFileInputModel){
      const path = 'api/UserInsurance/DownloadUserInsuranceDocuments';
  
      return  this.globalService.PostDataById(downloadFileInputModel, path);
    }
}
