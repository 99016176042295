import { Status } from "app/enums";

export const locale = {
  lang: 'en',
  data: {
    COMMON: {
      THANKS_BADGE_SIGNUP: 'Thanks for signing up!',
      TEXT_HEY: 'Hey',
      TEXT_THANKS: 'Thanks,',
      TEXT_NOOSOM_TEAM: 'The Noosom Team',
      TEXT_READ_OUR: 'Read our',
      LINK_TEXT_PRIVACY_POLICY: 'Privacy Policy',
      LINK_TEXT_TERMS_CONDITIONS: 'Terms & Conditions',
      LINK_TEXT_BACK_TO_LOGIN: 'Back to Login',
      PLACEHOLDER_EMAIL: 'Type here...',
      PLACEHOLDER_TYPE_HERE: 'Type here...',
      CONTROL_LABEL_EMAIL: 'Email',
      CONTROL_LABEL_EMAIL_ADDRESS: 'Email Address',
      CONTROL_LABEL_CONFIRM_PASSWORD: 'Confirm Password',
      BUTTON_TEXT_SAVE_CHANGES: 'Save changes',
      TOAST_MESSAGE_SUCCESSFUL_OTP_VERIFIED: 'OTP verified successfully.',
      ERROR_MESSAGE_REQUIRED_EMAIL: 'The email address is required!',
      ERROR_MESSAGE_REQUIRED_PASSWORD: 'The password is required!',
      ERROR_MESSAGE_INVALID_EMAIL: 'Please enter your email address in format:\nyourname@example.com',
      ERROR_MESSAGE_INVALID_PASSWORD: 'Must contain at least one number [0-9] and special character [~`! @#$%^&*()-_+={}[]|\;:"<>,./?], one uppercase [A-Z] and lowercase letter [a-z], and a minimum of 6 or more characters.',
      ERROR_MESSAGE_MISMATCHED_PASSWORD: 'Confirm password must match.',
      ERROR_MESSAGE_REQUIRED_CONFIRMPASSWORD: 'The confirm password is required!',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'Could not connect with server.',
      ERROR_MESSAGE_INVALID_OTP_DATA: 'Enter valid OTP data.',
      ERROR_MESSAGE_INVALID_OTP: 'Enter valid OTP.',
      INVITATION_TEXT:'You have got an invitation to Connect on Noosom from your 0: name.',
      COMMON_STATUS:
        [
          { ErrorCode: Status.InvalidOTP, ErrorMessage: 'Invalid OTP.' },
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'This email address is not registered with any Noosom account.' }
        ],
    },
    SIGNIN: {
      WELCOME_TEXT: 'Welcome to Noosom',
      MOTIVATION_BADGE_SIGNIN: 'Sign-In and start the adventure',
      SIGNIN_AGREEMENT_TEXT: 'By signing in, you agree to our',
      TOOLTIP_TEXT_BUTTON_LOGIN: 'Login',
      CONTROL_LABEL_EMAIL: 'Email Address',
      CONTROL_LABEL_PASSWORD: 'Password',
      CONTROL_LABEL_REMEMBERME: 'Remember Me',
      LINK_TEXT_LABEL_FORGOTPASSWORD: 'Forgot Your Password?',
      LINK_TEXT_LABEL_CREATEACCOUNT: 'Create an Account',
      LABEL_TEXT_VERSION: 'Version',
      LABEL_TEXT_UPDATED_ON: 'Updated on',
      TEXT_NEWONPLATFORM: 'New on our platform?',
      BUTTON_TEXT_LOGIN: 'Login',
      TOAST_MESSAGE_SUCCESSFULLOGINMESSAGE: 'You have successfully logged in as an {0} user to Noosom. Now you can start to explore. Enjoy! 🎉',
      TOAST_MESSAGE_SUCCESSFULLOGINWELCOMETEXT: '👋 Welcome, {0}!',
      ERROR_MESSAGE_NOATTEMPTS: 'Invalid username or password. You have no attempts remaining.',
      LOGIN_STATUS:
        [
          { ErrorCode: Status.UserNotFound, ErrorMessage: '<b>Sorry.</b> The email address that you have entered does not match any account.<br>Check again or sign up for an account.' },
          { ErrorCode: Status.EmailNotVarified, ErrorMessage: 'Your email address is not verified.<br>First verify your email address.' },
          { ErrorCode: Status.AccountLocked, ErrorMessage: 'Your account has been locked.' },
          { ErrorCode: 0, ErrorMessage: 'Logged-in successful.' },
          { ErrorCode: Status.InvalidCredential, ErrorMessage: 'Invalid username or password.<br>You have {0} attempts remaining.' }
        ],
      DIGITS: [1, 2, 3, 4],
      ROLES: [{ title: 'Admin', value: 'Admin' }, { titlle: 'Client', value: 'Client' }, { title: 'User', value: 'User' }]
    },
    SIGNUP:
    {
      MOTIVATION_BADGE_SIGNUP:'Sign-up! It will take less than a minute.',
      STEPPER_TITLE_ACCOUNT:'Account',
      STEPPER_SUB_TITLE_ACCOUNT:'Enter Username',
      STEPPER_TITLE_PERSONAL:'Personal',
      STEPPER_SUB_TITLE_PERSONAL:'Enter Information',
      HEADING_ACCOUNT:'Account Information',
      HEADING_PERSONAL:'Personal Information',
      HEADING_SELECTPLAN:'Select Plan',
      TOOLTIP_TEXT_BUTTON_PREVIOUS:'Previous',
      TOOLTIP_TEXT_BUTTON_NEXT:'Next',
      TOOLTIP_TEXT_BUTTON_SUBMIT:'Submit',
      TOOLTIP_TEXT_BUTTON_CALENDAR:'Toggle Calendar',
      CONTROL_LABEL_USERID:'Your Allocated Noosom Username',
      CONTROL_LABEL_EMAILADDRESS:'Email Address',
      CONTROL_LABEL_PASSWORD:'Password',
      CONTROL_LABEL_CONFIRMPASSWORD:'Confirm Your Password',
      CONTROL_LABEL_REGION:'Your Region',
      CONTROL_LABEL_HOMECOUNTRY:'Your Home Country',
      CONTROL_LABEL_FIRSTNAME:'First Name',
      CONTROL_LABEL_MIDDLENAME:'Middle Name',
      CONTROL_LABEL_LASTNAME:'Last Name',
      CONTROL_LABEL_BIRTHDATE:'Date of Birth',
      CONTROL_LABEL_MOBILENO:'Mobile Number',
      CONTROL_LABEL_SELECTPLAN:'Free forever for now, or buy later.',
      PLACEHOLDER_PASSWORD:'Type here...',
      PLACEHOLDER_REGION:'Select Region',
      PLACEHOLDER_COUNTRY:'Select Country',
      PLACEHOLDER_TELEPHONENUMBER:'0123456789',
      BUTTON_TEXT_PREVIOUS:'Previous',
      BUTTON_TEXT_NEXT:'Next',
      BUTTON_TEXT_SUBMIT:'Submit',
      TOOLTIPTEXT_CONTROL_LABEL_REGION_1:'Your data will be stored',
      TOOLTIPTEXT_CONTROL_LABEL_REGION_2:'in  the selected region',
      TOOLTIPTEXT_CONTROL_LABEL_HOMECOUNTRY:'Select Your Home Country',
      TOOLTIPTEXT_CONTROL_LABEL_USERNAME:'You have been allocated a default username that you can personalize later',
      TOOLTIPTEXT_CONTROL_LABEL_FIRSTNAME:'Enter First Name',
      TOOLTIPTEXT_CONTROL_LABEL_LASTNAME:'Enter Last Name',
      TOAST_MESSAGE_SUCCESSFULSIGNUP:'Account created successfully.',
      ERROR_MESSAGE_REQUIRED_USERID:'The username is required!',
      ERROR_MESSAGE_EXISTED_USERID:'That username has been taken. Please try another.',
      ERROR_MESSAGE_AGEMAXOUT_BIRTHDATE:'That is an invalid Date of Birth for sign up. Your age must be 13 or older to sign up. Please enter a valid Date of Birth.',
      ERROR_MESSAGE_REQUIRED_EMAILADDRESS:'The email address is required!',
      ERROR_MESSAGE_EXISTED_EMAILADDRESS:'That email address has been taken. Please try another.',
      ERROR_MESSAGE_EXISTED_MOBILENUMBER:'That mobile number has been taken. Please try another.',
      ERROR_MESSAGE_MISMATCHED_PASSWORD:'Confirm password must match.',
      ERROR_MESSAGE_REQUIRED_REGION:'The region is required!',
      ERROR_MESSAGE_REQUIRED_HOMECOUNTRY:'The country is required!',
      ERROR_MESSAGE_REQUIRED_FIRSTNAME:'The first name is required!',
      ERROR_MESSAGE_REQUIRED_MIDDLENAME:'The middle name is required!',
      ERROR_MESSAGE_REQUIRED_LASTNAME:'The last name is required!',
      ERROR_MESSAGE_REQUIRED_BIRTHDATE:'The date of birth is required!',
      ERROR_MESSAGE_REQUIRED_MOBILENO:'The mobile number is required!',
      ERROR_MESSAGE_CONTACT_INVALID:'Invalid Contact Number',
      ERROR_MESSAGE_REQUIRED_SELECTPLAN:'Please select the free plan option!',
      ERROR_MESSAGE_MAX_LENGTH_50:'You have typed too many characters, maximum allowed is 50',
      ERROR_MESSAGE_MAX_LENGTH_100:'You have typed too many characters, maximum allowed is 100',
      ERROR_MESSAGE_MAX_LENGTH_12:'You have typed too many characters, maximum allowed is 12',
    },
    SUCCESSFUL_SIGNUP: {
      TEXT_DESCRIPTION_1: 'You will now receive an email for you to confirm your details, you need to do this first.',
      TEXT_DESCRIPTION_2: 'After you have verified your email address you will be able to use the system.',
      TEXT_DESCRIPTION_3: 'Press “Ok Button” to continue.',
      TOOLTIP_TEXT_BUTTON_OKAY: 'Okay',
      BUTTON_TEXT_OKAY: 'Okay'
    },
    VERIFY_EMAIL_ADDRESS: {
      TEXT_DESCRIPTION: 'Please enter the 6 - digit OTP ( One Time Password ) from your email address below to verify your identity.',
      TEXT_DESCRIPTION_NEW:'You have now activated your secure Noosom account, enjoy creating the story of your life using our web system and the free app.',
      BUTTON_TEXT_CONTINUE_WEB:'Continue & Use the Web',
      BUTTON_TEXT_CONTINUE_APP:'Exit & Use the App',
      TOOLTIP_TEXT_BUTTON_VERIFY_AND_LOGIN: 'Verify & Login',
      BUTTON_TEXT_VERIFY_LOGIN: 'Verify & Login',
      TOAST_MESSAGE_SUCCESSFUL_EMAIL_VERIFIED: 'Email Address verified successfully.'
    },
    VERIFY_SIGNIN_OTP: {
      TEXT_DESCRIPTION: 'Please enter the 6 - digit OTP ( One Time Password ) from your email address below to sign in to your Noosom account.',
      TEXT_DESCRIPTION_INVITATION:'Please respond to this invitation to verify your identity and link with your 0 name. Once complete you can privately share information.',
      TOOLTIP_TEXT_BUTTON_VERIFY_OTP: 'Verify OTP',
      BUTTON_TEXT_VERIFY_OTP: 'Verify OTP'
    },
    FORGOT_PASSWORD: {
      HEADING_FORGOT_PASSWORD: 'Forgot Your Password?',
      TEXT_DESCRIPTION: 'Enter your email address and we’ll send you the instructions to reset your password.',
      TOOLTIP_TEXT_BUTTON_SEND_RESET_LINK: 'Send reset link',
      BUTTON_TEXT_SEND_RESET_LINK: 'Send Reset Link',
      TOAST_MESSAGE_SENT_RESET_PASSWORD_LINK: 'Reset password link has been sent to your registered email address.'   //mobile number and 
    },
    RESET_PASSWORD: {
      HEADING_RESET_PASSWORD: 'Reset Your Password.',
      TEXT_DESCRIPTION: 'To make sure account is secure, you’ll be logged out from other devices once you set your new password',
      TOOLTIP_TEXT_BUTTON_SAVE_CHANGES: 'Save changes',
      CONTROL_LABEL_NEW_PASSWORD: 'New Password',
      TOAST_MESSAGE_RESET_PASSWORD: 'Your password has been changed successfully.',
      ERROR_MESSAGE_LINK_EXPIRED: 'This link has been expired, Please try again.'
    }
  }
};
