import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from '@fake-db/fake-db.service';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { ContextMenuModule } from '@ctrl/ngx-rightclick';

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';

import { coreConfig } from 'app/app-config';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
// import { fakeBackendProvider } from 'app/auth/helpers'; // used to create fake backend
 import {  ErrorInterceptor } from 'app/auth/helpers'; //JwtInterceptor,
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { ContextMenuComponent } from 'app/main/extensions/context-menu/context-menu.component';
import { AnimatedCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/animated-custom-context-menu/animated-custom-context-menu.component';
import { BasicCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/basic-custom-context-menu/basic-custom-context-menu.component';
import { SubMenuCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/sub-menu-custom-context-menu/sub-menu-custom-context-menu.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpModule } from '@angular/http';
import { NgxLoadingModule,ngxLoadingAnimationTypes} from "ngx-loading";
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { AgGridModule } from 'ag-grid-angular';
import { DatePipe } from '@angular/common';
import { QuillModule } from 'ngx-quill';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CalendarModule } from 'app/main/apps/calendar/calendar.module';
import { ThemeCustomiserModule } from './layout/components/theme-customiser/theme-customiser.module';
import { JobComponent } from './jobs/job/job.component';
import { CoreMenuModulenew } from './core-menu/core-menu.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { environment } from '../environments/environment';
const appRoutes: Routes = [

  {
    path: 'about-me',
    loadChildren: () => import('./about-me/about-me.module').then(m => m.AboutMeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'shared',
    loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'diary',
    loadChildren: () => import('./diary/diary.module').then(m => m.DiaryModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'Utilities',
    loadChildren: () => import('./import-data/import-data.module').then(m => m.ImportDataModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'My-diary-planner',
    loadChildren: () => import('./planner-events/planner-events.module').then(m => m.PlannerEventsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'quickpost-list',
    loadChildren: () => import('./quickpost-list/quickpost-list.module').then(m => m.QuickpostListModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'jobs',
    loadChildren: () => import('./jobs/jobs.module').then(m => m.JobsModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'my-noosom-profile',
  //   loadChildren: () => import('./my-noosom-profile/my-noosom-profile.module').then(m => m.MyNoosomProfileModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'mylist',
    loadChildren: () => import('app/mylist/mylist.module').then(m => m.MylistModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'user-profile',
    loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'my-image-gallery',
    loadChildren: () => import('./my-image-gallery/my-image-gallery.module').then(m => m.MyImageGalleryModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-settings',
    loadChildren: () => import('./user-settings/user-settings.module').then(m => m.UserSettingsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'filemanager',
    loadChildren: () => import('./filemanager/filemanager.module').then(m => m.FilemanagerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'address',
    loadChildren: () => import('./address/address.module').then(m => m.AddressModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'school',
    loadChildren: () => import('./school/school.module').then(m => m.SchoolModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'paperback',
    loadChildren: () => import('./paperback-view/paperback-view.module').then(m => m.PaperbackViewModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'apps',
    loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
   },
  {
    path: 'experiments',
    loadChildren: () => import('./experiments/experiments.module').then(e=>e.ExperimentsModule)
  },
  {
    path: 'extensions',
    loadChildren: () => import('./main/extensions/extensions.module').then(m => m.ExtensionsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mypastimes',
    loadChildren: () => import('./mypastimes/mypastimes.module').then(m => m.MypastimesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mylifestory',
    loadChildren: () => import('./my-life-story/my-life-story.module').then(m => m.MyLifeStoryModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'me-on-web',
    loadChildren: () => import('./me-on-web/me-on-web.module').then(m => m.MeOnWebModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'legals',
    loadChildren: () => import('./legals/legals.module').then(m => m.LegalsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'diary-view',
    loadChildren: () => import('./diary-view/diary-view.module').then(m => m.DiaryViewModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'map-view',
    loadChildren: () => import('./angular-map/angular-map.module').then(m => m.AngularMapModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'quick-post',
    loadChildren: () => import('./quick-post/quick-post.module').then(m => m.QuickPostModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'jobs',
    loadChildren: () => import('./jobs/jobs.module').then(m => m.JobsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'todo',
    loadChildren: () => import('./to-do-list/to-do-list.module').then(m => m.ToDoListModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'insurance',
    loadChildren: () => import('./insurance/insurance.module').then(m => m.InsuranceModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    //redirectTo: 'home', //'/dashboard/ecommerce',
    redirectTo:'pages/authentication/login-v2',
    pathMatch: 'full'
  },
  {
    path: ':username',
    component: JobComponent
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/coming-soon' //Error 404 - Page not found
  },
  
];
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http,'./assets/i18n/','.json');
  }

@NgModule({
  declarations: [
    AppComponent,
    ContextMenuComponent,
    BasicCustomContextMenuComponent,
    AnimatedCustomContextMenuComponent,
    SubMenuCustomContextMenuComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgSelectModule,
    HttpModule,
    HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true
    }),
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy',
      anchorScrolling: 'enabled'
    }),
    NgbModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot(
      {
        loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]}
        }
    ),
    ContextMenuModule,
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    CardSnippetModule,
    LayoutModule,
    ContentHeaderModule,
    AgGridModule,
    QuillModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxLoadingModule.forRoot({animationType: ngxLoadingAnimationTypes.none}),
    CalendarModule,
    ThemeCustomiserModule,
    CoreMenuModulenew,
  ],
  schemas:[ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    DatePipe,
    //{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide:HTTP_INTERCEPTORS,useClass: ErrorInterceptor, multi: true },

    // ! IMPORTANT: Provider used to create fake backend, comment while using real API
    //fakeBackendProvider
  ],
  entryComponents: [BasicCustomContextMenuComponent, AnimatedCustomContextMenuComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}

