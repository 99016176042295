import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from 'app/Services/global.service';
import {TblUserFamilyMembers,FamilyMembersLoadDataInputModel} from '../about-me-model.model'
@Injectable({
  providedIn: 'root'
})
export class OwnFamilyService {
  public gridRefresh:boolean=false;
  constructor(
    private _http: HttpClient,private globalService: GlobalService
  ) { }
  CreateUpdateUserFamilyMember(userFamily:TblUserFamilyMembers){
    const path='api/UserFamilyMember/CreateUpdateUserFamilyMember';
    return  this.globalService.PostDataById(userFamily , path);
  }
  GetUserFamilyMemberLoadData(_data:number){
    const path= 'api/UserFamilyMember/GetUserFamilyMemberLoadData';
    return  this.globalService.PostDataById(_data , path);
}
GetUserFamilyMembers(familyMembersLoadDataInputModel:FamilyMembersLoadDataInputModel){
  const path= 'api/UserFamilyMember/GetUserFamilyMembers'
  return  this.globalService.PostDataById(familyMembersLoadDataInputModel , path);
}
CreateUpdateUserFamilyMemberunauthorize(userFamily:TblUserFamilyMembers){
  const path='api/UserConnectionRequest/CreateUpdateUserFamilyMember';
  return  this.globalService.PostDataAnonymous(userFamily , path);
}

getGridRefresh():boolean{
  //console.log(this.gridRefresh)
    return this.gridRefresh;
  }

  setGridRefresh(refresh) {
    //console.log('refresh')
    this.gridRefresh = refresh;
    //console.log(this.gridRefresh)
  }
}
