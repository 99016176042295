export class Legalmodel {
}
export class TblUserLegals {
    UserLegalId: number;
    UserDetailsId: number;
    LegalDocumentName: string;
    Notes: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}

export class UserLegalModel {
    UserLegalId: number;
    UserDetailsId: number;
    LegalDocumentName: string;
    Notes: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}

export class LegalLoadDataInputModel {
    UserLegalId: number;
    GetMetaDataYN?: boolean;
    docYN?:boolean;
}

export class LegalDocumentListOutputModel {
    UserLegalDocumentId: number;
    UserDetailsId: number;
    UserLegalId: number;
    DocumentName: string;
    DocumentOldName: string;
    DocumentPath: string;
    FileType: string;
    Size: string;
    SizeInBytes: number;
    DocumentType: string;
    FileCreatedDate: string;
    FileLastModifiedDate: string;
    FileCreatedLatitude: number ;
    FileCreatedLongitude: number ;
    AllFileMetadata: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}

export class LegalLoadDataOutputModel {
    UserLegal: TblUserLegals;
    LegalDocumentList: Array<LegalDocumentListOutputModel>;
}
