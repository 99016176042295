<!-- Main Sidebar -->
<div class="sidebar-wrapper">
  <div class="card-body d-flex justify-content-center" ngbDropdown>
    <!-- <button class="btn btn-primary btn-toggle-sidebar btn-block" (click)="AddEvent()" rippleEffect>
      <span class="align-middle">Add Event</span>
    </button> -->
        <button
          ngbDropdownToggle
          class="btn btn-primary btn-toggle-sidebar btn-block"
          id="dropdownMenuButton"
          rippleEffect
        >
        <span class="align-left">{{'CALENDAR_MAIN.BUTTON_TEXT_ADD_AN_EVENT'|translate}}</span>
        </button>
        <div ngbDropdownMenu class="justify-content-left" aria-labelledby="dropdownMenuButton">
          <a ngbDropdownItem (click)="AddQuickPost()"><span><i data-feather="plus-square"></i></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{DiaryJournal}}</a>
          <!-- <a ngbDropdownItem (click)="AddEvent()" ><span><i data-feather="calendar"></i></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{'CALENDAR_MAIN.CONTROL_LABEL_ADD_AN_EVENT'|translate}}</a> -->
          <!-- <a ngbDropdownItem (click)="onComingSoon()" ><span><i data-feather="upload-cloud"></i></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{'CALENDAR_MAIN.CONTROL_LABEL_IMPORT_DATA'|translate}}</a> -->
        </div>
  </div>
  <div class="card-body pb-0">
    <h5 class="section-label mb-1">
      <span class="align-middle">{{'CALENDAR_MAIN.CONTROL_LABEL_FILTER'|translate}}</span>
    </h5>
    <div class="custom-control custom-checkbox mb-1">
      <input
        type="checkbox"
        class="custom-control-input select-all"
        id="select-all"
        [checked]="checkAll"
        (change)="toggleCheckboxAll($event)"
      />
      <label class="custom-control-label" for="select-all">{{'CALENDAR_MAIN.CONTROL_LABEL_VIEW_ALL'|translate}}</label>
    </div>
    <div class="calendar-events-filter">
      <div
        class="custom-control  custom-checkbox mb-1 custom-control-{{ calendar.color }}"
        *ngFor="let calendar of calendarRef"
      >
        <input
          type="checkbox"
          (change)="checkboxChange($event, calendar.id)"
          class="custom-control-input input-filter"
          id="calendar-{{ calendar.id }}"
          [checked]="calendar.checked"
        />
        <label class="custom-control-label" for="calendar-{{ calendar.id }}">{{ calendar.filter }}</label>
      </div>
    </div>
  </div>
    <div class="card-body d-flex justify-content-center" ngbDropdown>
      <!-- <button class="btn btn-primary btn-toggle-sidebar btn-block" (click)="AddEvent()" rippleEffect>
        <span class="align-middle">Add Event</span>
      </button> -->
      <button  class="btn btn-primary btn-block" [routerLink]="['/quickpost-list']" rippleEffect placement="top"
      ngbTooltip="{{'CALENDAR_MAIN.CONTROL_LABEL_LIST_ALL'| translate}}">{{'CALENDAR_MAIN.CONTROL_LABEL_LIST_ALL'| translate}}
      <!-- btn btn-primary btn-block -->
    </button>
  </div>
  
</div>
<div class="mt-auto">
  <img src="assets/images/pages/calendar-illustration1.png" alt="Calendar illustration" class="img-fluid" style="padding:10px" />
</div>
<!--/ Main Sidebar -->
