import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';

import { SearchService } from 'app/layout/components/navbar/navbar-search/search.service';
import { locale as english } from 'app/layout/components/menu/i18n/en';
import { locale as french } from 'app/layout/components/menu/i18n/fr';
import { locale as german } from 'app/layout/components/menu/i18n/de';
import { locale as portuguese } from 'app/layout/components/menu/i18n/pt';
import { locale as gujarati } from 'app/layout/components/menu/i18n/gj';
import { locale as hindi } from 'app/layout/components/menu/i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { id } from '@swimlane/ngx-datatable';
import { CoreConfigService } from '@core/services/config.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { QuickPostComponent } from 'app/quick-post/quick-post/quick-post.component';
import {QuickpostlistService} from 'app/quickpost-list/quickpostlist.service'
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { Constant } from 'app/Classes/constant';
@Component({
  selector: 'app-navbar-bookmark',
  templateUrl: './navbar-bookmark.component.html'
})
export class NavbarBookmarkComponent implements OnInit {
  // Public
  public bookmarkText = '';
  public openBookmarkRef = false;
  public activeIndex = 0;
  public apiData;
  public pages = [];
  public bookmarkSearchLimit;
  public bookmarkedItems;
  tooltiptranslate;
  private _unsubscribeAll: Subject<any>;
  public coreConfig: any;
  currentAppLanguage: any;
  templcstg = JSON.parse(this._encryptDecryptService.get(Constant.EncrDecrKey, localStorage.getItem('lcstrg'))) 
  // Decorator
  @ViewChild('openBookmark') private _bookmarkElement: ElementRef;
  @HostListener('keydown.escape') fn() {
    this.removeOverlay();
    this.openBookmarkRef = false;
    this.bookmarkText = '';
  }
  @HostListener('document:click', ['$event']) clickout(event) {
    // Close Bookmark if Clicked on Overlay
    if (event.target.className === 'content-overlay') {
      this.removeOverlay();
      this.openBookmarkRef = false;
      this.bookmarkText = '';
    }

    // Close Bookmark if clicked Outside of Container
    if (!(event.target.nodeName === 'INPUT') && this.openBookmarkRef === true) {
      this.removeOverlay();
      this.openBookmarkRef = false;
      this.bookmarkText = '';
    }
  }

  /**
   *
   * @param document
   * @param _searchService
   */
  constructor(@Inject(DOCUMENT) private document, public _searchService: SearchService,private dialog: MatDialog,private quickpostlistService: QuickpostlistService,
  private _coreTranslationService: CoreTranslationService,private _coreConfigService: CoreConfigService,
  private _translateService: TranslateService, private _encryptDecryptService:EncrDecrService) {
    this._unsubscribeAll = new Subject();
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._translateService.onLangChange.subscribe(lang => {
      // console.log('lang');
      // console.log(lang.lang);
      setTimeout(() => {
        let _LangJsonObj = this.GetCurrentLanguageJsonObject(lang.lang);
        if (_LangJsonObj)
          this.currentAppLanguage = _LangJsonObj;
      }, 100);
    })
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Add Bookmark
   *
   * @param id
   */
  addBookmark(id) {
    const index = this.pages.findIndex(object => {
      return object.id === id;
    });
    this.pages[index].isBookmarked = true;

    this.bookmarkedItems.push(this.pages[index]);
  }

  /**
   * Remove Bookmark
   *
   * @param id
   */
  removeBookmark(id) {
    const index = this.bookmarkedItems.findIndex(object => {
      return object.id === id;
    });
    this.bookmarkedItems[index].isBookmarked = false;
    this.bookmarkedItems.splice(index, 1);
  }

  /**
   * Open Bookmark
   */
  openBookmark() {
    this.openBookmarkRef = true;
    this._searchService.onIsBookmarkOpenChange.next(this.openBookmarkRef);
  }

  /**
   * Close Bookmark
   */
  closeBookmark() {
    this.openBookmarkRef = false;
    this._searchService.onIsBookmarkOpenChange.next(this.openBookmarkRef);
  }

  /**
   * Remove Overlay
   */
  removeOverlay() {
    this.document.querySelector('.app-content').classList.remove('show-overlay');
  }

  /**
   * Next Active Match
   */
  nextActiveMatch() {
    this.activeIndex = this.activeIndex < this.bookmarkSearchLimit - 1 ? ++this.activeIndex : this.activeIndex;
  }

  /**
   * Previous Active Match
   */
  prevActiveMatch() {
    this.activeIndex = this.activeIndex > 0 ? --this.activeIndex : 0;
  }

  /**
   * Auto Suggestion
   *
   * @param event
   */
  autoSuggestion(event) {
    if (38 === event.keyCode) {
      return this.prevActiveMatch();
    }
    if (40 === event.keyCode) {
      return this.nextActiveMatch();
    }
  }

  /**
   * Toggle Bookmark
   *
   * @param id
   */
  toggleBookmark(id) {
    const index = this.pages.findIndex(object => {
      return object.id === id;
    });
    if (this.pages[index].isBookmarked === true) {
      this.removeBookmark(id);
    } else {
      this.addBookmark(id);
    }
  }

  /**
   * Toggle Bookmark Popup
   */
  toggleBookmarkPopup() {
    setTimeout(() => {
      if (this.openBookmarkRef === false) {
        this.openBookmark();
      } else {
        this.closeBookmark();
      }
      setTimeout(() => {
        this._bookmarkElement.nativeElement.focus();
      }, 0);
    }, 0);
  }

  convertors(id){
   const indexArray=[
    {id:1,data:this.currentAppLanguage.data.MENU.CALENDAR},
    {id:2,data: this.templcstg?.DiaryOrJournal ? this.templcstg?.DiaryOrJournal == 2 ? this.currentAppLanguage.data.MENU.ADD_JOURNAL_ENTRY
    : this.currentAppLanguage.data.MENU.ADD_DIARY_ENTRY  : this._encryptDecryptService.get(Constant.EncrDecrKey,sessionStorage.getItem('DiaryJournal')) == '2' ? this.currentAppLanguage.data.MENU.ADD_JOURNAL_ENTRY
    : this.currentAppLanguage.data.MENU.ADD_DIARY_ENTRY},
    {id:4,data:this.currentAppLanguage.data.MENU.UPLOAD}  
  ]
  this.tooltiptranslate=indexArray.filter(item => item.id === id)
   return this.tooltiptranslate.map(item => item.data);
  }

  openDialog(id){
    if(id==2){
      setTimeout(() => {
  this.openDialogs(QuickPostComponent, 0)
}, 500);
    }
  }

  openDialogs(component: any, _data: any = []) {
    const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = this.isMobileScreen() ? '100%' : '66%'; 
    // dialogConfig.height = "80%";
    //dialogConfig.position = { top: '2%' };
    dialogConfig.data = _data;
    const dialogRef = this.dialog.open(component, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
       if(data)
      this.quickpostlistService.setGridRefresh(true);
      }
    );
  }
  private isMobileScreen(): boolean {
    return window.innerWidth <= 767; // You can adjust this value as per your requirements
  }

  /**
   * Update Bookmark
   *
   * @param event
   */
  bookmarkUpdate(event) {
    const val = event.target.value.toLowerCase();
    if (val !== '') {
      this.document.querySelector('.app-content').classList.add('show-overlay');
    } else {
      this.document.querySelector('.app-content').classList.remove('show-overlay');
    }
    this.autoSuggestion(event);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);
    this._searchService.onApiDataChange.subscribe(res => {
      this.apiData = res;
      this.pages = this.apiData[0].data;
      this.bookmarkedItems = this.pages.filter(page => page.isBookmarked === true);
      this.bookmarkSearchLimit = this.apiData[0].bookmarkLimit;
    });
    this._searchService.onIsBookmarkOpenChange.subscribe(res => {
      this.openBookmarkRef = res;
    });
  }
  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }
}
