import { Status } from "app/enums";

export const locale = {
  lang: 'hn',
  data: {
    COMMON: {
      CONTROL_LABEL_EMAIL_ADDRESS: 'ईमेल पता',
      PLACEHOLDER_EMAIL_ADDRESS: 'यहाँ लिखो...',
      PLACEHOLDER_TYPE_HERE: 'यहाँ लिखो...',
      PLACEHOLDER_MOBILE_NUMBER: '०१२३४५६७८९',
      BUTTON_TEXT_SAVE_CHANGES: 'परिवर्तनों को सुरक्षित करें',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS: 'ईमेल की आवश्यकता है!',
      ERROR_MESSAGE_INVALID_EMAIL: 'कृपया अपना ईमेल पता प्रारूप में दर्ज करें:\nyourname@example.com',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'सर्वर से कनेक्ट नहीं हो सका.',
      TOOLTIP_TEXT_BUTTON_CLOSE: 'बंद करना',
      COMMON_STATUS:
        [
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'उपयोगकर्ता नहीं मिल सका।' }
        ],
    },
    QUICKPOST: {
      HEADING_LABEL_ADD_A_QUICKPOST: 'जोड़ें',
      HEADING_LABEL_EDIT_A_QUICKPOST: 'संपादित करें',
      HEADING_TEXT: 'प्रविष्टि',
      TAB_HEADING_QUICKPOST: 'त्वरित पोस्ट',
      TAB_HEADING_TRACKERS: 'ट्रैकर',
      TAB_HEADING_UPLOAD: 'डालना',
      TAB_HEADING_IMAGES_AND_VIDEOS: 'चित्र और वीडियो',
      INFO_TEXT: 'अपने सभी डायरी/जर्नल विवरण जोड़ें',
      INFO_TEXT3: 'यहाँ अपनी मूड चुनें और क्षण को मूल्यांकित करें',
      INFO_TEXT1: 'कुछ तस्वीरें और वीडियो का चयन करें। आप उन्हें एक बार में अपलोड कर सकते हैं या एक साथ Upload All चुन सकते हैं।',
      INFO_TEXT2: 'आपकी सभी अपलोड की गई तस्वीरें और वीडियो यहां प्रदर्शित की जाएगी।',
      INFO_TEXT4: 'पेपरबैक दृश्य को हाथ से लिखी डायरी की तरह दिखने के लिए डिज़ाइन किया गया है, कोई छवियाँ नहीं, केवल पोस्ट की तारीख, समय, स्थान और आपके अपने नोट्स।',
      CONTROL_LABEL_TITLE: 'शीर्षक',
      CONTROL_LABEL_DIARY_DATE: 'की तारीख',
      CONTROL_LABEL_DIARY_TIME_FROM: 'समय से',
      CONTROL_LABEL_DIARY_TIME_TO: 'समय तक',
      CONTROL_LABEL_TO_DATE: 'समाप्ति दिनांक',
      CONTROL_LABEL_FROM_DATE: 'प्रारंभ दिनांक',
      CONTROL_LABEL_MONTH: 'माह',
      CONTROL_LABEL_DATE_RANGE: 'तिथि सीमा',
      CONTROL_LABEL_SEARCH: 'खोजें',
      CONTROL_LABEL_LOCATION: 'स्थान',
      CONTROL_LABEL_POST_TYPE: 'विज्ञापन प्रकार',
      CONTROL_LABEL_EVENT: 'प्रसंग',
      CONTROL_LABEL_TAG_CATEGORY: 'टैग श्रेणी',
      CONTROL_LABEL_POST_CONTENT: 'पोस्ट सामग्री',
      TOOLTIP_TEXT_BUTTON_CALENDAR: 'कैलेंडर',
      TOOLTIP_TEXT_BUTTON_LOCATION: 'स्थान',
      TOOLTIP_TEXT_BUTTON_SEARCH_ON_MAP: 'स्थान यहाँ खोजें',
      TOOLTIP_TEXT_ADD_A_QUICKPOST: 'जोड़ें',
      TOOLTIP_TEXT_BUTTON_EDIT: 'संपादित करें',
      TOOLTIP_TEXT_TEXT_STYLE: 'पाठ शैली',
      TOOLTIP_TEXT_TEXT_SIZE: 'टेक्स्ट का साइज़',
      TOOLTIP_TEXT_TEXT_COLOUR: 'पाठ का रंग',
      TOOLTIP_TEXT_TEXT_ALIGNMENT: 'पाठ्य संरेखण',
      TOOLTIP_TEXT_BOLD: 'बोल्ड',
      TOOLTIP_TEXT_ITALIC: 'तिरछा',
      TOOLTIP_TEXT_UNDERLINE: 'रेखांकन',
      TOOLTIP_TEXT_LINK: 'जोड़ना',
      TOOLTIP_TEXT_BULLET_LIST: 'गोली सूची',
      TOOLTIP_TEXT_NUMBERED_LIST: 'क्रमांकित सूची',
      TOOLTIP_TEXT_CLEAR_FORMAT: 'संरूपण साफ करना',
      TOOLTIP_TEXT_DIARY_DESCRIPTION: 'आपकी डायरी / जर्नल प्रविष्टि का एक छोटा विवरण या शीर्षक',
      TOOLTIP_TEXT_DIARY_DATE_CREATED: 'यह फ़ील्ड डायरी प्रविष्टि की तारीख है जब बनाई गई थी',
      TOOLTIP_TEXT_DIARY_START_TIME: 'यह फ़ील्ड डायरी प्रविष्टि का प्रारंभ समय दिखाता है जो बनाया गया था',
      TOOLTIP_TEXT_DIARY_END_TIME: 'यह फ़ील्ड डायरी प्रविष्टि का समाप्त समय दिखाता है जो बनाया गया था',
      TOOLTIP_TEXT_SEARCH_MAP_BUTTON: 'मानचित्र पर खोज करने और अपनी डायरी प्रविष्टि के स्थान का चयन करने के लिए खोज पर क्लिक करें। आप पिन का उपयोग करके स्थान को समायोजित कर सकेंगे',
      TOOLTIP_TEXT_DEFAULT_PRIVACY_SETTING: 'निजी हमेशा डिफ़ॉल्ट होगा। जब आप अपग्रेड करेंगे तो आपको दोस्तों, परिवार या सार्वजनिक के साथ साझा करने की क्षमता होगी',
      TOOLTIP_TEXT_DIARY_EVENT_LINKING: 'आप अपने डायरी पोस्ट को विशिष्ट घटनाओं से लिंक कर सकते हैं जैसे कि जब आप यात्रा कर रहे हों, दोस्तों या परिवार के साथ रात का खाना कर रहे हों आदि...',
      TOOLTIP_TEXT_TAG_CODE_CATEGORY: 'टैग कोड श्रेणी आपके खुद के विशिष्ट शब्द हैं जिन्हें आप नूसोम वेब सिस्टम में खोजने के लिए उपयोग कर सकते हैं',
      TOOLTIP_TEXT_DIARY_DESCRIPTION_EDITOR: 'संपादक का उपयोग करके अपनी डायरी / जर्नल प्रविष्टि का विवरण दर्ज करें',
      PLACEHOLDER_DATE: 'dd/mm/yyyy',
      PLACEHOLDER_SEARCH_LOCATION_HERE: 'स्थान यहाँ खोजें',
      PLACEHOLDER_SELECT_EVENT: 'इवेंट चुनें',
      PLACEHOLDER_SELECT_POST_TYPE: 'पोस्ट प्रकार का चयन करें',
      PLACEHOLDER_ADD_TAG: 'टैग जोड़ो',
      PLACEHOLDER_TYPE_HERE: 'यहाँ लिखो...',
      PLACEHOLDER_ADD_SELECT_TAG_HERE: 'यहाँ टैग जोड़ें/चयन करें...',
      SWEET_ALERT_TEXT: "आप इसे पूर्ववत नहीं कर पाएंगे!",
      SWEET_ALERT_CONFIRM_BUTTON_TEXT: 'हाँ, मिटा दो!',
      SWEET_ALERT_CANCEL_BUTTON_TEXT: 'रद्द करना',
      SWEET_ALERT_TEXT_DELETED: 'हटा दिया!',
      SWEET_ALERT_CONFIRM_BUTTON_TEXT_OKAY: 'ओके',
      SWEET_ALERT_TITLE_QUICKPOST_TEXT: 'क्या आप सुनिश्चित हैं, कि आप इस त्वरित पोस्ट और इससे संबद्ध सभी अनुलग्नकों को हटाना चाहते हैं?',
      SWEET_ALERT_TITLE_QUICKPOST_IMAGE: 'क्या आप वाकई इस त्वरित पोस्ट दस्तावेज़ को हटाना चाहते हैं?',
      SWEET_ALERT_DELETE_TEXT: 'हटा दिया गया!',
      BUTTON_TEXT_SEARCH_ON_MAP: 'स्थान यहाँ खोजें',
      BUTTON_TEXT_ADD_A_QUICKPOST: 'जोड़ें',
      BUTTON_TEXT_SAVE_CHANGES: 'परिवर्तनों को सुरक्षित करें',
      BUTTON_TEXT_DELETE: 'मिटाना',
      BUTTON_TEXT_CANCEL: 'रद्द करें',
      BUTTON_TEXT_SAVE_AND_CLOSE: 'सहेजें और बंद करें',
      BUTTON_TEXT_SAVE_AND_NEXT: 'सहेजें और अगला',
      BUTTON_TEXT_ABANDON: 'छोड़ देना',
      HEADING_TEXT_DIARY: 'डायरी',
      HEADING_TEXT_JOURNAL: 'जर्नल',
      HEADING_TEXT_PAPERBACKVIEW: 'पेपरबैक दृश्य',
      TOAST_MESSAGE_QUICKPOST_ADDED_SUCCESSFULLY: 'त्वरित पोस्ट सफलतापूर्वक जोड़ा गया',
      TOAST_MESSAGE_QUICKPOST_UPDATED_SUCCESSFULLY: 'त्वरित पोस्ट सफलतापूर्वक अपडेट किया गया',
      WARNING_MESSAGE_SELECT_LOCATION: 'कृपया मानचित्र पर स्थान का चयन करें।',
      ERROR_MESSAGE_REQUIRED_TITLE: 'शीर्षक आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_DIARY_DATE: 'डायरी तिथि आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_DIARY_TIME: 'डायरी का समय आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_LOCATION: 'स्थान आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_POST_TYPE: 'पोस्ट प्रकार आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_POST_CONTENT: 'पोस्ट सामग्री आवश्यक है!',
      ERROR_MESSAGE_MAX_LENGTH_100: 'आपने बहुत अधिक वर्ण टाइप किए हैं, अधिकतम अनुमत 100 है',
      ERROR_MESSAGE_MAX_LENGTH_1000: 'आपने बहुत अधिक वर्ण टाइप किए हैं, अधिकतम अनुमत 1000 है',
    },
    TRACKERS: {
      HOW_IS_YOUR_MOOD_TEXT: 'इस समय आपका मूड कैसा है?',
      HOW_WOULD_YOU_RATE_TEXT: 'आप इस क्षण का मूल्यांकन कैसे करेंगे?',
      MOOD_TEXT_HAPPY: 'खुश',
      MOOD_TEXT_SAD: 'उदास',
      MOOD_TEXT_ANGRY: 'नाराज़',
      MOOD_TEXT_CRYING: 'रोना',
      MOOD_TEXT_MEH: 'हुंह',
      MOOD_TEXT_EXCITED: 'उत्तेजित',
      MOOD_TEXT_SLEEPY: 'सुस्त',
      MOOD_TEXT_LONELY: 'अकेला',
    },
    UPLOAD: {
      BUTTON_TEXT_UPLOAD_ALL: 'सभी अपलोड करें',
      BUTTON_TEXT_PAUSE_ALL: 'सभी को रोकें',
      BUTTON_TEXT_DOWNLOAD_ALL: 'सभी डाउनलोड',
      BUTTON_TEXT_REMOVE_ALL: 'सभी हटाएं',
      CONTROL_LABEL_MULTIPLE: 'विभिन्न',
      COLUMN_HEADING_NAME: 'नाम',
      COLUMN_HEADING_SIZE: 'आकार',
      COLUMN_HEADING_PROGRESS: 'प्रगति',
      COLUMN_HEADING_STATUS: 'स्थति',
      COLUMN_HEADING_DATE_CREATED: 'निर्माण की तिथि',
      COLUMN_HEADING_TYPE: 'प्रकार',
      COLUMN_HEADING_ACTIONS: 'कार्रवाई',
      HEADING_TEXT_SELECT_IMAGES: 'छवियों और वीडियो का चयन करें',
      HEADING_TEXT_UPLOAD_QUEUE: 'अपलोड कतार',
      HEADING_TEXT_QUEUE_PROGRESS: 'कतार प्रगति',
      PLACEHOLDER_CHOOSE_FILES: 'फ़ाइलों का चयन करें',
      TEXT_FILE_TYPES: 'अनुमत फ़ाइल प्रकार:',
      TEXT_QUEUE_LENGTH: 'कतार की लंबाई:',
      TEXT_MB: 'MB',
    },
    TODO: {
      LABEL_ADD_TASK: 'कार्य जोड़ें',
      LABEL_ADD_A_TASK: 'एक कार्य जोड़ें',
      LABEL_TITLE: 'शीर्षक',
      LABEL_DUE_DATE: 'नियत तारीख',
      LABEL_DUE_TIME: 'नियत समय',
      LABEL_PRIORITY: 'प्राथमिकता',
      LABEL_DESCRIPTION: 'विवरण',
      LABEL_EDIT_TASK: 'कार्य संपादित करें',
      LABEL_BUTTON_COMPLETE: 'पूर्ण',
      LABEL_BUTTON_MARK_COMPLETE: 'पूर्ण के रूप में चिह्नित करें',
      LABEL_BUTTON_MARK_INCOMPLETE: 'अपूर्ण के रूप में चिह्नित करें',
      LABEL_BUTTON_MARK_IMPORTANT: 'महत्वपूर्ण के रूप में चिह्नित करें',
      LABEL_BUTTON_MARK_UNIMPORTANT: 'महत्वहीन के रूप में चिह्नित करें',
      ERROR_MESSAGE_TITLE_REQUIRED: 'शीर्षक आवश्यक है!',
      ERROR_MESSAGE_PRIORITY_REQUIRED: 'प्राथमिकता आवश्यक है!',
      ERROR_MESSAGE_DUE_TIME_REQUIRED: 'नियत समय आवश्यक है!',
      ERROR_MESSAGE_DUE_DATE_REQUIRED: 'नियत तारीख आवश्यक है!',
      ERROR_MESSAGE_DESCRIPTION_REQUIRED: 'विवरण आवश्यक है!',
      ERROR_MESSAGE_MAX_50: 'आपने बहुत अधिक अक्षर टाइप किए हैं, अधिकतम अनुमति 50 है'

    }
  }
};
