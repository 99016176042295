import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/messaging';  // Import Firebase messaging
import { messaging } from '../firebase-config'; // Import messaging from the config file
import { BehaviorSubject } from 'rxjs';
import { LoadingScreenService } from './loading-screen.service';


@Injectable({
  providedIn: 'root'
})
export class MessagingService {
   messageSubject = new BehaviorSubject<any>(null); // Store the latest message
  currentMessage = this.messageSubject.asObservable(); // Observable to subscribe to
  constructor(
    private loadingScreenService: LoadingScreenService,
  ) {
    //this.listenForServiceWorkerMessages(); // Listen
    this.receiveMessage();
   }

  requestPermission() {
    return messaging.requestPermission()
      .then(() => {
      //  console.log('Notification permission granted.');
        return messaging.getToken();
      })
      .then((token) => {
      //  console.log('FCM Token:', token);
        // Send the token to your server for later use
        return token;
      })
      .catch((error) => {
        this.loadingScreenService.stopLoading();
     //   console.error('Unable to get permission to notify.', error);
      });
  }

  // Listen for incoming messages
  receiveMessage() {
 //   console.log(messaging)
    messaging.onMessage((payload) => {
      console.log('Message received. ', payload);
      this.messageSubject.next(payload);
      // Handle the message payload, such as showing a notification or alert
    });
  }

   listenForServiceWorkerMessages() {
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data && event.data.type === 'notification') {
        console.log('Notification from SW:', event.data.payload);
        this.messageSubject.next(event.data.payload); // Update the BehaviorSubject with the notification payload
      }
    });
  }
  
}