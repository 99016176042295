import { Injectable } from '@angular/core';
import { GlobalService } from 'app/Services/global.service';

@Injectable({
  providedIn: 'root'
})
export class LifeStoryService {

  constructor(private globalService:GlobalService) { }

  GetUserLifeStoryData(data) {  //UserDetailsId
    const path = 'api/UserLifeStory/GetUserLifeStoryData';
    return this.globalService.PostDataById(data, path);
  }
  CreateUpdateUserLifeStory(data) {  //UserDetailsId
    const path = 'api/UserLifeStory/CreateUpdateUserLifeStory';
    return this.globalService.PostDataById(data, path);
  }
  GetMeOnWebListData(data) {  //UserDetailsId
    const path = 'api/MeOnWebList/GetMeOnWebListData';
    return this.globalService.PostDataById(data, path);
  }
  CreateUpdateUserMeOnWeb(data) {  //UserDetailsId
    const path = 'api/MeOnWebList/CreateUpdateUserMeOnWeb';
    return this.globalService.PostDataById(data, path);
  }
  GetMeOnWebById(data) {  //UserDetailsId
    const path = 'api/MeOnWebList/GetMeOnWebById';
    return this.globalService.PostDataById(data, path);
  }
  DeleteUserMeOnWeb(data) {  //UserDetailsId
    const path = 'api/MeOnWebList/DeleteUserMeOnWeb';
    return this.globalService.PostDataById(data, path);
  }
}
