import { AfterViewInit, Attribute, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { locale as english } from 'app/CustomComponents/grid-view/i18n/en';
import { locale as french } from 'app/CustomComponents/grid-view/i18n/fr';
import { locale as german } from 'app/CustomComponents/grid-view/i18n/de';
import { locale as portuguese } from 'app/CustomComponents/grid-view/i18n/pt';
import { locale as gujarati } from 'app/CustomComponents/grid-view/i18n/gj';
import { locale as hindi } from 'app/CustomComponents/grid-view/i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
@Component({
  selector: 'app-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextBoxComponent,
      multi: true
    }
  ]
})
export class TextBoxComponent implements OnInit, ControlValueAccessor {

  @ViewChild('textBox', { read: ElementRef }) textBox: ElementRef;
  public passwordTextType: boolean;

  @Input() for: string = "";
  @Input() label: string = "Default label";
  //@Input() characterCount = "";
  @Input() maxChars = "";
  @Input() maxlength: number; 
  @Input() type: string = "text";
  @Input() formControlName: string = "";
  @Input() placeholder: string = "";
  @Input() showEyeIcon: boolean = false;
  @Input() classes: string = "";
  @Input() textBoxLinkLabel: string = "";
  @Input() textBoxLinkURL: string = "";
  @Input() required: boolean = false;
  @Input() showRequiredSign: boolean = false;
  @Input() applyMargin: boolean = false;
  @Input() tooltipInfo: string = "";
  @Input() position:string = "";
  @Input() showInfoIcon:boolean = false;
  @Input() tabindex: string = "";
  @Output() OnFocusOut: EventEmitter<any> = new EventEmitter();
  //value!: string;
  private _value: string = '';
  public readonly: string = "";
  public autocomplete: string = "off";
  public autofocus: string = "";
  public aria_describedby: string = "";
  public for_lbl: string = "";
  OnChange: (val: string) => void;
  characterCount: number = 0;

  constructor(
    // @Attribute('tabindex') tabindex: string = "0",
    @Attribute('readonly') readonly: string = "",
    @Attribute('autocomplete') autocomplete: string = "",
    // @Attribute('autofocus') autofocus:string="",
    @Attribute('aria-describedby') aria_describedby: string = "",
    @Attribute('for') for_lbl: string = "",
    private _coreTranslationService: CoreTranslationService,
  ) {
    // this.tabindex = tabindex;
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this.readonly = readonly;
    this.autocomplete = autocomplete;
    // this.autofocus=autofocus;
    this.aria_describedby = aria_describedby,
      this.for_lbl = for_lbl
  }
  @Input()
  get value(): string {
    return this._value;
  }

  set value(val: string) {
    this._value = val;
    this.updateCharacterCount(val);
  }


  updateCharacterCount(value: string) {
   // console.log(this.maxlength)
    this.characterCount = value ? value.length : 0;
  }
  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.OnChange = fn;
  }
  registerOnTouched(fn: any): void {

  }
  setDisabledState?(isDisabled: boolean): void {

  }

  ngOnInit(): void {
  }
  togglePasswordTextType() {
    if (this.type == "password") {
      this.passwordTextType = !this.passwordTextType;
    }
  }
  emitFocusOutEvent(event) {
    this.OnFocusOut.emit(event);
  }
  // updateCharacterCount(text): void {
  //   console.log(text)
  //   // Update the character count with the length of the input text
  //   this.characterCount = text.length;}

}

