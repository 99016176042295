import { Injectable } from '@angular/core';
import { GlobalService } from 'app/Services/global.service';
import { GetLatestQuickPostInputModel, GetQuickPostListInputModel } from 'app/home/home-model.model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuickPostService {
  tracker: any = 0;
  rating: any = 0;
  constructor(private globalService: GlobalService) { }

  getTracker(): Observable<any> {
    return of(this.tracker);
  }


  setTracker(moodId) {
    this.tracker = moodId;
  }

  getRating(): Observable<any> {
    return of(this.rating);
  }


  setRating(Id) {
    this.rating = Id;
  }
  GetUserQuickPostList(_data: GetQuickPostListInputModel) {
    const path = 'api/UserQuickPost/GetUserQuickPostList'
    return this.globalService.PostDataById(_data, path);
  }

  GetUserQuickPostLoadData(_data) {
    const path = 'api/UserQuickPost/GetUserQuickPostLoadData'
    return this.globalService.PostDataById(_data, path);
  }

  GetLatestQuickPostByCreatedDateAndTime(_data: GetLatestQuickPostInputModel) {
    const path = 'api/Home/GetLatestQuickPostByCreatedDateAndTime'
    return this.globalService.PostDataById(_data, path);
  }

  GetUserConnectionRequests(data) {
    const path = 'api/UserConnectionRequest/GetUserConnectionRequestsBothSide';
    return this.globalService.PostDataById(data, path);
  }

  CreateUpdateUserQuickPostShare(data){
    const path = 'api/UserQuickPost/CreateUpdateUserQuickPostShare';
    return this.globalService.PostDataById(data,path);
  }
  
  GetUserQuickPostSharingById(data){
    const path = 'api/UserQuickPost/GetUserQuickPostSharingById';
    return this.globalService.PostDataById(data,path);
  } 

}
