import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {NoosomPlans,NoosomCategoryID} from 'app/enums';
import { AuthenticationService } from 'app/auth/service';
import { stat } from 'fs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService) { }

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this._authenticationService.currentUserValue;
    if (currentUser) {
      // check if route is restricted by role
     // console.log(route,currentUser)
      if (route.data.roles && route.data.roles.indexOf(currentUser.Role) === -1) {
        // role not authorised so redirect to not-authorized page
        this._router.navigate(['/pages/miscellaneous/not-authorized']);
        return false;
      }
      else if((state.url=="/mylist/awards")||
      (state.url=="/mylist/diaryEntries")||
      (state.url=="/mylist/friends")||
      (state.url=="/mylist/families")||
      (state.url=="/mylist/jobs")||
      (state.url=="/mylist/achievement")||
      (state.url=="/diary-view")||
      (state.url=="/mypastimes")||
      (state.url=="/mylifestory")||
      (state.url=="/me-on-web")||
      (state.url=="/apps/todo/all")||
      (state.url=="/paperback")||
      (state.url=="/legals")||
      (state.url=="/map-view")||
      (state.url=="/blog")){
        if(this._authenticationService.currentUserValue.CurrentNoosomPlanId==NoosomPlans.Premium_1 || this._authenticationService.currentUserValue.NoosomCategoryEnumId==NoosomCategoryID.Test){
         return true;
        } 
        else{  
        this._router.navigate(['/pages/miscellaneous/not-authorized']);}
                //this._authenticationService.logout();
        return false;
      }
      else if((state.url == '/shared')){
        if(this._authenticationService.currentUserValue.CurrentNoosomPlanId==NoosomPlans.Noosom_Free_Forever && this._authenticationService.currentUserValue.NoosomCategoryEnumId==NoosomCategoryID.User){
          return true;
         } 
         else{  
         this._router.navigate(['/pages/miscellaneous/not-authorized']);}
                 //this._authenticationService.logout();
         return false;
      }
      // else if((state.url=="/jobs")){
      //   this._router.navigate(['/pages/miscellaneous/not-authorized']);
      //   return false;
      // }

      // authorised so return true
      return true;
    }
    else if(state.url=="/diary"){
      return true;
    }

    // not logged in so redirect to login page with the return url
    this._router.navigate(['/pages/authentication/login-v2'], { queryParams: { returnUrl: state.url } });
    //this._router.navigate(['/pages/authentication/login-v2']);
    return false;
  }
}
