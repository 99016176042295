import { Injectable } from '@angular/core';
import { GlobalService } from 'app/Services/global.service';
import { GetRandomUserQuickPostDocumentInputModel } from '../home-model.model';
@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private globalService: GlobalService) { }

  GetUserQuickPostLoadData(data:number){
    const path = 'api/UserQuickPost/GetUserQuickPostLoadData';
    return this.globalService.PostDataById(data,path);
  }
  GetNoosomCouponCodeList(data){
    const path = 'api/Noosom/GetNoosomCouponCodeList';
    return this.globalService.PostDataById(data,path);
  }

  GetUserDetails(data){
    const path = 'api/Account/GetUserDetails';
    return this.globalService.PostDataById(data,path);
  }
}
