import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
import { locale as german } from '../i18n/de';
import { locale as portuguese } from '../i18n/pt';
import { locale as gujarati } from '../i18n/gj';
import { locale as hindi } from '../i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { CoreConfigService } from '@core/services/config.service';
import { takeUntil } from 'rxjs/operators';
import { AddressesModel, TblUserAddresses } from '../address-model.model';
import { E } from 'assets/extensions/extensions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MapModalType } from 'app/enums';
import { MapAddressDataModel, MapAddressOutputModel } from './map-address-data-model.model';
import { Constant } from 'app/Classes/constant';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { GoogleMap } from '@angular/google-maps';
import { GlobalService } from 'app/Services/global.service';
@Component({
  selector: 'app-map-address',
  templateUrl: './map-address.component.html',
  styleUrls: ['./map-address.component.scss']
})
export class MapAddressComponent implements OnInit {
  @ViewChild('SearchOnMapTextBox', { read: ElementRef }) txtSearchOnMap: ElementRef;
  @ViewChild('map') map: GoogleMap;
  public loading = false;
  currentAppLanguage: any;
  public coreConfig: any;
  private _unsubscribeAll: Subject<any>;
  public error = '';
  public IsDraggable: boolean = false;
  public IsSaveButtonVisible: boolean = false;
  public IsSearchTextVisible: boolean = false;
  public IsSearchOnMap: boolean = false;
  public SearchStringForMap: string = '';
  public mapModalType: MapModalType = MapModalType.SearchString;
  public Lat: number;
  public Long: number;
  public dialogTitle: string;
  public frmMapComponent: FormGroup;
  infoWindowContent
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: true,
    disableDefaultUI: true,
    zoom: 18,
    // disableDoubleClickZoom: true,
    // maxZoom: 15,
    // minZoom: 8,
  };
 
  polylinePaths: google.maps.LatLngLiteral[] = [
    // { lat: 37.7749, lng: -122.4194 },
    // { lat: 37.7849, lng: -122.4094 },
    // // more points here
  ];
  polylinePaths2: google.maps.LatLngLiteral[] = [
    // { lat: 37.7749, lng: -122.4194 },
    // { lat: 37.7849, lng: -122.4094 },
    // // more points here
  ];
  polylineOptions: google.maps.Polyline | null = null;
  // markers: Array<google.maps.MarkerOptions> = [];
  markers:google.maps.MarkerOptions
  userAddresses: Array<AddressesModel> = [];  //Using Same for School
  dateformat: any;
  constructor(
    private dialogRef: MatDialogRef<MapAddressComponent>,
    private _coreTranslationService: CoreTranslationService,
    private _toastrService: CustomToastrService,
    private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private globalService: GlobalService,
    @Inject(MAT_DIALOG_DATA) public data: MapAddressDataModel
  ) {
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._unsubscribeAll = new Subject();
    if (this.data) {
      // console.log('mapModalType');
      // console.log(this.data.mapModalType);
      //  console.log(this.data.Zoom);
      this.options.zoom = this.data.Zoom;
      this.mapModalType = this.data.mapModalType;
      if (typeof this.data.Data === 'string' && this.mapModalType == MapModalType.SearchString)  //Search On Map
        this.SearchStringForMap = E.ConvertToString(this.data.Data);
      else if (this.mapModalType == MapModalType.AddressModel || this.mapModalType == MapModalType.SchoolModel || this.mapModalType == MapModalType.QuickPostModel) {
        this.userAddresses = this.data.Data;
        if (this.userAddresses && this.userAddresses.length > 1)
          this.dialogTitle = "View all Addresses on a Map";
      }
      else if (this.mapModalType == MapModalType.LatLong) {
        let _date = this.data.Data as MapAddressOutputModel;
        this.Lat = _date.lat;
        this.Long = _date.long;
        this.SearchStringForMap = _date.searchstring;
      }
    }
    this.dateformat=this.globalService.getDateFormat()
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      if (this.txtSearchOnMap)
        this.txtSearchOnMap.nativeElement.focus();

    }, 500);
  }

  ngOnInit(): void {
   // this.markers = [];
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      if (this.coreConfig.layout.skin == 'dark') {
        this.options = {
          zoomControl: true,
          scrollwheel: true,
          disableDefaultUI: true,
          zoom: 18,
          styles: [
            { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
            { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
            { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
            {
              featureType: "administrative.locality",
              elementType: "labels.text.fill",
              stylers: [{ color: "#d59563" }],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [{ color: "#d59563" }],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [{ color: "#263c3f" }],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [{ color: "#6b9a76" }],
            },
            {
              featureType: "road",
              elementType: "geometry",
              stylers: [{ color: "#38414e" }],
            },
            {
              featureType: "road",
              elementType: "geometry.stroke",
              stylers: [{ color: "#212a37" }],
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [{ color: "#9ca5b3" }],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [{ color: "#746855" }],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.stroke",
              stylers: [{ color: "#1f2835" }],
            },
            {
              featureType: "road.highway",
              elementType: "labels.text.fill",
              stylers: [{ color: "#f3d19c" }],
            },
            {
              featureType: "transit",
              elementType: "geometry",
              stylers: [{ color: "#2f3948" }],
            },
            {
              featureType: "transit.station",
              elementType: "labels.text.fill",
              stylers: [{ color: "#d59563" }],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [{ color: "#17263c" }],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [{ color: "#515c6d" }],
            },
            {
              featureType: "water",
              elementType: "labels.text.stroke",
              stylers: [{ color: "#17263c" }],
            },
          ]
        }
      } else if (this.coreConfig.layout.skin == 'default') {
        this.options = {zoomControl: true,
          scrollwheel: true,
          disableDefaultUI: true,
          zoom: 18, styles: [] }
      }
    });
    this.frmMapComponent = this._formBuilder.group({
      SearchField: [this.SearchStringForMap, Validators.required],
    });
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);
    if (this.data.mapModalType == MapModalType.QuickPostModel) {
      this.dialogTitle = this.currentAppLanguage.data.MAP.DIALOG_TITLE_VIEW_ADDRESS_ON_MAP;
    }
    if (this.data.mapModalType == MapModalType.AddressModel) {
      this.dialogTitle = this.currentAppLanguage.data.MAP.DIALOG_TITLE_VIEW_ON_MAP;
    }
    if (this.data.mapModalType == MapModalType.SchoolModel) {
      this.dialogTitle = this.currentAppLanguage.data.MAP.DIALOG_TITLE_VIEW_SCHOOL_ON_MAP;
    }
    if (this.IsSearchOnMap) {
      this.dialogTitle = this.currentAppLanguage.data.MAP.DIALOG_TITLE_SEARCH_ON_MAP;
    }
    if (this.userAddresses && this.userAddresses.length > 0) {
      let indexi=0
      setTimeout(() => {
        this.userAddresses.forEach(element => {
          if (element.Latitude != null && element.Longitude != null) {
            indexi++;
            this.center = {
              lat: element.Latitude,
              lng: element.Longitude,
            };
            const getRandomContrastColor = (baseColor: string): string => {
              const contrastThreshold = 4.5; // Minimum contrast ratio recommended by WCAG
              const baseLuminance = getLuminance(baseColor);
              let newColor: string;
              do {
                  newColor = getRandomColor();
              } while (getContrastRatio(newColor, '#FFFFFF') < contrastThreshold * baseLuminance);
              return newColor;
          };
          
          const getRandomColor = (): string => {
              return '#' + Math.floor(Math.random() * 16777215).toString(16); // Random hex color
          };
          
          const getLuminance = (color: string): number => {
              const rgb = parseInt(color.slice(1), 16); // Convert hex to RGB
              const r = (rgb >> 16) & 0xff;
              const g = (rgb >>  8) & 0xff;
              const b = (rgb >>  0) & 0xff;
              const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255; // Calculate luminance according to sRGB
              return luminance;
          };
          
          const getContrastRatio = (color1: string, color2: string): number => {
              const luminance1 = getLuminance(color1);
              const luminance2 = getLuminance(color2);
              const contrast = (luminance1 + 0.05) / (luminance2 + 0.05); // Add small offset to avoid division by zero
              return Math.max(contrast, 1 / contrast);
          };
          
          // Usage:
          const iconColor = getRandomContrastColor('#FFFFFF'); // Generate contrast color
            this.markers =(
              {
                position: { lat: element.Latitude, lng: element.Longitude },
                draggable: this.IsDraggable,
                label: {
                  text: (indexi ).toString(),
                  color: '#FFFFFF',
                  fontSize: '14px',
                  fontWeight: 'bold',
                },
                icon:{
                  path: google.maps.SymbolPath.CIRCLE,
                  fillColor: iconColor,
                  fillOpacity: 1,
                  scale: 15,
                  strokeWeight: 0,},

              });
              
            //(element.AddressName + ': ' + element.Address1 + ', ' + element.AddressTown + ', ' + element.AddressPostCode)
             this.infoWindowContent = `
                <div class="newpop-up">
                  <p style="font-weight: normal;margin:0px; padding:0px">Address Tag: <span style="font-weight: bold;">${element.AddressTag}</span></p>
                  <p style="font-weight: normal; margin: 0px; padding: 0px">Address: <span style="font-weight: bold;">
                  ${element.Address1 ? element.Address1 : ''}${element.Address1 && element?.Address2 ? ', ' : ''}
                  ${element?.Address2 ? element.Address2 : ''}${(element?.Address2 || element?.Address1) && element?.Address3 ? ', ' : ''}
                  ${element?.Address3 ? element.Address3 : ''}${(element?.Address3 || element?.Address2 || element?.Address1) && element?.AddressTown ? ', ' : ''}
                  ${element?.AddressTown ? element.AddressTown : ''}${(element?.AddressTown || element?.Address3 || element?.Address2 || element?.Address1) && element?.AddressPostCode ? ', ' : ''}
                  ${element?.AddressPostCode ? element.AddressPostCode : ''}
                  </span>
                  </p>
                </div>
              `; //${indexi}/${element.AddressPostCode}
              const infowindow = new google.maps.InfoWindow({
                content: this.infoWindowContent,
                zIndex: 9999
              });
              const marker = new google.maps.Marker(this.markers);
              //infowindow.open(this.map.googleMap, marker);
              // Add event listener for mouseover to show the info window
              marker.addListener('mouseover', () => {
                infowindow.open(this.map.googleMap, marker);
              });

              // Add event listener for mouseout to close the info window
              marker.addListener('mouseout', () => {
                infowindow.close();
              });
         
              marker.setMap(this.map.googleMap);
              // this.markers.push(marker)
               const position: google.maps.LatLngLiteral = { lat: element.Latitude, lng: element.Longitude };

               this.polylinePaths2.push(position)
          }
        });
        this.polylinePaths = [...this.polylinePaths2]; // Ensure a new reference for change detection
        this.drawPolyline(this.polylinePaths);
      }, 10);
    }

    if (E.ConvertToString(this.SearchStringForMap) != '') {
      if (this.mapModalType == MapModalType.SearchString) {
        this.getGeoLocation(this.SearchStringForMap).subscribe(data => {
          this.Lat = (<google.maps.LatLng>data).lat();
          this.Long = (<google.maps.LatLng>data).lng();
          this.addMarkerOnMap(this.Lat, this.Long, this.SearchStringForMap);
        });
      }
      else if (this.mapModalType == MapModalType.LatLong) {
        this.addMarkerOnMap(this.Lat, this.Long, this.SearchStringForMap);
      }
    }
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key.toLocaleLowerCase() === "escape") {
        this.onCancelClick();
      }
    });
  }
  onSearchClick() {

    if (E.ConvertToString(this.frmMapComponent.get('SearchField').value) != '') {
      this.getGeoLocation(this.frmMapComponent.get('SearchField').value).subscribe(data => {

        this.Lat = (<google.maps.LatLng>data).lat();
        this.Long = (<google.maps.LatLng>data).lng();
        this.addMarkerOnMap(this.Lat, this.Long, this.SearchStringForMap);
      });
    }
  }

  drawPolyline(coordinates: google.maps.LatLngLiteral[]): void {
    const lineSymbol = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      scale: 4,
      strokeColor: '#F68275',
      strokeWeight: 2,
    };
    this.polylineOptions = new google.maps.Polyline({
      path: coordinates,
      strokeColor: '#52057f',
      strokeOpacity: 0.8,
      strokeWeight: 5,
      // icons: [{
      //   icon: lineSymbol,
      //  // offset: '100%',
      //   repeat: '100px' // Adjust this value to control the spacing of the arrows
      // }],
      map: this.map.googleMap
    });
    const bounds = new google.maps.LatLngBounds();
    coordinates.forEach((location) => {
      bounds.extend(location);
    });
    this.map.fitBounds(bounds);
  }

  addMarkerOnMap(Latitude: number, Longitude: number, Title: string) {
    this.center = {
      lat: Latitude,
      lng: Longitude,
    };
  //  this.markers = [];
    this.markers=(
      {
        position: { lat: Latitude, lng: Longitude },
        draggable: this.IsDraggable,
        // label: {
        //   color: 'red',
        //   text: element.AddressName,
        // },
        title: Title,
        //options: { animation: google.maps.Animation.BOUNCE },
      });
      const marker = new google.maps.Marker(this.markers);
      setTimeout(() => {
        marker.setMap(this.map.googleMap);
      }, 500);
  }

  onCloseButtonClick() {
    this.closeDialog();
  }
  onCancelClick() {
    this.userAddresses = [];
    this.closeDialog();
  }
  onDragEndMarker(event: google.maps.MouseEvent, index) {
    // console.log('Marker Changed');
    if (this.userAddresses && this.userAddresses.length > 0) {
      this.userAddresses[index].Latitude = event.latLng.lat();
      this.userAddresses[index].Longitude = event.latLng.lng();
    }
    else if (this.Lat && this.Long) {
      this.Lat = event.latLng.lat();
      this.Long = event.latLng.lng();
      // console.log(this.Lat);
      // console.log(this.Long);
    }
  }
  closeDialog(ReloadParentData: boolean = false) {
    this.dialogRef.close(ReloadParentData ? this.userAddresses : []);
  }

  getGeoLocation(address: string): Observable<any> {
    let geocoder = new google.maps.Geocoder();
    return Observable.create(observer => {
      geocoder.geocode({
        'address': address
      }, (results, status) => {
        //console.log('results');
        //console.log(results);
        if (status == google.maps.GeocoderStatus.OK) {
          observer.next(results[0].geometry.location);
          observer.complete();
        }
        else if (status == google.maps.GeocoderStatus.ZERO_RESULTS) {
          setTimeout(() => {
            this._toastrService.Warning(this.currentAppLanguage.data.ADDRESS.ERROR_MESSAGE_MAP_ZERO_RESULTS, Constant.WarningToastrTitle);
          }, 10);
        } else {
          // console.log('Error: ', results, ' & Status: ', status);
          observer.error();
        }
      });
    });
  }

  onSubmit() {
    let Event = ''; let _data; let _searchstring = '';
    if (((this.Lat == null) || (this.Lat == undefined) && (this.Long == null) || (this.Long == undefined))) {
      this.onSearchClick();
    }

    setTimeout(() => {
      if (this.IsSearchTextVisible, this.txtSearchOnMap)
        if (this.IsSearchTextVisible && this.txtSearchOnMap) {
          if (E.ConvertToString(this.txtSearchOnMap.nativeElement.value) !== '') {
            _searchstring = E.ConvertToString(this.txtSearchOnMap.nativeElement.value);
          }
          else {
            return;
          }
        }
      if (this.IsSearchOnMap) {
        Event = 'SearchOnMap';
        _data = new MapAddressOutputModel(this.Lat, this.Long, _searchstring);
      }
      this.dialogRef.close({ event: Event, data: _data });
    }, 1000);
  }

  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }
}
