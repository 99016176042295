<div class="card" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <h5 cdkDragHandle class="card-header" style="justify-content: unset;">{{dialogTitle}}&nbsp;<span ngbTooltip={{infoTooltip}}>
        <span data-feather="info"></span>
      </span><button class="close-button close" type="button" ngbTooltip="{{'COMMON.TOOLTIP_TEXT_BUTTON_CLOSE'|translate}}"
            (click)="onCloseButtonClick()" placement="right">&times;</button>
    </h5>
    <div class="card-body card-body-dialog">
        <form id="frmMeonWeb" [formGroup]="frmMeonWeb" (ngSubmit)="onSubmit()">
            <!-- <form >    -->
            <section id="basic-tabs-components">
                <div class="row">
                    <div class="col">
                        <div class="card-body">
                            <div class="scroll-area">
                                <div class="row">
                                    <div class="form-group col-lg-6 col-sm-12 mb-0">
                                        <app-text-box [type]="'text'" #entryname
                                            [label]="'MEONWEB.CONTROL_LABEL_FIELD_ENTRY_NAME' | translate"
                                            [showRequiredSign]="true" [maxlength]="50" [showInfoIcon]="true"
                                            [tooltipInfo]="'MEONWEB.TOOLTIP_TEXT_WEB_TITLE'|translate" [position]="'right'"
                                            [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate"
                                            aria-describedby="EntryName" for="EntryName"
                                            formControlName="EntryName"
                                            [classes]="{ 'is-invalid': submitted && f.EntryName.errors }">
                                        </app-text-box>
                                        <span *ngIf="submitted && f.EntryName.errors">
                              <small *ngIf="f.EntryName.errors.required"
                                class="text-danger">{{'MEONWEB.ERROR_MESSAGE_ENTRY_NAME_REQUIRED'|translate}}</small>
                              <small *ngIf="f.EntryName.errors.maxlength"
                                class="text-danger">{{'MEONWEB.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
                            </span>
                                    </div>
                                    <div class="col-sm col-lg-6">
                                        <div class="form-group mb-0">
                                            <label class="">{{'MEONWEB.CONTROL_LABEL_FIELD_DATE_POSTED' | translate}}
                                                <span ngbTooltip="{{'MEONWEB.TOOLTIP_TEXT_WEB_PUBLICATION_DATE'|translate}}" placement="right">
                                                    <span data-feather="help-circle"
                                                      class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                                            </label>
                                            <div class="input-group" style="width: 100%;">
                                                <input id="DatePosted" name="DatePosted" [placeholder]="'dd/mm/yyyy'"
                                                    [bsConfig]="{ showWeeks: false,containerClass:'theme-blue',dateInputFormat:dateformat}"
                                                   formControlName="DatePosted"
                                                    required bsDatepicker #dpstartdate="bsDatepicker"
                                                    class="form-control">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-primary feather icon-calendar"
                                                        (click)="dpstartdate.toggle()" type="button" rippleEffect
                                                        placement="top"
                                                        ngbTooltip="Toggle Calendar"></button>
                                                </div>
                                            </div>
                                            <!-- <span *ngIf="submitted && f.PolicyStartDate.invalid">
                                <small class="text-danger"
                                  *ngIf="f.PolicyStartDate.errors.required">{{'INSURANCE.ERROR_MESSAGE_REQUIRED_POLICY_START_DATE'
                                  | translate}}</small>
                              </span> -->
                                        </div>
                                    </div>
                                    <div class="col-lg col-sm">
                                        <app-text-box [type]="'text'" [maxlength]="250"
                                        [label]="'MEONWEB.CONTROL_LABEL_FIELD_URL_WEBSITE' | translate"
                                        [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate"  [showInfoIcon]="true"
                                        [tooltipInfo]="'MEONWEB.TOOLTIP_TEXT_WEB_URL'|translate" [position]="'right'"
                                        aria-describedby="URL" for="URL"
                                        formControlName="URL"
                                        [classes]="{ 'is-invalid': submitted && f.URL.errors }">
                                    </app-text-box>
                                    <span *ngIf="submitted && f.URL.errors">
                          <!-- <small *ngIf="f.InsuranceName.errors.required"
                            class="text-danger">{{'INSURANCE.ERROR_MESSAGE_REQUIRED_INSURANCE_NAME'|translate}}</small> -->
                          <small *ngIf="f.URL.errors.maxlength"
                            class="text-danger">{{'MEONWEB.ERROR_MESSAGE_MAX_LENGTH_250'|translate}}</small>
                        </span>
                                    </div>
                                    <div class="col-sm-12">
                                        <label
                                            style="color: #323091;">{{'MEONWEB.CONTROL_LABEL_FIELD_DESCRIPTION'|translate}}
                                            <span ngbTooltip="{{'MEONWEB.TOOLTIP_TEXT_WEB_DESCRIPTION'|translate}}" placement="right">
                                                <span data-feather="help-circle"
                                                  class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                                        </label>
                                        <fieldset class="form-group">
                                            <textarea style="width: 100%;" rows="1" [maxlength]="120" class="form-control" formControlName="Description" (input)="updateCharacterCount1($event.target.value)"
                                                placeholder="{{'COMMON.PLACEHOLDER_TYPE_HERE'|translate}}"></textarea>
                                        </fieldset>
                                        <p class="text-right mb-0">Character count: {{ characterCount1 }} / {{ maxChars1 }}</p>
                                        <span *ngIf="submitted && f.Description.errors">
                                            <small *ngIf="f.Description.errors.maxlength"
                                              class="text-danger">{{'MEONWEB.ERROR_MESSAGE_MAX_LENGTH_120'|translate}}</small>
                                          </span>
                                    </div>
                                    <div class="col-sm-12">
                                        <label
                                            style="color: #323091;">{{'MEONWEB.CONTROL_LABEL_FIELD_NOTES'|translate}}
                                            <span ngbTooltip="{{'MEONWEB.TOOLTIP_TEXT_WEB_OTHER_INFO'|translate}}" placement="right">
                                                <span data-feather="help-circle"
                                                  class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                                        </label>
                                        <fieldset class="form-group">
                                            <textarea style="width: 100%;" rows="2" class="form-control" formControlName="Notes" (input)="updateCharacterCount2($event.target.value)"
                                             [maxlength]="250"   placeholder="{{'COMMON.PLACEHOLDER_TYPE_HERE'|translate}}"></textarea>
                                        </fieldset>
                                        <p class="text-right mb-0"  >Character count: {{ characterCount2 }} / {{ maxChars2 }}</p>
                                        <span *ngIf="submitted && f.Description.errors">
                                            <small *ngIf="f.Description.errors.maxlength"
                                              class="text-danger">You have typed too many characters, maximum allowed is 250</small>
                                          </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </form>

    </div>
</div>
<div class="card-footer">
    <div class="align">
      <button type="submit" *ngIf="!IsForEdit" class="btn btn-primary" [disabled]="loading" placement="top"
        (click)="onSubmit()"
        ngbTooltip="{{'MEONWEB.BUTTON_TEXT_BUTTON_ADD_NEW_PASTIME' |translate}}">{{'MEONWEB.BUTTON_TEXT_BUTTON_ADD_NEW_PASTIME'
        |translate}}</button>
  
      <button type="submit" *ngIf="IsForEdit" class="btn btn-primary" [disabled]="loading" placement="top"
        ngbTooltip="{{'COMMON.BUTTON_TEXT_SAVE_CHANGES' | translate}}" (click)="onSubmit()"><span *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"></span>{{'COMMON.BUTTON_TEXT_SAVE_CHANGES'|translate}}</button>
  
      <button type="button" class="btn btn-outline-secondary ml-2" placement="top" (click)="onCloseButtonClick()"
        ngbTooltip="{{'COMMON.BUTTON_TEXT_CANCEL'|translate}}">{{'COMMON.BUTTON_TEXT_CANCEL'|translate}}</button>
    </div>
  </div>