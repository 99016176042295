import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { locale as english } from 'app/me-on-web/i18n/en';
import { locale as french } from 'app/me-on-web/i18n/fr';
import { locale as german } from 'app/me-on-web/i18n/de';
import { locale as portuguese } from 'app/me-on-web/i18n/pt';
import { locale as gujarati } from 'app/me-on-web/i18n/gj';
import { locale as hindi } from 'app/me-on-web/i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Constant } from 'app/Classes/constant';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { Observable, Subject } from 'rxjs';
import { CoreConfigService } from '@core/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { LifeStoryService } from 'app/my-life-story/life-story.service';
import { UserMeOnWebModel, UserMeOnWebLoadDataOutputModel } from 'app/my-life-story/life-story/lifestory.model'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { GlobalService } from 'app/Services/global.service';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { AwardLoadDataOutputModel } from 'app/about-me/about-me-model.model';
import { Status } from 'app/enums';
import { E } from 'assets/extensions/extensions';
import { TextBoxComponent } from 'app/CustomComponents/text-box/text-box.component';

@Component({
  selector: 'app-web-entry',
  templateUrl: './web-entry.component.html',
  styleUrls: ['./web-entry.component.scss']
})
export class WebEntryComponent implements OnInit {
  @ViewChild('entryname') entryname: TextBoxComponent;
  public loading = false;
  public submitted = false;
  public coreConfig: any;
  public dialogTitle: string;
  public IsForEdit: boolean = false;

  public error = '';
  public maxToDate: Date = new Date();
  public maxFromDate: Date = new Date();
  currentAppLanguage: any;
  _data: number = 0;
  private _unsubscribeAll: Subject<any>;
  public frmMeonWeb: FormGroup;
  userWebEntry: UserMeOnWebModel;
  webLoadData
  infoTooltip:string;
  dateformat: string;
  characterCount1: number = 0;
  maxChars1: number = 120;
  characterCount2: number = 0;
  maxChars2: number = 250;
  constructor(
    private _coreTranslationService: CoreTranslationService,
    private _coreConfigService: CoreConfigService,
    private _translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<WebEntryComponent>,
    private loadingScreenService: LoadingScreenService,
    private globalService: GlobalService,
    private encrDecr: EncrDecrService,
    private _toastrService: CustomToastrService,
    private meonWebService: LifeStoryService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this._unsubscribeAll = new Subject();
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._translateService.onLangChange.subscribe(lang => {
      setTimeout(() => {
        let _LangJsonObj = this.GetCurrentLanguageJsonObject(lang.lang);
        if (_LangJsonObj)
          this.currentAppLanguage = _LangJsonObj;
      }, 100);
    })
    this._data=data
    this.dateformat=this.globalService.getDateFormat().toUpperCase()
  }

  ngOnInit(): void {
    this.userWebEntry = new UserMeOnWebModel();
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);
    this.dialogTitle = this.currentAppLanguage.data.MEONWEB.DIALOG_TITLE_ADD_WEB;
    this.infoTooltip= this.currentAppLanguage.data.MEONWEB.TOOLTIP_TEXT_WEB_ENTRY_INFORMATION;
    this.frmMeonWeb = this._formBuilder.group({
      EntryName: [, Validators.required],
      DatePosted: [new Date],
      URL: [''],
      Description: [''],
      Notes: [''],
    });
    if (typeof this._data !== 'undefined') {
      // console.log('this._data')
      // console.log(this._data)
      if (this._data > 0) {
        this.IsForEdit = true;
        this.dialogTitle = 'Edit a Web Entry';
      
      setTimeout(() => {
        this.loadingScreenService.startLoading();
        this.meonWebService.GetMeOnWebById(this._data)
          .subscribe(
            (data) => {
              this.loadingScreenService.stopLoading();
              //  console.log(data);
              if (data.status == Status.Success) {
                this.userWebEntry = JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, data.data)));
                 // console.log(this.webLoadData)
                if (this.userWebEntry) {

                  this.frmMeonWeb.patchValue({
                    EntryName: this.userWebEntry.EntryName,
                    URL: this.userWebEntry.URL,
                    Description: this.userWebEntry.Description,
                    Notes: this.userWebEntry.Notes,
                    DatePosted: E.ConvertToDate(this.userWebEntry.DatePosted)
                   
                  });

                  if(this.userWebEntry.Description)
                    this.updateCharacterCount1(this.userWebEntry.Description)
                  if(this.userWebEntry.Notes)
                    this.updateCharacterCount2(this.userWebEntry.Notes)
                }

              }
              else {
                this._toastrService.Error(E.ConvertToString(data.message), Constant.ErrorToastrTitle);
              }
            },
            (error) => {
              this._toastrService.Error(error, Constant.ErrorToastrTitle);
              this.error = error;
              this.loadingScreenService.stopLoading();
            }
          );

      }, 500);
    }
    }
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key.toLocaleLowerCase() === "escape") {
        this.onCloseButtonClick();
      }
    });
  }

  updateCharacterCount1(text: string): void {
    // Update the character count with the length of the input text
    this.characterCount1 = text.length;
    // If character count exceeds the maximum limit, truncate the input
    if (this.characterCount1 > this.maxChars1) {
      text = text.substr(0, this.maxChars1);
      this.characterCount1 = this.maxChars1;
    }
  }
  updateCharacterCount2(text: string): void {
    // Update the character count with the length of the input text
    this.characterCount2 = text.length;
    // If character count exceeds the maximum limit, truncate the input
    if (this.characterCount2 > this.maxChars2) {
      text = text.substr(0, this.maxChars2);
      this.characterCount2 = this.maxChars2;
    }
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      if (this.entryname)
        this.entryname.textBox.nativeElement.focus();

    }, 500);
  }
  onSubmit() {
    this.submitted = true;
    if (this.frmMeonWeb.invalid) {
      return;
    }
    this.submitted = false;
    if (this.userWebEntry == null) {
      this.userWebEntry = new UserMeOnWebModel();
    }
    this.userWebEntry.EntryName = this.frmMeonWeb.get('EntryName').value;
    if(this.frmMeonWeb.get('DatePosted').value){
    this.userWebEntry.DatePosted = E.GetDateStringOnly(this.frmMeonWeb.get('DatePosted').value);}
    this.userWebEntry.URL = this.frmMeonWeb.get('URL').value;
    this.userWebEntry.Description = this.frmMeonWeb.get('Description').value;
    this.userWebEntry.Notes = this.frmMeonWeb.get('Notes').value;
    this.userWebEntry.UserDetailsId = this.authenticationService.currentUserValue.UserDetailsId;

    if (E.ConvertToNumber(this.userWebEntry.UserMeOnWebId) == 0 && this.IsForEdit == false) {
      this.userWebEntry.CreatedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userWebEntry.CreatedDate = E.GetTodayDateStringOnly();
      this.userWebEntry.CreatedTime = E.GetTodayTimeStringOnly();
      this.userWebEntry.ChangedDate = E.GetTodayDateStringOnly();
      this.userWebEntry.ChangedTime = E.GetTodayTimeStringOnly();
    }
    else {
      this.userWebEntry.ChangedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userWebEntry.ChangedDate = E.GetTodayDateStringOnly();
      this.userWebEntry.ChangedTime = E.GetTodayTimeStringOnly();
    }
    this.loading = true;
    this.loadingScreenService.startLoading();
   //console.log(this.userWebEntry)
    let response: Observable<any>;
    this.meonWebService.CreateUpdateUserMeOnWeb(this.userWebEntry)
      .subscribe(
        (result) => {
          this.loading = false;
          this.loadingScreenService.stopLoading();
          var message = ""; var errormessage = "";
          if (result.status == Status.RecordAdded) {
            message ='Me on Web Entry Added Successfully';
          }
          else if (result.status == Status.RecordUpdated) {
            message = 'Me on Web Entry Updated Successfully';
          }
          else {
            errormessage = E.ConvertToString(result.message);
          }
          if (message.length > 0) {
            this._toastrService.Success(message, Constant.SuccessToastrTitle);
            this.dialogRef.close();
            this.frmMeonWeb.reset();
            this.frmMeonWeb.patchValue({
              DatePosted: new Date()
            });
          }
          else {
            this._toastrService.Error(errormessage, Constant.ErrorToastrTitle);
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
          this.loading = false;
          this.loadingScreenService.stopLoading();
        }
      );

  }
  onCloseButtonClick() {
    this.dialogRef.close();
  }
  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }

  get f(){
    return this.frmMeonWeb.controls;
  }

}
