import { Status } from "app/enums";

export const locale = {
  lang: 'hn',
  data: {
    COMMON: {
      THANKS_BADGE_SIGNUP: 'साइन अप करने के लिए धन्यवाद!',
      TEXT_HEY: 'हे',
      TEXT_THANKS: 'धन्यवाद,',
      TEXT_NOOSOM_TEAM: 'नुसम टीम',
      TEXT_READ_OUR: 'हमारी',
      LINK_TEXT_PRIVACY_POLICY: 'गोपनीयता नीति',
      LINK_TEXT_TERMS_CONDITIONS: 'नियम एवं शर्तें',
      LINK_TEXT_BACK_TO_LOGIN: 'लॉगिन पर वापस जाएं',
      PLACEHOLDER_EMAIL: 'यहाँ लिखो...',
      PLACEHOLDER_TYPE_HERE: 'यहाँ लिखो...',
      TOOLTIP_TEXT_BUTTON_LOGIN: 'Login',
      CONTROL_LABEL_EMAIL: 'ईमेल',
      CONTROL_LABEL_EMAIL_ADDRESS: 'ईमेल पता',
      CONTROL_LABEL_CONFIRM_PASSWORD: 'पासवर्ड की पुष्टि कीजिये',
      BUTTON_TEXT_SAVE_CHANGES: 'परिवर्तनों को सुरक्षित करें',
      TOAST_MESSAGE_SUCCESSFUL_OTP_VERIFIED: 'ओटीपी सफलतापूर्वक सत्यापित।',
      ERROR_MESSAGE_REQUIRED_EMAIL: 'ईमेल पता की आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_PASSWORD: 'पासवर्ड की आवश्यक है!',
      ERROR_MESSAGE_INVALID_EMAIL: 'कृपया अपना ईमेल पता प्रारूप में दर्ज करें:\nyourname@example.com',
      ERROR_MESSAGE_INVALID_PASSWORD: 'कम से कम एक नंबर, एक अपरकेस और लोअरकेस अक्षर और कम से कम 8 या अधिक वर्ण होने चाहिए।',
      ERROR_MESSAGE_MISMATCHED_PASSWORD: 'पुष्टि करें कि पासवर्ड मेल खाना चाहिए।',
      ERROR_MESSAGE_REQUIRED_CONFIRMPASSWORD: 'पुष्टिकरण पासवर्ड आवश्यक है!',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'सर्वर से कनेक्ट नहीं हो सका.',
      ERROR_MESSAGE_INVALID_OTP_DATA: 'वैध ओटीपी डेटा दर्ज करें।',
      ERROR_MESSAGE_INVALID_OTP: 'मान्य ओटीपी दर्ज करें।',
      COMMON_STATUS:
        [
          { ErrorCode: Status.InvalidOTP, ErrorMessage: 'अमान्य ओटीपी.' },
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'उपयोगकर्ता नहीं मिल सका।' }
        ],
    },
    CALENDAR_MAIN: {
      BUTTON_TEXT_ADD_AN_EVENT: 'एक प्रसंग जोड़ें',
      CONTROL_LABEL_ADD_A_QUICKPOST: 'एक त्वरित पोस्ट जोड़ें',
      CONTROL_LABEL_ADD_AN_EVENT: 'एक प्रसंग जोड़ें',
      CONTROL_LABEL_IMPORT_DATA: 'इंपोर्ट डेटा',
      CONTROL_LABEL_FILTER: 'फिल्टर',
      CONTROL_LABEL_VIEW_ALL: 'सभी देखें',
      CONTROL_LABEL_QUICKPOST: 'त्वरित पोस्ट',
      CONTROL_LABEL_ADD_JOURNAL: 'एक जर्नल एंट्री जोड़ें',
      CONTROL_LABEL_ADD_DIARY: 'एक डायरी एंट्री जोड़ें',
      CONTROL_LABEL_LIST_ALL: 'सभी सूची',
      INFO_MESSAGE_COMING_SOON: 'जल्द आ रहा है',

    },
    CHECKOUT: {
      BUTTON_TEXT_APPLY: 'लागू करें',
      BUTTON_TEXT_CANCEL: 'रद्द करें',
      BUTTON_TEXT_REMOVE: 'हटाएं',
      BUTTON_TEXT_PROCEED_TO_BUY: 'खरीदारी करें',
      ERROR_MESSAGE_PG_UNDER_MAINTAINANCE: 'पेमेंट गेटवे असुधृत है, कृपया कुछ समय बाद पुनः प्रयास करें।',
      ERROR_MESSAGE_NO_DISCOUNT_FOUND: 'इस प्रकार का कोई डिस्काउंट कोड नहीं मिला।',
      HEADING_LABEL_CHECKOUT: 'चेकआउट',
      HEADING_LABEL_NOOSOM_PRIVATE: 'नूसोम प्राइवेट लिमिटेड',
      HEADING_LABEL_STORY_OF_YOUR_LIFE: 'आपके जीवन की कहानी',
      CONTROL_LABEL_CIN: 'सीआईएन',
      CONTROL_LABEL_GSTIN: 'जीएसटीआईएन',
      CONTROL_LABEL_DATE: 'तारीख',
      CONTROL_LABEL_INVOICE_TO: 'को चालान',
      CONTROL_LABEL_YOUR_NOOSOM_PLAN: 'आपकी नूसोम योजना',
      TABLE_HEADER_STORAGE_PLAN: 'स्टोरेज प्लान',
      TABLE_HEADER_COST: 'लागत',
      TABLE_HEADER_STORAGE_IN_GB: 'स्टोरेज इन जीबी',
      TABLE_HEADER_DISCOUNT: 'छूट %',
      TABLE_HEADER_DISCOUNT_AMOUNT: 'डिस्काउंट राशि',
      TABLE_HEADER_LINE_TOTAL: 'लाइन कुल'
    }
  }
};
