import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  private selectedItemsSubject = new BehaviorSubject<any[]>([]);
  selectedItems$ = this.selectedItemsSubject.asObservable();
  private removedItemsSubject = new BehaviorSubject<any>(null); // New Subject to track removed items
  removedItems$: Observable<any>;
  constructor(private globalService: GlobalService) { 
    const storedItems = JSON.parse(localStorage.getItem('selectedItems')) || [];
    this.selectedItemsSubject = new BehaviorSubject<any[]>(storedItems);
    this.removedItemsSubject = new BehaviorSubject<any>(null);

    this.selectedItems$ = this.selectedItemsSubject.asObservable();
    this.removedItems$ = this.removedItemsSubject.asObservable();
  }

  GetUserAddressDocumentsById(data){
    const path = 'api/UserAddress/GetUserAddressDocumentsById';
    return this.globalService.PostDataById(data,path);
  }

  GetUserSchoolDocumentsById(data){
    const path = 'api/UserSchool/GetUserSchoolDocumentsById';
    return this.globalService.PostDataById(data,path);
  }

  GetUserQuickPostDocumentsById(data){
    const path = 'api/UserQuickPost/GetUserQuickPostDocumentsById';
    return this.globalService.PostDataById(data,path);
  }

  GetUserPastimeDocumentsById(data){
    const path = 'api/UserPastime/GetUserPastimeDocumentsById';
    return this.globalService.PostDataById(data,path);
  }

  GetUserHobbyDocumentsById(data){
    const path = 'api/UserHobby/GetUserHobbyDocumentsById';
    return this.globalService.PostDataById(data,path);
  }

  GetUserLegalDocumentsById(data){
    const path = 'api/UserLegal/GetUserLegalDocumentsById';
    return this.globalService.PostDataById(data,path);
  }

  // getSelectedItems(): any[] {
  //   return this.selectedItemsSubject.getValue();
  // }

  // // Add an item to the list
  // addItem(item: any): void {
  //   const currentItems = this.getSelectedItems();
  
  //   // Check if the item is from the first card by identifying its NoosomPlanId or another unique property
  //   if (item?.NoosomPlanId === 2) {
  //     // Handling for the first card (only one item allowed)
  //     const updatedItems = [...currentItems.filter(i => i.NoosomPlanId !== 2), item];
  //     this.selectedItemsSubject.next(updatedItems);
  //   } else if (item?.NoosomPlanId === 1) {
  //     // Handling for the second card (only one item allowed)
  //     const updatedItems = [...currentItems.filter(i => i.NoosomPlanId !== 1), item];
  //     this.selectedItemsSubject.next(updatedItems);
  //   }
  // }
  
  // removefromCart(item: any): void {
  //   this.removedItemsSubject.next(item); // Update the removedItemsSubject with the removed item
  // }


  // removeItem(item: any): void {
  //   const currentItems = this.getSelectedItems();
  //   const updatedItems = currentItems.filter((i) => i !== item);
  //   this.selectedItemsSubject.next(updatedItems);
  // }

  // // New method to get the removed items as an Observable
  // getRemovedItems(): Observable<any> {
  //   return this.removedItemsSubject.asObservable();
  // }

  // Clear all items
  clearItems(): void {
    this.selectedItemsSubject.next([]);
    this.removefromCart([]);
  }
  getSelectedItems(): any[] {
    return this.selectedItemsSubject.getValue();
  }

  // Add an item to the list and update localStorage
  addItem(item: any): void {
    const currentItems = this.getSelectedItems();
    
    if (item?.NoosomPlanId === 2) {
      const updatedItems = [...currentItems.filter(i => i.NoosomPlanId !== 2), item];
      this.selectedItemsSubject.next(updatedItems);
      localStorage.setItem('selectedItems', JSON.stringify(updatedItems));
    } else if (item?.NoosomPlanId === 1) {
      const updatedItems = [...currentItems.filter(i => i.NoosomPlanId !== 1), item];
      this.selectedItemsSubject.next(updatedItems);
      localStorage.setItem('selectedItems', JSON.stringify(updatedItems));
    }
  }

  // Remove an item from the list and update localStorage
  removeItem(item: any): void {
    const currentItems = this.getSelectedItems();
    const updatedItems = currentItems.filter((i) => i !== item);
    this.selectedItemsSubject.next(updatedItems);
    localStorage.setItem('selectedItems', JSON.stringify(updatedItems));
  }

  // Remove from cart and update removedItemsSubject
  removefromCart(item: any): void {
    this.removedItemsSubject.next(item); // Update the removedItemsSubject with the removed item
    this.removeItem(item); // Also remove the item from the list and localStorage
  }

  // New method to get the removed items as an Observable
  getRemovedItems(): Observable<any> {
    return this.removedItemsSubject.asObservable();
  }
}
