import { AfterContentInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'app/auth/service';
import { Constant } from 'app/Classes/constant';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { GlobalService } from 'app/Services/global.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
import { locale as german } from '../i18n/de';
import { locale as portuguese } from '../i18n/pt';
import { locale as gujarati } from '../i18n/gj';
import { locale as hindi } from '../i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { CoreConfigService } from '@core/services/config.service';
import { map, takeUntil } from 'rxjs/operators';
import { SchoolDocumentListOutputModel, SchoolLoadDataOutputModel, TblUserSchools } from '../school-model.model';
import { MapModalType, Status } from 'app/enums';
import { TextBoxComponent } from 'app/CustomComponents/text-box/text-box.component';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { E } from 'assets/extensions/extensions';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { BeforeSlideDetail, InitDetail } from 'lightgallery/lg-events';
import lgZoom from 'lightgallery/plugins/zoom';
import thumbNail from 'lightgallery/plugins/thumbnail';
import pager from 'lightgallery/plugins/pager';
import share from 'lightgallery/plugins/share';
import autoPlay from 'lightgallery/plugins/autoplay';
import { MapAddressComponent } from 'app/address/map-address/map-address.component';
import comment from 'lightgallery/plugins/comment';
import fullScreen from 'lightgallery/plugins/fullscreen';
import rotate from 'lightgallery/plugins/rotate';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DatePipe } from '@angular/common';
import { MapAddressDataModel } from 'app/address/map-address/map-address-data-model.model';
import { LightGallery } from 'lightgallery/lightgallery';
import Swal from 'sweetalert2';
import { DeleteInputModel, DownloadFileInputModel, DownloadFileOutputModel } from 'app/Models/output-model';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import video from 'lightgallery/plugins/video';
import lgvideo from 'lightgallery/plugins/video';
import lgVideo from 'lightgallery/plugins/video';
import Zoom from 'lightgallery/plugins/zoom';
import vimeoThumbnail from 'lightgallery/plugins/vimeoThumbnail'
import { GeneralService } from 'app/Services/general.service';

@Component({
  selector: 'app-add-school',
  templateUrl: './add-school.component.html',
  styleUrls: ['./add-school.component.scss']
})
export class AddSchoolComponent implements OnInit, AfterContentInit {
  @ViewChild('notes') notes :ElementRef;
  @ViewChild('addressTag') addressTag: TextBoxComponent;
  @ViewChild('SchoolGallery') lightGallery!: LightGallery;
  @ViewChild('nav') nav: NgbNav;
  public loading = false;
  public submitted = false;
  currentAppLanguage: any;
  public coreConfig: any;
  private _unsubscribeAll: Subject<any>;
  public error = '';
  dialogTitle: string;
  public PlaceholderState;
  public PlaceholderCountry;
  public PlaceholderRegion;
  public frmSchool: FormGroup;
  public IsForEdit: boolean = false;
  public IsForAbandon: boolean = false;
  date: Date = new Date();
  public maxToDate: Date = new Date();
  public maxFromDate: Date = new Date();
  public DocumentList: SchoolDocumentListOutputModel[] = [];
  public DocumentList1: SchoolDocumentListOutputModel[] = [];
  public deleteDocumentsInputModel: Array<DeleteInputModel> = [];
  public downloadFileInputModel: DownloadFileInputModel;
  private needRefresh = false;
  private subSaveSchool$?: Subscription;
  private subDownloadSchoolAtt$?: Subscription;
  private subDeleteSchoolAtt$?: Subscription;
  private subDeleteSchool$?: Subscription;
  private subStateList$?: Subscription;
  private subCountryList$?: Subscription;
  public pageAdvancedEllipses = 1;
  public pagesNumber;
  page=0;
  deleteInputModel: DeleteInputModel = new DeleteInputModel();
  GalleryItems: any = [];
  GalleryItems1: any = [];
  schoolLoadDataOutputModel$: Observable<SchoolLoadDataOutputModel>;
  schoolLoadDataOutputModel: SchoolLoadDataOutputModel = new SchoolLoadDataOutputModel();
  userSchool: TblUserSchools;
  uploaderWarning:boolean=false;
  forgotuploadWarning:boolean=false;
  _data: number = 0;
  _ImageUrlAPI: string = Constant.API + 'api/UserSchool/UploadUserSchoolDocuments';
  //_FolderPath: string = '/Schools/';
  public uploader: FileUploader = new FileUploader({
    url: this._ImageUrlAPI,
    isHTML5: true,
    method: "PUT",
    itemAlias: "MultiFileUploaderSchool",
    disableMultipart: false, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
    formatDataFunction: (item: FileItem) => item._file,
    formatDataFunctionIsAsync: false,
    maxFileSize: 1 * 1024 * 1024 * 1024,
    //Notes: do not add headers here.
    authToken: `Bearer ` + this.authenticationService.currentUserValue.Token,
    allowedFileType: ["image", "tiff", "bmp", "png", "jpg", "jpeg", "pdf", "video", "mp4", "mpeg", "mov", "avi", "ogv", "webm", "flv"] //"video", "mp4", "mpeg", "mov", "avi", "ogv", "webm", "flv" ,"doc", "docx", "xls", "xlsx", "ppt",
  });
  galleryRefresh:LightGallery;
  settings = {
    counter: false,
    appendSubHtmlTo: '.lg-toolbar',
    commentBox: true,
    lgVideo: true,
    download: false,
    thumbnail: true,
    slideVideo: true,
    pager:false,
    autoplayFirstVideo: false,
    autoplayVideoOnSlide:false,
    plugins: [Zoom, thumbNail, autoPlay, fullScreen, rotate, lgvideo, lgVideo, video, vimeoThumbnail] //share,comment (commented for the future use)
  };
  //thumbnailDataURL: string | null = null;
  
  public tab = 1;
  UserSchoolDocument: number[];
  cache: { [key: string]: string } = {};
  infoTooltip: string;
  todateRequired:boolean=true;
  dateformat: string;
  uploadCount=0;
  timeformat: string;
  dateformat1: string;
  constructor(
    private _formBuilder: FormBuilder,
    private _toastrService: CustomToastrService,
    private dialogRef: MatDialogRef<AddSchoolComponent>,
    private globalService: GlobalService,
    private encrDecr: EncrDecrService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private _coreTranslationService: CoreTranslationService,
    private _coreConfigService: CoreConfigService,
    private loadingScreenService: LoadingScreenService,
    private datepipe: DatePipe,
    private generalService : GeneralService,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._unsubscribeAll = new Subject();
    this._data = data;
    this.dateformat=this.globalService.getDateFormat().toUpperCase();
    this.dateformat1=this.globalService.getDateFormat();
    this.timeformat = this.globalService.getTimeFormat();
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      if (this.addressTag)
        this.addressTag.textBox.nativeElement.focus();
          if (this.notes)
          this.notes.nativeElement.focus();
    }, 500);
  }

  ngAfterViewChecked(): void {
    if (this.needRefresh) {
      if (this.lightGallery) {
      this.galleryRefresh.refresh();
      }
      this.needRefresh = false;
    }
  }
  onBeforeClose = async (detail) => {
    this.galleryRefresh.refresh();
  }
  onInitGallery = async (detail) => {
    detail.instance.settings.licenseKey = this.authenticationService.currentUserValue.Parameter.LightGalleryLicenseKey
    this.lightGallery = detail.instance;
    this.galleryRefresh = detail.instance;
    const customButton = `<button id="lg-custom-button-delete" style="padding-top:8px" class="lg-icon"><img style="height:30px;" src="assets/images/icons/trash.png"></button>`;
    const tagButton = `<button id="lg-custom-button-download" class="custombutton12 lg-icon"><img style="height:30px" class="custombutton12" src="assets/images/icons/download.png"></button>`;
    const customHelpButton = `<button id="lg-custom-button-help" style="padding-top:10px" class="svg-icon lg-icon lg-group">
    <img class="svg-icon" style="height:28px;" src="assets/images/icons/HELP.png"></button>`;
    //const refreshButton = `<button id="lg-custom-button-refresh" style="padding-top:10px" class="svg-icon lg-icon lg-group">
    //<img class="svg-icon" style="height:28px;" src="assets/images/pages/refresh-ccw.svg"></button>`
    const $toolbar = this.lightGallery.$toolbar;
    $toolbar.append(customButton);
    $toolbar.append(tagButton);
    $toolbar.append(customHelpButton);
    //$toolbar.append(refreshButton);
    document.getElementById("lg-custom-button-delete").addEventListener("click", async () => {
      const idname = detail.instance.galleryItems[detail.instance.index];
      await this.onDeleteDocument(idname.alt);
      let galleryItems1 = JSON.parse(JSON.stringify(detail.instance.galleryItems));
      const updatedGalleryItems = galleryItems1.filter((item, index) => index !== detail.instance.index);
      detail.instance.updateSlides(updatedGalleryItems, Math.max(detail.instance.index - 1, 0));
      detail.instance.slidesUpdated = true
      let slidesUpdated = false;
    });
    document.getElementById("lg-custom-button-download").addEventListener("click", () => {
      const downloadDocument: SchoolDocumentListOutputModel = new SchoolDocumentListOutputModel;
      const idname = detail.instance.galleryItems[detail.instance.index];
      // var urlWithoutClass = idname.src.split('?');
      // downloadDocument.DocumentPath = urlWithoutClass[0];
      if(idname.src){
        downloadDocument.DocumentPath = idname.src;}
        else if(idname.video){
          downloadDocument.DocumentPath = JSON.parse(idname.video).source[0].src;
        }
      downloadDocument.UserSchoolDocumentId = idname.alt;
      this.onDownloadDocument(downloadDocument)
    });
    document.getElementById("lg-custom-button-help").addEventListener("click", () => {
      this.supportFunction();
    });
    // document.getElementById("lg-custom-button-refresh").addEventListener("click", () => {
    //   detail.instance.refresh();
    // });
  };

  onInitGallery1 = async (detail) => {
    detail.instance.settings.licenseKey = this.authenticationService.currentUserValue.Parameter.LightGalleryLicenseKey
    this.lightGallery = detail.instance;
    this.galleryRefresh = detail.instance;
    const customButton = `<button id="lg-custom-button-delete1" style="padding-top:8px" class="lg-icon"><img style="height:30px;" src="assets/images/icons/trash.png"></button>`;
    const tagButton = `<button id="lg-custom-button-download1" class="custombutton12 lg-icon"><img style="height:30px" class="custombutton12" src="assets/images/icons/download.png"></button>`;
    const customHelpButton = `<button id="lg-custom-button-help1" style="padding-top:10px" class="svg-icon lg-icon lg-group">
    <img class="svg-icon" style="height:28px;" src="assets/images/icons/HELP.png"></button>`;
    //const refreshButton = `<button id="lg-custom-button-refresh" style="padding-top:10px" class="svg-icon lg-icon lg-group">
    //<img class="svg-icon" style="height:28px;" src="assets/images/pages/refresh-ccw.svg"></button>`
    const $toolbar = this.lightGallery.$toolbar;
    $toolbar.append(customButton);
    $toolbar.append(tagButton);
    $toolbar.append(customHelpButton);
    //$toolbar.append(refreshButton);
    document.getElementById("lg-custom-button-delete1").addEventListener("click", async () => {
      const idname = detail.instance.galleryItems[detail.instance.index];
      await this.onDeleteDocument(idname.alt);
      let galleryItems1 = JSON.parse(JSON.stringify(detail.instance.galleryItems));
      const updatedGalleryItems = galleryItems1.filter((item, index) => index !== detail.instance.index);
      detail.instance.updateSlides(updatedGalleryItems, Math.max(detail.instance.index - 1, 0));
      detail.instance.slidesUpdated = true
      let slidesUpdated = false;
    });
    document.getElementById("lg-custom-button-download1").addEventListener("click", () => {
      const downloadDocument: SchoolDocumentListOutputModel = new SchoolDocumentListOutputModel;
      const idname = detail.instance.galleryItems[detail.instance.index];
      // var urlWithoutClass = idname.src.split('?');
      // downloadDocument.DocumentPath = urlWithoutClass[0];
      if(idname.src){
        downloadDocument.DocumentPath = idname.src;}
        else if(idname.video){
          downloadDocument.DocumentPath = JSON.parse(idname.video).source[0].src;
        }
      downloadDocument.UserSchoolDocumentId = idname.alt;
      this.onDownloadDocument(downloadDocument)
    });
    document.getElementById("lg-custom-button-help1").addEventListener("click", () => {
      this.supportFunction();
    });
    // document.getElementById("lg-custom-button-refresh").addEventListener("click", () => {
    //   detail.instance.refresh();
    // });
  };



  supportFunction() {

    Swal.fire({
      title: "Help!",
      text: "Information about the icons in the Light Gallery.",
      imageUrl: 'assets/images/pages/raw-icon-labels.png',
      showCancelButton: false,
      confirmButtonColor: '#7367F0',
      //cancelButtonColor: '#E42728',
      confirmButtonText: "Okay",
      cancelButtonText: this.currentAppLanguage.data.SCHOOL.SWEET_ALERT_CANCEL_BUTTON_TEXT,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }

    })

  }

  ngOnInit(): void {
    this.userSchool = new TblUserSchools();
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);
    this.infoTooltip= this.currentAppLanguage.data.SCHOOL.INFO_TEXT;
    this.dialogTitle = this.currentAppLanguage.data.SCHOOL.DIALOG_TITLE_ADD_SCHOOL;
    this.frmSchool = this._formBuilder.group({
      AddressTag: ['', Validators.required],
      SchoolName: ['', Validators.required],
      EducationReceived: ['', Validators.required],
      Address1: ['', Validators.required],
      Address2: [''],
      Address3: [''],
      AddressTown: ['', Validators.required],
      StateId: [, Validators.required],
      AddressPostCode: ['', Validators.required],
      CountryId: [, Validators.required],
      RegionId: [, Validators.required],
      CurrentSchoolYn: [],
      SchoolFromDate: [, Validators.required],
      SchoolToDate: [],
      Notes: [],
     
    });
    //console.log('this._data');
    //console.log(this._data);
    if (typeof this._data !== 'undefined') {
      if (this._data > 0) {   //Note: API is being called to load region list in add a new address.
        this.IsForEdit = true;
        this.dialogTitle = this.currentAppLanguage.data.SCHOOL.DIALOG_TITLE_EDIT_SCHOOL;
      }
      const path = 'api/UserSchool/GetUserSchoolLoadData';
      //let response: Observable<any>;
      var input = {
        recordId: this._data,
        docYN:false
      }
      this.schoolLoadDataOutputModel$ = this.globalService.PostDataById(input, path)
        .pipe(
          map((data) => {
            if (data.status == Status.Success) {
              this.schoolLoadDataOutputModel = <SchoolLoadDataOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, data.data)));
              if (this.schoolLoadDataOutputModel.SchoolDocumentList) {
                this.schoolLoadDataOutputModel.SchoolDocumentList.forEach(async (doc) => {
                  if (doc.FileType.toLocaleLowerCase() == "documents") {
                    this.DocumentList.push(doc);
                  }
                  else if (doc.FileType.toLocaleLowerCase() == "images" || "videos") {
                    await this.AddItemToGallery(doc.UserSchoolDocumentId, doc.DocumentPath, doc.DocumentOldName, doc.Size, doc.FileType,doc.CreatedDate, doc.CreatedTime);
                    this.needRefresh = true;
                  }
                });
              }
              if (this.schoolLoadDataOutputModel.UserSchool) {
                this.userSchool = this.schoolLoadDataOutputModel.UserSchool;
                this.frmSchool.setValue({
                  Address1: this.userSchool.Address1,
                  Address2: this.userSchool.Address2,
                  Address3: this.userSchool.Address3,
                  SchoolFromDate: E.ConvertToDate(this.userSchool.SchoolFromDate),
                  SchoolToDate: E.ConvertToDate(this.userSchool.SchoolToDate),
                  AddressTag: this.userSchool.AddressTag,
                  SchoolName: this.userSchool.SchoolName,
                  AddressPostCode: this.userSchool.AddressPostCode,
                  AddressTown: this.userSchool.AddressTown,
                  RegionId: this.userSchool.RegionId,
                  CountryId: this.userSchool.CountryId,
                  StateId: this.userSchool.StateId,
                  EducationReceived: this.userSchool.EducationReceived,
                  Notes: this.userSchool.Notes,
                  CurrentSchoolYn: this.userSchool.CurrentSchoolYn,
                });
               // this.toggleSelection(this.userSchool.CurrentSchoolYn)
                if (this.userSchool.CountryId) {
                  this.frmSchool.get('CountryId').enable();
                }
                if (this.userSchool.StateId) {
                  this.frmSchool.get('StateId').enable();
                }
              }
              // console.log(this.userSchool);
              return this.schoolLoadDataOutputModel;
            }
            else {
              this._toastrService.Error(E.ConvertToString(data.message), Constant.ErrorToastrTitle);
            }
          },
            (error) => {
              this._toastrService.Error(error, Constant.ErrorToastrTitle);
              this.error = error;
              //this.loadingScreenService.stopLoading();
            }
          ));
      //}, 500);
    }
    this.PlaceholderRegion = this.currentAppLanguage.data.SCHOOL.PLACEHOLDER_REGION;
    this.PlaceholderCountry = this.currentAppLanguage.data.SCHOOL.PLACEHOLDER_COUNTRY;
    this.PlaceholderState = this.currentAppLanguage.data.SCHOOL.PLACEHOLDER_STATE;
    this.frmSchool.get('CountryId').disable();
    this.frmSchool.get('RegionId').disable();

    this.uploader.onAfterAddingFile = (file: any) => {
      if((this.uploader.queue.length>0) && (this.uploader.isUploading==false)){
        this.forgotuploadWarning=true;}
      file.withCredentials = false;
    };
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      fileItem.withCredentials = false;
    };
    this.uploader.onBeforeUploadItem = (fileItem) => {
      this.loadingScreenService.startLoading();
      this.uploader.setOptions({
        headers: [
          { name: 'Access-Control-Allow-Origin', value: "*" },
          { name: 'FileSize', value: fileItem.file.size },
          { name: 'UserDetailsID', value: E.ConvertToString(this.authenticationService.currentUserValue.UserDetailsId) },
          { name: 'UserSchoolID', value: E.ConvertToString(this._data) }]
      });
      //this.uploader.setOptions({ url: fileItem.url + fileItem.file.name });
      return fileItem;
    };
    // this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    //   alert('File uploaded');
    // };
    this.uploader.response.subscribe(async result => {
      if (result) {
        this.loadingScreenService.startLoading()
        let _result = JSON.parse(result);
        if(this.uploader.progress!=100){
          this.uploaderWarning=true;
          this.loadingScreenService.startLoading();
      }else if(this.uploader.progress==100){
        this.uploaderWarning=false;
        this.forgotuploadWarning=false;
       // this.uploadCount = 0;
        this.loadingScreenService.stopLoading();
      }
        if (_result.status == Status.Success) {
          this._toastrService.Success("File Uploaded Successfully")
          this.uploadCount = this.uploadCount+1;
          let _SchoolDocument = <SchoolDocumentListOutputModel>JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, _result.data));
          // console.log('_SchoolDocument');
          // console.log(_SchoolDocument);
          if (_SchoolDocument.FileType.toLocaleLowerCase() == "documents") {
            this.DocumentList.push(_SchoolDocument);
            this.DocumentList1.push(_SchoolDocument);
          }
          else if (_SchoolDocument.FileType.toLocaleLowerCase() == "images" || "videos") {
            await this.AddItemToGallery(_SchoolDocument.UserSchoolDocumentId, _SchoolDocument.DocumentPath, _SchoolDocument.DocumentOldName, _SchoolDocument.Size, _SchoolDocument.FileType,_SchoolDocument.CreatedDate, _SchoolDocument.CreatedTime);
            await this.AddItemToGallery1(_SchoolDocument.UserSchoolDocumentId, _SchoolDocument.DocumentPath, _SchoolDocument.DocumentOldName, _SchoolDocument.Size, _SchoolDocument.FileType,_SchoolDocument.CreatedDate, _SchoolDocument.CreatedTime);
            this.needRefresh = true;
          }
        }
        else if (_result.status == Status.StorageFull) {
          this.loadingScreenService.stopLoading();
          this.uploader.cancelAll();
          this.uploader.clearQueue()
          Swal.fire({
            title: "Storage Full",
            text: _result.message,
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#7367F0',
            cancelButtonColor: '#E42728',
            confirmButtonText: "Buy More Storage",
            cancelButtonText: this.currentAppLanguage.data.SCHOOL.SWEET_ALERT_CANCEL_BUTTON_TEXT,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1'
            }
          })
            .then(
              (async (result) => {
                if (result.value) {
                  //this.uploader.clearQueue();
                  this.dialogRef.close(true);
                  this._router.navigate(['/pages/pricing'])
                }
              })
            );

        }
      }
     // this.loadingScreenService.stopLoading();
    });

    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key.toLocaleLowerCase() === "escape") {
        this.onCancelClick();
      }
    });
  }

  loadPage(event,filetype){
    // this.savePosition();
     this.pageAdvancedEllipses
    this.getAttachmentList(filetype,event)
   // setTimeout(() => this.restorePosition(), 0);
   }

   getAttachmentList(FileType?,page?) {
    this.GalleryItems=[]
    this.DocumentList = [];
    //this.needRefresh = true;
    if(page)
    this.page = page
    var getMyAttachmentsInputModel = {
      RecordId: this._data,
      Page:this.page,
      Pagesize:20,
      FileType:FileType,
    //  QuickPostType:QuickPostType.Public,
      GetMetaDataYN:false
    }
    this.loadingScreenService.startLoading();
    this.generalService.GetUserSchoolDocumentsById(getMyAttachmentsInputModel).subscribe(async result => {
      if (result) {
        if (result.status == Status.Success) {
          let _attachmentlist = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
         // console.log(_attachmentlist)
          if (_attachmentlist) {
            _attachmentlist.forEach(async(doc) => {
              if (doc.FileType.toLocaleLowerCase() == "documents") {
                this.DocumentList.push(doc);
              }
              else if (doc.FileType.toLocaleLowerCase() == "images" || "videos") {
                await this.AddItemToGallery(doc.UserSchoolDocumentId, doc.DocumentPath, doc.DocumentOldName, doc.Size, doc.FileType,doc.CreatedDate, doc.CreatedTime);
                this.needRefresh = true;
              }
              if(_attachmentlist[0]?.TotalNumberOfRecords>0)
                this.pagesNumber=_attachmentlist[0]?.TotalNumberOfRecords;
            });
         } 
        this.loadingScreenService.stopLoading();
        }
        else {
          this.loadingScreenService.stopLoading();
          this._toastrService.Error(result.Message, Constant.ErrorToastrTitle);
        }
      }
    });
  }

  checkQueueLength(queuLength){
    console.log(queuLength)
    if((queuLength>0) && (this.uploader.isUploading==false)){
      this.forgotuploadWarning=true;}
      else if(queuLength==0)
        {this.forgotuploadWarning=false;
         this.uploaderWarning=false;
        }
  }
  onFileSelected(event){
    //console.log(event)
    this.uploader.uploadAll();
  }
  async AddItemToGallery1(_id: number, _url: string, documentname: string, documentsize: string, filetype: string,CreatedDate:string, CreatedTime:string): Promise<any> {
    const loadingItem = {
      id: _id,
      size: documentsize,
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: 'https://noosom1sg.b-cdn.net/NoosomAssets/Images/noosomads/Logo-3-1.gif', // Placeholder or loading indicator for the thumbnail
      subHtml: documentname, documentsize,
      type: filetype,
      name: documentname,
      createdDate:CreatedDate,
      createdTime:CreatedTime
    };
      
    this.GalleryItems1.push(loadingItem);
    let thumb = this.cache[_url]; // Check if thumbnail is already in cache
  
    if (!thumb) {
      thumb = await this.generateThumbnailFromVideoURL(_url, filetype);
      this.cache[_url] = thumb; // Save the thumbnail to cache
    }
  
    const galleryItem = {
      id: _id,
      size: documentsize,
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: thumb,
      subHtml: documentname, documentsize,
      type: filetype,
      name: documentname,
      createdDate:CreatedDate,
      createdTime:CreatedTime
    };
    const index = this.GalleryItems1.findIndex(item => item.id === _id);
    this.GalleryItems1[index] = galleryItem;
  //  console.log(this.attachmentlist)
  } 

  async AddItemToGallery(_id: number, _url: string, documentname: string, documentsize: string, filetype: string,CreatedDate:string, CreatedTime:string): Promise<any> {
    const loadingItem = {
      id: _id,
      size: documentsize,
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: 'https://noosom1sg.b-cdn.net/NoosomAssets/Images/noosomads/Logo-3-1.gif', // Placeholder or loading indicator for the thumbnail
      subHtml: documentname, documentsize,
      type: filetype,
      name: documentname,
      createdDate:CreatedDate,
      createdTime:CreatedTime
    };
      
    this.GalleryItems.push(loadingItem);
    let thumb = this.cache[_url]; // Check if thumbnail is already in cache
  
    if (!thumb) {
      thumb = await this.generateThumbnailFromVideoURL(_url, filetype);
      this.cache[_url] = thumb; // Save the thumbnail to cache
    }
  
    const galleryItem = {
      id: _id,
      size:documentsize,
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: thumb,
      subHtml: documentname, documentsize,
      type: filetype,
      name: documentname,
      createdDate:CreatedDate,
      createdTime:CreatedTime
    };
    const index = this.GalleryItems.findIndex(item => item.id === _id);
    this.GalleryItems[index] = galleryItem;
  //  console.log(this.attachmentlist)
  }
  
  
    async generateThumbnailFromVideoURL(videoURL: string, type: string): Promise<string> {
      if (type === "Videos") {
        return new Promise<string>((resolve, reject) => {
          const video = document.createElement('video');
          video.crossOrigin = 'anonymous'; // Enable cross-origin for video
          video.src = videoURL;
    
          video.addEventListener('loadedmetadata', () => {
            video.currentTime = 10.5; // Adjust as needed
    
            video.addEventListener('seeked', () => {
              const canvas = document.createElement('canvas');
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
    
              const ctx = canvas.getContext('2d');
              ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    
              // Draw a play icon in the center of the canvas
              const playIconSize = 250; // Adjust the size as needed
              ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
              ctx.beginPath();
              ctx.moveTo(canvas.width / 2 - playIconSize / 2, canvas.height / 2 - playIconSize / 2);
              ctx.lineTo(canvas.width / 2 + playIconSize / 2, canvas.height / 2);
              ctx.lineTo(canvas.width / 2 - playIconSize / 2, canvas.height / 2 + playIconSize / 2);
              ctx.closePath();
              ctx.fill();
    
              // Resolve with the data URL of the canvas
              resolve(canvas.toDataURL('image/png'));
    
              // Clean up
              canvas.remove();
              video.remove();
            });
    
            video.addEventListener('error', (error) => {
              resolve("assets/images/pages/Cannot_play.png")
              //  reject(`Error loading video: ${error.message}`);
            });
          });
    
          video.addEventListener('error', (error) => {
            resolve("assets/images/pages/Cannot_play.png")
            //  reject(`Error loading video metadata: ${error.message}`);
          });
    
          // Load the video
          video.load();
        });
      }
    }
  
    async generateVideoData(src): Promise<string> {
      const videoData = {
        source: [{ src: src, type: 'video/mp4' }],
        attributes: { preload: true, controls: true }
      };
      return JSON.stringify(videoData);
    }

  onTabActive(tabNumber: number) {
    if(tabNumber==1){this.infoTooltip= this.currentAppLanguage.data.SCHOOL.INFO_TEXT;}
    if(tabNumber==2){
      this.infoTooltip=this.currentAppLanguage.data.SCHOOL.INFO_TEXT4;
      setTimeout(() => {
      if (this.notes)
      this.notes.nativeElement.focus();
    },500)
  }
  else if(tabNumber==3){this.infoTooltip= this.currentAppLanguage.data.SCHOOL.INFO_TEXT1;}
  else if(tabNumber==4){this.infoTooltip= this.currentAppLanguage.data.SCHOOL.INFO_TEXT2;
    this.getAttachmentList('Images');
  }
  else if(tabNumber==5){this.infoTooltip= this.currentAppLanguage.data.SCHOOL.INFO_TEXT3;
    this.getAttachmentList('Documents');
  }
    this.tab = tabNumber;
  }

  // toggleSelection(event){
  //   if(event==true){
  //     setTimeout(() => {
  //       this.frmSchool.get('SchoolToDate').setValue(null)
  //       this.frmSchool.get('SchoolToDate').setErrors(null)
  //       //console.log(this.frmSchool.get('SchoolToDate'))
  //      this.todateRequired=false;
  //     }, 100);
 
  //   }
  //   else {
  //     this.frmSchool.get('SchoolToDate').setErrors({ 'required': true })
  //     this.todateRequired=true;
  //   }
  // }

  onSubmit(tabs: boolean) {

    if (typeof this.userSchool.Latitude === 'undefined' || typeof this.userSchool.Longitude === 'undefined' || this.userSchool.Latitude == null || this.userSchool.Longitude == null) {
      this._toastrService.Warning(this.currentAppLanguage.data.SCHOOL.WARNING_MESSAGE_SELECT_LOCATION, Constant.WarningToastrTitle);
      this.onSearchOnMapClick();
      return;
    }

    if(this.uploaderWarning){
      Swal.fire({
        title: 'Warning',
        text: 'Your documents or files are still uploading. Please note that any remaining files will not be uploaded or will be terminated if you proceed to submit the form. Are you sure you want to continue?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-success'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.uploaderWarning=false;
          this.onSubmit(tabs)
        }
        else{
          this.nav.select(3);
        }
      });
      return;
     }
     if(this.forgotuploadWarning){
      Swal.fire({
        title: 'Warning',
        text: 'You have some documents or files to upload. Please note that all your files will not be uploaded or will be terminated if you proceed to submit the form. Are you sure you want to submit the form?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-success'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.forgotuploadWarning=false;
          this.onSubmit(tabs)
        }
        else{
          this.nav.select(3);
        }
      });
      return;
     }

    this.submitted = true;
    if (this.frmSchool.invalid) {
      return;
    }
    this.submitted = false;
    if (this.userSchool == null) {
      this.userSchool = new TblUserSchools();
    }
    this.userSchool.AddressTag = this.frmSchool.get('AddressTag').value;
    this.userSchool.SchoolName = this.frmSchool.get('SchoolName').value;
    this.userSchool.EducationReceived = this.frmSchool.get('EducationReceived').value;
    this.userSchool.Address1 = this.frmSchool.get('Address1').value;
    this.userSchool.Address2 = this.frmSchool.get('Address2').value;
    this.userSchool.Address3 = this.frmSchool.get('Address3').value;
    this.userSchool.AddressTown = this.frmSchool.get('AddressTown').value;
    this.userSchool.AddressPostCode = this.frmSchool.get('AddressPostCode').value;
    this.userSchool.RegionId = this.frmSchool.get('RegionId').value;
    //console.log(this.frmAddress.get('AddressToDate').value);
    this.userSchool.CountryId = this.frmSchool.get('CountryId').value;
    this.userSchool.StateId = this.frmSchool.get('StateId').value;
    this.userSchool.Notes = this.frmSchool.get('Notes').value;
    this.userSchool.SchoolFromDate = E.GetDateStringOnly(this.frmSchool.get('SchoolFromDate').value);
    this.userSchool.SchoolToDate = E.GetDateStringOnly(this.frmSchool.get('SchoolToDate').value);
    this.userSchool.UserDetailsId = this.authenticationService.currentUserValue.UserDetailsId;
    if (this.frmSchool.get('CurrentSchoolYn').value)
      this.userSchool.CurrentSchoolYn = this.frmSchool.get('CurrentSchoolYn').value;
    else
      this.userSchool.CurrentSchoolYn = false;
    if (this.frmSchool.get('CountryId').value) {
      this.userSchool.AddressCounty = this.schoolLoadDataOutputModel.CountryList.filter(c => c.CountryId == this.frmSchool.get('CountryId').value)[0].CountryName;
    }
    if (E.ConvertToNumber(this.userSchool.UserSchoolId) == 0 && this.IsForEdit == false) {
      this.userSchool.CreatedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userSchool.CreatedDate = E.GetTodayDateStringOnly();
      this.userSchool.CreatedTime = E.GetTodayTimeStringOnly();
      this.userSchool.ChangedDate = E.GetTodayDateStringOnly();
      this.userSchool.ChangedTime = E.GetTodayTimeStringOnly();
    }
    else {
      this.userSchool.ChangedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userSchool.ChangedDate = E.GetTodayDateStringOnly();
      this.userSchool.ChangedTime = E.GetTodayTimeStringOnly();
    }
    if (this.tab <= 2 || this.IsForEdit) {

      //this.loadingScreenService.startLoading();
      const path = 'api/UserSchool/CreateUpdateUserSchool';
      this.subSaveSchool$ = this.globalService.PostDataById(this.userSchool, path).subscribe(
        (result) => {

          //this.loadingScreenService.stopLoading();
          var message = "";
          // console.log('result');
          // console.log(result);
          if (result.status == Status.RecordAdded) {
            message = this.currentAppLanguage.data.SCHOOL.TOAST_MESSAGE_SCHOOL_ADDED_SUCCESSFULLY;
            this.tab = this.tab + 1;
            this.nav.select(this.tab)
            if(this.tab==2){
              setTimeout(() => {
              // console.log(this.notes)
              if (this.notes)
              this.notes.nativeElement.focus();
            },500)
          }
            const jsonString = this.encrDecr.get(Constant.EncrDecrKey, result.data);
            const parsedObject = JSON.parse(jsonString);
            this._data = parsedObject.Data.UserSchoolId
            this.userSchool.UserSchoolId = parsedObject.Data.UserSchoolId
            this.IsForAbandon = true;
          }
          else if (result.status == Status.RecordUpdated) {
            message = this.currentAppLanguage.data.SCHOOL.TOAST_MESSAGE_SCHOOL_UPDATED_SUCCESSFULLY;
            if (!tabs) {
              this.tab = this.tab + 1
              this.nav.select(this.tab)
            }
          }
          if (message.length > 0) {
            this._toastrService.Success(message, Constant.SuccessToastrTitle);
            if ((this.tab == 1) || tabs) {
              this.dialogRef.close(true);
              this.frmSchool.reset();
              this.frmSchool.patchValue({
                SchoolFromDate: new Date(),
                SchoolToDate: new Date()
              });
            }
            (<any>this.frmSchool.get('AddressTag')).nativeElement.focus();
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;

          //this.loadingScreenService.stopLoading();
        }
      );
    }
    else if (this.tab >= 2 && this.tab <= 5) {
      this.tab = this.tab + 1;
      this.nav.select(this.tab)
      if (tabs) {
        this.dialogRef.close(true);
        this.frmSchool.reset();
      }
    }
  }

  async onDownloadAllDocument() {
    for (let index = 0; index < this.DocumentList.length; index++) {
      const _doc = this.DocumentList[index];
      var _res = await this.DownloadMySchoolDocument(_doc, true);
      // console.log('_res');
      // console.log(_res);
      if (_res) {
        if ((index + 1) === this.DocumentList.length) {
          this._toastrService.Success('Downloaded All Successfully.', Constant.SuccessToastrTitle);
        }
      } else {
        break;
      }
    }
  }

  async onDownloadDocument(document: SchoolDocumentListOutputModel) {
    if (document) {
      await this.DownloadMySchoolDocument(document);
    }
  }

  async DownloadMySchoolDocument(Document: SchoolDocumentListOutputModel, IsDownloadAll = false) {
    //console.log('DeleteMyAddressDocument');
    this.loadingScreenService.startLoading();
    if (typeof Document === 'undefined' || Document == null) {
      return false;
    }
    this.downloadFileInputModel = {
      DownloadID: Document.UserSchoolDocumentId,
      FilePath: Document.DocumentPath,
      UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId
    };
    let promise = new Promise((resolve, reject) => {
      var Result: boolean = false;
      const path = 'api/UserSchool/DownloadUserSchoolDocuments';
      this.subDownloadSchoolAtt$ = this.globalService.PostDataById(this.downloadFileInputModel, path).subscribe(
        (result) => {
          this.loadingScreenService.stopLoading();
          // console.log('result');
          // console.log(result.status);
          // console.log(Status.Success);
          if (result.status == Status.Success) {
            let _downloaded = <DownloadFileOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)));
            var sampleArr = this.globalService.base64ToArrayBuffer(E.ConvertToString(_downloaded.FileBase64String));
            this.globalService.saveByteArray(_downloaded.FileName, sampleArr, _downloaded.FileMimeType);
            if (IsDownloadAll === false)
              this._toastrService.Success('Downloaded Successfully.', Constant.SuccessToastrTitle);
            //console.log(this.DocumentList);
            Result = true;
          }
          else {
            this._toastrService.Error(result.message, Constant.ErrorToastrTitle);
            Result = false;
          }
          resolve(Result);
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          this.loadingScreenService.stopLoading();
          resolve(Result);
        }
      );
    });
    return await promise;
  }

  async OnSchoolDeleteClick() {
    //console.log('OnAddressDeleteClick');
    Swal.fire({
      title: this.currentAppLanguage.data.SCHOOL.SWEET_ALERT_TITLE_SCHOOL_TEXT,
      text: this.currentAppLanguage.data.SCHOOL.SWEET_ALERT_TEXT,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: this.currentAppLanguage.data.SCHOOL.SWEET_ALERT_CONFIRM_BUTTON_TEXT,
      cancelButtonText: this.currentAppLanguage.data.SCHOOL.SWEET_ALERT_CANCEL_BUTTON_TEXT,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    })
      .then(
        (async (result) => {
          if (result.value) {
            const UserSchoolId = this._data
            const a = await this.DeleteMySchool(UserSchoolId);
          }
        })
      );
  }

  async DeleteMySchool(UserSchoolId: number) {
    this.loadingScreenService.startLoading();
    this.deleteInputModel = {
      DeleteID: UserSchoolId,
      UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId
    }
    let promise = new Promise((resolve, reject) => {
      var Result: boolean;
      const path = 'api/UserSchool/DeleteUserSchool';
      this.subDeleteSchool$ = this.globalService.PostDataById(this.deleteInputModel, path).subscribe(
        (data) => {
          this.loadingScreenService.stopLoading();
          var result = JSON.parse(JSON.stringify(data));
          if (result.status == Status.Success) {
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: E.ConvertToString(result.message),
              customClass: {
                confirmButton: 'btn btn-success'
              }
            });
            this.dialogRef.close(true);
            this.frmSchool.reset();
            Result = true;
          }
          else {
            this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
            Result = false;
          }
          resolve(Result);
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          this.loadingScreenService.stopLoading();
        }
      );
    });
    return await promise;
  }

  async onDeleteAllDocument() {
    let UserSchoolDocumentIds: number[] = [];
    if (this.DocumentList) {
      this.DocumentList.forEach(_doc => {
        UserSchoolDocumentIds.push(_doc.UserSchoolDocumentId);
      });
      if (UserSchoolDocumentIds.length > 0) {
        Swal.fire({
          title: this.currentAppLanguage.data.SCHOOL.SWEET_ALERT_TITLE_ALL_ADDRESS_DOCUMENT_TEXT,
          text: this.currentAppLanguage.data.SCHOOL.SWEET_ALERT_TEXT,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#7367F0',
          cancelButtonColor: '#E42728',
          confirmButtonText: this.currentAppLanguage.data.SCHOOL.SWEET_ALERT_CONFIRM_BUTTON_TEXT,
          cancelButtonText: this.currentAppLanguage.data.SCHOOL.SWEET_ALERT_CANCEL_BUTTON_TEXT,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1'
          }
        })
          .then(
            (async (result) => {
              if (result.value) {
                const _res = await this.DeleteMySchoolDocument(UserSchoolDocumentIds);
              }
            })
          );
      }
    }
  }

  // async onDeleteDocument(userschoolDocumentId: number) {
  //   let UserSchoolDocumentIds: number[] = [];
  //   if (userschoolDocumentId) {
  //     UserSchoolDocumentIds.push(userschoolDocumentId);
  //     Swal.fire({
  //       title: this.currentAppLanguage.data.SCHOOL.SWEET_ALERT_TITLE_ADDRESS_DOCUMENT_TEXT,
  //       text: this.currentAppLanguage.data.SCHOOL.SWEET_ALERT_TEXT,
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#7367F0',
  //       cancelButtonColor: '#E42728',
  //       confirmButtonText: this.currentAppLanguage.data.SCHOOL.SWEET_ALERT_CONFIRM_BUTTON_TEXT,
  //       cancelButtonText: this.currentAppLanguage.data.SCHOOL.SWEET_ALERT_CANCEL_BUTTON_TEXT,
  //       customClass: {
  //         confirmButton: 'btn btn-primary',
  //         cancelButton: 'btn btn-danger ml-1'
  //       }
  //     })
  //       .then(
  //         (async (result) => {
  //           if (result.value) {
  //             const a = await this.DeleteMySchoolDocument(UserSchoolDocumentIds);
  //           }
  //         })
  //       );
  //   }
  // }
  // async DeleteMySchoolDocument(UserSchoolDocumentIds: Array<number>) {
  //   //console.log('DeleteMyAddressDocument');
  //   this.loadingScreenService.startLoading();
  //   if (UserSchoolDocumentIds.length < 1) {
  //     return false;
  //   }
  //   this.deleteDocumentsInputModel = [];
  //   UserSchoolDocumentIds.forEach(userschoolDocumentId => {
  //     this.deleteDocumentsInputModel.push({
  //       DeleteID: userschoolDocumentId,
  //       UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId
  //     });
  //   });
  //   let promise = new Promise((resolve, reject) => {
  //     var Result: boolean;
  //     const path = 'api/UserSchool/DeleteUserSchoolDocuments';
  //     this.subDeleteSchoolAtt$ = this.globalService.PostDataById(this.deleteDocumentsInputModel, path).subscribe(
  //       (data) => {
  //         this.loadingScreenService.stopLoading();
  //         //console.log(data);
  //         var result = JSON.parse(JSON.stringify(data));
  //         //console.log(result);
  //         if (result.status == Status.Success) {
  //           Swal.fire({
  //             icon: 'success',
  //             title: 'Deleted!',
  //             text: E.ConvertToString(result.message),
  //             customClass: {
  //               confirmButton: 'btn btn-success'
  //             }
  //           });
  //           this.DocumentList = this.DocumentList.filter(x => !UserSchoolDocumentIds.includes(x.UserSchoolDocumentId));
  //           const indexToRemove: number = this.GalleryItems.findIndex(item => item.id == UserSchoolDocumentIds);
  //           if (indexToRemove !== -1) {
  //             this.GalleryItems.splice(indexToRemove, 1);
  //           }
  //           Result = true;
  //         }
  //         else {
  //           this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
  //           Result = false;
  //         }
  //         resolve(Result);
  //       },
  //       (error) => {
  //         this._toastrService.Error(error, Constant.ErrorToastrTitle);
  //         this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
  //         this.loadingScreenService.stopLoading();
  //       }
  //     );
  //   });
  //   return await promise;
  // }

  async onDeleteDocument(userschoolDocumentId: number) {
    if (!userschoolDocumentId) {
      return;
    }
  
    try {
      await this.showConfirmationDialog();
      const result = await this.DeleteMySchoolDocument([userschoolDocumentId]);
  
      if (result) {
        // Next line of code after both function1 and function2 have completed
      }
    } catch (error) {
    }
  }
  
  showConfirmationDialog(): Promise<void> {
    return new Promise<void>((resolve) => {
      Swal.fire({
        title: this.currentAppLanguage.data.SCHOOL.SWEET_ALERT_TITLE_ADDRESS_DOCUMENT_TEXT,
        text: this.currentAppLanguage.data.SCHOOL.SWEET_ALERT_TEXT,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7367F0',
        cancelButtonColor: '#E42728',
        confirmButtonText: this.currentAppLanguage.data.SCHOOL.SWEET_ALERT_CONFIRM_BUTTON_TEXT,
        cancelButtonText: this.currentAppLanguage.data.SCHOOL.SWEET_ALERT_CANCEL_BUTTON_TEXT,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
      }).then((result) => {
        if (result.value) {
          resolve();
        }
      });
    });
  }
  
  async DeleteMySchoolDocument(UserSchoolDocumentIds: Array<number>): Promise<boolean> {
    this.loadingScreenService.startLoading();
  
    try {
      if (UserSchoolDocumentIds.length < 1) {
        return false;
      }
  
      this.deleteDocumentsInputModel = [];
       this.UserSchoolDocument = UserSchoolDocumentIds
      UserSchoolDocumentIds.forEach((userschoolDocumentId) => {
        this.deleteDocumentsInputModel.push({
          DeleteID: userschoolDocumentId,
          UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId,
        });
      });
  
      const result = await this.postDataAndHandleResponse();
  
      return result;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    } finally {
      this.loadingScreenService.stopLoading();
    }
  }
  
  private postDataAndHandleResponse(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const path = 'api/UserSchool/DeleteUserSchoolDocuments';
      this.subDeleteSchoolAtt$ = this.globalService.PostDataById(this.deleteDocumentsInputModel, path).subscribe(
        (data) => {
          const result = JSON.parse(JSON.stringify(data));
  
          if (result.status === Status.Success) {
            // Handle success
            this.handleSuccess(result);
            resolve(true);
          } else {
            // Handle failure
            this.handleFailure(result);
            resolve(false);
          }
        },
        (error) => {
          // Handle network error
          this.handleNetworkError(error);
          resolve(false);
        }
      );
    });
  }
  
  private handleSuccess(result: any): void {
    Swal.fire({
      icon: 'success',
      title: 'Deleted!',
      text: E.ConvertToString(result.message),
      customClass: {
        confirmButton: 'btn btn-success',
      },
    });
  
    // Modify this part as needed
    this.DocumentList = this.DocumentList.filter((x) => !this.UserSchoolDocument.includes(x.UserSchoolDocumentId));
    this.DocumentList1 = this.DocumentList1.filter((x) => !this.UserSchoolDocument.includes(x.UserSchoolDocumentId));
    const indexToRemove: number = this.GalleryItems.findIndex((item) => item.id == this.UserSchoolDocument);
    if (indexToRemove !== -1) {
      this.GalleryItems.splice(indexToRemove, 1);
    }
    const indexToRemovenew: number = this.GalleryItems1.findIndex((item) => item.id == this.UserSchoolDocument);
    if (indexToRemovenew !== -1) {
      this.GalleryItems1.splice(indexToRemovenew, 1);
    }
  }
  
  private handleFailure(result: any): void {
    this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
  }
  
  private handleNetworkError(error: any): void {
    this._toastrService.Error(error, Constant.ErrorToastrTitle);
    this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
  }
  


  onComingSoon() {
    this._toastrService.Info(this.currentAppLanguage.data.SCHOOL.INFO_MESSAGE_COMING_SOON, Constant.InfoToastrTitle);
  }
  onCancelClick() {
    this.dialogRef.close(false);
  }

  onSearchOnMapClick() {
    if (E.ConvertToString(this.frmSchool.get('Address1').value) != '' && E.ConvertToString(this.frmSchool.get('AddressTown').value) != ''
      && E.ConvertToString(this.frmSchool.get('AddressPostCode').value) != '') {
      this.openDialogMapAddress()

    }
    else {
      this._toastrService.Error("The required fields are empty", Constant.ErrorToastrTitle);
    }
  }

  onStateChange(event) {
    if (event != null) {
      this.PlaceholderCountry = "";
      //this.loadingScreenService.startLoading();
      const path = 'api/State/GetCountryRegionId';
      var list = this.globalService.PostDataAnonymous(event, path).subscribe(
        (data) => {
          //this.loadingScreenService.stopLoading();
          var result = JSON.parse(data._body);
          if (result.status == Status.Success) {
           // console.log(JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data)))
            var countryRegion = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
            this.frmSchool.get('CountryId').setValue(countryRegion?.CountryId);
            this.frmSchool.get('RegionId').setValue(countryRegion?.RegionId);
         //   this.familyMemberLoadDataOutputModel.StateList = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
          //  if (this.familyMemberLoadDataOutputModel.StateList != null) {
          //    this.frmFamily.get('StateId').enable();
          //  }
          }
        },
        (error) => {
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          //this.loadingScreenService.stopLoading();
        }
      );
    }
    else {
      this.PlaceholderCountry = this.currentAppLanguage.data.SCHOOL.PLACEHOLDER_STATE;
      this.frmSchool.get('RegionId').setValue(null);
      this.PlaceholderRegion = this.currentAppLanguage.data.SCHOOL.PLACEHOLDER_REGION;
      this.frmSchool.get('RegionId').disable();
      this.frmSchool.get('CountryId').setValue(null);
      this.PlaceholderCountry = this.currentAppLanguage.data.SCHOOL.PLACEHOLDER_COUNTRY;
      this.frmSchool.get('CountryId').disable();
    }
  }

  GetMapSearchString(): string {
    let result = '';
    if (this.frmSchool.get('Address1').value)
      result += (this.frmSchool.get('Address1').value + ', ');
    if (this.frmSchool.get('AddressTown').value)
      result += (this.frmSchool.get('AddressTown').value + ', ');
    if (this.frmSchool.get('AddressPostCode').value)
      result += (this.frmSchool.get('AddressPostCode').value);
    return result;
  }

  openDialogMapAddress() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "70%"
    dialogConfig.height = "55%";
    dialogConfig.data = new MapAddressDataModel(MapModalType.SearchString, this.GetMapSearchString());
    const dialogRef: MatDialogRef<MapAddressComponent> = this.dialog.open(MapAddressComponent, dialogConfig);
    dialogRef.componentInstance.IsDraggable = true;
    dialogRef.componentInstance.IsSaveButtonVisible = true;
    dialogRef.componentInstance.IsSearchOnMap = true;
    dialogRef.afterClosed().subscribe(
      result => {
        // console.log('Map closed');
        // console.log(result);
        if (result) {
          if (E.ConvertToString(result.event) == 'SearchOnMap' && result.data) {
            this.userSchool.Latitude = result.data.lat;
            this.userSchool.Longitude = result.data.long;
          }
        }
        // this.loadData();
      }
    );
  }

  OnSchoolToDateChange(event) {
    // console.log('OnSchoolToDateChange');
    // console.log(this.maxFromDate);
    // console.log(this.frmSchool.get('AddressToDate').value);
    let datefrom = this.frmSchool.get('SchoolFromDate').value;
    let dateto = this.frmSchool.get('SchoolToDate').value;
    if (datefrom && dateto) {
      if (datefrom.getTime() > dateto.getTime()) {
        this.frmSchool.get('SchoolFromDate').setValue(dateto);
      }
    }
    if (dateto)
      this.maxFromDate = dateto;
  }

  onCountryChange(event) {
    this.schoolLoadDataOutputModel.StateList = null;
    this.frmSchool.get('StateId').setValue(null);
    this.PlaceholderState = this.currentAppLanguage.data.SCHOOL.PLACEHOLDER_STATE;
    this.frmSchool.get('StateId').disable();
    if (event != null) {
      this.PlaceholderCountry = "";
      //this.loadingScreenService.startLoading();
      const path = 'api/State/GetStateList';
      this.subStateList$ = this.globalService.PostDataAnonymous(event, path).subscribe(
        (data) => {
          //this.loadingScreenService.stopLoading();
          var result = JSON.parse(data._body);
          if (result.status == Status.Success) {
            this.schoolLoadDataOutputModel.StateList = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
            if (this.schoolLoadDataOutputModel.StateList != null) {
              this.frmSchool.get('StateId').enable();
            }
          }
        },
        (error) => {
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          //this.loadingScreenService.stopLoading();
        }
      );
    }
    else {
      this.PlaceholderCountry = this.currentAppLanguage.data.SCHOOL.PLACEHOLDER_COUNTRY;
    }
  }
  onRegionChange(event) {
    this.schoolLoadDataOutputModel.CountryList = null;
    this.frmSchool.get('CountryId').setValue(null);
    this.PlaceholderCountry = this.currentAppLanguage.data.SCHOOL.PLACEHOLDER_COUNTRY;
    this.frmSchool.get('CountryId').disable();
    if (event != null) {
      this.PlaceholderRegion = "";
      //this.loadingScreenService.startLoading();
      const path = 'api/Country/GetCountryList';
      this.subCountryList$ = this.globalService.PostDataAnonymous(event, path).subscribe(
        (data) => {
          //this.loadingScreenService.stopLoading();
          var result = JSON.parse(data._body);
          if (result.status == Status.Success) {
            this.schoolLoadDataOutputModel.CountryList = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
            if (this.schoolLoadDataOutputModel.CountryList != null) {
              this.frmSchool.get('CountryId').enable();
            }
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          //this.loadingScreenService.stopLoading();
        }
      );
    }
    else {
      this.PlaceholderRegion = this.currentAppLanguage.data.SCHOOL.PLACEHOLDER_REGION;
    }
  }
  onCloseButtonClick() {
    this.dialogRef.close(false);
  }
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
    // console.log(index, prevIndex);
  };
  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }
  get f() {
    return this.frmSchool.controls;
  }

  ngOnDestroy() {
    if (this.subSaveSchool$)
      this.subSaveSchool$.unsubscribe();
    if (this.subDownloadSchoolAtt$)
      this.subDownloadSchoolAtt$.unsubscribe();
    if (this.subDeleteSchoolAtt$)
      this.subDeleteSchoolAtt$.unsubscribe();
    if (this.subDeleteSchool$)
      this.subDeleteSchool$.unsubscribe();
    if (this.subStateList$)
      this.subStateList$.unsubscribe();
    if (this.subCountryList$)
      this.subCountryList$.unsubscribe();
    if (this.uploader && this.uploader.response)
      this.uploader.response.unsubscribe();
  }
}
