import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from 'app/Services/global.service';
import {TblUserAchievements} from '../about-me-model.model'
@Injectable({
  providedIn: 'root'
})
export class MyAchievementService {

  constructor(
    private _http: HttpClient,private globalService: GlobalService
  ) { }
  CreateUpdateUserAchievement(userAchievement:TblUserAchievements){
    const path='api/UserAchievement/CreateUpdateUserAchievement'
    return  this.globalService.PostDataById(userAchievement , path);
  }
  GetUserAchievementLoadData(_data:number){
    const path='api/UserAchievement/GetUserAchievementLoadData'
    return  this.globalService.PostDataById(_data , path);
  }
}
