import { Injectable } from '@angular/core';
import { GlobalService } from 'app/Services/global.service';

@Injectable({
  providedIn: 'root'
})
export class PasttimesService {

  constructor(private globalService:GlobalService) { }
  CreateUpdateUserPastime(data) { //create or update data
    const path = 'api/UserPastime/CreateUpdateUserPastime';
    return this.globalService.PostDataById(data, path);
  }
  GetUserPastimes(data) { //list view
    const path = 'api/UserPastime/GetUserPastimes';
    return this.globalService.PostDataById(data, path);
  }
  GetUserPastimeLoadData(data) {  //edit view
    const path = 'api/UserPastime/GetUserPastimeLoadData';
    return this.globalService.PostDataById(data, path);
  }
  DeleteUserPastime(data) { //delete pastimes
    const path = 'api/UserPastime/DeleteUserPastime';
    return this.globalService.PostDataById(data, path);
  }

  DeleteUserPastimeDocuments(data) { //delete document multiple or single data
    const path = 'api/UserPastime/DeleteUserPastimeDocuments';
    return this.globalService.PostDataById(data, path);
  }

  CreateUpdateUserHobby(data) { //create or update data
    const path = 'api/UserHobby/CreateUpdateUserHobby';
    return this.globalService.PostDataById(data, path);
  }

  GetUserHobbies(data) { //create or update data
    const path = 'api/UserHobby/GetUserHobbies';
    return this.globalService.PostDataById(data, path);
  }

  GetUserHobbyLoadData(data) { //Edit data
    const path = 'api/UserHobby/GetUserHobbyLoadData';
    return this.globalService.PostDataById(data, path);
  }

  DeleteUserHobby(data) { //create or update data
    const path = 'api/UserHobby/DeleteUserHobby';
    return this.globalService.PostDataById(data, path);
  }

  DeleteUserHobbyDocuments(data) { //create or update data
    const path = 'api/UserHobby/DeleteUserHobbyDocuments';
    return this.globalService.PostDataById(data, path);
  }

}
