import { Injectable } from '@angular/core';
import { GlobalService } from 'app/Services/global.service';

@Injectable({
  providedIn: 'root'
})
export class LegalserviceService {

  constructor(private globalService:GlobalService) { }

  CreateUpdateUserLegal(data){ //create/update
    const path = 'api/UserLegal/CreateUpdateUserLegal';
    return this.globalService.PostDataById(data,path);
  }
  GetUserLegalLoadData(data){ //edit data
    const path = 'api/UserLegal/GetUserLegalLoadData';
    return this.globalService.PostDataById(data,path);
  }
  GetUserLegals(data){
    const path = 'api/UserLegal/GetUserLegals';
    return this.globalService.PostDataById(data,path);
  }
  DeleteUserLegal(data){
    const path = 'api/UserLegal/DeleteUserLegal';
    return this.globalService.PostDataById(data,path);
  }
  DeleteUserLegalDocuments(data){
    const path = 'api/UserLegal/DeleteUserLegalDocuments';
    return this.globalService.PostDataById(data,path);
  }

  firebase(data){
    const path = 'api/UserConnectionRequest/SendNotification';
    return this.globalService.PostDataAnonymous(data,path);
  }

}
