import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appRemoveMargins]'
})
export class RemoveMarginsDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) { }


  ngAfterViewInit() {
    const elements = this.el.nativeElement.querySelectorAll('*');
    elements.forEach((element: HTMLElement) => {
      if (['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(element.tagName)) {
        this.replaceWithPTag(element);
      } else {
        this.removeStyles(element);
      }
    });
  }
  
  private removeStyles(element: HTMLElement) {
    this.renderer.setStyle(element, 'margin', '0');
    this.renderer.setStyle(element, 'padding', '0');
  }
  
  private replaceWithPTag(element: HTMLElement) {
    const pTag = this.renderer.createElement('p');
    pTag.innerHTML = element.innerHTML;
    this.renderer.setStyle(pTag, 'margin', '0');
    this.renderer.setStyle(pTag, 'padding', '0');
    element.parentNode.replaceChild(pTag, element);
  }
}
