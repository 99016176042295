import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
import { locale as german } from '../i18n/de';
import { locale as portuguese } from '../i18n/pt';
import { locale as gujarati } from '../i18n/gj';
import { locale as hindi } from '../i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { TextBoxComponent } from 'app/CustomComponents/text-box/text-box.component';
import { CoreConfigService } from '@core/services/config.service';
import { E } from 'assets/extensions/extensions';
import { AuthenticationService } from 'app/auth/service';
import { Status, Enums, MapModalType } from 'app/enums';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { Observable, Subject } from 'rxjs';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { GlobalService } from 'app/Services/global.service';
import { Constant } from 'app/Classes/constant';
import { takeUntil } from 'rxjs/operators';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { MedicationLoadDataInputModel, MedicationLoadDataOutputModel, TblUserMedications } from '../about-me-model.model';
import { MyMedicationService } from './my-medication.service'


@Component({
  selector: 'app-my-medication',
  templateUrl: './my-medication.component.html',
  styleUrls: ['./my-medication.component.scss']
})
export class MyMedicationComponent implements OnInit {
  @ViewChild('medicationname') medicationname: TextBoxComponent;
  public coreConfig: any;
  public dialogTitle: string;
  public IsForEdit: boolean = false;
  public maxToDate: Date = new Date();
  public maxFromDate: Date = new Date();
  currentAppLanguage: any;
  private _unsubscribeAll: Subject<any>;
  public frmMedication: FormGroup;
  public loading = false;
  public submitted = false;
  _data: number = 0;
  public error = '';
  userMedication: TblUserMedications;
  medicationLoadDataOutputModel: MedicationLoadDataOutputModel;
  medicationLoadDataInputModel: MedicationLoadDataInputModel;
  infoTooltip: any;
  dateformat: string;
  constructor(
    private dialogRef: MatDialogRef<MyMedicationComponent>,
    private medicationService: MyMedicationService,
    private _coreTranslationService: CoreTranslationService,
    private _coreConfigService: CoreConfigService,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private loadingScreenService: LoadingScreenService,
    private globalService: GlobalService,
    private encrDecr: EncrDecrService,
    private _toastrService: CustomToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._unsubscribeAll = new Subject();
    this._data = data;
    this.dateformat=this.globalService.getDateFormat().toUpperCase()
  }
  ngAfterViewInit(): void {
    this.medicationname.textBox.nativeElement.focus();
  }
  ngOnInit(): void {
    this.userMedication = new TblUserMedications();
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);
    this.dialogTitle = this.currentAppLanguage.data.MY_MEDICATION_FORM.DIALOG_TITLE_ADD_MEDICATION;
    this.infoTooltip= this.currentAppLanguage.data.MY_MEDICATION_FORM.SECTION_TEXT;
    this.frmMedication = this._formBuilder.group({
      PrescribedBy: [],
      RefillDurationId: [],
      MedicationName: [, Validators.required],
      ConditionFor: [, Validators.required],
      Dosage: [],
      Frequency: [, Validators.required],
      LastRefillDate: [],
      NextRefillDate: [],
      Notes: [],
    });
    if (typeof this._data !== 'undefined') {
      // console.log('this._data')
      // console.log(this._data)
      if (this._data > 0) {
        this.IsForEdit = true;
        this.dialogTitle = this.currentAppLanguage.data.MY_MEDICATION_FORM.DIALOG_TITLE_EDIT_MEDICATION;
      }
      setTimeout(() => {
        this.medicationLoadDataInputModel = new MedicationLoadDataInputModel();
        this.medicationLoadDataInputModel.UserDetailsId = this.authenticationService.currentUserValue.UserDetailsId;
        this.medicationLoadDataInputModel.UserMedicationId = this._data;
        this.loadingScreenService.startLoading();
       // console.log(this.medicationLoadDataInputModel)
        let response: Observable<any>;
        this.medicationService.GetUserMedicationLoadData(this.medicationLoadDataInputModel)
          .subscribe(
            (data) => {
              this.loadingScreenService.stopLoading();
              //  console.log(data);
              if (data.status == Status.Success) {

                this.medicationLoadDataOutputModel = <MedicationLoadDataOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, data.data)));
               //  console.log(this.medicationLoadDataOutputModel)
                if (this.medicationLoadDataOutputModel.UserMedication) {
                  this.userMedication = this.medicationLoadDataOutputModel.UserMedication;
                  this.frmMedication.patchValue({
                    MedicationName: this.userMedication.MedicationName,
                    ConditionFor: this.userMedication.ConditionFor,
                    Dosage: this.userMedication.Dosage,
                    Frequency: this.userMedication.Frequency,
                    PrescribedBy: this.userMedication.PrescribedBy,
                    RefillDurationId: this.userMedication.RefillDurationId,
                    LastRefillDate: E.ConvertToDate(this.userMedication.LastRefillDate),
                    NextRefillDate: E.ConvertToDate(this.userMedication.NextRefillDate),
                    Notes: this.userMedication.Notes,
                  });

                }

              }
              else {
                this._toastrService.Error(E.ConvertToString(data.message), Constant.ErrorToastrTitle);
              }
            },
            (error) => {
              this._toastrService.Error(error, Constant.ErrorToastrTitle);
              this.error = error;
              this.loadingScreenService.stopLoading();
            }
          );

      }, 500);
      //}
    }
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key.toLocaleLowerCase() === "escape") {
        this.onCloseButtonClick();
      }
    });
  }

  onSubmit() {
    this.frmMedication.controls['LastRefillDate'].setErrors(null);
    this.frmMedication.controls['NextRefillDate'].setErrors(null);
    this.submitted = true;
    if (this.frmMedication.invalid) {
     //  console.log(this.frmMedication.invalid)
      return;
    }
    this.submitted = false;
    if (this.userMedication == null) {
      this.userMedication = new TblUserMedications();
    }
    this.userMedication.MedicationName = this.frmMedication.get('MedicationName').value;
    this.userMedication.ConditionFor = this.frmMedication.get('ConditionFor').value;
    this.userMedication.Dosage = this.frmMedication.get('Dosage').value;
    this.userMedication.Frequency = this.frmMedication.get('Frequency').value;
    this.userMedication.PrescribedBy = this.frmMedication.get('PrescribedBy').value;
    this.userMedication.RefillDurationId = this.frmMedication.get('RefillDurationId').value;
    this.userMedication.LastRefillDate = E.GetDateStringOnly(this.frmMedication.get('LastRefillDate').value);
    this.userMedication.NextRefillDate = E.GetDateStringOnly(this.frmMedication.get('NextRefillDate').value);
    this.userMedication.Notes = this.frmMedication.get('Notes').value;
    this.userMedication.UserDetailsId = this.authenticationService.currentUserValue.UserDetailsId;

    
    if (E.ConvertToNumber(this.userMedication.UserMedicationId) == 0 && this.IsForEdit == false) {
      this.userMedication.CreatedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userMedication.CreatedDate = E.GetTodayDateStringOnly();
      this.userMedication.CreatedTime = E.GetTodayTimeStringOnly();
      this.userMedication.ChangedDate = E.GetTodayDateStringOnly();
      this.userMedication.ChangedTime = E.GetTodayTimeStringOnly();
    }
    else {
      this.userMedication.ChangedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userMedication.ChangedDate = E.GetTodayDateStringOnly();
      this.userMedication.ChangedTime = E.GetTodayTimeStringOnly();
    }
    this.loading = true;
    this.loadingScreenService.startLoading();
    //console.log(this.userMedication)
    let response: Observable<any>;
    this.medicationService.CreateUpdateUserMedication(this.userMedication)
      .subscribe(
        (result) => {
          this.loading = false;
          this.loadingScreenService.stopLoading();
          var message = ""; var errormessage = "";
          if (result.status == Status.RecordAdded) {
            message = this.currentAppLanguage.data.MY_MEDICATION_FORM.TOAST_MESSAGE_MEDICATION_ADDED_SUCCESSFULLY;
          }
          else if (result.status == Status.RecordUpdated) {
            message = this.currentAppLanguage.data.MY_MEDICATION_FORM.TOAST_MESSAGE_MEDICATION_UPDATED_SUCCESSFULLY;
          }
          else {
            errormessage = E.ConvertToString(result.message);
          }
          if (message.length > 0) {
            this._toastrService.Success(message, Constant.SuccessToastrTitle);
            this.dialogRef.close();
            this.frmMedication.reset();
            this.frmMedication.patchValue({
              LastRefillDate: new Date(),
              NextRefillDate: new Date()
            });
          }
          else {
            this._toastrService.Error(errormessage, Constant.ErrorToastrTitle);
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
          this.loading = false;
          this.loadingScreenService.stopLoading();
        }
      );

  }
  OnNextRefillDateChange(event) {
    let datefrom = this.frmMedication.get('LastRefillDate').value;
    let dateto = this.frmMedication.get('NextRefillDate').value;
    if (datefrom && dateto) {
      if (datefrom.getTime() > dateto.getTime()) {
        this.frmMedication.get('LastRefillDate').setValue(dateto);
      }
    }
    if (dateto)
      this.maxFromDate = dateto;
  }
  onCloseButtonClick() {
    this.dialogRef.close();
  }
  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }
  get f() {
    return this.frmMedication.controls;
  }
}
