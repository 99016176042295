import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
import { locale as german } from '../i18n/de';
import { locale as portuguese } from '../i18n/pt';
import { locale as gujarati } from '../i18n/gj';
import { locale as hindi } from '../i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { CoreConfigService } from '@core/services/config.service';
import {Observable, Subject} from 'rxjs';
import { Constant } from 'app/Classes/constant';
import { E } from 'assets/extensions/extensions';
import { Status, Enums, MapModalType } from 'app/enums';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'app/auth/service';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { GlobalService } from 'app/Services/global.service';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import {MyAchievementService} from './my-achievement.service'
import {TblUserAchievements,AchievementLoadDataOutputModel,AchievementsLoadDataInputModel} from '../about-me-model.model'
import { TextBoxComponent } from 'app/CustomComponents/text-box/text-box.component';
@Component({
  selector: 'app-my-achievement',
  templateUrl: './my-achievement.component.html',
  styleUrls: ['./my-achievement.component.scss']
})
export class MyAchievementComponent implements OnInit {
  @ViewChild('achievementname') achievementname: TextBoxComponent;
  public coreConfig: any;
  public dialogTitle: string;
  public IsForEdit: boolean = false;
  public loading = false;
  public submitted = false;
  maxDate=new Date();
  public error = '';
  _data: number = 0;
  currentAppLanguage: any;
  private _unsubscribeAll: Subject<any>;
  public frmAchievement: FormGroup;
  userAchievement:TblUserAchievements;
  achievementLoadDataOutputModel:AchievementLoadDataOutputModel;
  infoTooltip: string;
  dateformat: string;
  // achievementsLoadDataInputModel:AchievementsLoadDataInputModel;
  constructor(
    private dialogRef: MatDialogRef<MyAchievementComponent>,
    private _coreTranslationService: CoreTranslationService,
    private _coreConfigService: CoreConfigService,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private loadingScreenService: LoadingScreenService,
    private globalService: GlobalService,
    private encrDecr: EncrDecrService,
    private _toastrService: CustomToastrService,
    public achievementService:MyAchievementService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._unsubscribeAll = new Subject();
    this._data = data;
    this.dateformat=this.globalService.getDateFormat().toUpperCase()
   }
   ngAfterViewInit(): void {
    this.achievementname.textBox.nativeElement.focus();
  }
  ngOnInit(): void {
    this.userAchievement = new TblUserAchievements();
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
     });
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);
    if(this._data==1)
    this.dialogTitle = this.currentAppLanguage.data.MY_AWARDS_ACHIEVEMENT.DIALOG_TITLE_ADD_ACHIEVEMENT;
    if(this._data==2)
    this.dialogTitle = this.currentAppLanguage.data.MY_AWARDS_ACHIEVEMENT.DIALOG_TITLE_ADD_EDUCATIONAL_ACHIEVEMENT;
    if(this._data==3)
    this.dialogTitle = this.currentAppLanguage.data.MY_AWARDS_ACHIEVEMENT.DIALOG_TITLE_ADD_PROFESSIONAL_ACHIEVEMENT;
    if(this._data==4)
    this.dialogTitle = this.currentAppLanguage.data.MY_AWARDS_ACHIEVEMENT.DIALOG_TITLE_ADD_MISCELLANEOUS_ACHIEVEMENT;
    this.infoTooltip= this.currentAppLanguage.data.MY_AWARDS_ACHIEVEMENT.SECTION_TEXT;
    this.frmAchievement = this._formBuilder.group({
      AchievementName: ['', Validators.required],
      Occasion:['',Validators.required],
      DateReceived: [, Validators.required],
      OccasionNotes: [,Validators.required],
    });
    if (typeof this._data !== 'undefined') {
      // console.log('this._data')
      // console.log(this._data)
      if (this._data > 5) {
        this.IsForEdit = true;
        this.dialogTitle = this.currentAppLanguage.data.MY_AWARDS_ACHIEVEMENT.DIALOG_TITLE_EDIT_ACHIEVEMENT;
      }
      setTimeout(() => {
        this.loadingScreenService.startLoading();
        // console.log(this.achievementsLoadDataInputModel);
        let response: Observable<any>;
        this.achievementService.GetUserAchievementLoadData(this._data)
          .subscribe(
            (data) => {
              this.loadingScreenService.stopLoading();
              if (data.status == Status.Success) {
                this.achievementLoadDataOutputModel = <AchievementLoadDataOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, data.data)));
               //  console.log(this.achievementLoadDataOutputModel)
                if (this.achievementLoadDataOutputModel.UserAchievement) {
                  this.userAchievement = this.achievementLoadDataOutputModel.UserAchievement;
                  this.frmAchievement.patchValue({
                     AchievementName: this.userAchievement.AchievementName,
                     Occasion:this.userAchievement.Occasion,
                     DateReceived: E.ConvertToDate(this.userAchievement.DateReceived),
                     OccasionNotes: this.userAchievement.OccasionNotes,
                  });

                }

              }
              else {
                this._toastrService.Error(E.ConvertToString(data.message), Constant.ErrorToastrTitle);
              }
            },
            (error) => {
              this._toastrService.Error(error, Constant.ErrorToastrTitle);
              this.error = error;
              this.loadingScreenService.stopLoading();
            }
          );

      }, 500);
      //}
      this.userAchievement.AchievementTypeId=this.userAchievement.AchievementTypeId;
    }
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key.toLocaleLowerCase() === "escape") {
        this.onCloseButtonClick();
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.frmAchievement.invalid) {
      return;
    }
    this.submitted = false;
    if (this.userAchievement == null) {
      this.userAchievement = new TblUserAchievements();
    }
    this.userAchievement.AchievementName = this.frmAchievement.get('AchievementName').value;
    this.userAchievement.Occasion = this.frmAchievement.get('Occasion').value;
    this.userAchievement.DateReceived = E.GetDateStringOnly(this.frmAchievement.get('DateReceived').value);
    this.userAchievement.OccasionNotes = this.frmAchievement.get('OccasionNotes').value;
    this.userAchievement.UserDetailsId = this.authenticationService.currentUserValue.UserDetailsId;
    if(this._data<=4)
    this.userAchievement.AchievementTypeId=this._data;
    if (E.ConvertToNumber(this.userAchievement.UserAchievementId) == 0 && this.IsForEdit == false) {
      this.userAchievement.CreatedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userAchievement.CreatedDate = E.GetTodayDateStringOnly();
      this.userAchievement.CreatedTime = E.GetTodayTimeStringOnly();
      this.userAchievement.ChangedDate = E.GetTodayDateStringOnly();
      this.userAchievement.ChangedTime = E.GetTodayTimeStringOnly();
    }
    else {
      this.userAchievement.ChangedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userAchievement.ChangedDate = E.GetTodayDateStringOnly();
      this.userAchievement.ChangedTime = E.GetTodayTimeStringOnly();
    }
    this.loading = true;
    this.loadingScreenService.startLoading();
   // console.log(this.userAchievement)
    let response: Observable<any>;
    this.achievementService.CreateUpdateUserAchievement(this.userAchievement)
      .subscribe(
        (result) => {
          this.loading = false;
          this.loadingScreenService.stopLoading();
          var message = ""; var errormessage = "";
        //  console.log(result)
          if (result.status == Status.RecordAdded) {
            message = this.currentAppLanguage.data.MY_AWARDS_ACHIEVEMENT.TOAST_MESSAGE_ACHIEVEMENT_ADDED_SUCCESSFULLY;
          }
          else if (result.status == Status.RecordUpdated) {
            message = this.currentAppLanguage.data.MY_AWARDS_ACHIEVEMENT.TOAST_MESSAGE_ACHIEVEMENT_UPDATED_SUCCESSFULLY;
          }
          else {
            errormessage = E.ConvertToString(result.message);
          }
          if (message.length > 0) {
            this._toastrService.Success(message, Constant.SuccessToastrTitle);
            this.dialogRef.close();
            this.frmAchievement.reset();
            this.frmAchievement.patchValue({
              DateReceived: new Date()
            });
          }
          else {
            this._toastrService.Error(errormessage, Constant.ErrorToastrTitle);
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
          this.loading = false;
          this.loadingScreenService.stopLoading();
        }
      );

  }
get f(){
  return this.frmAchievement.controls;
}
  onCloseButtonClick() {
    this.dialogRef.close();
  }
  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }

}
