import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from 'app/auth/service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if ([401, 403].indexOf(err.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          
          //? Can also logout and reload if needed
          // if(this._authenticationService.currentUserValue.Token)
          // this._authenticationService.logout();
          setTimeout(() => {
            this._router.navigate(['/pages/authentication/login-v2']);
            setTimeout(() => {
              sessionStorage.clear();
              localStorage.clear();
            sessionStorage.removeItem("NoosomPlan");
            localStorage.removeItem('currentUser');
            window.location.reload();
          }, 1000);
        }, 1000);
          //this._router.navigate(['/pages/miscellaneous/not-authorized']);
          
          //location.reload(true);
        }
        // throwError
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
