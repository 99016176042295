<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown" (click)="getnotification()" [ngbTooltip]="'MENU.TOOLTIP_TEXT_NOTIFICATION' | translate"
    ><i class="ficon" data-feather="bell"></i
    ><span class="badge badge-pill badge-danger badge-up">{{
      notification?.length
    }}</span></a
  >
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto" style="font-size: 1.286rem;">Notifications</h4>
        <div class="badge badge-pill badge-light-primary" style="padding: .5em .6em .4em .6em; font-size: 100%">
          {{ notification?.length }} New
        </div>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <ng-container *ngIf="notification?.length; else noResultsFiles">
    <li class="scrollable-container media-list" [perfectScrollbar]>
      <span class="d-flex" *ngFor="let message of notification">
        <div class="media d-flex align-items-start">
          <div class="media-left">
              <div class="avatar bg-light-success">
                <div class="avatar-content"> {{ message.UserFamilyMemberFullName ? (message.UserFamilyMemberFullName |initials) : (message.UserFriendFullName |initials)}}</div>
              </div> 
          </div>
          <div class="media-body">
            <p class="media-heading"><strong>{{ message.UserFamilyMemberFullName ? message.UserFamilyMemberFullName : message.UserFriendFullName }}</strong></p>
            <small class="notification-text">{{ message.UserFamilyMemberFullName ? "You got a Connection request from your Family Member" : "You got a Connection request from your Friend" }}</small>
          </div>
          <div class="media-right" style="display: flex; gap: 10px;">
            <div class="avatar bg-light-success">
              <div class="avatar-content" (click)="callUpdateFunction(message,3)"><i class="avatar-icon" data-feather="check"></i></div>
            </div> 
            <div class="avatar bg-light-danger">
              <div class="avatar-content" (click)="callUpdateFunction(message,4)"><i class="avatar-icon" data-feather="x"></i></div>
            </div>
        </div>
        </div></span>
      <!-- <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">System Notifications</h6>
        <div class="custom-control custom-control-primary custom-switch">
          <input
            class="custom-control-input"
            id="systemNotification"
            type="checkbox"
            [checked]="notifications.system"
          />
          <label class="custom-control-label" for="systemNotification"></label>
        </div>
      </div> -->
      <!-- <a class="d-flex" href="javascript:void(0)" *ngFor="let systemMessage of notifications.systemMessages">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div
              class="avatar"
              [ngClass]="{
                'bg-light-danger': systemMessage.icon === 'x',
                'bg-light-success': systemMessage.icon === 'check',
                'bg-light-warning': systemMessage.icon === 'alert-triangle'
              }"
            >
              <div class="avatar-content"><i class="avatar-icon" [data-feather]="systemMessage.icon"></i></div>
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading" [innerHTML]="systemMessage.heading"></p>
            <small class="notification-text">{{ systemMessage.text }}</small>
          </div>
        </div></a> -->
      
    </li></ng-container>
    <ng-template #noResultsFiles>
      <li class="scrollable-container media-list" [perfectScrollbar]>
        <a class="media d-flex align-items-start">
          <div class="d-flex justify-content-start">
            <!-- <i data-feather="alert-circle" class="mr-25"></i> -->
            <span style="color:black">No results found.</span>
          </div></a
        >
      </li>
    </ng-template>
    <!--/ Notifications content -->

    <!-- Notifications footer -->
    <!-- <li class="dropdown-menu-footer">
      <a class="btn btn-primary btn-block" href="javascript:void(0)">Read all notifications</a>
    </li> -->
    <!--/ Notifications footer -->
  </ul>
</li>
