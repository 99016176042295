import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from 'app/Services/global.service';
import {TblUserVaccinations} from '../about-me-model.model'
@Injectable({
  providedIn: 'root'
})
export class VaccinationService {

  constructor(
    private _http: HttpClient,private globalService: GlobalService
  ) { }
  GetUserVaccinationLoadData(_data:number){
    const path='api/UserVaccination/GetUserVaccinationLoadData'
    return  this.globalService.PostDataById(_data, path);
   }
   CreateUpdateUserVaccination(userVaccination:TblUserVaccinations){
    const path='api/UserVaccination/CreateUpdateUserVaccination'
   return  this.globalService.PostDataById(userVaccination, path);
  }
}
