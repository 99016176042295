import { Status } from "app/enums";

export const locale = {
  lang: 'en',
  data: {
    COMMON:{
      THANKS_BADGE_SIGNUP:'Thanks for signing up!',
      TEXT_HEY:'Hey',
      TEXT_THANKS:'Thanks,',
      TEXT_NOOSOM_TEAM:'The Noosom Team',
      TEXT_READ_OUR:'Read our',
      LINK_TEXT_PRIVACY_POLICY:'Privacy Policy',
      LINK_TEXT_TERMS_CONDITIONS:'Terms & Conditions',
      LINK_TEXT_BACK_TO_LOGIN:'Back to login',
      PLACEHOLDER_EMAIL:'Type here...',
      PLACEHOLDER_TYPE_HERE:'Type here...',
      CONTROL_LABEL_EMAIL:'Email',
      CONTROL_LABEL_EMAIL_ADDRESS:'Email Address',
      CONTROL_LABEL_CONFIRM_PASSWORD:'Confirm Password',
      BUTTON_TEXT_SAVE_CHANGES:'Save changes',
      TOAST_MESSAGE_SUCCESSFUL_OTP_VERIFIED:'OTP verified successfully.',
      ERROR_MESSAGE_REQUIRED_EMAIL:'The Email Address is required!',
      ERROR_MESSAGE_REQUIRED_PASSWORD:'The password is required!',
      ERROR_MESSAGE_INVALID_EMAIL:'Please enter your email address in format:\nyourname@example.com',
      ERROR_MESSAGE_INVALID_PASSWORD:'Must contain at least one number, one uppercase and lowercase letter, and a minimum of 8 or more characters.',
      ERROR_MESSAGE_MISMATCHED_PASSWORD:'Confirm password must match.',
      ERROR_MESSAGE_REQUIRED_CONFIRMPASSWORD:'The confirm password is required!',
      ERROR_MESSAGE_CONNECTIONREFUSED:'Could not connect with server.',
      ERROR_MESSAGE_INVALID_OTP_DATA:'Enter valid OTP data.',
      ERROR_MESSAGE_INVALID_OTP:'Enter valid OTP.',
      COMMON_STATUS:
      [
        {ErrorCode:Status.InvalidOTP,ErrorMessage:'Invalid OTP.'},
        {ErrorCode:Status.UserNotFound,ErrorMessage:'User could not be found.'}
      ],
    },
    CALENDAR_MAIN:{
      BUTTON_TEXT_ADD_AN_EVENT: 'Add An Event',
      CONTROL_LABEL_ADD_A_QUICKPOST:'Add a Quick Post',
      CONTROL_LABEL_ADD_AN_EVENT:'Add an Event',
      CONTROL_LABEL_IMPORT_DATA:'Import Data',
      CONTROL_LABEL_FILTER:'Filter',
      CONTROL_LABEL_VIEW_ALL:'View All',
      CONTROL_LABEL_QUICKPOST:'Quick Post',
      CONTROL_LABEL_ADD_JOURNAL:'Add a Journal Entry',
      CONTROL_LABEL_ADD_DIARY:'Add a Diary Entry',
      CONTROL_LABEL_LIST_ALL:'List All',
      INFO_MESSAGE_COMING_SOON:'Coming Soon',
    },
    CHECKOUT:{
      BUTTON_TEXT_APPLY:'Apply',
      BUTTON_TEXT_CANCEL:'Cancel',
      BUTTON_TEXT_REMOVE:'Remove',
      BUTTON_TEXT_PROCEED_TO_BUY:'Proceed to Buy',
      ERROR_MESSAGE_PG_UNDER_MAINTAINANCE:'Payment Gateway is under maintenance, Try again after sometime.',
      ERROR_MESSAGE_NO_DISCOUNT_FOUND:'No Such Discount Code Found',
      HEADING_LABEL_CHECKOUT:'CHECKOUT',
      HEADING_LABEL_NOOSOM_PRIVATE:'NOOSOM PRIVATE LIMITED',
      HEADING_LABEL_STORY_OF_YOUR_LIFE:'The Story of Your Life',
      CONTROL_LABEL_CIN:'CIN',
      CONTROL_LABEL_GSTIN:'GSTIN',
      CONTROL_LABEL_DATE:'Date',
      CONTROL_LABEL_INVOICE_TO:'INVOICE TO:',
      CONTROL_LABEL_YOUR_NOOSOM_PLAN:'Your Noosom Plan',
      TABLE_HEADER_STORAGE_PLAN:'Storage Plan',
      TABLE_HEADER_COST:'Cost',
      TABLE_HEADER_STORAGE_IN_GB:'Storage in GB',
      TABLE_HEADER_DISCOUNT:'Discount %',
      TABLE_HEADER_DISCOUNT_AMOUNT:'Discount Amount',
      TABLE_HEADER_LINE_TOTAL:'Line Total'
    }
  }
};
