import { Component, ElementRef, Inject, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constant } from 'app/Classes/constant';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { AttachmentType, FileType, Status } from 'app/enums';
import { MyImageGalleryService } from 'app/my-image-gallery/my-image-gallery.service';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { Observable, Subject, Subscription } from 'rxjs';
import { E } from 'assets/extensions/extensions';
import { AuthenticationService } from 'app/auth/service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewGalleryInputModel } from 'app/Models/image-gallery-model.model';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'app/Services/global.service';

@Component({
  selector: 'app-select-file',
  templateUrl: './select-file.component.html',
  styleUrls: ['./select-file.component.scss']
})
export class SelectFileComponent implements OnInit {
  public id:number;
 
  public viewGalleryInputModel: ViewGalleryInputModel;
  public attachmentlist: any = [];
  loading:boolean=false;
  currentAppLanguage: any;
  public coreConfig: any;
  private _unsubscribeAll: Subject<any>;
  btnname: any = [];
  selectedFolderIds=[];
  constructor(private activatedRoute: ActivatedRoute,
    private EncrDecr: EncrDecrService,
    public filemanagerService:MyImageGalleryService,
    public authenticationService:AuthenticationService,
    private modalService: NgbModal,
    private loadingScreenService: LoadingScreenService,
    private _router: Router,
    private _toastrService: CustomToastrService,
    private dialogRef: MatDialogRef<SelectFileComponent>,
    private globalService:GlobalService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
      this.id=data;
     }

  ngOnInit(): void {
    if(this.id){
      this.GetUserFolderById(this.id)
     }

    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key.toLocaleLowerCase() === "escape") {
        this.onCancelClick();
      }
    });
  }

  onCancelClick() {
    this.dialogRef.close(false);
  }

  GetUserFolderById(id){
    // let response: Observable<any>;
    this.loadingScreenService.startLoading();
    this.filemanagerService.GetUserFilesByFolderId(id).subscribe(async (result) => {
          if (result.status == Status.Success) {
            let data = JSON.parse((this.EncrDecr.get(Constant.EncrDecrKey, result.data)));
            let _attachmentlist=data.Data
            // console.log(data)
            this.loadingScreenService.stopLoading();
            if (_attachmentlist) {
              this.attachmentlist=_attachmentlist
             // console.log(this.attachmentlist)
          }
          }
          this.loadingScreenService.stopLoading();
        },
      );
  }

  toggleSelectAll(): void {
    if (this.selectedFolderIds.length === this.attachmentlist.length) {
      // If all folders are already selected, unselect all
      this.selectedFolderIds = [];
    } else {
      // If not all folders are selected, select all
      this.selectedFolderIds = this.attachmentlist.map(folder => folder.DocumentPath);
    }
  
    // Log selectedFolderIds for debugging
  
    // Update checkboxes visually
    const checkboxes = document.querySelectorAll('.folder-checkbox') as NodeListOf<HTMLInputElement>;
    checkboxes.forEach(checkbox => {
      checkbox.checked = this.selectedFolderIds.includes(Number(checkbox.value));
    });
   // console.log(this.selectedFolderIds)
  }
  confirmSelection() {
   // console.log(this.selectedFolderIds)
    this.globalService.addUrls(this.selectedFolderIds);
    this.dialogRef.close('select folder');
  }

  toggleCheckbox(checkbox: HTMLInputElement, documentPath: string): void {
  this.selectedFolderIds.push(documentPath);
 // console.log(this.selectedFolderIds)
  // //  checkbox.checked = !checkbox.checked; // Toggle the checkbox state
  //  // this.onCheckboxChange(checkbox.checked, documentPath); // Call the existing handler function
}

  onCheckboxChange(checked: boolean, folderId): void {
    if (checked) {
      // Add folderId to selectedFolderIds if checkbox is checked
      this.selectedFolderIds.push(folderId);
    } else {
      // Remove folderId from selectedFolderIds if checkbox is unchecked
      const index = this.selectedFolderIds.indexOf(folderId);
      if (index !== -1) {
        this.selectedFolderIds.splice(index, 1);
      }
    }
   // console.log(this.selectedFolderIds)
  }
  
  isFolderSelected(folderId): boolean {
   //console.log(folderId)
    return this.selectedFolderIds.includes(folderId);
  }

  ngOnDestroy() {
   
  }

}

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number): string {
    if (value) {
      value = value.replace(/<br\s*\/?>/gi, ' '); // replace <br> with space
      value = value.trim().replace(/\s+/g, ' '); // remove extra whitespace
      if (value.length > limit) {
        value = value.substring(0, limit) + '...';
      }
    }
    return value;
  }
}
