<!-- <p>my-medication works!</p> -->
<div class="card" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h5 cdkDragHandle class="card-header" style="justify-content: unset;">{{dialogTitle}}&nbsp;<span ngbTooltip={{infoTooltip}}>
    <span data-feather="info"></span>
  </span><button class="close-button close" type="button"
      (click)="onCloseButtonClick()" ngbTooltip="{{'COMMON.TOOLTIP_TEXT_BUTTON_CLOSE'|translate}}" placement="right">&times;</button>
  </h5>
  <div class="card-body card-body-dialog" *ngIf="(jobLoadDataOutputModel$ | async) as jobload">
    <!-- <div class="row py-3 pl-3">
        <div class="col-sm">
          <i>{{'MY_JOBS.SECTION_TEXT_JOBS'|translate}}</i>
        </div>
      </div> -->
    <form id="frmJobs" [formGroup]="frmJobs" (ngSubmit)="onSubmit()">
      <!-- <form> -->
      <div class="row scroll-area">
        <div class="col-sm-12 form-group mb-0">
          <app-text-box [type]="'text'" #Employer [label]="'MY_JOBS.CONTROL_LABEL_EMPLOYER'| translate" formControlName="Employer" [maxlength]="250"
            [showRequiredSign]="true" [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="" [showInfoIcon]="true"
            [tooltipInfo]="'MY_JOBS.TOOLTIP_TEXT_EMPLOYER_NAME'|translate" [position]="'right'"
            [classes]="{ 'is-invalid': submitted && f.Employer.errors }">
          </app-text-box>
          <span *ngIf="submitted && f.Employer.invalid">
            <small class="text-danger" *ngIf="f.Employer.errors.required">{{'ABOUTME.ERROR_MESSAGE_REQUIRED_EMPLOYER' |
              translate}}</small>
              <small class="text-danger" *ngIf="f.Employer.errors.maxlength">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_250' |
                translate}}</small>
          </span>
        </div>
        <div class="col-sm-12 form-group mb-0">
          <app-text-box [type]="'text'" [label]="'MY_JOBS.CONTROL_LABEL_JOB_DESCRIPTION'| translate"
            formControlName="JobDescription" [showRequiredSign]="true" [showInfoIcon]="true" [maxlength]="250"
            [tooltipInfo]="'MY_JOBS.TOOLTIP_TEXT_JOB_ROLE'|translate" [position]="'right'"
            [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby=""
            [classes]="{ 'is-invalid': submitted && f.JobDescription.errors }">
          </app-text-box>
          <span *ngIf="submitted && f.JobDescription.invalid">
            <small class="text-danger"
              *ngIf="f.JobDescription.errors.required">{{'ABOUTME.ERROR_MESSAGE_REQUIRED_JOBDESCRIPTION' |
              translate}}</small>
              <small class="text-danger" *ngIf="f.JobDescription.errors.maxlength">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_250' |
                translate}}</small>
          </span>
        </div>
        <div class="col-sm-12">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" name="CurrentAddressYn"
              formControlName="CurrentJobYn" value="checked" checked />
            <label class="form-check-label"
              for="inlineCheckbox1">{{'MY_JOBS.CONTROL_LABEL_CURRENT_JOBS'|translate}}
              <span ngbTooltip="{{'MY_JOBS.TOOLTIP_TEXT_CURRENT_JOB'|translate}}" placement="right">
                <span data-feather="help-circle"
                  class="info-icon align-middle mr-sm-25 mr-0"></span></span>
            </label>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label" for="Datefrom">{{'MY_JOBS.CONTROL_LABEL_DATE_FROM' | translate}}
              <span ngbTooltip="{{'MY_JOBS.TOOLTIP_TEXT_JOB_START_DATE'|translate}}" placement="right">
                <span data-feather="help-circle"
                  class="info-icon align-middle mr-sm-25 mr-0"></span></span>
              <strong> *</strong></label>
            <div class="input-group border-color">
              <input id="Datefrom" name="Datefrom" formControlName="JobFromDate" [maxDate]="maxFromDate" [placeholder]="dateformat"
                [bsConfig]="{ showWeeks: false,containerClass:'theme-blue',dateInputFormat:dateformat}" required
                bsDatepicker #dpLast="bsDatepicker" class="form-control">
              <div class="input-group-append">
                <button class="btn btn-outline-primary feather icon-calendar" (click)="dpLast.toggle()" type="button"
                  rippleEffect placement="top"
                  ngbTooltip="{{'ABOUTME.TOOLTIP_TEXT_BUTTON_CALENDAR' | translate}}"></button>
              </div>
            </div>
            <span *ngIf="submitted && f.JobFromDate.errors">
                        <small *ngIf="f.JobFromDate.errors.required"
                         class="text-danger">{{'MY_JOBS.ERROR_MESSAGE_JOB_FROM_DATE_REQUIRED'| translate}}</small>
                      </span>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group" style="padding-top:3px;">
            <label class="form-label" for="Dateto">{{'MY_JOBS.CONTROL_LABEL_DATE_TO' |translate}}
              <span ngbTooltip="{{'MY_JOBS.TOOLTIP_TEXT_JOB_END_DATE'|translate}}" placement="right">
                <span data-feather="help-circle"
                  class="info-icon align-middle mr-sm-25 mr-0"></span></span></label>
            <div class="input-group">
              <input id="Dateto" name="Dateto" formControlName="JobToDate" (ngModelChange)="OnDateToChange($event)"
                [bsConfig]="{ showWeeks: false,containerClass:'theme-blue',dateInputFormat:dateformat}"  [placeholder]="dateformat"
                bsDatepicker #dpNext="bsDatepicker" class="form-control">
              <div class="input-group-append">
                <button class="btn btn-outline-primary feather icon-calendar" (click)="dpNext.toggle()" type="button"
                  rippleEffect placement="top"
                  ngbTooltip="{{'ABOUTME.TOOLTIP_TEXT_BUTTON_CALENDAR' | translate}}"></button>
              </div>
            </div>
            <span *ngIf="submitted && f.JobToDate.errors">
                        <small *ngIf="f.JobToDate.errors.required"
                         class="text-danger">{{'MY_JOBS.ERROR_MESSAGE_DATE_TO_REQUIRED'| translate}}</small>
                      </span>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12 form-group mb-0">
          <app-text-box [type]="'text'" [label]="'MY_JOBS.CONTROL_LABEL_ADDRESS1'| translate" formControlName="Address1"
            [showRequiredSign]="true" [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" [showInfoIcon]="true"
            [tooltipInfo]="'MY_JOBS.TOOLTIP_TEXT_STREET_NAME'|translate" [position]="'right'" [maxlength]="50"
            aria-describedby="Address1" [classes]="{ 'is-invalid': submitted && f.Address1.errors }">
          </app-text-box>
          <span *ngIf="submitted && f.Address1.invalid">
            <small class="text-danger" *ngIf="f.Address1.errors.required">{{'ABOUTME.ERROR_MESSAGE_REQUIRED_STREET' |
              translate}}</small>
              <small class="text-danger" *ngIf="f.Address1.errors.maxlength">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_50' |
                translate}}</small>
          </span>
        </div>
        <div class="col-lg-4 col-sm-12 form-group mb-0">
          <app-text-box [type]="'text'" [label]="'MY_JOBS.CONTROL_LABEL_ADDRESS2'| translate" formControlName="Address2" [showInfoIcon]="true"
          [tooltipInfo]="'MY_JOBS.TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_1'|translate" [position]="'right'" [maxlength]="50"
            [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="Address2">
          </app-text-box>
          <span *ngIf="submitted && f.Address2.invalid">
            <!-- <small class="text-danger" *ngIf="f.Address1.errors.required">{{'ABOUTME.ERROR_MESSAGE_REQUIRED_STREET' |
              translate}}</small> -->
              <small class="text-danger" *ngIf="f.Address2.errors.maxlength">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_50' |
                translate}}</small>
          </span>
        </div>
        <div class="col-lg-4 col-sm-12 form-group mb-0">
          <app-text-box [type]="'text'" [label]="'MY_JOBS.CONTROL_LABEL_ADDRESS3'| translate" formControlName="Address3" [showInfoIcon]="true"
          [tooltipInfo]="'MY_JOBS.TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_2'|translate" [position]="'right'" [maxlength]="50"
            [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="Address3">
          </app-text-box>
          <span *ngIf="submitted && f.Address3.invalid">
              <small class="text-danger" *ngIf="f.Address3.errors.maxlength">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_50' |
                translate}}</small>
          </span>
        </div>
        <div class="col-lg-4 col-sm-12 form-group mb-0">
          <app-text-box [type]="'text'" [label]="'MY_JOBS.CONTROL_LABEL_ADDRESS_TOWN'| translate"
            formControlName="AddressTown" [showRequiredSign]="true" [showInfoIcon]="true" [maxlength]="50"
            [tooltipInfo]="'MY_JOBS.TOOLTIP_TEXT_TOWN_VILLAGE_CITY'|translate" [position]="'right'"
            [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby=""
            [classes]="{ 'is-invalid': submitted && f.AddressTown.errors }">
          </app-text-box>
          <span *ngIf="submitted && f.AddressTown.invalid">
            <small class="text-danger"
              *ngIf="f.AddressTown.errors.required">{{'ABOUTME.ERROR_MESSAGE_REQUIRED_ADDRESSTOWN' |
              translate}}</small>
              <small class="text-danger" *ngIf="f.AddressTown.errors.maxlength">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_50' |
                translate}}</small>
          </span>
        </div>
        <div class="col-lg-4 col-sm-7 form-group mb-0">
          <app-text-box [type]="'text'" [label]="'MY_JOBS.CONTROL_LABEL_ADDRESS_POSTALCODE'| translate"
            formControlName="AddressPostCode" [showRequiredSign]="true" [showInfoIcon]="true" [maxlength]="50"
            [tooltipInfo]="'MY_JOBS.TOOLTIP_TEXT_POSTAL_ZIPCODE'|translate" [position]="'right'"
            [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby=""
            [classes]="{ 'is-invalid': submitted && f.AddressPostCode.errors }">
          </app-text-box>
          <span *ngIf="submitted && f.AddressPostCode.invalid">
            <small class="text-danger"
              *ngIf="f.AddressPostCode.errors.required">{{'ABOUTME.ERROR_MESSAGE_REQUIRED_ADDRESSPOSTCODE' |
              translate}}</small>
              <small class="text-danger" *ngIf="f.AddressPostCode.errors.maxlength">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_50' |
                translate}}</small>
          </span>
        </div>
        <div class="col-sm py-4" style="top:9px;">
          <button type="button" class="btn btn-primary" style="width:100%" [disabled]="loading" placement="top"
            (click)="onSearchOnMapClick()"
            ngbTooltip="{{'MY_JOBS.BUTTON_TEXT_SEARCH_ON_MAP' | translate}}"><span></span><i data-feather="search"
              class="mr-0 mr-sm-1"></i>
            {{'MY_JOBS.BUTTON_TEXT_SEARCH_ON_MAP'|translate}}</button>
        </div>
        <div class="col-lg-4 col-sm-12">
          <label class="form-label" for="StateId">{{'MY_JOBS.CONTROL_LABEL_ADDRESS_STATE' | translate}}
            <span ngbTooltip="{{'MY_JOBS.TOOLTIP_TEXT_STATE_PROVINCE'|translate}}" placement="right">
              <span data-feather="help-circle"
                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
            <strong> *</strong>
          </label>
          <ng-select bindLabel="StateName" [items]="jobload?.StateList" bindValue="StateId" selectOnTab="true"
            id="StateId" name="StateId" placeholder="{{'MY_JOBS.PLACEHOLDER_SELECT_STATE'|translate}}"
            formControlName="StateId" [required]="true" [placeholder]="PlaceholderState"
            (ngModelChange)="onStateChange($event)"></ng-select>
          <span *ngIf="submitted && f.StateId.invalid">
            <small class="text-danger"
              *ngIf="f.StateId.errors.required">{{'ABOUTME.ERROR_MESSAGE_REQUIRED_ADDRESS_STATE'|translate}}</small>
          </span>
        </div>
        <div class="col-lg-4 col-sm-12 mb-2">
          <label class="form-label" for="CountryId">{{'MY_JOBS.CONTROL_LABEL_COUNTRY' | translate}}
            <span ngbTooltip="{{'MY_JOBS.TOOLTIP_TEXT_COUNTRY'|translate}}" placement="right">
              <span data-feather="help-circle"
                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
            <strong> *</strong>
          </label>
          <ng-select bindLabel="CountryName" [items]="jobload?.CountryList" bindValue="CountryId" selectOnTab="true"
            id="CountryId" name="CountryId" placeholder="{{'MY_JOBS.PLACEHOLDER_SELECT_COUNTRY'|translate}}"
            formControlName="CountryId" [required]="true" [placeholder]="PlaceholderCountry"
            ></ng-select>
          <span *ngIf="submitted && f.CountryId.invalid">
            <small class="text-danger"
              *ngIf="f.CountryId.errors.required">{{'ABOUTME.ERROR_MESSAGE_REQUIRED_ADDRESS_COUNTRY'|translate}}</small>
          </span>
        </div>
        <div class="col-lg-4 col-sm-12 mb-2">
          <label class="form-label" for="RegionId">{{'MY_JOBS.CONTROL_LABEL_REGION' |translate}}
            <span ngbTooltip="{{'MY_JOBS.TOOLTIP_TEXT_REGION_CONTINENT'|translate}}" placement="right">
              <span data-feather="help-circle"
                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
            <strong> *</strong>
          </label>
          <ng-select bindLabel="RegionName" [items]="jobload?.RegionList" bindValue="RegionId" selectOnTab="true"
            id="RegionId" name="RegionId" formControlName="RegionId" [required]="true" [placeholder]="PlaceholderRegion"
           ></ng-select>
          <span *ngIf="submitted && f.RegionId.invalid">
            <small class="text-danger"
              *ngIf="f.RegionId.errors.required">{{'ABOUTME.ERROR_MESSAGE_REQUIRED_ADDRESS_REGION'|translate}}</small>
          </span>
        </div>
        <div class="col-sm-12 pt-2">
          <label class="form-label" for="Notes">{{'MY_VACCINATION_FORM.CONTROL_LABEL_NOTES' | translate}}
            <span ngbTooltip="{{'MY_JOBS.TOOLTIP_TEXT_JOB_NOTES'|translate}}" placement="right">
              <span data-feather="help-circle"
                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
          </label>
          <fieldset>
            <textarea class="col-sm-12 form-control" rows="4" formControlName="Notes"
              placeholder="{{'COMMON.PLACEHOLDER_TYPE_HERE'|translate}}"></textarea>
          </fieldset>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="card-footer">
  <div class="align">
    <button type="submit" *ngIf="!IsForEdit" class="btn btn-primary" [disabled]="loading" placement="top"
      (click)="onSubmit()"
      ngbTooltip="{{'MY_JOBS.BUTTON_TEXT_ADD_A_NEW_JOBS' |translate}}">{{'MY_JOBS.BUTTON_TEXT_ADD_A_NEW_JOBS'
      |translate}}</button>

    <button type="submit" *ngIf="IsForEdit" class="btn btn-primary" [disabled]="loading" placement="top"
      ngbTooltip="{{'COMMON.BUTTON_TEXT_SAVE_CHANGES' | translate}}" (click)="onSubmit()"><span *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"></span>{{'COMMON.BUTTON_TEXT_SAVE_CHANGES'|translate}}</button>

    <button type="button" class="btn btn-outline-secondary ml-2" placement="top" (click)="onCloseButtonClick()"
      ngbTooltip="{{'MY_MEDICATION_FORM.BUTTON_TEXT_CANCEL'|translate}}">{{'MY_MEDICATION_FORM.BUTTON_TEXT_CANCEL'|translate}}</button>
  </div>
</div>