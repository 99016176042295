import { Injectable } from '@angular/core';
import { Constant } from 'app/Classes/constant';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import * as moment from 'moment';

@Injectable({
providedIn: 'root'
})
export class TimezoneService {
private selectedTimeZoneOffset: number = null; // Default to UTC

constructor(private EncrDecr: EncrDecrService) {
moment.updateLocale(moment.locale(), {
meridiem: null, // Remove meridiem (AM/PM) information
});
}

getCurrentTime(): moment.Moment {
// Get the current time in the set timezone offset
return moment.utc().add(this.selectedTimeZoneOffset, 'minutes');
}

 GetCurrentLocaleDateTimedefault(): Date {
  let value; let result;
  value = new Date().toLocaleString("en-GB");
  let _momentvar = moment(value, 'DD/MM/YYYY, HH:mm:ss');
  let _moment = _momentvar.format('YYYY-MM-DDTHH:mm:ss.sssZ');
  result = new Date(_moment);
  return result;
}

// if (this.timeFormat) {
//   return this.timeFormat;
// } else {
//   const storedFormat = localStorage.getItem('timeformat');
//   if (storedFormat) {
//     this.timeFormat = this.EncrDecr.get(Constant.EncrDecrKey, storedFormat);
//   } else {
//     this.timeFormat = 'HH:mm';
//   }
//   return this.timeFormat;
// }

getFormattedCurrentTime(): string {
// Return the current time formatted as a string
if(this.selectedTimeZoneOffset !==null){
return this.getCurrentTime().format('YYYY-MM-DD HH:mm:ss');
}
else{
  const storedFormat = localStorage.getItem('timezonevalue');
  if(storedFormat){
  this.selectedTimeZoneOffset =  Number(this.EncrDecr.get(Constant.EncrDecrKey, storedFormat))
  return this.getCurrentTime().format('YYYY-MM-DD HH:mm:ss');
}
else{
  return this.GetCurrentLocaleDateTimedefault().toUTCString()
}
 }
}

 GetCurrentLocaleDateTime(): Date {
  let moment = new Date(this.getFormattedCurrentTime())
  return moment
}

getTimezone(): string {
// This returns the offset as a string, like "UTC+02:00"
const offsetHours = Math.floor(this.selectedTimeZoneOffset / 60);
const offsetMinutes = Math.abs(this.selectedTimeZoneOffset % 60);
return `UTC${this.selectedTimeZoneOffset >= 0 ? '+' : ''}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;
}

setTimezone(displayName: string): void {
if (displayName) {
const offset = this.extractOffset(displayName);
if (offset !== null) {
  const timezonevalue = this.EncrDecr.set(Constant.EncrDecrKey, JSON.stringify(offset))
  localStorage.setItem('timezonevalue',timezonevalue)
this.selectedTimeZoneOffset = offset;
} else {
console.error(`Invalid timezone display name: ${displayName}`);
}
} else {
console.error('Invalid timezone data provided');
}
}

private extractOffset(displayName: string): number | null {
// Pattern to extract UTC offset from the display name
const offsetPattern = /UTC([+-])(\d{2}):?(\d{2})/;
const match = displayName.match(offsetPattern);
if (match) {
const sign = match[1] === '+' ? 1 : -1;
const hours = parseInt(match[2], 10);
const minutes = parseInt(match[3], 10);
return sign * (hours * 60 + minutes);
}
return null;
}
}
