import { UserSettingsModel } from "app/auth/models/user";
import { CountryListOutputModel } from "app/Models/country-model.model";
import { TblErrorLogs } from "app/Models/error-log-model.model";
import { RegionListOutputModel } from "app/Models/region-model.model";

export class TblUsers {
    UserId: number;
    UserName: string;
    EmailAddress: string;
    FullName: string;
    Password: string;
    PasswordQuestion: string;
    Otp: string;
    OtpsaveDate: Date;
    OtpsaveTime: Date;
    OtprememberMeYn: boolean;
    OtprememberDays: boolean;
    LastLoginDate: Date;
    LastActivityDate: Date;
    LastPasswordChangedDate: Date;
    LastLockedOutDate: Date;
    ApprovedYn: boolean;
    InActiveYn: boolean
    LoggedInYn: boolean;
    LogOutYn: boolean;
    LockedOutYn: boolean;
    MustChangePasswordYn: boolean
    SendSmsyn: boolean;
    SessionTimeoutYn: boolean;
    SessionTimeOut: number;
    TimelineTabsInfo: string;
    SessionId: string;
    CreatedDate: Date;
    CreatedTime: Date;
    CreatedBy: number;
    ChangedDate: Date;
    ChangedTime: Date;
    ChangedBy: number;
}
export class SignupInputModel {
    UserName: string;
    EmailAddress: string;
    Password: string;
    CountryId: number;
   // MobileCountryId: number;
    ContactNumberCountryCode: string;
    //PlanId: number;
    //RegionId: number;
    FirstName: string;
    //MiddleName: string;
    LastName: string;
    DateofBirth: string;
   // MobileNumber: string;
    NoosomWebUrl: string;
    CreatedDate: Date;
    CreatedTime: Date;
    CreatedBy: number;
    ChangedDate: Date;
    ChangedTime: Date;
    ChangedBy: number;
}

export class SigninInputModel {
    UserName: string;
    Password: string;
    ErrorLog: TblErrorLogs;
    NotificationToken?: string;
}

export class SignupLoadDataOutputModel {
    CountryList: Array<CountryListOutputModel>
}

export class SignupOutputModel {
    UserId: number;
    UserDetailsId: number;
    FirstName: string
    LastName: string;
    Email: string;
}

export class ForgotPasswordInputModel {
    Email: string;
}

export class ResetPasswordInputModel {
    NewPassword: string;
    UserId: number;
    Email: string;
}
export class SignOutInputModel {
    UserId: number;
    UserSettings: UserSettingsModel;
    UserDetailId:number;
    NotificationToken:string;
}
export class ValidateMobileNumberInputModel {
    MobileNumber: string;
    ContactNumberCountryCode : string;
}